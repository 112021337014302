import React from "react";

import {
    Button,
    SimpleGrid,
    Stack,
    CardBody,
    Text,
    Image,
    Card,
    VStack, Avatar, Spacer, Center,
} from '@chakra-ui/react';

import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../src/components/Loader/Loader";

import DefaultSparePart from "../../assets/img/imageSparePart.svg";
import CartPlus from "../../assets/img/cartPlus.svg";
import {useNavigate} from "react-router-dom";
import sparePart from "../../assets/img/imageSparePart.svg";
import EmptyList from "../../assets/img/empty.svg";

const ListRequest = (props) => {
    const navigate = useNavigate();
    const columnName = {
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "qty" : "Stock",
        "qty_inrequest" : "In Request",
    }

    return (
        <>
            {(props.dataSpareParts)?.length === 0 ?
                <Center w={"full"} h={"90vh"}>
                    <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                    <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                        No items in here..
                    </Text>
                </Center>
                :
                <InfiniteScroll dataLength={props.dataSpareParts.length} next={() => props.setFetchMoreData(true)} hasMore={props.hasMoreCard} loader={Loader()}>
                    <SimpleGrid py={"5vh"} px={"2%"} columns={2} spacingX='40px' spacingY='20px'>
                        {props.dataSpareParts.map((dataSparePart) => (
                            <Card
                                key={dataSparePart.id + 1000}
                                bg={"white"}
                                borderRadius={"xl"}
                                direction={{ base: 'column', sm: 'row' }}
                                overflow='hidden'
                                variant='outline'
                                position={"initial"}
                            >
                                <VStack m={10} spacing='3' align={"center"}>
                                    <Spacer/>

                                    <Avatar cursor={"pointer"} onClick={() => props.setRefreshImage("onClick")} size={"2xl"} src={dataSparePart["image_bin"]} icon={<Image src={sparePart} />} marginBottom={"2%"}/>

                                    <Button
                                        variant='solid'
                                        colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                                        position={"initial"}
                                        onClick={() => {
                                            if ((props.dataBinStorage).some(binPartCode => binPartCode === dataSparePart?.part_code)) {
                                                props.setErrorMessage("Part Code: " + dataSparePart?.part_code + " needs to be put on bin first, please contact admin!");
                                                props.onOpenErrorModal();
                                            }
                                            else {
                                                navigate({
                                                    pathname: "/request/requestNow",
                                                    search: "?id=" + dataSparePart?.id,
                                                });
                                            }
                                        }}
                                    >
                                        Request Now!
                                    </Button>
                                    <Button
                                        variant='solid'
                                        colorScheme='orange'
                                        position={"initial"}
                                        onClick={() => {
                                            if ((props.dataBinStorage).some(binPartCode => binPartCode === dataSparePart?.part_code)) {
                                                props.setErrorMessage("Part Code: " + dataSparePart?.part_code + " needs to be put on bin first, please contact admin!");
                                                props.onOpenErrorModal();
                                            }
                                            else {
                                                let tempModalData = {};
                                                Object.keys(columnName).map((elem) => tempModalData[elem] = dataSparePart[elem]);
                                                tempModalData["id"] = dataSparePart["id"];
                                                tempModalData["qty_request"] = 1;
                                                props.setModalData(tempModalData);
                                                props.onOpenSparePartPopUp();
                                            }
                                        }}
                                        rightIcon={<Image src={CartPlus} alt={"cart"}  />}
                                    >
                                        Add to cart
                                    </Button>

                                    <Spacer/>
                                </VStack>

                                <Stack paddingLeft={4}>
                                    <CardBody w={"full"}>
                                        {Object.keys(columnName).map((elem, index) => (
                                            <Stack key={dataSparePart.id + index} spacing={0}>
                                                <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} pt={2}>
                                                    {columnName[elem]}
                                                </Text>
                                                <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} pb={2}>
                                                    {dataSparePart[elem]}
                                                </Text>
                                            </Stack>
                                        ))}
                                    </CardBody>
                                </Stack>
                            </Card>
                        ))}
                    </SimpleGrid>
                </InfiniteScroll>
            }
        </>
    );
}

export default ListRequest;