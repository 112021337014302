import React, {useEffect, useState, useRef} from 'react';

import {
    Button,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Container,
    ModalHeader,
    Box,
    Center, Input,
} from '@chakra-ui/react'

import QrReader from 'react-qr-scanner'
import Axios from "../../services/api/Axios";
import Cookies from "js-cookie";

const SparePartScanner = (props) => {
    const [data, setData] = useState({});
    const [dataInitialization,setDataInitialization] = useState(false);
    const [result, setResult] = useState('');
    const [isScanning, setIsScanning] = useState(false);
    const [serving, setServing] = useState('');
    const [message, setMessage] = useState({
        "error": '',
        "success": ''
    });

    const initialRef = useRef()
    const zeroPad = (num, places) => String(num).padStart(places, '0');

    const history = {
        dataSparePartQR: "/sparepart/item?id=",
        dataServingURL: "/sediaan/item?id=",
        accessToken : Cookies.get("accessToken"),
    }

    const handleGetServing = async () => {
        try{
            let getServing = await Axios.get(history.dataServingURL + Cookies.get("idServing"),
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            setServing(getServing?.data?.data?.sediaan?.huruf);
        }
        catch (error){

        }
    }

    function handleScanned(value){
        console.log(value);
        setResult(value);
        setIsScanning(false);
    }

    function handleConfirm(){
        let tempMessage = JSON.parse(JSON.stringify(message));
        let id = Number((props.modalData.part_code).substring(1));

        if (props.scanType === "bin") {
            let binLocQR = serving + "-" + (props.modalData.part_code)[0] + zeroPad(id, 5) + "-" + props.modalData.bin_location;

            if (binLocQR === result) {
                tempMessage["error"] = '';
                tempMessage["success"] = "QR Bin Location match!";

                if (props.type === "Outgoing") {
                    if (!props.isMulti) {
                        const dataQR = props.modalData?.meta?.labelQR;

                        if (dataQR && dataQR.length > 0) props.setQuantityToScan(props.requestData?.qty_requestChange ? props.requestData?.qty_requestChange : props.requestData?.qty_request);

                        props.setConfirmAction("Bin Storage");
                    }
                    else {
                        let tempPromisesModal = Array.from(props.promisesModal);
                        let tempCountPromisesModal = JSON.parse(JSON.stringify(props.countPromisesModal));

                        tempPromisesModal.shift();

                        if (tempPromisesModal.length) {
                            tempCountPromisesModal["now"] += 1;
                            tempCountPromisesModal["current"] = (tempPromisesModal.sort())[0].part_name
                        }
                        else {
                            tempCountPromisesModal["now"] = 0;
                            tempCountPromisesModal["total"] = 0;
                            tempCountPromisesModal["current"] = ''
                        }

                        props.setPromisesModal(tempPromisesModal);
                        props.setCountPromisesModal(tempCountPromisesModal);
                    }
                }
                else props.setConfirmAction("Bin Storage");

            }
            else tempMessage["error"] = "QR Bin Location didn't match! (Correct QR Code: " + binLocQR + ')'
        }
        else tempMessage = handleManipulateLabelQR(props.modalData);

        setMessage(tempMessage);

        if (!tempMessage["error"]) props.onClose(true);
    }

    const handleManipulateLabelQR = (dataSparePart) => {
        const dataQR = dataSparePart?.meta?.labelQR;

        let codeQR = '';
        let message = {
            "error": '',
            "success": '',
        };

        if (props.type === "Bin Storage") {
            const binLabelQR = JSON.parse(JSON.stringify(props.promisesModal));

            if (Object.keys(binLabelQR).length !== 0) {
                let labelQR = (Object.keys(binLabelQR).sort())[0];

                if (labelQR === result) {
                    message["error"] = '';
                    message["success"] = "QR Label ID matched!";
                    let tempCountPromisesModal = JSON.parse(JSON.stringify(props.countPromisesModal));

                    if (binLabelQR[labelQR] > 1) {
                        tempCountPromisesModal["now"] += 1;
                        binLabelQR[labelQR] -= 1;
                    }
                    else {
                        tempCountPromisesModal["now"] = 1;

                        delete binLabelQR[labelQR]

                        tempCountPromisesModal["total"] = binLabelQR[(Object.keys(binLabelQR).sort())[0]];
                        tempCountPromisesModal["current"] = (Object.keys(binLabelQR).sort())[0];
                    }

                    if (!Object.keys(binLabelQR).length) {
                        let tempModalData = JSON.parse(JSON.stringify(dataSparePart));
                        tempModalData["meta"]["binQR"] = {};

                        Axios.post(history.dataSparePartQR + dataSparePart.id,
                            {meta: {...dataSparePart?.meta, "binQR": {}}},
                            {
                                headers: {'Authorization': 'Bearer ' + history.accessToken},
                            }).then((response) => console.log(response));

                        props.setModalData(tempModalData);
                        props.setConfirmAction("preQR");
                    }

                    props.setCountPromisesModal(tempCountPromisesModal);
                    props.setPromisesModal(binLabelQR);
                    props.onClose(true);
                }
                else message["error"] = "QR Label ID didn't match! (Correct QR Code: " + labelQR + ')'
            }
        }
        else if (props.type === "Restore") {
            const pattern = /^\d{2}[-]\d{2}[-][A-Z]{1}\d{5}[-]\d{2}$/g;

            if (result.match(pattern)){
                let tempArray = Array.from(JSON.parse(JSON.stringify(dataQR)));
                const dataLabel = (result.split('-'));

                if (Number(dataLabel[1]) === dataSparePart?.sediaan_id && dataLabel[2] === dataSparePart?.part_code){
                    const sameSequenceIndex = dataQR.findIndex(data => (data.year === Number("20" + dataLabel[0]) && data.sequence === Number(dataLabel[3])));

                    if (sameSequenceIndex !== -1){
                        tempArray[sameSequenceIndex]["quantity"] += 1
                    }
                    else tempArray.push({
                        "year": Number("20" + dataLabel[0]),
                        "quantity": 1,
                        "sequence": Number(dataLabel[3])
                    })

                    let tempCountPromisesModal = JSON.parse(JSON.stringify(props.countPromisesModal));
                    tempCountPromisesModal["now"] += 1;

                    props.setCountPromisesModal(tempCountPromisesModal);
                    props.setModalData({...dataSparePart, "meta": {...dataSparePart["meta"], "labelQR": tempArray}});
                    props.setQuantityToScan(props.quantityToScan - 1);
                }
                else message["error"] = "QR Label Spare Part ID is incorrect!"
            }
            else if (result === "forceScan") {
                let tempCountPromisesModal = JSON.parse(JSON.stringify(props.countPromisesModal));
                tempCountPromisesModal["now"] += 1;

                props.setCountPromisesModal(tempCountPromisesModal);
                props.setQuantityToScan(props.quantityToScan - 1);
            }
            else {
                message["error"] = "QR Label ID pattern is incorrect! Type: 'forceScan' to proceed without label"
            }
        }
        else {
            let tempArray = Array.from(JSON.parse(JSON.stringify(dataQR)));
            let labelQuantityToDecrease = props.quantityToScan;

            if (dataQR.length > 0 && result !== "forceScan"){
                const getFirstOutYear = Math.min(...dataQR.map(x => {return x.year}));
                const getFirstOutSequence = Math.min(...dataQR.filter(data => data.year === getFirstOutYear).map((data) => {
                    return data.sequence
                }));
                const getFirstOutQuantity = dataQR.find((label) => (label.year === getFirstOutYear && label.sequence === getFirstOutSequence)).quantity;

                codeQR = zeroPad(getFirstOutYear % 100, 2) + '-' + zeroPad(dataSparePart.sediaan_id, 2) + '-' + dataSparePart.part_code + '-' + zeroPad(getFirstOutSequence, 2);

                if (codeQR === result) {
                    let tempCountPromisesModal = JSON.parse(JSON.stringify(props.countPromisesModal));

                    if (getFirstOutQuantity > 1) {
                        let indexOfLabelQuantityToChange = tempArray.findIndex((label) => (label.year === getFirstOutYear && label.sequence === getFirstOutSequence))
                        tempArray[indexOfLabelQuantityToChange]["quantity"] -= 1

                        message["success"] = "QR Label ID: " + codeQR + " has its quantity reduced by " + 1 + " (Remaining label quantity: " + (labelQuantityToDecrease - 1) + " item(s)";

                        tempCountPromisesModal["now"] += 1;
                        labelQuantityToDecrease -= 1
                    }
                    else {
                        tempArray = dataQR.filter((label) => (label.year !== getFirstOutYear || label.sequence !== getFirstOutSequence));

                        message["success"] = "QR Label ID: " + codeQR + " has its quantity reduced to zero (Continuing to the next QR Label ID)." + (labelQuantityToDecrease - getFirstOutQuantity > 0 ? " Remaining outgoing quantity: " + (labelQuantityToDecrease - getFirstOutQuantity) + " item)" : '');

                        labelQuantityToDecrease -= getFirstOutQuantity

                        if (tempArray.length) {
                            const nextGetFirstOutYear = Math.min(...tempArray.map(x => {return x.year}));
                            const nextGetFirstOutSequence = Math.min(...tempArray.filter(data => data.year === nextGetFirstOutYear).map((data) => {
                                return data.sequence
                            }));
                            const nextGetFirstOutQuantity = tempArray.find((label) => (label.year === nextGetFirstOutYear && label.sequence === nextGetFirstOutSequence)).quantity;
                            const nextCodeQR = zeroPad(nextGetFirstOutYear % 100, 2) + '-' + zeroPad(dataSparePart.sediaan_id, 2) + '-' + dataSparePart.part_code + '-' + zeroPad(nextGetFirstOutSequence, 2);
                            const total = (nextGetFirstOutQuantity >= labelQuantityToDecrease ? labelQuantityToDecrease : nextGetFirstOutQuantity);

                            tempCountPromisesModal["now"] = 1;
                            tempCountPromisesModal["total"] = total;
                            tempCountPromisesModal["current"] = nextCodeQR;
                        }
                        else {
                            tempCountPromisesModal["now"] = 1;
                            tempCountPromisesModal["total"] = labelQuantityToDecrease;
                            tempCountPromisesModal["current"] = "(No Label ID)";
                        }
                    }

                    props.setCountPromisesModal(tempCountPromisesModal);
                }
                else message["error"] = "QR Label ID didn't match! (Correct QR Code: " + codeQR + ") or continue with 'forceScan'";

                console.log(dataQR);
                console.log(tempArray);
                console.log(labelQuantityToDecrease);

                props.setModalData({...dataSparePart, "meta": {...dataSparePart["meta"], "labelQR": tempArray}});
                props.setQuantityToScan(labelQuantityToDecrease);
            }
            else {
                message["error"] = "Continuing without QR Label ID, remaining quantity: " + labelQuantityToDecrease + ". Type 'forceScan' to force continue without QR Label ID";

                if (result === "forceScan") {
                    if (dataQR.length <= 0) {
                        labelQuantityToDecrease = 0;
                        props.setCountPromisesModal({
                            "now": 0,
                            "total": 0,
                            "current": '',
                        });
                    }
                    else {
                        labelQuantityToDecrease -= 1;
                    }
                    props.setQuantityToScan(labelQuantityToDecrease);

                    message["error"] = '';
                }
            }
        }

        return message;
    }

    function handleModalType(type){
        let data = {}
        switch(type){
            case "Bin Storage":
                data.header = "QR Code: Label " + (props.scanType === "bin" ? "Bin Location" : "ID");
                data.title = "QR Code value: ";
                data.content = (props.scanType === "label" ? "Scanning " + props.countPromisesModal.now + " of " + props.countPromisesModal.total + " item(s) with Label ID: " + props.countPromisesModal.current : "Please point your camera towards the QR Code!");
                data.confirmation = "Confirm";
                data.confirmationColor = "whatsapp";
                data.cancel = "Cancel";
                data.handleConfirmation = handleConfirm;
                break;
            case "Outgoing":
                data.header = "QR Code: Label " + (props.scanType === "bin" ? "Bin Location" : "ID");
                data.title = "QR Code value: ";
                data.content = (props.scanType === "label" || props.isMulti ? "Scanning " + props.countPromisesModal.now + " of " + props.countPromisesModal.total + " item(s) with Label: " + props.countPromisesModal.current + " (Remaining: " + props.quantityToScan + ")" : "Please point your camera towards the QR Code!");
                data.confirmation = "Confirm";
                data.confirmationColor = "whatsapp";
                data.cancel = "Cancel";
                data.handleConfirmation = handleConfirm;
                break;
            case "Restore":
                data.header = "QR Code: Label " + (props.scanType === "bin" ? "Bin Location" : "ID");
                data.title = "QR Code value: ";
                data.content = (props.scanType === "label" ? "Scanning " + props.countPromisesModal.now + " of " + props.countPromisesModal.total + " item(s)" : "Please point your camera towards the QR Code!");
                data.confirmation = "Confirm";
                data.confirmationColor = "whatsapp";
                data.cancel = "Cancel";
                data.handleConfirmation = handleConfirm;
                break;
            default:
                break;
        }
        setData(data);
    }

    useEffect(() => {
        if (!props.isOpen) {
            setResult('');
            setMessage({
                "error": '',
                "success": ''
            })
        }
    }, [props.isOpen])

    useEffect(() => {
        handleModalType(props.type);
        if (!dataInitialization) handleGetServing().then(() => setDataInitialization(true));
    }, [props.modalData, props.scanType, props.countPromisesModal, props.isMulti, props.quantityToScan])

    useEffect(() => {
        if (result) {
            handleConfirm();
            initialRef.current.focus();
        }
        else {
            setMessage({
                "error": '',
                "success": ''
            })
        }
    }, [result])

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            closeOnOverlayClick={false}
            isCentered
            initialFocusRef={initialRef}
            autoFocus={false}
            size={"sm"}
        >
            <ModalOverlay
                bg={"blackAlpha.500"}
                backdropFilter='auto'
                backdropBlur='2px'
            />
            <ModalContent>
                <ModalBody>
                    <ModalCloseButton/>
                    <ModalHeader>{data.header}</ModalHeader>
                    <Container>
                        <Center>
                            <Box bg='gray.50' w='90%' p={1} color='white' border={isScanning ? "1px dotted " + process.env.REACT_APP_BORDER_COLOR : ''} borderRadius={"lg"}>
                                {isScanning &&
                                    <QrReader
                                        facingMode={"rear"}
                                        delay={1000}
                                        style={{
                                            height: "80%",
                                            width: "100%",
                                            marginBottom: 10
                                        }}
                                        onError={(error) => console.error(error)}
                                        onScan={(e) => !e ? console.log(e?.text) : handleScanned(e?.text)}
                                    />
                                }
                                <Center>
                                    <Button
                                        size={"sm"}
                                        variant='solid'
                                        colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                                        position={"initial"}
                                        onClick={() => setIsScanning(isScanning => !isScanning)}
                                    >
                                        {!isScanning ? "Open" : "Close"} Scanner
                                    </Button>
                                </Center>
                            </Box>
                        </Center>
                        <Text className="disable-text-selection" fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"} pt={3}>{data.content}</Text>
                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT} textAlign={"center"} pt={3}>{data.title}</Text>
                        <Input
                            ref={initialRef}
                            textAlign={"center"}
                            color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                            borderColor={process.env.REACT_APP_INPUT_COLOR}
                            borderRadius={"xl"}
                            focusBorderColor={!!message.error ? "red"
                                :
                                (!!message.success ? process.env.REACT_APP_BORDER_COLOR : process.env.REACT_APP_CHECKBOX_COLOR)
                        }
                            _hover={{
                                borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD)
                            }}
                            type={"text"}
                            placeholder={"Scanned QR Code"}
                            value={result}
                            onChange={(e) => setResult(e.target.value)}
                            isInvalid={!!message.error}
                        />
                        {!!message.error &&
                            <Text className="disable-text-selection" fontSize={"sm"} color={"red"}>
                                {message.error}
                            </Text>
                        }
                        {!!message.success &&
                            <Text fontSize={"sm"} color={process.env.REACT_APP_BORDER_COLOR}>
                                {message.success}
                            </Text>
                        }
                    </Container>
                </ModalBody>

                <ModalFooter alignSelf={"center"}>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default SparePartScanner;