import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import Select from "react-select";

import {
    Button,
    ButtonGroup,
    Center,
    Container, FormControl, FormLabel, HStack,
    Image, Spacer,
    Table,
    TableContainer,
    Tbody,
    Td, Text,
    Th,
    Thead,
    Tr,
    useDisclosure, VStack
} from "@chakra-ui/react";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import SubHeader from "./SubHeader";

import Cookies from "js-cookie";
import Axios from "../../services/api/Axios";
import Modal from "../../components/Modal/AreYouSure";

import {AddIcon, CheckIcon} from "@chakra-ui/icons";
import TableEditIcon from "../../assets/icons/table/TableEdit.svg";
import TableDeleteIcon from "../../assets/icons/table/TableDelete.svg";
import EmptyList from "../../assets/img/empty.svg";

const UserManagement = () => {
    const columnName = ["Name", "Email", "Role", "Organizer", "Email Alerts", "Description", "Action"];
    const isResolutionHigh = useMediaQuery({ query: "(min-width: 1920px)" })

    const history = {
        dataUsersURL : "/admin/user?limit=9999&offset=0",
        deleteDataUserURL: "/admin/user?id=",
        servingURL : "/sediaan/list",
        accessToken : Cookies.get("accessToken"),
        role : Cookies.get("role"),
        idServing : Cookies.get("idServing"),
    }

    const navigate = useNavigate();

    const [dataUsers,setDataUsers] = useState([]);
    const [constantDataUsers,setConstantDataUsers] = useState([]);
    const [dataServings,setDataServings] = useState([]);
    const [dataInitialization,setDataInitialization] = useState(false);

    const [userDeleteIdentification, setUserDeleteIdentification] = useState(0);
    const [confirmDelete, setConfirmDelete] = useState('');

    const [servingOptions, setServingOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({
        "serving": {
            "label": "All",
            "value": 0,
        },
        "role": {
            "label": "All",
            "value": "All",
        },
        "name": {
            "label": "All",
            "value": "All",
        }
    })

    const handleGetDataUser = async () => {
        try{
            let getDataUsers = await Axios.get(history.dataUsersURL,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let tempUserOptions = [
                {
                    "label": "All",
                    "value": "All"
                }
            ];

            getDataUsers?.data?.data?.result?.map((user) => {
                let userOption  = {};
                userOption["value"] = user?.name;
                userOption["label"] = user.name;

                tempUserOptions.push(userOption);
            })

            setUserOptions(tempUserOptions);
            setConstantDataUsers(getDataUsers?.data?.data?.result);

            handleGetDataServing().then(() => setDataUsers(getDataUsers?.data?.data?.result));
        }
        catch(error){
            console.error(error);
        }
    }

    const handleGetDataServing = async () => {
        try{
            let statusServing = await Axios.get(history.servingURL,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            // console.log(statusServing);
            setDataServings(statusServing.data?.data?.sediaans);

            let tempServingOptions = [
                {
                    "label": "All",
                    "value": 0
                }
            ];

            statusServing.data?.data?.sediaans?.map((serving) => {
                let servingOption  = {};
                servingOption["value"] = serving?.id;
                servingOption["label"] = (serving?.nama).charAt(0).toUpperCase() + (serving?.nama).slice(1);

                tempServingOptions.push(servingOption);
            })

            setServingOptions(tempServingOptions);
        }
        catch(error){
            console.error(error);
        }
    }

    const handleDeleteDataUser = async () => {
        try{
            let deleteDataUser = await Axios.delete(history.deleteDataUserURL + userDeleteIdentification,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            setDataInitialization(false)
            console.log(deleteDataUser);
        }
        catch(error){
            console.error(error);
        }
        setUserDeleteIdentification(0);
    }

    const handleFilter = (selectedFilter, value) => {
        const isServingAll = selectedOptions["serving"]["value"] === 0;
        const isRoleAll = selectedOptions["role"]["value"] === "All";
        const isNameAll = selectedOptions["name"]["value"] === "All";

        if (selectedFilter === "serving") {
            if (value === 0) {
                let tempData = constantDataUsers
                if (!isRoleAll || !isNameAll) {
                    tempData = constantDataUsers.filter(user => ((!isRoleAll ? user.roleName === selectedOptions["role"]["value"] : true) && (!isNameAll ? user.name === selectedOptions["name"]["value"] : true)));
                }

                setDataUsers(tempData);
            }
            else {
                let tempData = constantDataUsers.filter(user => user.sediaan_id === value);
                if (!isRoleAll || !isNameAll) {
                    tempData = constantDataUsers.filter(user => user.sediaan_id === value && ((!isRoleAll ? user.roleName === selectedOptions["role"]["value"] : true) && (!isNameAll ? user.name === selectedOptions["name"]["value"] : true)));
                }

                setDataUsers(tempData);
            }
        }
        else if (selectedFilter === "role") {
            if (value === "All") {
                let tempData = constantDataUsers
                if (!isServingAll || !isNameAll) {
                    tempData = constantDataUsers.filter(user => ((!isServingAll ? user.sediaan_id === selectedOptions["serving"]["value"] : true) && (!isNameAll ? user.name === selectedOptions["name"]["value"] : true)));
                }

                setDataUsers(tempData);
            }
            else {
                let tempData = constantDataUsers.filter(user => user.roleName === value);
                if (!isServingAll || !isNameAll) {
                    tempData = constantDataUsers.filter(user => user.roleName === value && ((!isServingAll ? user.sediaan_id === selectedOptions["serving"]["value"] : true) && (!isNameAll ? user.name === selectedOptions["name"]["value"] : true)));
                }

                setDataUsers(tempData);
            }
        }
        else if (selectedFilter === "name") {
            if (value === "All") {
                let tempData = constantDataUsers
                if (!isServingAll || !isRoleAll) {
                    tempData = constantDataUsers.filter(user => ((!isServingAll ? user.sediaan_id === selectedOptions["serving"]["value"] : true) && (!isRoleAll ? user.roleName === selectedOptions["role"]["value"] : true)));
                }

                setDataUsers(tempData);
            }
            else {
                let tempData = constantDataUsers.filter(user => user.name === value);
                if (!isServingAll || !isRoleAll) {
                    tempData = constantDataUsers.filter(user => user.name === value && ((!isServingAll ? user.sediaan_id === selectedOptions["serving"]["value"] : true) && (!isRoleAll ? user.roleName === selectedOptions["role"]["value"] : true)));
                }

                setDataUsers(tempData);
            }
        }

    }

    const {
        isOpen: isOpenDeleteModal,
        onOpen: onOpenDeleteModal,
        onClose: onCloseDeleteModal
    } = useDisclosure()

    useEffect(() => {
        if (!dataInitialization) handleGetDataUser().then(() => setDataInitialization(true));
        if (confirmDelete === "Delete") handleDeleteDataUser().then(() => setConfirmDelete(''));
    }, [confirmDelete]);

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingTop={"2vh"}>
                <Modal
                    isOpen={isOpenDeleteModal}
                    onOpen={onOpenDeleteModal}
                    onClose={onCloseDeleteModal}
                    setConfirmAction={setConfirmDelete}
                    type={"Delete"}
                />

                <SubHeader/>

                <ButtonGroup py={"1%"}>
                    <Button
                        rightIcon={<AddIcon/>}
                        variant='solid'
                        colorScheme='facebook'
                        position={"initial"}
                        onClick={() => navigate('/userManagement/create')}
                    >
                        New User
                    </Button>
                </ButtonGroup>

                <HStack pb={"1%"} spacing={"2%"}>
                    <FormControl>
                        <HStack spacing={5} w={"full"}>
                            <VStack align={"left"}>
                                <FormLabel fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Organization: </FormLabel>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textAlign: "center",
                                            borderColor: state.isFocused ? 'peachpuff' : 'rebeccapurple',
                                            borderRadius: 10,
                                            width: "12vw",
                                            ":hover": {
                                                borderColor: "mediumpurple"
                                            },
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                    ? undefined
                                                    : isSelected
                                                        ? data.color
                                                        : isFocused
                                                            ? "lightpink"
                                                            : undefined,
                                                color: isDisabled
                                                    ? '#ccc'
                                                    : isSelected
                                                        ? "green"
                                                            ? process.env.REACT_APP_BORDER_COLOR
                                                            : 'black'
                                                        : data.color,
                                                cursor: isDisabled ? 'not-allowed' : 'default',

                                                ':active': {
                                                    ...styles[':active'],
                                                    backgroundColor: !isDisabled
                                                        ? isSelected
                                                            ? data.color
                                                            : "green"
                                                        : undefined,
                                                },
                                            };
                                        },
                                    }}
                                    onChange={(e) => {
                                        handleFilter("serving", Number(e.value));
                                        setSelectedOptions(selectedOptions => ({...selectedOptions, "serving": e}))
                                    }}
                                    value={selectedOptions["serving"]}
                                    options={servingOptions}
                                />
                            </VStack>

                            <VStack align={"left"}>
                                <FormLabel fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Role: </FormLabel>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textAlign: "center",
                                            borderColor: state.isFocused ? 'peachpuff' : 'rebeccapurple',
                                            borderRadius: 10,
                                            width: "12vw",
                                            ":hover": {
                                                borderColor: "mediumpurple"
                                            },
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                    ? undefined
                                                    : isSelected
                                                        ? data.color
                                                        : isFocused
                                                            ? "lightpink"
                                                            : undefined,
                                                color: isDisabled
                                                    ? '#ccc'
                                                    : isSelected
                                                        ? "green"
                                                            ? process.env.REACT_APP_BORDER_COLOR
                                                            : 'black'
                                                        : data.color,
                                                cursor: isDisabled ? 'not-allowed' : 'default',

                                                ':active': {
                                                    ...styles[':active'],
                                                    backgroundColor: !isDisabled
                                                        ? isSelected
                                                            ? data.color
                                                            : "green"
                                                        : undefined,
                                                },
                                            };
                                        },
                                    }}
                                    onChange={(e) => {
                                        handleFilter("role", e.value);
                                        setSelectedOptions(selectedOptions => ({...selectedOptions, "role": e}))
                                    }}
                                    value={selectedOptions["role"]}
                                    options={[
                                        {
                                            "label": "All",
                                            "value": "All"
                                        },
                                        {
                                            "label": "Superior",
                                            "value": "Superior"
                                        },
                                        {
                                            "label": "Admin",
                                            "value": "Admin"
                                        },
                                        {
                                            "label": "User",
                                            "value": "Teknisi"
                                        }
                                    ]}
                                />
                            </VStack>

                            <VStack align={"left"}>
                                <FormLabel fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Name: </FormLabel>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textAlign: "center",
                                            borderColor: state.isFocused ? 'peachpuff' : 'rebeccapurple',
                                            borderRadius: 10,
                                            width: "12vw",
                                            ":hover": {
                                                borderColor: "mediumpurple"
                                            },
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                    ? undefined
                                                    : isSelected
                                                        ? data.color
                                                        : isFocused
                                                            ? "lightpink"
                                                            : undefined,
                                                color: isDisabled
                                                    ? '#ccc'
                                                    : isSelected
                                                        ? "green"
                                                            ? process.env.REACT_APP_BORDER_COLOR
                                                            : 'black'
                                                        : data.color,
                                                cursor: isDisabled ? 'not-allowed' : 'default',

                                                ':active': {
                                                    ...styles[':active'],
                                                    backgroundColor: !isDisabled
                                                        ? isSelected
                                                            ? data.color
                                                            : "green"
                                                        : undefined,
                                                },
                                            };
                                        },
                                    }}
                                    onChange={(e) => {
                                        handleFilter("name", e.value);
                                        setSelectedOptions(selectedOptions => ({...selectedOptions, "name": e}))
                                    }}
                                    value={selectedOptions["name"]}
                                    options={userOptions}
                                />
                            </VStack>

                            <Spacer/>

                            <ButtonGroup gap={3}>
                                <Button
                                    colorScheme={"teal"}
                                    onClick={() => {
                                        setSelectedOptions({
                                            "serving": {
                                                "label": "All",
                                                "value": 0,
                                            },
                                            "role": {
                                                "label": "All",
                                                "value": "All",
                                            },
                                            "name": {
                                                "label": "All",
                                                "value": "All",
                                            }
                                        })
                                        setDataUsers(constantDataUsers);
                                    }}
                                >
                                    Reset
                                </Button>
                            </ButtonGroup>
                        </HStack>
                    </FormControl>
                </HStack>

                {(dataUsers)?.length === 0 ?
                    <Center w={"full"}>
                        <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                        <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                            No items in here..
                        </Text>
                    </Center>
                    :
                    <TableContainer maxHeight={isResolutionHigh ? "58vh" : "54vh"} overflowY={"auto"} whiteSpace={"normal"} display={"flex"} border={"1px solid #B7D4CF"} borderRadius={"lg"}>
                        <Table variant='simple' size={"lg"}>
                            <Thead zIndex={0} pos='sticky' top={0} bg={process.env.REACT_APP_TABLE_COLOR}>
                                <Tr>
                                    {columnName.map((elem) => (
                                        <Th key={elem}>
                                            <Text className='text-center font-bold text-sm' textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem}</Text>
                                        </Th>
                                    ))}
                                </Tr>
                            </Thead>

                            <Tbody>
                                {dataUsers.map((elem) => (
                                    <Tr key={elem.id}>
                                        <Td>
                                            <Center>
                                                {elem?.name?.length >= 25 ? (elem.name).slice(0,40) + ".." : elem.name}
                                            </Center>
                                        </Td>
                                        <Td>
                                            <Center>
                                                {elem?.email?.length >= 40 ? (elem.email).slice(0,40) + ".." : elem.email}
                                            </Center>

                                        </Td>
                                        <Td>
                                            <Center>
                                                {elem.roleName === "Teknisi" ? "User" : elem.roleName }
                                            </Center>
                                        </Td>
                                        <Td>
                                            <Center>
                                                {(dataServings?.find(x => x.id === elem.sediaan_id).nama).charAt(0).toUpperCase() + (dataServings?.find(x => x.id === elem.sediaan_id).nama).slice(1)}
                                            </Center>
                                        </Td>
                                        <Td>
                                            <Center>
                                                {elem.isEmailNotification ? <CheckIcon/> : ''}
                                            </Center>
                                        </Td>
                                        <Td>
                                            <Center>
                                                {elem.roleName === "Superior" ? "All" : ((dataServings?.find(x => x.id === elem.sediaan_id).nama).charAt(0).toUpperCase() + (dataServings?.find(x => x.id === elem.sediaan_id).nama).slice(1))} access
                                            </Center>
                                        </Td>
                                        <Td>
                                            <Center>
                                                <HStack>
                                                    {(history.role !== "Superior" && elem.roleName !== "Superior" && Number(history.idServing) === Number(elem?.sediaan_id)) ?
                                                        <>
                                                            <Image
                                                                src={TableEditIcon}
                                                                cursor={'pointer'}
                                                                alt="edit"
                                                                onClick={() => navigate({
                                                                    pathname: "/userManagement/edit",
                                                                    search: "?id=" + elem?.id,
                                                                })}
                                                            />
                                                            <Image
                                                                src={TableDeleteIcon}
                                                                cursor={'pointer'}
                                                                alt="delete"
                                                                onClick={() => {
                                                                    setUserDeleteIdentification(elem?.id);
                                                                    onOpenDeleteModal();
                                                                }}
                                                            />
                                                        </>
                                                        :
                                                        (history.role === "Superior" ?
                                                                <>
                                                                    <Image
                                                                        src={TableEditIcon}
                                                                        cursor={'pointer'}
                                                                        alt="edit"
                                                                        onClick={() => navigate({
                                                                            pathname: "/userManagement/edit",
                                                                            search: "?id=" + elem?.id,
                                                                        })}
                                                                    />
                                                                    <Image
                                                                        src={TableDeleteIcon}
                                                                        cursor={'pointer'}
                                                                        alt="delete"
                                                                        onClick={() => {
                                                                            setUserDeleteIdentification(elem?.id);
                                                                            onOpenDeleteModal();
                                                                        }}
                                                                    />
                                                                </>
                                                                :
                                                                <Text>Unauthorized</Text>
                                                        )
                                                    }
                                                </HStack>
                                            </Center>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>

                        </Table>
                    </TableContainer>
                }
            </Container>
            <Footer />
        </>
    );
}

export default UserManagement;
