import React, {Component, useEffect, useState} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import NavigationGuard from "../../services/hooks/NavigationGuard";
import DashboardSubHeader from "./SubHeader";

import { Container, Grid, GridItem, Select, Flex, Text, Center } from "@chakra-ui/react";
import Cookies from "js-cookie";
import Axios from "../../services/api/Axios";

const DashboardSumContainer = () => {
    const [dataInitialization,setDataInitialization] = useState(false);
    const [dataDashboard,setDataDashboard] = useState([]);

    const history = {
        dataDashboardURL : "/request/rekap?sediaan_id=" + Cookies.get("idServing") + "&status=CLOSE",
        accessToken : Cookies.get("accessToken"),
    }

    const getCurrentYear = new Date().getFullYear();

    const getCurrentMonth = (new Date().getMonth()) + 1;
    const getLastDayOfMonth = new Date((new Date(getCurrentYear, getCurrentMonth, 1)) - 1).getDate();

    let firstDayOfWeekDate = new Date();
    let dayFirst = firstDayOfWeekDate.getDay() || 7;
    if (dayFirst !== 1) firstDayOfWeekDate.setHours(-24 * (dayFirst - 1));

    let getFirstDayOfWeek = firstDayOfWeekDate.getDate();

    let lastDayOfWeekDate = new Date();
    let dayLast = lastDayOfWeekDate.getDay() || 7;
    if (dayLast !== 7) lastDayOfWeekDate.setHours(24 * (7 - dayLast));

    let getLastDayOfWeek = lastDayOfWeekDate.getDate();

    const handleGetDataDashboard = async (value) => {
        let queryString = '';
        if (value === "yearly") {
            queryString += "&date_start=" + getCurrentYear + "-01-01&date_end=" + getCurrentYear + "-12-31"
        }
        else if (value === "monthly") {
            queryString += "&date_start=" + getCurrentYear + '-' + getCurrentMonth + "-01&date_end=" + getCurrentYear + '-' + getCurrentMonth + '-' + getLastDayOfMonth
        }
        else if (value === "weekly") {
            queryString += "&date_start=" + getCurrentYear + '-' + getCurrentMonth + '-' + getFirstDayOfWeek + "&date_end=" + getCurrentYear + '-' + getCurrentMonth + '-' + getLastDayOfWeek
        }
        console.log(queryString);
        try{
            let getDataDashboard = await Axios.get(history.dataDashboardURL + queryString,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let tempDataServices = getDataDashboard?.data?.data?.rekapRequest;

            setDataDashboard(tempDataServices);
        }
        catch(error){
            console.error(error);
        }
    }

    useEffect(() => {
        if (!dataInitialization) handleGetDataDashboard().then(() => setDataInitialization(true));
    }, [])
    
    return (
        <Container boxShadow="xl" maxW={"full"} className="bg-[#F3F8F8] m-4 rounded-lg pt-[2vh] pb-[4vh]">
            <Flex maxW={"md"} className="p-4">
                <Text className="font-bold text-center p-2 whitespace-nowrap">Data Range</Text>
                <Select defaultValue={"total"} onChange={(e) => handleGetDataDashboard(e.target.value)}>
                    <option value='weekly'>Weekly</option>
                    <option value='monthly'>Monthly</option>
                    <option value='yearly'>Yearly</option>
                    <option value='total'>Total</option>
                </Select>
            </Flex>
            
            <Grid className="mt-[4vh]" templateColumns='repeat(4, 1fr)' gap={6}>
                <GridItem boxShadow="xl" w='20vw' h='15vh' bg='white' className="rounded-xl">
                    <Center className="font-semibold mt-8" textColor={process.env.REACT_APP_TEXT_COLOR_LIGHT}>Total Data Request</Center>
                    <Center className={"font-extrabold text-3xl bg-gradient-to-b text-transparent from-[#AC7DBA] to-[#3b193d] bg-clip-text"}>{dataDashboard.requests}</Center>
                </GridItem>
                <GridItem boxShadow="xl" w='20vw' h='15vh' bg='white' className="rounded-xl">
                    <Center className="font-semibold mt-8" textColor={process.env.REACT_APP_TEXT_COLOR_LIGHT}>Total Qty Request</Center>
                    <Center className={"font-extrabold text-3xl bg-gradient-to-b text-transparent from-[#AC7DBA] to-[#3b193d] bg-clip-text"}>{dataDashboard.qty_request}</Center>
                </GridItem>
                <GridItem boxShadow="xl" w='20vw' h='15vh' bg='white' className="rounded-xl">
                    <Center className="font-semibold mt-8" textColor={process.env.REACT_APP_TEXT_COLOR_LIGHT}>Total Qty Outgoing</Center>
                    <Center className={"font-extrabold text-3xl bg-gradient-to-b text-transparent from-[#AC7DBA] to-[#3b193d] bg-clip-text"}>{dataDashboard.qty_receive}</Center>
                </GridItem>
                <GridItem boxShadow="xl" w='20vw' h='15vh' bg='white' className="rounded-xl">
                    <Center className="font-semibold mt-8"  textColor={process.env.REACT_APP_TEXT_COLOR_LIGHT}>Performance</Center>
                    <Center className={"font-extrabold text-3xl bg-gradient-to-b text-transparent from-[#AC7DBA] to-[#3b193d] bg-clip-text"}>{(Number(dataDashboard.qty_request) === 0 || Number(dataDashboard.qty_receive) === 0) ? 0 : Math.round((dataDashboard.qty_receive / dataDashboard.qty_request) * 100)}%</Center>
                </GridItem>
            </Grid>
        </Container>
    );
}
 
export default DashboardSumContainer;