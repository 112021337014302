import React from "react";
import {
    Avatar,
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Center,
    Container, Divider,
    Flex, Grid, GridItem,
    Heading,
    HStack, Image,
    Spacer, Stack,
    Text, VStack
} from "@chakra-ui/react";
import {
    CheckIcon,
    HamburgerIcon,
    MinusIcon,
} from "@chakra-ui/icons";

import sparePart from "../../assets/img/imageSparePart.svg";
import EmptyList from "../../assets/img/empty.svg";

const ListOutgoing = ({dataOutgoing, onOpenSparePartCart, onOpenSparePartPopUp, setIsMulti, onOpenDeleteModal, setModalData, setRequestData, setCartData, setRefreshImage}) => {
    const columnName = {
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "bin_location" : "Bin Location",
        "price" : "Price / pcs",
        "qty" : "Stock",
        "qty_request" : "Request Quantity",
    }

    return (
        <>
            {(dataOutgoing)?.length === 0 ?
                <Center w={"full"} marginTop={"8.2%"}>
                    <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                    <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                        No items in here..
                    </Text>
                </Center>
                :
                <Container maxW={"full"} position={"relative"} marginTop={"14.5vh"} maxH={"70vh"} overflowY={"auto"}>
                    {dataOutgoing.map((elem) => (
                        <Card
                            key={elem?.id}
                            marginX={"5%"}
                            marginTop={5}
                            variant={"outline"}
                            borderColor={process.env.REACT_APP_INPUT_COLOR}
                            boxShadow={"0 4px 8px 0 rgba(0, 0, 0, 0.2)"}>
                            <CardHeader>
                                <Flex>
                                    <HStack spacing={5}>
                                        <Heading size='md' color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Request Case: {elem?.meta?.request_number}</Heading>
                                        <Text fontWeight={"bold"} borderRadius={"md"} bg={"blue.100"} color={"royalblue"}>User: {elem?.user?.name}</Text>
                                        <Text fontWeight={"bold"} borderRadius={"md"} bg={"red.100"} color={"mediumvioletred"}>{(new Date(elem.createdAt)).toString().slice(0, 33)}</Text>
                                        <Text fontWeight={"bold"} borderRadius={"md"} bg={"yellow.100"} color={"yellow.700"}>{elem?.requestSpareparts[0]?.machine?.code}</Text>
                                    </HStack>

                                    <Spacer/>

                                    <HStack spacing={2}>
                                        <Button
                                            variant='solid'
                                            colorScheme="facebook"
                                            rightIcon={<HamburgerIcon/>}
                                            onClick={() => {
                                                setCartData(elem);
                                                onOpenSparePartCart();
                                            }}
                                        >
                                            Show List
                                        </Button>
                                    </HStack>
                                </Flex>
                            </CardHeader>

                            <CardBody>
                                {elem?.requestSpareparts?.sort(function (a, b) {return a.qty_receive - b.qty_receive})?.map((dataSparePart) => (
                                    <Card
                                        key={dataSparePart?.id + dataSparePart?.request_id}
                                        marginTop={2}
                                        bg={"white"}
                                        variant={"outline"}
                                        borderColor={"purple.200"}
                                        borderRadius={"xl"}
                                        direction={{ base: 'column', sm: 'row' }}
                                        overflow='hidden'
                                    >
                                        <Flex w={"full"}>
                                            <Center>
                                                <Avatar ml={2} cursor={"pointer"} onClick={() => setRefreshImage("onClick")} size={"xl"} src={dataSparePart["image_bin"]} icon={<Image src={sparePart} />} marginBottom={"2%"}/>
                                            </Center>

                                            <CardBody w={"max"}>
                                                <Stack>
                                                    <Grid templateColumns='repeat(4, 1fr)' gap={2} w={"full"}>
                                                        {Object.keys(columnName).map((elem, index) => (
                                                            <GridItem key={dataSparePart.id + index} w={"full"} borderRadius={"lg"}>
                                                                <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnName[elem]}</Text>
                                                                {elem !== "qty_request" ?
                                                                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem === "price" && "Rp "}{(dataSparePart?.sparepart ? ((dataSparePart?.sparepart[elem])?.length > 30 ? (dataSparePart?.sparepart[elem])?.slice(0, 30) + ".." : (dataSparePart?.sparepart[elem])) : '')}{elem === "price" && ",00"}</Text>
                                                                    :
                                                                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{dataSparePart[elem] ? dataSparePart[elem] : 0}</Text>
                                                                }
                                                            </GridItem>
                                                        ))}
                                                    </Grid>
                                                </Stack>
                                            </CardBody>

                                            {/*onClick={() => handleOutgoingSpareParts(dataSparePart?.id, (dataSparePart?.sparepart?.qty - (dataSparePart?.qty_request ? dataSparePart?.qty_request : 0)))}*/}
                                            <VStack spacing={2} margin={2} align={"end"}>
                                                <Button
                                                    size={"sm"}
                                                    variant='solid'
                                                    colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                                                    rightIcon={<CheckIcon/>}
                                                    isDisabled={dataSparePart?.qty_receive !== null}
                                                    onClick={() => {
                                                        let tempModalData = JSON.parse(JSON.stringify(dataSparePart));
                                                        let newModalData = tempModalData?.sparepart;
                                                        newModalData["line"] = dataSparePart?.machine?.line?.name;
                                                        newModalData["machine"] = dataSparePart?.machine?.code;
                                                        setModalData(newModalData);
                                                        let tempRequestData = JSON.parse(JSON.stringify(tempModalData));
                                                        delete tempRequestData?.sparepart;
                                                        tempRequestData["user"] = elem?.user?.name;
                                                        setRequestData(tempRequestData);
                                                        setIsMulti(false);
                                                        console.log(dataSparePart);
                                                        onOpenSparePartPopUp();
                                                    }}
                                                >
                                                    Accept
                                                </Button>
                                                <Button
                                                    size={"sm"}
                                                    variant='solid'
                                                    colorScheme='red'
                                                    rightIcon={<MinusIcon/>}
                                                    isDisabled={dataSparePart?.qty_receive !== null}
                                                    onClick={() => {
                                                        let tempModalData = JSON.parse(JSON.stringify(dataSparePart));
                                                        setModalData(tempModalData?.sparepart);
                                                        let tempRequestData = JSON.parse(JSON.stringify(tempModalData));
                                                        delete tempRequestData?.sparepart;
                                                        tempRequestData["user"] = elem?.user?.name;
                                                        setRequestData(tempRequestData);
                                                        onOpenDeleteModal();
                                                    }
                                                    }
                                                >
                                                    Reject
                                                </Button>
                                                <Spacer/>
                                                {dataSparePart?.qty_receive !== null &&
                                                    <Text fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Received Quantity: {dataSparePart?.qty_receive}</Text>
                                                }
                                            </VStack>
                                        </Flex>
                                    </Card>
                                ))}
                            </CardBody>

                            <Divider />

                            <CardFooter blockSize={1} borderRadius={"lg"} backgroundColor={"purple.50"}>
                                <HStack spacing={1}>
                                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                        {(elem?.requestSpareparts)?.length}
                                    </Text>
                                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                        item{(elem?.requestSpareparts)?.length > 1 ? 's' : ''} requested
                                    </Text>
                                </HStack>
                            </CardFooter>
                        </Card>
                    ))}
                </Container>
            }
        </>
    );
}

export default ListOutgoing;