import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Center,
    Checkbox,
    Container,
    Divider,
    Flex,
    Heading,
    HStack,
    Image,
    Select,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from "@chakra-ui/react";
import {CheckCircleIcon, DeleteIcon} from "@chakra-ui/icons";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import SparePartSubHeader from "../ReOrderPoint/SubHeader";
import Modal from "../../components/Modal/AreYouSure";

import Cookies from "js-cookie";
import Axios from "../../services/api/Axios";

import BackIcon from "../../assets/icons/hero/Sparepart/Hero_back.svg";
import EmptyList from "../../assets/img/empty.svg";

const ProgressOrder = () => {
    const navigate = useNavigate();

    let history = {
        listReOrderURL : "/reorder/list?status=SUBMIT&sediaan_id=" + Cookies.get("idServing") + "&limit=9999",
        updateIncomingStatus: "/incoming/update?id=",
        deleteIncoming: "/incoming/delete?id=",
        accessToken : Cookies.get("accessToken"),
    }

    const [dataOrder, setDataOrder] = useState([]);
    const [dataInitialization, setDataInitialization] = useState(false);
    const [checkBoxes, setCheckBoxes] = useState({});
    const [isActionButtonDisable, setIsActionButtonDisable] = useState([]);
    const [confirmAction, setConfirmAction] = useState('');

    const columnNames = {
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "part_category" : "Item Category",
        "qty_order" : "Order Quantity",
        "qty_uom" : "Unit",
        "price" : "Item Price",
        "total_price" : "Total Price",
        "main_supplier" : "Main Supplier",
        "status" : "Status Order",
    }

    const {
        isOpen: isOpenRequestModal,
        onOpen: onOpenRequestModal,
        onClose: onCloseRequestModal
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenReportModalDelete,
        onOpen: onOpenReportModalDelete,
        onClose: onCloseReportModalDelete
    } = useDisclosure()

    const handleOrder = async () => {
        let listReOrderCase = await Axios.get(history.listReOrderURL,
            {
                headers: {'Authorization': 'Bearer ' + history.accessToken},
            });

        let listCase = [];

        listReOrderCase?.data?.data?.reorders.map((elem) => {
            if ((elem.case_number).slice(0, 3) !== "NPO") {
                listCase.push(elem)
            }
        })

        setDataOrder(listCase);
    }

    const handleAction = (event) => {
        switch(event){
            case true:
                Object.keys(checkBoxes).map((elem) => {
                    Object.keys(checkBoxes[elem]).map(async (value) => {
                        if (checkBoxes[elem][value]["isChecked"] === true && checkBoxes[elem][value]["status"]) {
                            let updateOrder = await Axios.post(history.updateIncomingStatus + value,
                                {status: checkBoxes[elem][value]["status"]},
                                {
                                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                                });

                            console.log(updateOrder);
                        }
                    })
                    return ''
                })

                setConfirmAction('');
                break;
            case "Delete":
                Object.keys(checkBoxes).map((elem) => {
                    Object.keys(checkBoxes[elem]).map(async (value) => {
                        if (checkBoxes[elem][value]["isChecked"]) {
                            let deleteOrder = await Axios.post(history.deleteIncoming + value,
                                {},
                                {
                                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                                });

                            console.log(deleteOrder);
                        }
                    })
                    return ''
                })

                setConfirmAction('');
                break;
            default:
                break;
        }

        handleOrder().then(() => {
            setCheckBoxes({});
            setIsActionButtonDisable([]);
            onOpenSuccessModal();
        });
    }

    useEffect(() => {
        if (!dataInitialization) {handleOrder().then(() => setDataInitialization(true))}
        if (confirmAction !== '') handleAction(confirmAction);
    }, [confirmAction, dataOrder])

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingTop={"2vh"}>
                <SparePartSubHeader position={"fixed"}/>

                <Modal
                    isOpen={isOpenRequestModal}
                    onOpen={onOpenRequestModal}
                    onClose={onCloseRequestModal}
                    setConfirmSentResetPassword={setConfirmAction}
                    type={"Request"}
                />

                <Modal
                    isOpen={isOpenReportModalDelete}
                    onOpen={onOpenReportModalDelete}
                    onClose={onCloseReportModalDelete}
                    setConfirmAction={setConfirmAction}
                    type={"Delete"}
                />

                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    type={"Success"}
                />

                <Flex marginTop={"12vh"} marginBottom={1} w={"98%"} position={"fixed"}>
                    <ButtonGroup gap={3}>
                        <Button leftIcon={<Image src={BackIcon} alt="" />} backgroundColor={process.env.REACT_APP_ENV_COLOR} borderRadius="8px" variant='solid' _hover={{ bg: process.env.REACT_APP_BUTTON_HOVER_COLOR }} _active={{ bg: process.env.REACT_APP_TEXT_COLOR_LIGHT }} boxShadow="lg" onClick={() => navigate("/reOrderPoint/list")}>
                            <Text className="text-white font-extrabold tracking-wide ml-2">Back</Text>
                        </Button>
                        {/*<Button leftIcon={<Image src={ExportIcon} alt="" />} backgroundColor="#19C4E3" borderRadius="8px" variant="solid" _hover={{ bg: process.env.REACT_APP_BUTTON_HOVER_COLOR }} _active={{ bg: process.env.REACT_APP_TEXT_COLOR_LIGHT }} boxShadow="lg">*/}
                        {/*    <Text className="text-white font-extrabold tracking-wide ml-2">Export</Text>*/}
                        {/*</Button>*/}
                    </ButtonGroup>

                    <Spacer/>

                    <ButtonGroup gap={3}>
                        <Button
                            variant='solid'
                            colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                            rightIcon={<CheckCircleIcon/>}
                            isDisabled={isActionButtonDisable.length === 0}
                            onClick={onOpenRequestModal}
                        >
                            Save
                        </Button>
                        <Button
                            variant='solid'
                            colorScheme="red"
                            rightIcon={<DeleteIcon/>}
                            isDisabled={isActionButtonDisable.length === 0}
                            onClick={onOpenReportModalDelete}
                        >
                            Delete
                        </Button>
                    </ButtonGroup>
                </Flex>

                <Divider variant={"dashed"} borderColor={process.env.REACT_APP_BORDER_COLOR} margin={2} w={"98%"} marginTop={"20vh"} position={"fixed"}/>

                {(dataOrder)?.length === 0 ?
                    <Center w={"full"} marginTop={"10%"}>
                        <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                        <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                            No items in here..
                        </Text>
                    </Center>
                    :
                    <Container maxW={"full"} position={"relative"} marginTop={"20.5vh"} maxH={"64vh"} overflowY={"auto"}>
                        {dataOrder.map((elem) => (
                            <Card
                                key={elem.id}
                                marginX={"5%"}
                                marginY={5}
                                variant={"outline"}
                                borderColor={process.env.REACT_APP_INPUT_COLOR}
                                boxShadow={"0 4px 8px 0 rgba(0, 0, 0, 0.2)"}>
                                <CardHeader>
                                    <Flex>
                                        <HStack spacing={5}>
                                            <Heading size='md' color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Order Case: {elem.case_number}</Heading>
                                            <Text fontWeight={"bold"} borderRadius={"md"} bg={"blue.100"} color={"royalblue"}>PIC: {elem.user?.name}</Text>
                                            <Text fontWeight={"bold"} borderRadius={"md"} bg={"red.100"} color={"mediumvioletred"}>{(new Date(elem.createdAt)).toString().slice(0, 33)}</Text>
                                        </HStack>
                                    </Flex>
                                </CardHeader>

                                <CardBody>
                                    <TableContainer whiteSpace={"normal"}>
                                        <Table variant="simple">
                                            <Thead zIndex={1} pos='sticky' top={0} bg={process.env.REACT_APP_TABLE_COLOR}>
                                                <Tr>
                                                    <Th>
                                                        <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}></Text>
                                                    </Th>
                                                    <Th>
                                                        <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>No</Text>
                                                    </Th>
                                                    {Object.keys(columnNames).map((elem) => (
                                                        <Fragment key={columnNames[elem]}>
                                                            <Th>
                                                                <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnNames[elem]}</Text>
                                                            </Th>
                                                        </Fragment>
                                                    ))}
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {elem.reorderSpareparts.map((item, index) => (
                                                    <Tr key={item.id}>
                                                        <Td>
                                                            <Checkbox
                                                                size="lg"
                                                                colorScheme={process.env.REACT_APP_BORDER_COLOR}
                                                                borderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                                isChecked={checkBoxes[elem.id] ? (checkBoxes[elem.id][item.id] ? (checkBoxes[elem.id][item.id]["isChecked"] === true) : false) : false}
                                                                isDisabled={item.status === "done"}
                                                                onChange={() => {
                                                                    let tempObject = JSON.parse(JSON.stringify(checkBoxes));
                                                                    if (!tempObject[elem.id]) tempObject[elem.id] = {};
                                                                    if(!tempObject[elem.id][item.id]) tempObject[elem.id][item.id] = {};

                                                                    if (!tempObject[elem.id][item.id]["isChecked"]) tempObject[elem.id][item.id]["isChecked"] = true
                                                                    else tempObject[elem.id][item.id]["isChecked"] = !tempObject[elem.id][item.id]["isChecked"]

                                                                    setCheckBoxes(tempObject);

                                                                    let tempArray = Array.from(isActionButtonDisable);
                                                                    if (tempArray.includes(item.id)) {
                                                                        index = tempArray.indexOf(item.id);
                                                                        tempArray.splice(index, 1);
                                                                    }
                                                                    else tempArray.push(item.id);

                                                                    setIsActionButtonDisable(tempArray);

                                                                }}
                                                            >
                                                            </Checkbox>
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {index + 1}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart?.part_code}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart?.part_name}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart?.brand}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart?.type}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart?.part_category}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.qty_reorder}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart?.qty_uom}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart?.price}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {(item.sparepart?.price) ? Number(item.sparepart?.price) * Number(item.qty_reorder) : ''}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart?.main_supplier}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} w={"11%"}>
                                                            <Select
                                                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                                borderColor={process.env.REACT_APP_INPUT_COLOR }
                                                                borderRadius={"xl"}
                                                                focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                                _hover={{
                                                                    borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                                                }}
                                                                textAlign={"center"}
                                                                bg="white"
                                                                fontWeight={"semibold"}
                                                                defaultValue={item.status}
                                                                isDisabled={checkBoxes[elem.id] ? (checkBoxes[elem.id][item.id] ? !(checkBoxes[elem.id][item.id]["isChecked"] === true) : true) : true}
                                                                onChange={(e) => {
                                                                    setCheckBoxes(checkBoxes => ({...checkBoxes, [elem.id]: ({...checkBoxes[elem.id], [item.id]: ({...checkBoxes[elem.id][item.id], "status": e.target.selectedOptions[0].value})})}))
                                                                }}
                                                            >
                                                                <option value={"ssc"}>SSC</option>
                                                                <option value={"incoming"}>Incoming</option>
                                                                <option disabled value={"done"}>Done</option>
                                                            </Select>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </CardBody>

                                <Divider />

                                <CardFooter blockSize={1} borderRadius={"lg"} backgroundColor={"purple.50"}>
                                    <HStack spacing={1}>
                                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                            {(elem?.reorderSpareparts)?.length}
                                        </Text>
                                        <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                            item{(elem?.reorderSpareparts)?.length > 1 ? 's' : ''}
                                        </Text>
                                    </HStack>
                                </CardFooter>
                            </Card>
                        ))}
                    </Container>
                }
            </Container>
            <Footer />
        </>
    );
}

export default ProgressOrder;
