import React, {useEffect, useState} from "react";

import {Container, Box, useDisclosure, Divider} from "@chakra-ui/react";

import Axios from "../../services/api/Axios"
import Cookies from "js-cookie";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import SidebarRequest from "./SidebarRequest";
import HeaderRequestMobile from "./Mobile/HeaderRequest";
import ListRequest from "./ListRequest";
import ListRequestMobile from "./Mobile/ListRequest";
import SparePartPopUp from "../../components/Modal/SparePartPopUp";
import Modal from "../../components/Modal/AreYouSure";
import SparePartCart from "../../components/Modal/SparePartCart";
import {useMediaQuery} from "react-responsive";

let state = {
    pages: ["list", "archive", "opname"],
    dropDownList : ["part_name", "brand", "type"],
    offset: 25,
    dataOffset : 0,
    dropDownOffset : 25,
    dropDownSelectOffset : 0,
}

const Request = () => {
    const [dataSpareParts,setDataSpareParts] = useState([]);
    const [tempDataSpareParts,setTempDataSpareParts] = useState([]);
    const [dataInitialization,setDataInitialization] = useState(false);
    const [fetchMoreData,setFetchMoreData] = useState(false);

    const [dataDropDownSpareParts,setDataDropDownSpareParts] = useState({});
    const [fetchMoreDropDownData,setFetchMoreDropDownData] = useState([false, ""]);
    const [dataDropDownChange,setDataDropDownChange] = useState(false);

    const [requestConfirmation, setRequestConfirmation] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);

    const [modalData, setModalData] = useState({});
    const [requestData, setRequestData] = useState({});
    const [cartData, setCartData] = useState({})

    const [refreshImage, setRefreshImage] = useState('')
    const [hasMoreCard, setHasMoreCard] = useState(true);
    const [hasMoreDropDown, setHasMoreDropDown] = useState({});
    const [isCurrentServing, setIsCurrentServing] = useState(true);
    const [dataBinStorage,setDataBinStorage] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" })

    let history = {
        dataSparePartURL : "/sparepart/list?order=id,desc&sediaan_id=" + Cookies.get("idServing") +  "&is_active=true&&offset=",
        dataBinStorageURL : "/sparepart/list?order=updatedAt,desc&pending_bin=1&sediaan_id=" + Cookies.get("idServing") + "&limit=9999&dropdown=part_code&count=1",
        imageSparePartURL : "/image/item?id=",
        dataDropDownSparePartURL : "/sparepart/list?sediaan_id=" + Cookies.get("idServing") + "&is_active=true&dropdown=",
        postRequestNow : "/request/now",
        postRequestCart : "/request/submit",
        dataCartURL : "/request/cart",
        itemServingURL : "/sediaan/item?id=",
        accessToken : Cookies.get("accessToken"),
        idServing : Cookies.get("idServing"),
        serving : Cookies.get("serving"),
        role : Cookies.get("role"),
    }

    const handleCheckServing = async () => {
        try {
            let getDataServing = await Axios.get(history.itemServingURL + history.idServing,
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });

            if (getDataServing?.data?.data?.sediaan?.nama !== history.serving) setIsCurrentServing(false);
        }
        catch (error) {
            console.error(error);
        }
    }

    const handleCheckBinStorage = async () => {
        try {
            let getDataBinStorage = await Axios.get(history.dataBinStorageURL,
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });

            setDataBinStorage(getDataBinStorage?.data?.data?.spareparts?.part_code);
        }
        catch (error) {
            console.error(error);
        }
    }

    const handleGetDataSpareParts = async () => {
        try{
            if (dataInitialization) state.dataOffset += 25;

            let getDataSpareParts = await Axios.get(history.dataSparePartURL + state.dataOffset,
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });

            (getDataSpareParts?.data?.data?.spareparts).map(async (dataSparePart) => {
                if (!dataSparePart["image_bin"] && dataSparePart?.image_id && dataSparePart?.image_id !== 1) {
                    let getDataImage = await Axios.get(history.imageSparePartURL + dataSparePart.image_id,
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });

                    dataSparePart["image_bin"] = getDataImage?.data?.data?.image?.bin;

                    setRefreshImage("fetchData");
                }
            })

            if ((getDataSpareParts?.data?.data?.spareparts)?.length < 25) {
                setHasMoreCard(false);
            }
            else setHasMoreCard(true);

            setDataSpareParts(dataSpareParts.concat(getDataSpareParts?.data?.data?.spareparts));

            if (!dataInitialization) {
                setTempDataSpareParts(getDataSpareParts?.data?.data?.spareparts);
            }
        }
        catch(error){
            console.error(error);
        }
    };

    const handleGetDataDropDownSpareParts = async () => {
        let objectDropDown = {};
        let objectHasMore = {};
        state.dropDownOffset = state.offset;

        state.dropDownList.map(async (dropDownListItem) => {
            let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            objectHasMore[dropDownListItem] = (getDataDropDownSpareParts?.data?.data?.spareparts)[dropDownListItem]?.length >= 25;
            setHasMoreDropDown(objectHasMore);

            if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
            objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
        })

        setDataDropDownSpareParts(objectDropDown);
    };

    const handleGetMoreDataDropDownSpareParts = async (dropDownListItem) => {
        let objectDropDown = JSON.parse(JSON.stringify(dataDropDownSpareParts));
        let objectHasMore = JSON.parse(JSON.stringify(hasMoreDropDown));

        let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + "&offset=" + state.dropDownOffset,
            {
                headers: {"Authorization": "Bearer " + history.accessToken},
            });

        objectHasMore[dropDownListItem] = (getDataDropDownSpareParts?.data?.data?.spareparts)[dropDownListItem]?.length >= 25;
        setHasMoreDropDown(objectHasMore);

        objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem])
        setDataDropDownSpareParts(objectDropDown);

        state.dropDownOffset += state.offset;
    };

    const handleGetDataDependentDropDownSpareParts = async (dropDownList) => {
        let objectDropDown = {};
        let objectHasMore = {};

        let queryString = '';

        Object.keys(dropDownList).map(async (dropDownListItem) => {
            if (dropDownList[dropDownListItem].length) {
                queryString = queryString.concat('&', dropDownListItem, '=', dropDownList[dropDownListItem])
            }
        })

        let searchString = queryString.replace(/ /g, '_');

        Object.keys(dropDownList).map(async (dropDownListItem) => {
            if (dropDownList[dropDownListItem].length === 0) {
                let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + searchString + "&limit=999",
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                objectHasMore[dropDownListItem] = false
                setHasMoreDropDown(objectHasMore);

                if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
                objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
            }
            else {
                let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + "&limit=999",
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                objectHasMore[dropDownListItem] = false
                setHasMoreDropDown(objectHasMore);

                if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
                objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
            }
        })

        setDataDropDownSpareParts(objectDropDown);
    };

    const handleSearchDataDropDownSparePartListItem = async (dropDownListItem, queryString) => {
        let objectDropDown = JSON.parse(JSON.stringify(dataDropDownSpareParts));
        let objectHasMore = JSON.parse(JSON.stringify(hasMoreDropDown));

        let tempString = queryString.replace(/ /g, '_');
        tempString = tempString.replace('.', '_');

        let getDataDropDownSpareParts = await Axios.get(`${history.dataDropDownSparePartURL + dropDownListItem}&${dropDownListItem}=%${tempString}%&limit=9999`,
            {
                headers: {"Authorization": "Bearer " + history.accessToken},
            });

        objectHasMore[dropDownListItem] = false;
        setHasMoreDropDown(objectHasMore);

        objectDropDown[dropDownListItem].splice(0, objectDropDown[dropDownListItem].length);
        objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);

        setDataDropDownSpareParts(objectDropDown);
        setDataDropDownChange(true);
    };

    const handleSearchSpareParts = async (dropDownValue) => {
        let searchQuery = '';

        Object.keys(dropDownValue).map((elem) => {
            if (dropDownValue[elem]) return searchQuery += ("&" + elem + "=" + dropDownValue[elem])
            return ''
        })

        try{
            let getDataSpareParts = await Axios.get(history.dataSparePartURL + 0 + "&limit=9999" + searchQuery,
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });

            (getDataSpareParts?.data?.data?.spareparts).map(async (dataSparePart) => {
                if (!dataSparePart["image_bin"] && dataSparePart?.image_id && dataSparePart?.image_id !== 1) {
                    let getDataImage = await Axios.get(history.imageSparePartURL + dataSparePart.image_id,
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });

                    dataSparePart["image_bin"] = getDataImage?.data?.data?.image?.bin;

                    setRefreshImage("fetchSearchData");
                }
            })

            setHasMoreCard(false);
            
            setDataSpareParts(getDataSpareParts?.data?.data?.spareparts);
        }
        catch(error){
            console.error(error);
        }
        setIsSearchActive(true);
    };

    const handleUseOldDataSpareParts = () => {
        let tempData = Array.from(tempDataSpareParts);
        if (tempData.length !== 0) setDataSpareParts(tempData);
        state.dataOffset = 0;

        if (tempData.length >= 25) setHasMoreCard(true);
    };

    const handleRequestSpareParts = async () => {
        try{
            let postRequest = await Axios.post(history.postRequestNow + (history.role === "Superior" ? ("?sediaan_id=" + history.idServing) : ''),
                {sparepart_id: modalData.id, qty_request: (requestData.qty_requestChange ? requestData.qty_requestChange : 1), machine_id: requestData.machine},
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });

            console.log(postRequest);
            setModalData({});
            setRequestData({});
            onOpenSuccessModal();
        }
        catch(error){
            console.error(error);
        }
    }

    const handleRequestCart = async (checkBoxData) => {
        try{
            let postRequest = await Axios.post(history.postRequestCart + (history.role === "Superior" ? ("?sediaan_id=" + history.idServing) : ''),
                {request_sparepart_id: checkBoxData},
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });

            console.log(postRequest);
            onOpenSuccessModal();
        }
        catch(error){
            console.error(error);
        }
        handleGetDataCart().then();
    }

    const handleChangeQuantityRequestCart = (requestQuantityChange, checkBoxData) => {
        let tempChangeQuantity = {}
        checkBoxData.map((elem) => (
            requestQuantityChange.hasOwnProperty(elem) ? tempChangeQuantity[elem] = requestQuantityChange[elem] : ''
        ))
        Object.keys(tempChangeQuantity).map(async (id) => {
            let updateDataCart = await Axios.post(history.dataCartURL + (history.role === "Superior" ? ("?sediaan_id=" + history.idServing) : ''),
                {request_sparepart_id: id, qty_request: tempChangeQuantity[id]},
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                })

            console.log(updateDataCart);
        })
    }

    const handleGetDataCart = async () => {
        try{
            let getDataCart = await Axios.get(history.dataCartURL + (history.role === "Superior" ? ("?sediaan_id=" + history.idServing) : ''),
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });
            setCartData(getDataCart?.data?.data?.request)
        }
        catch(error){
            console.error(error);
        }
    };

    const handleAddToCart = async () => {
        try{
            let addDataCart = await Axios.post(history.dataCartURL + (history.role === "Superior" ? ("?sediaan_id=" + history.idServing) : ''),
                {sparepart_id: modalData.id, qty_request: (requestData.qty_requestChange ? requestData.qty_requestChange : 1), machine_id: requestData.machine },
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });
            console.log(addDataCart);
            setRequestData({});
            setModalData({})
            handleGetDataCart().then();
            onOpenSuccessModal();
        }
        catch(error){
            console.error(error);
        }
    };

    const handleDeleteItemCart = (checkBoxData) => {
        try{
            checkBoxData.map(async (elem) => {
                let deleteDataCart = await Axios.delete(history.dataCartURL + "?request_sparepart_id=" + elem,
                    {
                        headers: {'Authorization': 'Bearer ' + history.accessToken},
                    })

                handleGetDataCart().then();
                console.log(deleteDataCart);
            })

            onOpenSuccessModal();
        }
        catch(error){
            console.error(error);
        }
    };

    const {
        isOpen: isOpenSparePartPopUp,
        onOpen: onOpenSparePartPopUp,
        onClose: onCloseSparePartPopUp
    } = useDisclosure()

    const {
        isOpen: isOpenSparePartCart,
        onOpen: onOpenSparePartCart,
        onClose: onCloseSparePartCart
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenErrorModal,
        onOpen: onOpenErrorModal,
        onClose: onCloseErrorModal
    } = useDisclosure()

    useEffect(() => {
        if (!isSearchActive) if (fetchMoreData) handleGetDataSpareParts().then(() => setFetchMoreData(false));
        if (!dataInitialization) {
            handleCheckServing().then();
            handleCheckBinStorage().then();
            handleGetDataCart().then();
            handleGetDataDropDownSpareParts().then();
            handleGetDataSpareParts().then(() => setDataInitialization(true));
        }
        if (requestConfirmation === "requestNow") handleRequestSpareParts().then(() => setRequestConfirmation(''));
        else if (requestConfirmation === "addToCart") handleAddToCart().then(() => setRequestConfirmation(''));
        if (fetchMoreDropDownData[0]) handleGetMoreDataDropDownSpareParts(fetchMoreDropDownData[1]).then(() => setFetchMoreDropDownData([false, '']));
        if (dataDropDownChange) setDataDropDownChange(false);
        if (refreshImage !== '') setRefreshImage('');
    }, [fetchMoreData, fetchMoreDropDownData, requestConfirmation, isSearchActive, dataDropDownChange, refreshImage]);

    return (
        <>
            <Navbar />

            <SparePartPopUp
                isOpen={isOpenSparePartPopUp}
                onOpen={onOpenSparePartPopUp}
                onClose={onCloseSparePartPopUp}
                setRequestConfirmation={setRequestConfirmation}
                modalData={modalData}
                requestData={requestData}
                setRequestData={setRequestData}
                type={"Request"}
            />
            <SparePartCart
                isOpen={isOpenSparePartCart}
                onOpen={onOpenSparePartCart}
                onClose={onCloseSparePartCart}
                cartData={cartData}
                handleDeleteItemCart={handleDeleteItemCart}
                handleRequestCart={handleRequestCart}
                handleChangeQuantityRequestCart={handleChangeQuantityRequestCart}
                type={"Request"}
            />
            <Modal
                isOpen={isOpenSuccessModal}
                onOpen={onOpenSuccessModal}
                onClose={onCloseSuccessModal}
                type={"Success"}
            />

            <Modal
                isOpen={isOpenErrorModal}
                onOpen={onOpenErrorModal}
                onClose={onCloseErrorModal}
                errorMessage={errorMessage}
                type={"Error"}
            />

            {!isTabletOrMobile ?
                <Container maxW={"full"} paddingLeft={"30vw"} paddingRight={"0%"} paddingBottom={"0%"}>
                    <Box position={"fixed"} left={'0'} zIndex={1}>
                        <SidebarRequest
                            dataDropDownSpareParts={dataDropDownSpareParts}
                            handleGetDataDropDownSpareParts={handleGetDataDropDownSpareParts}
                            handleGetDataDependentDropDownSpareParts={handleGetDataDependentDropDownSpareParts}
                            handleSearchSpareParts={handleSearchSpareParts}
                            onOpenSparePartCart={onOpenSparePartCart}
                            setIsSearchActive={setIsSearchActive}
                            setDataInitialization={setDataInitialization}
                            handleUseOldDataSpareParts={handleUseOldDataSpareParts}
                            cartData={cartData}
                            setFetchMoreDropDownData={setFetchMoreDropDownData}
                            handleSearchDataDropDownSparePartListItem={handleSearchDataDropDownSparePartListItem}
                            hasMoreDropDown={hasMoreDropDown}
                        />
                    </Box>
                    <Box bg={"#F3F8F8"}>
                        <ListRequest
                            dataSpareParts={dataSpareParts}
                            dataBinStorage={dataBinStorage}
                            setFetchMoreData={setFetchMoreData}
                            onOpenSparePartPopUp={onOpenSparePartPopUp}
                            onOpenErrorModal={onOpenErrorModal}
                            setErrorMessage={setErrorMessage}
                            setModalData={setModalData}
                            setRefreshImage={setRefreshImage}
                            hasMoreCard={hasMoreCard}
                            isCurrentServing={isCurrentServing}
                        />
                    </Box>
                </Container>
                :
                <>
                    <Container width={"100vw"}>
                        <HeaderRequestMobile
                            dataDropDownSpareParts={dataDropDownSpareParts}
                            handleGetDataDropDownSpareParts={handleGetDataDropDownSpareParts}
                            handleGetDataDependentDropDownSpareParts={handleGetDataDependentDropDownSpareParts}
                            handleSearchSpareParts={handleSearchSpareParts}
                            onOpenSparePartCart={onOpenSparePartCart}
                            setIsSearchActive={setIsSearchActive}
                            setDataInitialization={setDataInitialization}
                            handleUseOldDataSpareParts={handleUseOldDataSpareParts}
                            cartData={cartData}
                            setFetchMoreDropDownData={setFetchMoreDropDownData}
                            handleSearchDataDropDownSparePartListItem={handleSearchDataDropDownSparePartListItem}
                            hasMoreDropDown={hasMoreDropDown}
                        />
                    </Container>

                    <Divider variant={"dashed"} borderColor={process.env.REACT_APP_BORDER_COLOR}/>

                    <ListRequestMobile
                        dataSpareParts={dataSpareParts}
                        dataBinStorage={dataBinStorage}
                        setFetchMoreData={setFetchMoreData}
                        onOpenSparePartPopUp={onOpenSparePartPopUp}
                        onOpenErrorModal={onOpenErrorModal}
                        setErrorMessage={setErrorMessage}
                        setModalData={setModalData}
                        setRefreshImage={setRefreshImage}
                        hasMoreCard={hasMoreCard}
                        isCurrentServing={isCurrentServing}
                    />
                </>
            }

            <Footer />
        </>
    );
}

export default Request;
