import React, {useEffect, useState, useRef} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive"

import Cookies from "js-cookie";
import Axios from "../../services/api/Axios"

import {
    Flex,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    InputRightElement,
    InputGroup,
    Text,
    Link,
    Button,
    Heading,
    Image,
    useDisclosure,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import Modal from "../../components/Modal/AreYouSure"
import Footer from "../../components/Footer/Footer";
import iconSparePart from "../../assets/img/wpm.svg";

const Login = () => {
    const history = {
        loginURL : "/auth/login",
        resetURL : "/auth/reset",
        sparePartLoginParams : "?appSource=WPM Warehouse",
        navigate : useNavigate(),
        location : useLocation()
    }

    const {
        isOpen: isOpenRequestModal,
        onOpen: onOpenRequestModal,
        onClose: onCloseRequestModal
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const inputRef = useRef();

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" })

    const [focusedUsername, setFocusedUsername] = useState('');
    const [focusedEmail, setFocusedEmail] = useState('');
    const [focusedPassword, setFocusedPassword] = useState('');

    const [focusedError, setFocusedError] = useState('');
    const [focusedSuccess, setFocusedSuccess] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);

    const [focusedCheckBox, setFocusedCheckBox] = useState(false);
    const [cookiesExpires, setCookiesExpires] = useState(1)

    const [confirmSentResetPassword, setConfirmSentResetPassword] = useState(false)

    const handleLogin = async () => {
        try{
            let statusSignIn = await Axios.post(history.loginURL + history.sparePartLoginParams,
                {username: focusedUsername, password: focusedPassword},
            );
            const accessToken = statusSignIn.data?.data?.token;
            Cookies.set("accessToken", accessToken, {expires: cookiesExpires}, {secure: true, sameSite: "none"});
            setFocusedSuccess(true);
        }
        catch(error){
            setFocusedError(error.response?.data?.errors[0]?.message);
            setFocusedPassword('');
        }
    }

    const handleSentResetPassword = async () => {
        try{
            await Axios.post(history.resetURL,
                {email: focusedEmail, url: window.location.href.replace(history.loginURL, history.resetURL + "Password")},
            );

            onOpenSuccessModal();
        }
        catch(error){
            inputRef.current.blur();
            setFocusedError(error.response?.data?.errors[0]?.message);
        }
    }

    useEffect(() => {
        if(focusedSuccess) history.navigate("/home");
        if(focusedCheckBox) setCookiesExpires(7);
        if(confirmSentResetPassword) handleSentResetPassword().then(() => setConfirmSentResetPassword(false));
    }, [focusedSuccess, focusedCheckBox, confirmSentResetPassword])

    return (
        <>
            <Modal
                isOpen={isOpenRequestModal}
                onOpen={onOpenRequestModal}
                onClose={onCloseRequestModal}
                type={"Request"}
                setConfirmSentResetPassword={setConfirmSentResetPassword}
            />

            <Modal
                isOpen={isOpenSuccessModal}
                onOpen={onOpenSuccessModal}
                onClose={onCloseSuccessModal}
                type={"Success"}
            />

            <Flex
                minH={"100vh"}
                align={"center"}
                justify={"center"}
                bg={"white"}
                _dark={{ bg: "gray.400" }}
            >
                <Stack style={{padding: "0px"}} spacing={4} mx={"auto"} width={isTabletOrMobile ? "300px" : "500px"} py={12} px={6}>
                    <Stack align={"center"}>
                        <Image
                            alt={"WORKPLACE Icon"}
                            objectFit={"cover"}
                            src={iconSparePart}
                        />
                        <Heading
                            fontWeight={"bold"}
                            fontSize={"3xl"}
                            textAlign={"center"}
                            background={"linear-gradient(180deg, " + process.env.REACT_APP_ENV_COLOR + " 0%, #193D2D 100%)"}
                            backgroundClip={"text"}
                            style={{marginBottom:"1rem"}}
                        >
                            WPM WAREHOUSE MANAGEMENT
                        </Heading>
                        {!forgotPassword ?
                            ''
                            :
                            <>
                                <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                    Forgot Password?
                                </Text>
                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    We will send an email to reset your password
                                </Text>
                            </>
                        }
                    </Stack>
                    <Stack mx={"auto"}>
                        <FormControl id={!forgotPassword ? "username" : "email"}>
                            <FormLabel
                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                fontWeight={"bold"}
                            >
                                {!forgotPassword ? "Username" : "Email"}
                            </FormLabel>
                            <Input
                                ref={inputRef}
                                width={"100%"}
                                type={!forgotPassword ? "text" : "email"}
                                placeholder={!forgotPassword ? "Username" : "Email"}
                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                borderColor={!(!!focusedError) ? process.env.REACT_APP_INPUT_COLOR : "red.500"}
                                focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                _hover={{
                                    borderColor: (!forgotPassword ? (!(!!focusedError) ? process.env.REACT_APP_TEXT_COLOR_BOLD : "red.700") : process.env.REACT_APP_TEXT_COLOR_BOLD )
                                }}
                                onClick={() => setFocusedError('')}
                                onChange={!forgotPassword ? (e) => setFocusedUsername(e.target.value) : (e) => setFocusedEmail(e.target.value)}
                                onKeyPress={!forgotPassword ? ((e) => e.key === "Enter" ? handleLogin().then(e.currentTarget.blur()) : '') : ((e) => e.key === "Enter" ? onOpenRequestModal() : '')}
                                value={!forgotPassword ? focusedUsername : focusedEmail}
                                required
                            />
                        </FormControl>
                        {!forgotPassword ?
                            <>
                                <FormControl id={"password"}>
                                    <FormLabel
                                        color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                        fontWeight={"bold"}
                                    >
                                        Password
                                    </FormLabel>
                                    <InputGroup>
                                        <Input
                                            width={"100%"}
                                            type={showPassword ? "text" : "password"}
                                            placeholder={"***********"}
                                            color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                            borderColor={!(!!focusedError) ? process.env.REACT_APP_INPUT_COLOR : "red.500"}
                                            focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                            _hover={{
                                                borderColor: (!forgotPassword ? (!(!!focusedError) ? process.env.REACT_APP_TEXT_COLOR_BOLD : "red.700") : process.env.REACT_APP_TEXT_COLOR_BOLD )
                                            }}
                                            onClick={() => setFocusedError('')}
                                            onChange={(e) => setFocusedPassword(e.target.value)}
                                            onKeyPress={(e) => e.key === "Enter" ? handleLogin().then(e.currentTarget.blur()) : ''}
                                            value={focusedPassword}
                                            required
                                        />
                                        <InputRightElement h={"full"}>
                                            <Button
                                                variant={"ghost"}
                                                color={process.env.REACT_APP_BORDER_COLOR}
                                                onClick={() =>
                                                    setShowPassword((showPassword) => !showPassword)
                                                }
                                                _hover={{
                                                    bg: "transparent"
                                                }}
                                                _active={{
                                                    bg: "transparent"
                                                }}
                                            >
                                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                            </>
                            :
                            ''
                        }
                        {!!focusedError ?
                            <>
                                <Text fontSize={"sm"} color={"red"}>
                                    {focusedError}
                                </Text>
                            </>
                            :
                            ''
                        }
                        <Stack spacing={4}>
                            <Stack
                                direction={{ base: "column", sm: "row" }}
                                align={"start"}
                                justify={!forgotPassword ? "space-between" : "end"}
                            >
                                {!forgotPassword ?
                                    <>
                                        <Checkbox
                                            color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                            colorScheme={process.env.REACT_APP_BORDER_COLOR}
                                            borderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                            onChange={() => setFocusedCheckBox((focusedCheckBox) => !focusedCheckBox)}
                                        >
                                            Remember me
                                        </Checkbox>
                                    </>
                                    :
                                    ''
                                }
                                <Link
                                    color={process.env.REACT_APP_TEXT_COLOR_LIGHT}
                                    fontWeight={"bold"}
                                    onClick={() => {
                                        setFocusedEmail(focusedUsername);
                                        setFocusedUsername('');
                                        setFocusedPassword('');
                                        setFocusedError('');
                                        setForgotPassword((forgotPassword) => !forgotPassword);
                                    }
                                    }
                                >
                                    {!forgotPassword ? "Forgot password?" : "Back to Login"}
                                </Link>
                            </Stack>
                            <Button
                                bg={process.env.REACT_APP_ENV_COLOR}
                                color={"white"}
                                _hover={{
                                    bg: process.env.REACT_APP_BUTTON_HOVER_COLOR,
                                }}
                                _active={{
                                    bg: process.env.REACT_APP_TEXT_COLOR_LIGHT
                                }}
                                onClick={!forgotPassword ? handleLogin : onOpenRequestModal}
                                disabled={!forgotPassword ? (!(!!focusedUsername && !!focusedPassword)) : (!(!!focusedEmail))}
                            >
                                {!forgotPassword ? "Login" : "Send"}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Flex>
            <Footer />
        </>
    );
}

export default Login;