import React  from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Hero_ExportSparepart from "../../../components/Hero/Sparepart/Export/ExportSparepart_Hero";
import Footer from "../../../components/Footer/Footer";
import {Container} from "@chakra-ui/react";

const SparePartExport = () => {
    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingTop={"2vh"}>
                <Hero_ExportSparepart page={"export"}/>
            </Container>
            <Footer />
        </>
    );
}

export default SparePartExport;