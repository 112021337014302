import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Box,
    Flex,
    HStack,
    Link,
    IconButton,
    Text,
    Image,
    useColorModeValue, Select,
} from "@chakra-ui/react";

import Cookies from "js-cookie";
import Axios from "../../services/api/Axios"

import NavigationGuard from "../../services/hooks/NavigationGuard";
import NavbarItem from "./NavbarItem";
import iconSparePart from "../../assets/icons/footer/FooterWPM.svg";

const Navbar = (props) => {
    const state = {
        name: "",
        role: "",
        serving: "",
        servings: [],
    }

    const history = {
        routeHome : "/home",
        userURL : "/user",
        servingURL : "/sediaan/list",
        accessToken : Cookies.get("accessToken"),
        navigate : useNavigate(),
    }

    const [dataInitialization, setDataInitialization] = useState(false);
    const [navigateHome, setNavigateHome] = useState(false);
    const [servings, setServings] = useState([]);
    const [changeServing, setChangeServing] = useState(false);
    const [currentServing, setCurrentServing] = useState('');

    const handleGetUserInfo = async () => {
        try{
            let statusUser = await Axios.get(history.userURL,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            state.name = statusUser.data?.data?.user?.name;
            state.role = statusUser.data?.data?.user?.roleName;
            state.serving = statusUser.data?.data?.user?.sediaan_id;
            Cookies.set("name", state.name, {secure: true, sameSite: "none"});
            Cookies.set("role", state.role, {secure: true, sameSite: "none"});

            if (state.role === "Superior") {
                if (!Cookies.get("idServing")) Cookies.set("idServing", state.serving, {secure: true, sameSite: "none"});
            }
            else {
                Cookies.set("idServing", state.serving, {secure: true, sameSite: "none"});
            }

            try{
                let statusServing = await Axios.get(history.servingURL,
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                state.servings = await statusServing.data?.data?.sediaans;
                setServings(await statusServing.data?.data?.sediaans);
                const serving = await state.servings.find(x => x.id === state.serving).nama;

                setCurrentServing(serving)
                try {
                    props.setCurrentServing(serving)
                }
                catch (err){
                }

                Cookies.set("serving", await serving, {secure: true, sameSite: "none"});
            }
            catch(error){
                console.error(error);
            }

        }
        catch(error){
            console.error(error);
        }
    }

    const handleChangeServingSuperior = (serving) => {
        if (serving !== Cookies.get("idServing")) {
            Cookies.set("idServing", serving, {secure: true, sameSite: "none"});
            // try {props.setServingChange(true);}
            // catch (error) {
            // }
            setChangeServing(true);
        }
    }

    useEffect(() => {
        if (!dataInitialization) handleGetUserInfo().then(() => setDataInitialization(true));
        if (navigateHome) history.navigate(history.routeHome);
    }, [navigateHome, currentServing])

    return (
        <>
            <Box
                zIndex={99}
                height={"5rem"}
                position={"sticky"}
                top={0}
                bg={useColorModeValue(process.env.REACT_APP_ENV_COLOR, "green.900")}
                px={4}
            >
                <Flex h={20} alignItems={"center"} justifyContent={"space-between"}>
                    <IconButton
                        bg={useColorModeValue(process.env.REACT_APP_ENV_COLOR, "green.900")}
                        _hover={{
                            textDecoration: "none",
                            outlineColor: process.env.REACT_APP_BORDER_COLOR
                        }}
                        _active={{
                            textDecoration: "none",
                            outlineColor: "white"
                        }}
                        size={"md"}
                        icon={<Image src={iconSparePart} alt={"icon Workplace"} boxSize={"40px"} objectFit={"cover"}/>}
                        aria-label={"Open Menu"}
                        display={{ md: "none" }}
                        onClick={() => setNavigateHome(true)}
                    />
                    <HStack spacing={8} alignItems={"center"} w={"35vw"}>
                        <HStack
                            as={"nav"}
                            spacing={4}
                            display={{ base: "none", md: "flex" }}
                            w={"full"}
                        >
                            <Link
                                px={2}
                                py={1}
                                rounded={"md"}
                                _hover={{
                                    textDecoration: "none",
                                }}
                                w={"full"}
                            >
                                {Cookies.get("role") === "Superior" ?
                                    <HStack spacing={2} w={"full"}>
                                        <Text
                                            _hover={{
                                                textColor: process.env.REACT_APP_TEXT_COLOR_BOLD,
                                            }}
                                            fontSize={"25px"}
                                            fontWeight={"bold"}
                                            className={"text-white"}
                                            onClick={() => setNavigateHome(true)}
                                        >
                                            WPM Warehouse Management
                                        </Text>
                                        <Select
                                            maxW={"25%"}
                                            color={"whitesmoke"}
                                            textColor={"purple.800"}
                                            backgroundColor={"whiteAlpha.300"}
                                            borderColor={process.env.REACT_APP_INPUT_COLOR }
                                            borderRadius={"xl"}
                                            focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                            _hover={{
                                                borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                            }}
                                            textAlign={"center"}
                                            bg="white"
                                            fontWeight={"semibold"}
                                            value={Number(Cookies.get("idServing"))}
                                            onChange={(e) => handleChangeServingSuperior(e.target.value)}
                                            isDisabled={window.location.pathname !== "/home"}
                                        >
                                            {(servings).map((elem) => (
                                                    <option key={Number(elem.id)} value={Number(elem?.id)}>{(elem.nama).charAt(0).toUpperCase() + (elem.nama).slice(1)}</option>
                                                ))}
                                        </Select>
                                    </HStack>
                                    :
                                    <Text
                                        _hover={{
                                            textColor: process.env.REACT_APP_TEXT_COLOR_BOLD,
                                        }}
                                        fontSize={"25px"}
                                        fontWeight={"bold"}
                                        className={"text-white"}
                                        onClick={() => setNavigateHome(true)}
                                    >
                                        {Cookies.get("serving") ? ("WPM Warehouse Management " + Cookies.get("serving").charAt(0).toUpperCase() + Cookies.get("serving").slice(1)) : ("WPM Warehouse Management " + currentServing.charAt(0).toUpperCase() + currentServing.slice(1))}
                                    </Text>
                                }
                            </Link>
                        </HStack>
                    </HStack>
                    <Flex alignItems={"center"}>
                        <NavbarItem changeServing={changeServing} setChangeServing={setChangeServing} />
                    </Flex>
                </Flex>
            </Box>
        </>
    );
}

export default NavigationGuard(Navbar);