import React, {Fragment, useEffect, useState} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import SparePartSubHeader from "./SubHeader";
import {
    Button,
    ButtonGroup, Card, CardBody, CardFooter, CardHeader, Center, Checkbox,
    Container,
    Divider,
    Flex, Heading, HStack,
    Image, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select,
    Spacer,
    Table, TableContainer, Tbody, Td,
    Text,
    Th,
    Thead,
    Tr, useDisclosure
} from "@chakra-ui/react";
import Modal from "../../components/Modal/AreYouSure";
import {useNavigate} from "react-router-dom";
import BackIcon from "../../assets/icons/hero/Sparepart/Hero_back.svg";
import Cookies from "js-cookie";
import Axios from "../../services/api/Axios";
import EmptyList from "../../assets/img/empty.svg";
import ModalQR from "../../components/Modal/SparePartQR";
import {CheckCircleIcon} from "@chakra-ui/icons";
import SparePartCart from "../../components/Modal/SparePartCart";

const IncomingOrder = () => {
    const navigate = useNavigate();

    let history = {
        dataSparePartQR: "/sparepart/item?id=",
        listReOrderURL : "/reorder/list?status=SUBMIT&sediaan_id=" + Cookies.get("idServing") +  "&limit=9999",
        updateIncomingStatus: "/incoming/update?id=",
        deleteIncoming: "/incoming/delete?id=",
        postIncomingURL : "/incoming/submit?id=",
        accessToken : Cookies.get("accessToken"),
    }

    const [dataIncoming, setDataIncoming] = useState([]);
    const [dataInitialization, setDataInitialization] = useState(false);
    const [checkBoxes, setCheckBoxes] = useState({});
    const [isActionButtonDisable, setIsActionButtonDisable] = useState([]);
    const [invalidState, setInvalidState] = useState([]);
    const [isCheckBoxState, setIsCheckBoxState] = useState([]);
    const [confirmAction, setConfirmAction] = useState('');
    const [cartData, setCartData] = useState({});
    const [modalData, setModalData] = useState({});
    const [promisesModal, setPromisesModal] = useState([]);
    const [countPromisesModal, setCountPromisesModal] = useState({
        "now" : 0,
        "total": 0,
        "currentID": 0,
    })
    const [isOpenModalList, setIsOpenModalList] = useState(false);
    const [postLabelQR, setPostLabelQR] = useState({});

    const zeroPad = (num, places) => String(num).padStart(places, '0');

    const columnNames = {
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "part_category" : "Item Category",
        "qty_receive" : "Incoming Quantity",
        "qty_uom" : "Unit",
        "price" : "Item Price",
        "total_price" : "Total Price",
        "main_supplier" : "Main Supplier",
        "status" : "Status Order",
    }

    const {
        isOpen: isOpenRequestModal,
        onOpen: onOpenRequestModal,
        onClose: onCloseRequestModal
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenSparePartCart,
        onOpen: onOpenSparePartCart,
        onClose: onCloseSparePartCart
    } = useDisclosure()

    const {
        isOpen: isOpenReportModalQR,
        onOpen: onOpenReportModalQR,
        onClose: onCloseReportModalQR
    } = useDisclosure()

    const handleOrder = async () => {
        let listReOrderCase = await Axios.get(history.listReOrderURL,
            {
                headers: {'Authorization': 'Bearer ' + history.accessToken},
            });

        let listIncomingCase = [];

        listReOrderCase?.data?.data?.reorders.map((elem) => {
            if ((elem.case_number).slice(0, 3) !== "NPO") {
                let tempIncomingArray = [];
                (elem.reorderSpareparts).map((value) => {
                    if (value.status === "incoming") {
                        tempIncomingArray.push(value);
                    }
                })

                if (tempIncomingArray.length !== 0) {
                    listIncomingCase.push({...elem, "reorderSpareparts": tempIncomingArray})
                }
            }
        })

        setDataIncoming(listIncomingCase);
    }

    const handleManipulateLabelQR = (dataSparePart) => {
        const dataQR = dataSparePart?.meta?.labelQR ? dataSparePart?.meta?.labelQR : [];
        const binLabelQR = dataSparePart?.meta?.binQR ? dataSparePart?.meta?.binQR : {};
        const batchQR = dataSparePart?.meta?.batchQR ? dataSparePart?.meta?.batchQR : {};

        const year = new Date().getFullYear();

        let codeQR = '';
        let tempArray = Array.from(dataQR);
        let tempObject = {}

        tempObject["year"] = Number(year)
        tempObject["quantity"] = Number(dataSparePart.qty_receive)

        if (!batchQR[year]) {
            batchQR[year] = 1;
        }
        else {
            batchQR[year] += 1;
        }

        tempObject["sequence"] = batchQR[year];

        codeQR = zeroPad(tempObject["year"] % 100, 2) + '-' + zeroPad(dataSparePart.sediaan_id, 2) + '-' + dataSparePart.part_code + '-' + zeroPad(tempObject["sequence"], 2);

        tempArray.push(tempObject);
        binLabelQR[codeQR] = tempObject["quantity"]

        console.log(tempArray);

        return [codeQR, dataSparePart.id, tempArray, binLabelQR, batchQR]
    }

    function handleInvalidState(id, quantity) {
        if (Number(quantity) === 0) {
            let tempArray = [];
            tempArray.push(id);
            if (!invalidState.includes(id)) setInvalidState(invalidState.concat(tempArray));
        }
        else {
            if (invalidState.includes(id)) {
                let index = invalidState.indexOf(id);
                invalidState.splice(index, 1);
            }
        }
    }

    const handleAction = async (event) => {
        switch (event) {
            case true:
                let promiseArray = [];

                try {
                    Object.keys(checkBoxes).map((elem) => {
                        Object.keys(checkBoxes[elem]).map((value) => {
                            promiseArray.push(new Promise((resolve, reject) => {
                                if (checkBoxes[elem][value]["isChecked"] === true && checkBoxes[elem][value]["qty_receive"]) {
                                    let incomingOrder = Axios.post(history.postIncomingURL + value,
                                        {qty_receive: checkBoxes[elem][value]["qty_receive"]},
                                        {
                                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                                        });

                                    console.log(incomingOrder);
                                    resolve(incomingOrder);
                                }
                            }))
                        })
                        return ''
                    })

                    await Promise.all(promiseArray);

                    handleOrder().then(() => {
                        setCheckBoxes({});
                        setIsActionButtonDisable([]);
                        onOpenSuccessModal();
                        setIsOpenModalList(true);
                    });

                } catch (error) {
                    console.error(error);
                }
                break;
            case "QR":
                let tempArrayModalPromises = [];
                let tempObjectPostLabelQR = {};

                Object.keys(checkBoxes).map((card) => {
                    Object.keys(checkBoxes[card]).map((item, index) => {
                        if (checkBoxes[card][item]["isChecked"] && checkBoxes[card][item]["isToBePrinted"]) {
                            let tempObjectSparePart = {};
                            let [labelQR, idPart, arrayLabelPart, objectBinLabelQR, objectBatchQR] = handleManipulateLabelQR(checkBoxes[card][item])

                            tempObjectPostLabelQR[idPart] = [arrayLabelPart, objectBinLabelQR, objectBatchQR];

                            tempObjectSparePart["sparepart_id"] = idPart;
                            tempObjectSparePart["qr_code"] = labelQR;
                            tempObjectSparePart["qty_print"] = checkBoxes[card][item]["qty_receive"];
                            tempObjectSparePart["part_code"] = checkBoxes[card][item]["part_code"];
                            tempObjectSparePart["part_name"] = checkBoxes[card][item]["part_name"];
                            tempObjectSparePart["type"] = checkBoxes[card][item]["type"];
                            tempObjectSparePart["bin_location"] = checkBoxes[card][item]["bin_location"];

                            tempArrayModalPromises.push(tempObjectSparePart);
                        }
                        return 0
                    })
                    return 0
                })

                setPostLabelQR(tempObjectPostLabelQR);
                setCountPromisesModal({
                    "now": 1,
                    "total": tempArrayModalPromises.length,
                    "currentID": 0,
                })
                setPromisesModal(tempArrayModalPromises.sort());
                setCheckBoxes({});
                break;
            case "postQR":
                let id = countPromisesModal["currentID"];
                let previousMeta = cartData?.requestSpareparts?.find(data => data?.sparepart?.id === id);

                Axios.post(history.dataSparePartQR + id,
                    {meta: {...previousMeta?.sparepart?.meta, "labelQR": postLabelQR[id][0], "binQR": postLabelQR[id][1], "batchQR": postLabelQR[id][2]}},
                    {
                        headers: {'Authorization': 'Bearer ' + history.accessToken},
                    }).then((response) => console.log(response));
                break;
            default:
                break;
        }
    }

    console.log(cartData);

    useEffect(() => {
        if (!dataInitialization) {handleOrder().then(() => setDataInitialization(true))}
        if (confirmAction !== '') handleAction(confirmAction).then(() => setConfirmAction(''));
        if (promisesModal.length) {
            setModalData(promisesModal[0]);
            onOpenReportModalQR();
        }
    }, [confirmAction, JSON.stringify(promisesModal)])

    useEffect(() => {
        if (isOpenModalList && !isOpenSuccessModal) {
            onOpenSparePartCart();
            setIsOpenModalList(false);
        }
    }, [isOpenModalList, isOpenSuccessModal])

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingTop={"2vh"}>
                <SparePartSubHeader position={"fixed"}/>

                <Modal
                    isOpen={isOpenRequestModal}
                    onOpen={onOpenRequestModal}
                    onClose={onCloseRequestModal}
                    setConfirmSentResetPassword={setConfirmAction}
                    type={"Request"}
                />

                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    setRedirect={setIsOpenModalList}
                    type={"Success"}
                />

                <SparePartCart
                    isOpen={isOpenSparePartCart}
                    onOpen={onOpenSparePartCart}
                    onClose={onCloseSparePartCart}
                    cartData={cartData}
                    checkBoxes={checkBoxes}
                    setCheckBoxes={setCheckBoxes}
                    setConfirmAction={setConfirmAction}
                    type={"Incoming"}
                />

                <ModalQR
                    isOpen={isOpenReportModalQR}
                    onOpen={onOpenReportModalQR}
                    onClose={onCloseReportModalQR}
                    modalData={modalData}
                    setModalData={setModalData}
                    promisesModal={promisesModal}
                    setPromisesModal={setPromisesModal}
                    countPromisesModal={countPromisesModal}
                    setCountPromisesModal={setCountPromisesModal}
                    setConfirmAction={setConfirmAction}
                    isMultiple={true}
                    type={"Incoming"}
                />

                <Flex marginTop={"12vh"} marginBottom={1} w={"98%"} position={"fixed"}>
                    <ButtonGroup gap={3}>
                        <Button leftIcon={<Image src={BackIcon} alt="" />} backgroundColor={process.env.REACT_APP_ENV_COLOR} borderRadius="8px" variant='solid' _hover={{ bg: process.env.REACT_APP_BUTTON_HOVER_COLOR }} _active={{ bg: process.env.REACT_APP_TEXT_COLOR_LIGHT }} boxShadow="lg" onClick={() => navigate("/incoming/list")}>
                            <Text className="text-white font-extrabold tracking-wide ml-2">Back</Text>
                        </Button>
                    </ButtonGroup>

                    <Spacer/>

                    <ButtonGroup gap={3}>
                        <Button
                            variant='solid'
                            colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                            rightIcon={<CheckCircleIcon/>}
                            isDisabled={isActionButtonDisable.length === 0 || invalidState.length > 0}
                            onClick={() => {
                                let tempCheckBoxes = JSON.parse(JSON.stringify(checkBoxes));
                                let tempObject = {};
                                let tempArray = [];

                                Object.keys(tempCheckBoxes).map((card) => {
                                    Object.keys(tempCheckBoxes[card]).map((item, index) => {
                                        if (tempCheckBoxes[card][item]["isChecked"]) {
                                            let tempDataObject = {}
                                            tempDataObject["case_number"] = tempCheckBoxes[card][item]["case_number"];
                                            tempDataObject["qty_receive"] = tempCheckBoxes[card][item]["qty_receive"];
                                            tempDataObject["sparepart"] = tempCheckBoxes[card][item];
                                            tempDataObject["sparepart"]["id"] = tempCheckBoxes[card][item]["id"];

                                            delete tempDataObject["sparepart"]["qty_receive"];
                                            tempArray.push(tempDataObject);
                                        }
                                        return 0
                                    })
                                    return 0
                                })

                                tempObject["requestSpareparts"] = tempArray;
                                tempObject["checkBoxesData"] = checkBoxes;

                                setCartData(tempObject);
                                onOpenRequestModal();
                            }}
                        >
                            Update Quantity
                        </Button>
                    </ButtonGroup>
                </Flex>

                <Divider variant={"dashed"} borderColor={process.env.REACT_APP_BORDER_COLOR} margin={2} w={"98%"} marginTop={"22vh"} position={"fixed"}/>

                {(dataIncoming)?.length === 0 ?
                    <Center w={"full"} marginTop={"12%"}>
                        <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                        <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                            No items in here..
                        </Text>
                    </Center>
                    :
                    <Container maxW={"full"} position={"relative"} marginTop={"22.5vh"} maxH={"62vh"} overflowY={"auto"}>
                        {dataIncoming.map((elem) => (
                            <Card
                                key={elem.id}
                                marginX={"5%"}
                                marginY={5}
                                variant={"outline"}
                                borderColor={process.env.REACT_APP_INPUT_COLOR}
                                boxShadow={"0 4px 8px 0 rgba(0, 0, 0, 0.2)"}>
                                <CardHeader>
                                    <Flex>
                                        <HStack spacing={5}>
                                            <Heading size='md' color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Order Case: {elem.case_number}</Heading>
                                            <Text fontWeight={"bold"} borderRadius={"md"} bg={"blue.100"} color={"royalblue"}>PIC: {elem.user?.name}</Text>
                                            <Text fontWeight={"bold"} borderRadius={"md"} bg={"red.100"} color={"mediumvioletred"}>{(new Date(elem.createdAt)).toString().slice(0, 33)}</Text>
                                        </HStack>
                                    </Flex>
                                </CardHeader>

                                <CardBody>
                                    <TableContainer whiteSpace={"normal"}>
                                        <Table variant="simple">
                                            <Thead zIndex={1} pos='sticky' top={0} bg={process.env.REACT_APP_TABLE_COLOR}>
                                                <Tr>
                                                    <Th>
                                                        <Checkbox
                                                            size="lg"
                                                            colorScheme="green"
                                                            borderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                            onChange={() => {
                                                                let tempArrayCheckBox = Array.from(isCheckBoxState);

                                                                if (tempArrayCheckBox.includes(elem.id)){
                                                                    let index = tempArrayCheckBox.indexOf(elem.id);
                                                                    tempArrayCheckBox.splice(index, 1);
                                                                }
                                                                else {
                                                                    tempArrayCheckBox.push(elem.id);
                                                                }

                                                                let tempObject = JSON.parse(JSON.stringify(checkBoxes));
                                                                if (!tempObject[elem.id]) tempObject[elem.id] = {};
                                                                let tempArray = Array.from(isActionButtonDisable);

                                                                elem.reorderSpareparts.map((item, index) => {
                                                                    if (!tempObject[elem.id][item.id]) tempObject[elem.id][item.id] = {};

                                                                    if (Object.keys(tempObject[elem.id][item.id]).length === 0) {
                                                                        tempObject[elem.id][item.id]["id"] = item.sparepart_id;
                                                                        tempObject[elem.id][item.id]["sediaan_id"] = item.sparepart.sediaan_id;
                                                                        tempObject[elem.id][item.id]["part_code"] = item.sparepart.part_code;
                                                                        tempObject[elem.id][item.id]["part_name"] = item.sparepart.part_name;
                                                                        tempObject[elem.id][item.id]["brand"] = item.sparepart.brand;
                                                                        tempObject[elem.id][item.id]["type"] = item.sparepart.type;
                                                                        tempObject[elem.id][item.id]["qty"] = item.sparepart.qty;
                                                                        tempObject[elem.id][item.id]["qty_receive"] = item.qty_reorder;
                                                                        tempObject[elem.id][item.id]["price"] = item.sparepart.price;
                                                                        tempObject[elem.id][item.id]["bin_location"] = item.sparepart.bin_location;
                                                                        tempObject[elem.id][item.id]["meta"] = item.sparepart.meta;

                                                                        tempObject[elem.id][item.id]["case_number"] = elem.case_number;
                                                                    }

                                                                    tempObject[elem.id][item.id]["isChecked"] = tempArrayCheckBox.includes(elem.id);

                                                                    if (tempArrayCheckBox.includes(elem.id)){
                                                                        if (!tempArray.includes(item.id)) {
                                                                            tempArray.push(item.id);
                                                                        }
                                                                    }
                                                                    else {
                                                                        if (tempArray.includes(item.id)) {
                                                                            index = tempArray.indexOf(item.id);
                                                                            tempArray.splice(index, 1);
                                                                        }
                                                                    }

                                                                    return 0
                                                                })

                                                                setIsCheckBoxState(tempArrayCheckBox);
                                                                setCheckBoxes(tempObject);
                                                                setIsActionButtonDisable(tempArray);
                                                            }}
                                                        >
                                                        </Checkbox>
                                                    </Th>
                                                    <Th>
                                                        <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>No</Text>
                                                    </Th>
                                                    {Object.keys(columnNames).map((elem) => (
                                                        <Fragment key={columnNames[elem]}>
                                                            <Th>
                                                                <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnNames[elem]}</Text>
                                                            </Th>
                                                        </Fragment>
                                                    ))}
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {elem.reorderSpareparts.map((item, index) => (
                                                    <Tr key={item.sparepart.part_code + index}>
                                                        <Td>
                                                            <Checkbox
                                                                size="lg"
                                                                colorScheme={process.env.REACT_APP_BORDER_COLOR}
                                                                borderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                                isChecked={checkBoxes[elem.id] ? (checkBoxes[elem.id][item.id] ? (checkBoxes[elem.id][item.id]["isChecked"] === true) : false) : false}
                                                                onChange={() => {
                                                                    let tempObject = JSON.parse(JSON.stringify(checkBoxes));
                                                                    if (!tempObject[elem.id]) tempObject[elem.id] = {};
                                                                    if(!tempObject[elem.id][item.id]) tempObject[elem.id][item.id] = {};

                                                                    if (Object.keys(tempObject[elem.id][item.id]).length === 0) {
                                                                        tempObject[elem.id][item.id]["id"] = item.sparepart_id;
                                                                        tempObject[elem.id][item.id]["sediaan_id"] = item.sparepart.sediaan_id;
                                                                        tempObject[elem.id][item.id]["part_code"] = item.sparepart.part_code;
                                                                        tempObject[elem.id][item.id]["part_name"] = item.sparepart.part_name;
                                                                        tempObject[elem.id][item.id]["brand"] = item.sparepart.brand;
                                                                        tempObject[elem.id][item.id]["type"] = item.sparepart.type;
                                                                        tempObject[elem.id][item.id]["qty"] = item.sparepart.qty;
                                                                        tempObject[elem.id][item.id]["qty_receive"] = item.qty_reorder;
                                                                        tempObject[elem.id][item.id]["price"] = item.sparepart.price;
                                                                        tempObject[elem.id][item.id]["bin_location"] = item.sparepart.bin_location;
                                                                        tempObject[elem.id][item.id]["meta"] = item.sparepart.meta;

                                                                        tempObject[elem.id][item.id]["case_number"] = elem.case_number;
                                                                    }

                                                                    if (!tempObject[elem.id][item.id]["isChecked"]) tempObject[elem.id][item.id]["isChecked"] = true
                                                                    else tempObject[elem.id][item.id]["isChecked"] = !tempObject[elem.id][item.id]["isChecked"]

                                                                    setCheckBoxes(tempObject);

                                                                    let tempArray = Array.from(isActionButtonDisable);
                                                                    if (tempArray.includes(item.id)) {
                                                                        index = tempArray.indexOf(item.id);
                                                                        tempArray.splice(index, 1);
                                                                    }
                                                                    else tempArray.push(item.id);

                                                                    setIsActionButtonDisable(tempArray);

                                                                }}
                                                            >
                                                            </Checkbox>
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {index + 1}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart.part_code}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart.part_name}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart.brand}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart.type}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart.part_category}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            <NumberInput
                                                                borderColor={process.env.REACT_APP_INPUT_COLOR}
                                                                min={0.001}
                                                                max={999}
                                                                focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                                isDisabled={item.status !== "ssc" ? (checkBoxes[elem.id] ? (checkBoxes[elem.id][item.id] ? !(checkBoxes[elem.id][item.id]["isChecked"] === true) : true) : true) : true}
                                                                onChange={(e) => {
                                                                    setCheckBoxes(checkBoxes => ({...checkBoxes, [elem.id]: ({...checkBoxes[elem.id], [item.id]: ({...checkBoxes[elem.id][item.id], "qty_receive": Number(e)})})}))
                                                                    handleInvalidState(item.id, Number(e));
                                                                }}
                                                                defaultValue={(Number(item.qty_reorder))}
                                                            >
                                                                <NumberInputField _hover={{borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD}} />
                                                                <NumberInputStepper>
                                                                    <NumberIncrementStepper />
                                                                    <NumberDecrementStepper />
                                                                </NumberInputStepper>
                                                            </NumberInput>
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart.qty_uom}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart.price}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {(item.sparepart.price) * (checkBoxes[elem.id] && checkBoxes[elem.id][item.id] && checkBoxes[elem.id][item.id]["qty_receive"] ? checkBoxes[elem.id][item.id]["qty_receive"] : item.qty_reorder)}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {item.sparepart.main_supplier}
                                                        </Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} w={"11%"}>
                                                            <Select
                                                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                                borderColor={process.env.REACT_APP_INPUT_COLOR }
                                                                borderRadius={"xl"}
                                                                focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                                _hover={{
                                                                    borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                                                }}
                                                                textAlign={"center"}
                                                                bg="white"
                                                                fontWeight={"semibold"}
                                                                defaultValue={item.status}
                                                                isDisabled={true}
                                                            >
                                                                <option value={"ssc"}>SSC</option>
                                                                <option value={"incoming"}>Incoming</option>
                                                            </Select>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </CardBody>

                                <Divider />

                                <CardFooter blockSize={1} borderRadius={"lg"} backgroundColor={"purple.50"}>
                                    <HStack spacing={1}>
                                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                            {(elem?.reorderSpareparts)?.length}
                                        </Text>
                                        <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                            item{(elem?.reorderSpareparts)?.length > 1 ? 's' : ''}
                                        </Text>
                                    </HStack>
                                </CardFooter>
                            </Card>
                        ))}
                    </Container>
                }
            </Container>
            <Footer />
        </>
    );
}

export default IncomingOrder;
