import React  from "react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Text,
    Button,
    ButtonGroup,
    Image,
    Flex,
    Spacer,
    useDisclosure
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons"

import { CheckBoxesContext } from "../../components/CheckBoxes";
import Modal from "../../components/Modal/AreYouSure";

import ImportIcon from "../../assets/icons/hero/Sparepart/Hero_export.svg";
import ExportIcon from "../../assets/icons/hero/Sparepart/Hero_import.svg";
import BackIcon from "../../assets/icons/hero/Sparepart/Hero_back.svg"
import TablePrintIcon from "../../assets/img/print.svg";
import ModalQR from "../../components/Modal/SparePartQR";

const Buttons = (props) => {
    const navigate = useNavigate();
    const checkboxContext = useContext(CheckBoxesContext);

    const [showButton, setShowButton] = useState(false);
    const [confirmAction, setConfirmAction] = useState('');
    const [modalData, setModalData] = useState({});
    const [promisesModal, setPromisesModal] = useState([]);

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const handlePromise = (data) => {
        setModalData(data);
        onOpenReportModalQR();
    }

    const {
        isOpen: isOpenModalConfirmation,
        onOpen: onOpenModalConfirmation,
        onClose: onCloseModalConfirmation
    } = useDisclosure()

    const { 
        isOpen: isOpenArchiveModal, 
        onOpen: onOpenArchiveModal, 
        onClose: onCloseArchiveModal 
    } = useDisclosure()

    const {
        isOpen: isOpenRestoreModal,
        onOpen: onOpenRestoreModal,
        onClose: onCloseRestoreModal
    } = useDisclosure()

    const { 
        isOpen: isOpenDeleteModal, 
        onOpen: onOpenDeleteModal, 
        onClose: onCloseDeleteModal 
    } = useDisclosure()

    const {
        isOpen: isOpenReportModalQR,
        onOpen: onOpenReportModalQR,
        onClose: onCloseReportModalQR
    } = useDisclosure()

    useEffect(() => {
        if (checkboxContext.checkBoxState.length !== 0){
            setShowButton(true)
        }else{
            setShowButton(false)
        }
        switch(confirmAction){
            case "Archive":
                props.handleArchiveSparePart(checkboxContext.checkBoxState);
                setConfirmAction('');
                break;
            case "Restore":
                props.handleRestoreSparePart(checkboxContext.checkBoxState);
                setConfirmAction('');
                break;
            case "Delete":
                props.handleDeleteSparePart(checkboxContext.checkBoxState);
                setConfirmAction('');
                break;
            case "QR":
                let tempArrayModalPromises = [];

                let checkBoxes = Array.from(checkboxContext.checkBoxState);

                checkBoxes.map(item => {
                    let tempObjectSparePart = {};
                    let id = Number((item.part_code).substring(1));

                    let binLocQR = props.serving + "-" + (item.part_code)[0] + zeroPad(id, 5) + "-" + item.bin_location;

                    tempObjectSparePart["qr_code"] = binLocQR
                    tempObjectSparePart["part_code"] = item.part_code
                    tempObjectSparePart["part_name"] = item.part_name
                    tempObjectSparePart["type"] = item.type
                    tempObjectSparePart["bin_location"] = item.bin_location

                    tempArrayModalPromises.push(tempObjectSparePart);

                    return 0
                })

                setPromisesModal(tempArrayModalPromises.sort());
                setConfirmAction('');
                break;
            case true:
                if (props.role !== "Superior") props.handleStockOpnameSparePart("Submit");
                else {
                    let isNeedingCAPA = false;
                    let tempDataOpname = Array.from(props.dataOpnameSpareParts);

                    tempDataOpname.map((datumOpname) => {
                        if (Number(datumOpname.qty_difference) !== 0) isNeedingCAPA = true;
                    })

                    if (isNeedingCAPA) props.onOpenTextModal();
                    else props.handleStockOpnameSparePart("Approve");
                }

                setConfirmAction('');
                break;
            default:
                break;
        }
    }, [confirmAction, checkboxContext.checkBoxState])

    console.log(promisesModal);

    useEffect(() => {
        if (promisesModal.length) handlePromise(promisesModal[0]);
    }, [JSON.stringify(promisesModal)])

    return (
        <Flex paddingTop={5}>
            <Modal
                isOpen={isOpenModalConfirmation}
                onOpen={onOpenModalConfirmation}
                onClose={onCloseModalConfirmation}
                setConfirmSentResetPassword={setConfirmAction}
                type={"Request"}
            />

            <Modal
                isOpen={isOpenArchiveModal}
                onOpen={onOpenArchiveModal}
                onClose={onCloseArchiveModal}
                setConfirmAction={setConfirmAction}
                type={"Archive"}
            />

            <Modal
                isOpen={isOpenRestoreModal}
                onOpen={onOpenRestoreModal}
                onClose={onCloseRestoreModal}
                setConfirmAction={setConfirmAction}
                type={"Restore"}
            />

            <Modal
                isOpen={isOpenDeleteModal}
                onOpen={onOpenDeleteModal}
                onClose={onCloseDeleteModal}
                setConfirmAction={setConfirmAction}
                type={"Delete"}
            />

            <ModalQR
                isOpen={isOpenReportModalQR}
                onOpen={onOpenReportModalQR}
                onClose={onCloseReportModalQR}
                modalData={modalData}
                promisesModal={promisesModal}
                setPromisesModal={setPromisesModal}
                isMultiple={true}
                type={"List"}
            />

            <ButtonGroup gap={3}>
                {props.props.page === props.pages[0] ?
                    <>
                        <Button leftIcon={<AddIcon/>} backgroundColor="#F6F8FD" borderColor={process.env.REACT_APP_ENV_COLOR} borderRadius="8px" border="2px" boxShadow="lg" variant="outline" onClick={() => navigate("/item/create")}>
                            <Text className="ml-2" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>Add New Item</Text>
                        </Button>
                        <Button backgroundColor="#5E72E4" borderRadius="8px" variant="solid" _hover={{ bg: process.env.REACT_APP_BUTTON_HOVER_COLOR }} _active={{ bg: process.env.REACT_APP_TEXT_COLOR_LIGHT }} boxShadow="lg" onClick={() => navigate("/item/archive")}>
                            <Text className="text-white font-extrabold tracking-wide">History Archive</Text>
                        </Button>
                        <Button backgroundColor="#DE75A7" borderRadius="8px" variant="solid" _hover={{ bg: process.env.REACT_APP_BUTTON_HOVER_COLOR }} _active={{ bg: process.env.REACT_APP_TEXT_COLOR_LIGHT }} boxShadow="lg" onClick={() => props.role !== "Superior" ? navigate("/item/opname") : navigate("/item/stockOpname")}>
                            <Text className="text-white font-extrabold tracking-wide">Stock Opname</Text>
                        </Button>
                        <Button leftIcon={<Image src={ImportIcon} alt="" />} backgroundColor="#FB6340" borderRadius="8px" variant="solid" _hover={{ bg: process.env.REACT_APP_BUTTON_HOVER_COLOR }} _active={{ bg: process.env.REACT_APP_TEXT_COLOR_LIGHT }} boxShadow="lg" onClick={() => navigate("/item/import")}>
                            <Text className="text-white font-extrabold tracking-wide ml-2">Import</Text>
                        </Button>
                        <Button leftIcon={<Image src={ExportIcon} alt="" />} backgroundColor="#19C4E3" borderRadius="8px" variant="solid" _hover={{ bg: process.env.REACT_APP_BUTTON_HOVER_COLOR }} _active={{ bg: process.env.REACT_APP_TEXT_COLOR_LIGHT }} boxShadow="lg" onClick={() => navigate("/item/export")}>
                            <Text className="text-white font-extrabold tracking-wide ml-2">Export</Text>
                        </Button>
                    </>
                    :
                    <>
                        <Button leftIcon={<Image src={BackIcon} alt="" />} backgroundColor={process.env.REACT_APP_ENV_COLOR} borderRadius="8px" variant='solid' _hover={{ bg: process.env.REACT_APP_BUTTON_HOVER_COLOR }} _active={{ bg: process.env.REACT_APP_TEXT_COLOR_LIGHT }} boxShadow="lg" onClick={() => navigate("/item/list")}>
                            <Text className="text-white font-extrabold tracking-wide ml-2">Back</Text>
                        </Button>
                    </>
                }
            </ButtonGroup>

            <Spacer/>

            {props.props.page === props.pages[2] ?
                <ButtonGroup gap={3}>
                    <Button display={props.role !== "Superior" ? "flex" : "none"} colorScheme={"green"} onClick={() => props.handleStockOpnameSparePart("Save")}>
                        <Text color={"white"}>Save</Text>
                    </Button>

                    <Button display={props.role !== "Superior" ? "flex" : "none"} colorScheme={"orange"} onClick={onOpenModalConfirmation}>
                        <Text color={"white"}>Submit Opname</Text>
                    </Button>

                    <Button disabled={props.buttonOpnameDisabled} display={props.role === "Superior" ? "flex" : "none"} colorScheme={"yellow"} onClick={onOpenModalConfirmation}>
                        <Text color={"white"}>Validate</Text>
                    </Button>
                </ButtonGroup>
                :
                <ButtonGroup gap={3}>
                    <Button
                        colorScheme={props.props.page === props.pages[0] ? "blue" : "yellow"}
                        isDisabled={!showButton}
                        boxShadow="lg"
                        onClick={props.props.page === props.pages[0] ?
                            () => {
                                onOpenArchiveModal();
                            }
                            :
                            () => {
                                onOpenRestoreModal();
                            }
                        }
                    >
                        <Text className="text-white font-extrabold tracking-wide">{props.props.page === props.pages[0] ? "Archive" : "Restore"}</Text>
                    </Button>
                    {props.props.page === props.pages[0] &&
                        <Button
                            rightIcon={<Image src={TablePrintIcon}/>}
                            colorScheme={"gray"}
                            isDisabled={!showButton}
                            boxShadow="lg"
                            onClick={() => setConfirmAction("QR")}
                        >
                            <Text className="font-extrabold tracking-wide">Print</Text>
                        </Button>
                    }
                    {props.props.page === props.pages[1] &&
                        <Button
                            colorScheme={"red"}
                            isDisabled={!showButton}
                            boxShadow="lg"
                            onClick={() => {
                                onOpenDeleteModal();
                            }}
                        >
                            <Text className="text-white font-extrabold tracking-wide">Delete</Text>
                        </Button>
                    }
                </ButtonGroup>
            }
        </Flex>
    );
}

export default Buttons;