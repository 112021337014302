import React, {useEffect, useState} from "react";

import {
    Button,
    Container,
    Divider,
    Flex,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    useDisclosure,
} from "@chakra-ui/react";

import Axios from "../../services/api/Axios";
import Cookies from "js-cookie";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/AreYouSure";
import SubHeader from "./SubHeader";
import ListOutgoing from "./ListOutgoing";
import SparePartPopUp from "../../components/Modal/SparePartPopUp";
import SparePartCart from "../../components/Modal/SparePartCart";
import ModalScanner from "../../components/Modal/SparePartScanner";

const Outgoing = () => {
    let history = {
        dataSparePartQR: "/sparepart/item?id=",
        dataOutgoingURL : "/request/list?count=1&order=id&sediaan_id=" + Cookies.get("idServing") + "&limit=9999&status=SUBMIT",
        postOutgoingURL : "/outgoing/submit?id=",
        imageSparePartURL : "/image/item?id=",
        accessToken : Cookies.get("accessToken"),
    }

    const [dataInitialization,setDataInitialization] = useState(false);
    const [dataOutgoing,setDataOutgoing] = useState([]);

    const [modalData, setModalData] = useState({});
    const [requestData, setRequestData] = useState({});
    const [cartData, setCartData] = useState({});

    const [confirmAction, setConfirmAction] = useState('');
    const [refreshImage, setRefreshImage] = useState('')
    const [unusedRefreshData, setUnusedRefreshData] = useState({});

    const [scanType, setScanType] = useState('');
    const [quantityToScan, setQuantityToScan] = useState(-1);
    const [isMulti, setIsMulti] = useState(false);
    const [promisesModal, setPromisesModal] = useState([]);
    const [countPromisesModal, setCountPromisesModal] = useState({
        "now" : 0,
        "total": 0,
        "current": ''
    });
    const [sortList, setSortList] = useState([]);
    const [sort, setSort] = useState('');

    const zeroPad = (num, places) => String(num).padStart(places, '0');

    const handleGetDataOutgoing = async () => {
        try{
            let getDataOutgoing = await Axios.get(history.dataOutgoingURL,
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });

            (getDataOutgoing?.data?.data?.requests).map((elem) => (
                (elem?.requestSpareparts).map(async (dataSparePart) => {
                    if (!dataSparePart["image_bin"]  && dataSparePart?.sparepart?.image_id && dataSparePart?.sparepart?.image_id !== 1) {
                        let getDataImage = await Axios.get(history.imageSparePartURL + dataSparePart?.sparepart?.image_id,
                            {
                                headers: {'Authorization': 'Bearer ' + history.accessToken},
                            });

                        dataSparePart["image_bin"] = getDataImage?.data?.data?.image?.bin;

                        setRefreshImage("fetchData");
                    }
                })
            ))

            setDataOutgoing(getDataOutgoing?.data?.data?.requests)
        }
        catch(error){
            console.error(error);
        }
    };

    const handleOutgoingSpareParts = async () => {
        if(requestData?.id) {
            try{
                let submitDataSpareParts = await Axios.post(history.postOutgoingURL + requestData?.id,
                    {qty_receive: (requestData?.qty_requestChange ? requestData?.qty_requestChange : requestData?.qty_request)},
                    {
                        headers: {'Authorization': 'Bearer ' + history.accessToken},
                    });

                console.log(submitDataSpareParts)
                setRequestData({});
                setModalData({});
            }
            catch(error){
                console.error(error);
            }
        }
    }

    const handleCheckLabelSparePartQR = () => {
        if (requestData?.id) {
            setScanType("label");

            if (quantityToScan <= 0 && quantityToScan > -1){
                Axios.post(history.dataSparePartQR + modalData.id,
                    {meta: modalData?.meta},
                    {
                        headers: {'Authorization': 'Bearer ' + history.accessToken},
                    }).then((response) => console.log(response));

                handleOutgoingSpareParts().then(() => handleGetDataOutgoing().then(() => onOpenSuccessModal()));
                setQuantityToScan(-1);
            }
            else onOpenReportModalScanner();
        }
    }

    const handleOutgoingMultipleSpareParts = async (requestData, requestQuantityChange) => {
        let promiseArray = [];

        requestData.map((elem) => {
            promiseArray.push(new Promise((resolve, reject) => {
                let newRequestData = (cartData?.requestSpareparts).find(o => o.id === elem);
                try{
                    let submitDataSpareParts = Axios.post(history.postOutgoingURL + elem,
                        {qty_receive: (requestQuantityChange[elem] ? requestQuantityChange[elem] : newRequestData?.qty_request)},
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });
                    console.log(submitDataSpareParts)
                    resolve(submitDataSpareParts);
                }
                catch(error){
                    console.error(error);
                }
            }))

            return ''
        })

        await Promise.all(promiseArray);
        handleGetDataOutgoing().then();

        onOpenSuccessModal();
    }

    const handleDeleteOutgoing = async (requestData) => {
        let promiseArray = [];

        requestData.map((elem) => {
            promiseArray.push(new Promise((resolve, reject) => {
                try{
                    let submitDataSpareParts = Axios.post(history.postOutgoingURL + elem,
                        {qty_receive: 0},
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });
                    console.log(submitDataSpareParts)
                    resolve(submitDataSpareParts);
                }
                catch(error){
                    console.error(error);
                }
            }))

            return ''
        })

        await Promise.all(promiseArray);
        handleGetDataOutgoing().then();

        onOpenSuccessModal();
    }

    const handleRejectOutgoing = async  () => {
        try{
            let postDataOutgoing = await Axios.post(history.postOutgoingURL + requestData?.id,
                {qty_receive: 0},
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });
            console.log(postDataOutgoing);
            setRequestData({});
            setModalData({});
            onOpenSuccessModal();
            handleGetDataOutgoing().then();
        }
        catch(error){
            console.error(error);
        }
    }

    const {
        isOpen: isOpenSparePartPopUp,
        onOpen: onOpenSparePartPopUp,
        onClose: onCloseSparePartPopUp
    } = useDisclosure()

    const {
        isOpen: isOpenSparePartCart,
        onOpen: onOpenSparePartCart,
        onClose: onCloseSparePartCart
    } = useDisclosure()

    const {
        isOpen: isOpenReportModalScanner,
        onOpen: onOpenReportModalScanner,
        onClose: onCloseReportModalScanner
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenDeleteModal,
        onOpen: onOpenDeleteModal,
        onClose: onCloseDeleteModal
    } = useDisclosure()

    useEffect(() => {
        if (!dataInitialization) handleGetDataOutgoing().then(() => setDataInitialization(true));
        if (refreshImage !== '') setRefreshImage('');
        if (!isOpenSuccessModal) setUnusedRefreshData({});
    }, [dataInitialization, requestData, dataOutgoing, refreshImage, onCloseSuccessModal])

    useEffect(() => {
        if (quantityToScan > -1) handleCheckLabelSparePartQR();
    }, [quantityToScan])

    useEffect(() => {
        if (isMulti && promisesModal.length) {
            setModalData(promisesModal[0]);
            onOpenReportModalScanner();
        }
        if (isMulti && !promisesModal.length) {
            setIsMulti(false);
            setConfirmAction("QR Cart")
        }
        }, [confirmAction, JSON.stringify(promisesModal)])

    useEffect(() => {
        switch(confirmAction){
            case "Outgoing":
                setQuantityToScan(-1);
                setScanType("bin");
                onOpenReportModalScanner();

                setConfirmAction('');
                break;
            case "Delete":
                handleRejectOutgoing().then(() => setConfirmAction(''));
                break;
            case "Bin Storage":
                if (quantityToScan === -1){
                    setCountPromisesModal({
                        "now" : 0,
                        "total": 0,
                        "current": ''
                    });
                    handleOutgoingSpareParts().then(() => handleGetDataOutgoing().then(() => onOpenSuccessModal()));
                }
                else {
                    const dataQR = modalData?.meta?.labelQR;

                    const getFirstOutYear = Math.min(...dataQR.map(x => {return x.year}));
                    const getFirstOutSequence = Math.min(...dataQR.filter(data => data.year === getFirstOutYear).map((data) => {
                        return data.sequence
                    }));
                    const getFirstOutQuantity = dataQR.find((label) => (label.year === getFirstOutYear && label.sequence === getFirstOutSequence)).quantity;
                    const total = (getFirstOutQuantity >= (requestData?.qty_requestChange ? requestData?.qty_requestChange : requestData?.qty_request) ? (requestData?.qty_requestChange ? requestData?.qty_requestChange : requestData?.qty_request) : getFirstOutQuantity);
                    const codeQR = zeroPad(getFirstOutYear % 100, 2) + '-' + zeroPad(modalData.sediaan_id, 2) + '-' + modalData.part_code + '-' + zeroPad(getFirstOutSequence, 2);

                    setCountPromisesModal({
                        "now" : 1,
                        "total": total,
                        "current": codeQR
                    });
                }
                setConfirmAction('');
                break;
            case "Cart":
                console.log(cartData);
                let tempOutgoingData = Array.from(cartData?.outgoingData[0] ? cartData?.outgoingData[0] : []);
                let tempArrayModalPromises = [];

                Array.from(cartData?.requestSpareparts).map(item => {
                    let isFound = false;
                    tempOutgoingData.map(matchedItem => {
                        if (item.id === matchedItem){
                            isFound = true;
                        }
                        return 0
                    })

                    if (isFound) {
                        let tempObject = JSON.parse(JSON.stringify(item));

                        tempObject["part_code"] = item.sparepart.part_code;
                        tempObject["part_name"] = item.sparepart.part_name;
                        tempObject["bin_location"] = item.sparepart.bin_location;
                        tempObject["id"] = item.sparepart.id

                        tempArrayModalPromises.push(tempObject);
                    }

                    return 0
                })

                console.log(tempArrayModalPromises);

                setCountPromisesModal({
                    "now" : 1,
                    "total": tempArrayModalPromises.length,
                    "current": (tempArrayModalPromises.sort())[0].part_name
                });

                setScanType("bin");
                setIsMulti(true);
                setPromisesModal(tempArrayModalPromises.sort());
                setConfirmAction('');
                break;
            case "QR Cart":
                handleOutgoingMultipleSpareParts(cartData["outgoingData"][0], cartData["outgoingData"][1]).then(() => setConfirmAction(''));
                break;
            default:
                break;
        }
    }, [confirmAction]);

    const getSortList = (list) => {
        let returnList = ['Request Time'];
        setSort('Request Time');
        for (let i = 0; i < list.length; i++) {
            if (!returnList.includes(list[i].requestSpareparts[0].machine.code)) returnList.push(list[i].requestSpareparts[0].machine.code)
        }

        return returnList
    }

    const customSortByName = (list, match) => {
        const returnList = Array.from(list);
        function comparator(a, b) {
            if (a.requestSpareparts[0].machine.code === match) {
                return -1; // a comes before b
            } else if (b.requestSpareparts[0].machine.code === match) {
                return 1; // b comes before a
            }
            return 0;
        }

        returnList.sort(comparator);

        return returnList;
    }

    useEffect(() => {
        if (sort === 'Request Time' || sort === '') setSortList(getSortList(dataOutgoing));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataOutgoing]);

    useEffect(() => {
        if (sort !== 'Request Time' && sort !== '') {
            setDataOutgoing(customSortByName(dataOutgoing, sort));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort]);

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingTop={"2vh"}>
                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    type={"Success"}
                />
                <Modal
                    isOpen={isOpenDeleteModal}
                    onOpen={onOpenDeleteModal}
                    onClose={onCloseDeleteModal}
                    setConfirmAction={setConfirmAction}
                    type={"Delete"}
                />

                <ModalScanner
                    isOpen={isOpenReportModalScanner}
                    onOpen={onOpenReportModalScanner}
                    onClose={onCloseReportModalScanner}
                    setConfirmAction={setConfirmAction}
                    modalData={modalData}
                    setModalData={setModalData}
                    requestData={requestData}
                    quantityToScan={quantityToScan}
                    setQuantityToScan={setQuantityToScan}
                    scanType={scanType}
                    setScanType={setScanType}
                    isMulti={isMulti}
                    promisesModal={promisesModal}
                    setPromisesModal={setPromisesModal}
                    countPromisesModal={countPromisesModal}
                    setCountPromisesModal={setCountPromisesModal}
                    type={"Outgoing"}
                />

                <SparePartPopUp
                    isOpen={isOpenSparePartPopUp}
                    onOpen={onOpenSparePartPopUp}
                    onClose={onCloseSparePartPopUp}
                    modalData={modalData}
                    setModalData={setModalData}
                    requestData={requestData}
                    setRequestData={setRequestData}
                    setConfirmAction={setConfirmAction}
                    type={"Outgoing"}
                />

                <SparePartCart
                    isOpen={isOpenSparePartCart}
                    onOpen={onOpenSparePartCart}
                    onClose={onCloseSparePartCart}
                    cartData={cartData}
                    setCartData={setCartData}
                    setConfirmAction={setConfirmAction}
                    handleDeleteOutgoing={handleDeleteOutgoing}
                    type={"Outgoing"}
                />

                <SubHeader position={"fixed"}/>

                <Flex marginX={"5%"} marginTop={"4%"} marginBottom={1} w={"90%"} position={"fixed"} zIndex={99}>
                    <Menu autoSelect={false}>
                        <MenuButton
                          as={Button}
                          p={2}
                          rounded={'full'}
                          variant={'link'}
                          cursor={'pointer'}
                          minW={0}
                          bg={"purple.300"}
                          borderRadius={"lg"}
                        >
                            <Text className={"text-[#492472]"}>Sort By</Text>
                        </MenuButton>
                        <MenuList alignItems={'center'}>
                            {sortList.map((list) => (
                              <MenuItem
                                onClick={() => setSort(list)}
                                isDisabled={list === 'Request Time'}
                                color={sort === list ? process.env.REACT_APP_BORDER_COLOR : "black"}
                              >
                                  <Text borderRadius={"lg"} ml={2}>{list}</Text>
                              </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                    <Spacer/>

                    <HStack alignItems={"end"}>
                        <Text fontSize={"2xl"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                            {dataOutgoing.length}
                        </Text>
                        <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                            requests need an outgoing
                        </Text>
                    </HStack>
                </Flex>

                <Divider variant={"dashed"} borderColor={process.env.REACT_APP_BORDER_COLOR} margin={2} w={"98%"} marginTop={"14vh"} position={"fixed"}/>
                <ListOutgoing
                    dataOutgoing={dataOutgoing}
                    onOpenSparePartPopUp={onOpenSparePartPopUp}
                    onOpenReportModalScanner={onOpenReportModalScanner}
                    onOpenSparePartCart={onOpenSparePartCart}
                    onOpenDeleteModal={onOpenDeleteModal}
                    setModalData={setModalData}
                    setRequestData={setRequestData}
                    setCartData={setCartData}
                    setIsMulti={setIsMulti}
                    setRefreshImage={setRefreshImage}
                />
            </Container>
            <Footer />
        </>
    );
}

export default Outgoing;
