import React  from "react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from 'xlsx';

import {
    Text,
    Button,
    ButtonGroup,
    Image,
    Flex,
    Spacer,
    Input,
    useDisclosure
} from "@chakra-ui/react";

import Modal from "../../components/Modal/AreYouSure";

import ExportIcon from "../../assets/icons/hero/Sparepart/Hero_import.svg";

import Cookies from "js-cookie";
import { HistoryContext } from "./HistoryContext";

const ActionMenu = (props) => {
    const navigate = useNavigate();
    let historyContext = useContext(HistoryContext)
    const getUserRole = Cookies.get("role");

    const [confirmAction, setConfirmAction] = useState('');

    const { 
        isOpen: isOpenArchiveModal, 
        onOpen: onOpenArchiveModal, 
        onClose: onCloseArchiveModal 
    } = useDisclosure()

    const {
        isOpen: isOpenRestoreModal,
        onOpen: onOpenRestoreModal,
        onClose: onCloseRestoreModal
    } = useDisclosure()

    const { 
        isOpen: isOpenDeleteModal, 
        onOpen: onOpenDeleteModal, 
        onClose: onCloseDeleteModal 
    } = useDisclosure()

    useEffect(() => {
        switch(confirmAction){
            case "Archive":
                setConfirmAction('');
                break;
            case "Restore":
                setConfirmAction('');
                break;
            case "Delete":
                setConfirmAction('');
                break;
            default:
                break;
        }
    }, [confirmAction])

    function exportData(){
        const headerObject = historyContext.columnNames;
        const datas = historyContext.data;

        const headings = [];
        let heading = [];
        const exportDatas = [];

        (Object.keys(headerObject)).map((header) => (
          heading.push(headerObject[header])
        ))

        for (let i = 0; i < datas.length; i++) {
            let tempObject = {};

            (Object.keys(headerObject)).map((header) => (
              tempObject[headerObject[header]] = datas[i][header]
            ))

            exportDatas.push(tempObject);
        }

        headings.push(heading);

        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, exportDatas, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Spare Part');
        writeFile(wb, 'Export Spare Part.xlsx');
    }

    return (
        <Flex paddingTop={5}>
            <Modal
                isOpen={isOpenArchiveModal}
                onOpen={onOpenArchiveModal}
                onClose={onCloseArchiveModal}
                setConfirmAction={setConfirmAction}
                type={"Archive"}
            />

            <Modal
                isOpen={isOpenRestoreModal}
                onOpen={onOpenRestoreModal}
                onClose={onCloseRestoreModal}
                setConfirmAction={setConfirmAction}
                type={"Restore"}
            />

            <Modal
                isOpen={isOpenDeleteModal}
                onOpen={onOpenDeleteModal}
                onClose={onCloseDeleteModal}
                setConfirmAction={setConfirmAction}
                type={"Delete"}
            />

            <ButtonGroup gap={3}>
                <Button colorScheme={props.props.page === "request" ? "purple" : "blackAlpha"} onClick={() => navigate("/history/listRequest")}>
                    <Text className="text-white font-extrabold tracking-wide">Request</Text>
                </Button>
                <Button colorScheme={props.props.page === "outgoing" ? "purple" : "blackAlpha"} onClick={() => navigate("/history/listOutgoing")}>
                    <Text className="text-white font-extrabold tracking-wide">Outgoing</Text>
                </Button>
                <Button colorScheme={props.props.page === "incoming" ? "purple" : "blackAlpha"} onClick={() => navigate("/history/listIncoming")} visibility={getUserRole === "Teknisi" ? "hidden" : "visible"}>
                <Text className="text-white font-extrabold tracking-wide">Incoming</Text>
                </Button>
                <Button colorScheme={props.props.page === "opname" ? "purple" : "blackAlpha"} onClick={() => navigate("/history/listOpname")} visibility={getUserRole === "Teknisi" ? "hidden" : "visible"}>
                    <Text className="text-white font-extrabold tracking-wide">Opname</Text>
                </Button>
                
            </ButtonGroup>

            <Spacer/>
            <ButtonGroup className="mx-3">
                <Input
                    placeholder="Start Date"
                    size="md"
                    type="date"
                    onChange={(e) => historyContext.setDate1(e.target.value)}
                />
                <Input
                    placeholder="End Date"
                    size="md"
                    type="date"
                    onChange={(e) => historyContext.setDate2(e.target.value)}
                />
            </ButtonGroup>

            <ButtonGroup>
                <Button colorScheme={"twitter"} onClick={() => props.setDoFilter(true)}>
                    <Text className="text-white font-extrabold tracking-wide">Filter</Text>
                </Button>

                <Button colorScheme={"teal"} onClick={() => props.setDoFilter(false)}>
                    <Text className="text-white font-extrabold tracking-wide">Reset</Text>
                </Button>
            </ButtonGroup>

            <Spacer/>

            <ButtonGroup gap={3}>
                <Button leftIcon={<Image src={ExportIcon} alt="" />} backgroundColor="#19C4E3" borderRadius="8px" variant="solid" _hover={{ bg: process.env.REACT_APP_BUTTON_HOVER_COLOR }} _active={{ bg: process.env.REACT_APP_TEXT_COLOR_LIGHT }} boxShadow="lg" onClick={() => exportData()}>
                    <Text className="text-white font-extrabold tracking-wide ml-2">Export</Text>
                </Button>

                <Button onClick={() => navigate("/history/restore")} colorScheme={"orange"} display={(getUserRole !== "Teknisi" && props.props.page === "outgoing") ? "flex" : "none"}>
                    <Text className="text-white font-extrabold tracking-wide">Restore</Text>
                </Button>
            </ButtonGroup>
        </Flex>
    );
}

export default ActionMenu;