import React from "react";

import {
    Button,
    SimpleGrid,
    Stack,
    Text,
    Image,
    Card,
    VStack, Avatar, GridItem, Grid, Center,
} from '@chakra-ui/react';

import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../src/components/Loader/Loader";

import {PlusSquareIcon} from "@chakra-ui/icons";
import sparePart from "../../assets/img/imageSparePart.svg";
import EmptyList from "../../assets/img/empty.svg";

const ListIncoming = (props) => {
    const columnName = {
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "qty_min" : "Min Stock",
        "qty_max" : "Max Stock",
        "qty" : "Stock",
        "bin_location" : "Bin Loct"
    }

    return (
        <>
            {(props.dataSpareParts)?.length === 0 ?
                <Center w={"full"} h={"90vh"}>
                    <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                    <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                        No items in here..
                    </Text>
                </Center>
                :
                <InfiniteScroll dataLength={props.dataSpareParts.length} next={() => props.setFetchMoreData(true)} hasMore={props.hasMoreCard} loader={Loader()}>
                    <SimpleGrid py={"5vh"} px={"2%"} columns={2} spacingX='40px' spacingY='20px'>
                        {props.dataSpareParts.map((dataSparePart) => (
                            <Card
                                key={dataSparePart.id + 1000}
                                bg={"white"}
                                borderRadius={"xl"}
                                direction={{ base: 'column', sm: 'row' }}
                                overflow='hidden'
                                variant='outline'
                                position={"initial"}
                            >
                                <VStack m={10} spacing='3' align={"center"} w={"full"}>
                                    <Avatar cursor={"pointer"} onClick={() => props.setRefreshImage("onClick")} size={"2xl"} src={dataSparePart["image_bin"]} icon={<Image src={sparePart} />} marginBottom={"2%"}/>

                                    <Stack paddingLeft={4} w={"full"}>
                                        <Grid templateColumns='repeat(2, 1fr)' gap={2} py={2} w={"full"}>
                                            {Object.keys(columnName).map((elem, index) => (
                                                <GridItem key={dataSparePart.id + index} w={"full"} bg={"whitesmoke"} borderRadius={"lg"}>
                                                    <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnName[elem]}</Text>
                                                    <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(dataSparePart[elem])?.length > 25 ? (dataSparePart[elem])?.slice(0, 25) + ".." : (dataSparePart[elem])}</Text>
                                                </GridItem>
                                            ))}
                                        </Grid>

                                    </Stack>

                                    <Button
                                        variant='solid'
                                        colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                                        position={"initial"}
                                        onClick={() => {
                                            let tempModalData = {};
                                            Object.keys(columnName).map((elem) => tempModalData[elem] = dataSparePart[elem]);
                                            tempModalData["id"] = dataSparePart["id"];
                                            tempModalData["sediaan_id"] = dataSparePart["sediaan_id"];
                                            tempModalData["qty_max"] = dataSparePart["qty_max"];
                                            tempModalData["price"] = dataSparePart["price"];
                                            tempModalData["meta"] = dataSparePart["meta"];
                                            props.setModalData(tempModalData);
                                            props.onOpenSparePartPopUp();
                                        }}
                                        rightIcon={<PlusSquareIcon/>}
                                    >
                                        Add Stock
                                    </Button>
                                </VStack>
                            </Card>
                        ))}
                    </SimpleGrid>
                </InfiniteScroll>
            }
        </>
    );
}

export default ListIncoming;