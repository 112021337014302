import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import Select from 'react-select'

import {
    Button,
    Center,
    Container,
    FormControl,
    FormLabel,
    HStack,
    VStack,
    Image,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Menu,
    TableCaption,
    Box,
    useDisclosure, ButtonGroup,
} from "@chakra-ui/react";
import {AddIcon, ArrowRightIcon, SettingsIcon} from "@chakra-ui/icons";

import Cookies from "js-cookie";
import Axios from "../../services/api/Axios";

import SubHeader from "./SubHeader";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/AreYouSure";
import Loader from "../../components/Loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

import TableEditIcon from "../../assets/icons/table/TableEdit.svg";
import TableDeleteIcon from "../../assets/icons/table/TableDelete.svg";
import EmptyList from "../../assets/img/empty.svg";
import IconSparePart from "../../assets/icons/footer/Footer.svg";

const AssetManagement = (props) => {
    const columnName = (props?.page === "machine" ? ["Organizer", "Plant", "Department Code", "Department Name", "Action"] : ["Organizer", "Plant", "Action"]);
    const isResolutionHigh = useMediaQuery({ query: "(min-width: 1920px)" })

    const [selectedValue, setSelectedValue] = useState({
        "serving": 0,
        "line": [0, ''],
        "machine": 0,
    });

    const history = {
        dataAssetsURL : "/line/list?order=name" + (selectedValue["serving"] !== 0 ? "&sediaan_id=" + selectedValue["serving"] : '') + (selectedValue["line"][0] !== 0 ? "&name=" + selectedValue["line"][1] : '') + "&count=1&include=" + (props.page === "machine" ? "1" : "0") + "&limit=25&offset=",
        dataAssetsMachineURL: "/machine/item?include=1&id=",
        dataLinesURL : "/line/list?order=name" + (selectedValue["serving"] !== 0 ? "&sediaan_id=" + selectedValue["serving"] : '') + "&count=1&limit=9999",
        dataMachinesURL : "/machine/list?limit=9999&" + (selectedValue["serving"] !== 0 ? "&sediaan_id=" + selectedValue["serving"] : '') + (selectedValue["line"][0] !== 0 ? "&line_id=" + selectedValue["line"][0] : '') + "&count=1&include=1",
        deleteDataLineURL: "/line/item?id=",
        deleteDataMachineURL: "/machine/item?id=",
        servingURL : "/sediaan/list",
        accessToken : Cookies.get("accessToken"),
        role : Cookies.get("role"),
        idServing : Cookies.get("idServing"),
    }

    const navigate = useNavigate();

    const [dataAssets,setDataAssets] = useState([]);
    const [dataServings,setDataServings] = useState([]);
    const [dataLines,setDataLines] = useState([]);
    const [dataMachines,setDataMachines] = useState([]);
    const [dataInitialization,setDataInitialization] = useState(false);
    const [fetchMoreData,setFetchMoreData] = useState(false);
    const [dataOffset, setDataOffset] = useState(25)

    const [assetDeleteIdentification, setAssetDeleteIdentification] = useState(0);
    const [confirmDelete, setConfirmDelete] = useState('');
    const [hasMoreTable, setHasMoreTable] = useState(true);

    const handleGetDataAsset = async () => {
        try{
            let tempArray = [];
            if (selectedValue["machine"] !== 0) {
                let tempObject = {};
                let getDataAssets = await Axios.get(history.dataAssetsMachineURL + selectedValue["machine"],
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                tempObject["id"] = getDataAssets?.data?.data?.machine?.line_id;
                tempObject["name"] = getDataAssets?.data?.data?.machine?.line?.name;
                tempObject["sediaan_id"] = getDataAssets?.data?.data?.machine?.sediaan_id;
                tempObject["machines"] = [];
                tempObject["machines"].push(getDataAssets?.data?.data?.machine);

                tempArray.push(tempObject);
                setHasMoreTable(false);
            }
            else {
                let getDataAssets = await Axios.get(history.dataAssetsURL + 0,
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                tempArray = getDataAssets?.data?.data?.lines

                if ((getDataAssets?.data?.data?.lines)?.length < 25) {
                    setHasMoreTable(false);
                }
                else setHasMoreTable(true);
            }

            handleGetDataServing().then(() => setDataAssets(tempArray));

        }
        catch(error){
            console.error(error);
        }
    }

    const handleGetMoreDataAsset = async () => {
        try{
            let getDataAssets = await Axios.get(history.dataAssetsURL + dataOffset,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            if ((getDataAssets?.data?.data?.lines)?.length < 25) {
                setHasMoreTable(false);
            }
            else setHasMoreTable(true);

            setDataAssets(dataAssets.concat(getDataAssets?.data?.data?.lines));

            if (dataInitialization) setDataOffset(dataOffset => dataOffset + 25);
        }
        catch(error){
            console.error(error);
        }
    }

    const handleGetDataServing = async () => {
        try{
            let statusServing = await Axios.get(history.servingURL,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            // console.log(statusServing);
            let servings = (statusServing.data?.data?.sediaans)?.map((sediaan) => {
                let tempObject = {};
                tempObject["label"] = (sediaan?.nama).charAt(0).toUpperCase() + (sediaan?.nama).slice(1);
                tempObject["value"] = sediaan?.id

                return tempObject;
            })

            setDataServings(servings);
        }
        catch(error){
            console.error(error);
        }
    }

    const handleGetDataLines = async () => {
        try{
            let getDataLines = await Axios.get(history.dataLinesURL,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let lines = (getDataLines?.data?.data?.lines).map((line) => {
                let tempObject = {};
                tempObject["label"] = line?.name;
                tempObject["value"] = line?.id;

                return tempObject
            })

            setDataLines(lines);
        }
        catch(error){
            console.error(error);
        }
    }

    const handleGetDataMachines = async () => {
        try{
            let getDataMachines = await Axios.get(history.dataMachinesURL,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let machines = (getDataMachines?.data?.data?.machines).map((machine) => {
                let tempObject = {};
                tempObject["label"] = machine?.name;
                tempObject["value"] = machine?.id;

                return tempObject
            })

            setDataMachines(machines);
        }
        catch(error){
            console.error(error);
        }
    }


    const handleDeleteDataAsset = async () => {
        try{
            let deleteDataAsset = await Axios.delete((props.page === "machine" ? history.deleteDataMachineURL : history.deleteDataLineURL) + assetDeleteIdentification,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            setDataInitialization(false)
            setDataOffset(25);
            console.log(deleteDataAsset);
        }
        catch(error){
            console.error(error);
        }
        setAssetDeleteIdentification(0);
    }

    const {
        isOpen: isOpenDeleteModal,
        onOpen: onOpenDeleteModal,
        onClose: onCloseDeleteModal
    } = useDisclosure()

    useEffect(() => {
        if (!dataInitialization) handleGetDataAsset().then(() => handleGetDataLines().then(() => handleGetDataMachines().then(() => setDataInitialization(true))));
        if (fetchMoreData) handleGetMoreDataAsset().then(() => setFetchMoreData(false));
        if (confirmDelete === "Delete") handleDeleteDataAsset().then(() => setConfirmDelete(''));
    }, [confirmDelete, selectedValue, fetchMoreData]);

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingTop={"2vh"}>
                <Modal
                    isOpen={isOpenDeleteModal}
                    onOpen={onOpenDeleteModal}
                    onClose={onCloseDeleteModal}
                    setConfirmAction={setConfirmDelete}
                    type={"Delete"}
                />

                <SubHeader/>

                <HStack py={"1%"} spacing={"2%"}>
                    <Menu autoSelect={false}>
                        <MenuButton
                            as={Button}
                            p={2}
                            rounded={'full'}
                            variant={'link'}
                            cursor={'pointer'}
                            minW={0}
                            bg={"purple.300"}
                            borderRadius={"lg"}
                        >
                            <HStack>
                                <Text className={"ml-2 text-[#492472]"}>{props?.page === "machine" ? "Department" : "Plant"} Management</Text>
                                <Image src={IconSparePart} alt={"box"} />
                            </HStack>
                        </MenuButton>
                        <MenuList alignItems={'center'} className={"my-2"}>
                            <MenuItem
                                onClick={() => navigate('/assetManagement/listPlant')}
                                color={props?.page === "machine" ? "black" : process.env.REACT_APP_BORDER_COLOR}
                                icon={<ArrowRightIcon />}
                            >
                                <Text bg={props?.page === "machine" ? '' : "purple.50"} borderRadius={"lg"} ml={2} >Plant Management</Text>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                onClick={() => navigate('/assetManagement/listDepartment')}
                                color={props?.page === "machine" ? process.env.REACT_APP_BORDER_COLOR : "black"}
                                icon={<SettingsIcon />}
                            >
                                <Text bg={props?.page === "machine" ? "purple.50" : ''} borderRadius={"lg"} ml={2}>Department Management</Text>
                            </MenuItem>
                        </MenuList>
                    </Menu>

                    <Spacer/>

                    <Button
                        rightIcon={<AddIcon/>}
                        variant='solid'
                        colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                        position={"initial"}
                        onClick={props?.page === "machine" ? () => navigate('/assetManagement/createDepartment') : () => navigate('/assetManagement/createPlant')}
                    >
                        New {props?.page === "machine" ? "Department" : "Plant"}
                    </Button>
                </HStack>
                <HStack pb={"1%"} spacing={"2%"}>
                    <FormControl>
                        <HStack spacing={5} w={"full"}>
                            <VStack align={"left"}>
                                <FormLabel fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Organization: </FormLabel>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textAlign: "center",
                                            borderColor: state.isFocused ? 'peachpuff' : 'rebeccapurple',
                                            borderRadius: 10,
                                            width: "12vw",
                                            ":hover": {
                                                borderColor: "mediumpurple"
                                            },
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                    ? undefined
                                                    : isSelected
                                                        ? data.color
                                                        : isFocused
                                                            ? "lightpink"
                                                            : undefined,
                                                color: isDisabled
                                                    ? '#ccc'
                                                    : isSelected
                                                        ? process.env.REACT_APP_BORDER_COLOR
                                                            ? process.env.REACT_APP_BORDER_COLOR
                                                            : 'black'
                                                        : data.color,
                                                cursor: isDisabled ? 'not-allowed' : 'default',

                                                ':active': {
                                                    ...styles[':active'],
                                                    backgroundColor: !isDisabled
                                                        ? isSelected
                                                            ? data.color
                                                            : process.env.REACT_APP_BORDER_COLOR
                                                        : undefined,
                                                },
                                            };
                                        },
                                    }}
                                    onChange={(e) => {
                                        setSelectedValue({
                                            "serving": Number(e.value),
                                            "line": [0, ''],
                                            "machine": 0
                                        })
                                        setDataInitialization(false);
                                        setDataOffset(25);
                                    }}
                                    options={dataServings}
                                />
                            </VStack>
                            <VStack align={"left"}>
                                <FormLabel fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Plant: </FormLabel>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textAlign: "center",
                                            borderColor: state.isFocused ? 'peachpuff' : 'rebeccapurple',
                                            borderRadius: 10,
                                            width: "12vw",
                                            ":hover": {
                                                borderColor: "mediumpurple"
                                            },
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                    ? undefined
                                                    : isSelected
                                                        ? data.color
                                                        : isFocused
                                                            ? "lightpink"
                                                            : undefined,
                                                color: isDisabled
                                                    ? '#ccc'
                                                    : isSelected
                                                        ? process.env.REACT_APP_BORDER_COLOR
                                                            ? process.env.REACT_APP_BORDER_COLOR
                                                            : 'black'
                                                        : data.color,
                                                cursor: isDisabled ? 'not-allowed' : 'default',

                                                ':active': {
                                                    ...styles[':active'],
                                                    backgroundColor: !isDisabled
                                                        ? isSelected
                                                            ? data.color
                                                            : process.env.REACT_APP_BORDER_COLOR
                                                        : undefined,
                                                },
                                            };
                                        },
                                    }}
                                    onChange={(e) => {
                                        setSelectedValue(selectedValue => ({...selectedValue, "line": [Number(e.value), e.label], "machine": 0}))
                                        setDataInitialization(false);
                                        setDataOffset(25);
                                    }}
                                    options={dataLines}
                                />
                            </VStack>
                            <VStack align={"left"} display={props?.page === "machine" ? "flex" : "none"}>
                                <FormLabel fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Department Name: </FormLabel>
                                <Select
                                    isDisabled={props?.page !== "machine"}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textAlign: "center",
                                            borderColor: state.isFocused ? 'peachpuff' : 'rebeccapurple',
                                            borderRadius: 10,
                                            width: "12vw",
                                            ":hover": {
                                                borderColor: "mediumpurple"
                                            },
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                    ? undefined
                                                    : isSelected
                                                        ? data.color
                                                        : isFocused
                                                            ? "lightpink"
                                                            : undefined,
                                                color: isDisabled
                                                    ? '#ccc'
                                                    : isSelected
                                                        ? process.env.REACT_APP_BORDER_COLOR
                                                            ? process.env.REACT_APP_BORDER_COLOR
                                                            : 'black'
                                                        : data.color,
                                                cursor: isDisabled ? 'not-allowed' : 'default',

                                                ':active': {
                                                    ...styles[':active'],
                                                    backgroundColor: !isDisabled
                                                        ? isSelected
                                                            ? data.color
                                                            : process.env.REACT_APP_BORDER_COLOR
                                                        : undefined,
                                                },
                                            };
                                        },
                                    }}
                                    onChange={(e) => {
                                        setSelectedValue(selectedValue => ({...selectedValue, "machine": Number(e.value)}))
                                        setDataInitialization(false);
                                        setDataOffset(25);
                                    }}
                                    options={dataMachines}
                                />
                            </VStack>

                            <Spacer/>

                            <ButtonGroup gap={3}>
                                <Button
                                    colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                                    onClick={() => {
                                        setSelectedValue({
                                            "serving": 0,
                                            "line": [0, ''],
                                            "machine": 0
                                        })
                                        setDataInitialization(false);
                                        setDataOffset(25);
                                    }}
                                >
                                    Reset
                                </Button>
                            </ButtonGroup>
                        </HStack>
                    </FormControl>
                </HStack>

                {(dataAssets)?.length === 0 ?
                    <Center w={"full"}>
                        <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                        <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                            No items in here..
                        </Text>
                    </Center>
                    :
                    <Box w={"full"}>
                        <InfiniteScroll scrollableTarget={"tableAssets" + props.page} dataLength={dataAssets.length} next={() => setFetchMoreData(true)} hasMore={hasMoreTable} loader={''}>
                            <TableContainer id={"tableAssets" + props.page} maxHeight={isResolutionHigh ? "58vh" : "54vh"} overflowY={"scroll"} whiteSpace={"normal"} display={"block"} border={"1px solid #B7D4CF"} borderRadius={"lg"}>
                                <Table variant='simple' size={"lg"}>
                                    <TableCaption>
                                        {hasMoreTable ?
                                            <Loader/>
                                            :
                                            <Text className='text-center text-[#492472] font-bold text-sm'>End of Line (No more data)</Text>
                                        }
                                    </TableCaption>
                                    <Thead bg={process.env.REACT_APP_TABLE_COLOR}>
                                        <Tr>
                                            {columnName.map((elem) => (
                                                <Th key={elem}>
                                                    <Text className='text-center font-bold text-sm' textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem}</Text>
                                                </Th>
                                            ))}
                                        </Tr>
                                    </Thead>

                                    {props.page === "machine" ?
                                        <Tbody>
                                            {dataAssets.map((elem) => (
                                                (elem?.machines).map((machine) => (
                                                    <Tr key={machine.id}>
                                                        <Td>
                                                            <Center>
                                                                {(dataServings?.find(x => x.value === elem.sediaan_id)?.label)}
                                                            </Center>
                                                        </Td>
                                                        <Td>
                                                            <Center>
                                                                {elem?.name?.length >= 25 ? (elem?.name).slice(0,40) + ".." : elem?.name}
                                                            </Center>
                                                        </Td>
                                                        <Td>
                                                            <Center>
                                                                {machine?.code?.length >= 25 ? (machine?.code).slice(0,40) + ".." : machine?.code}
                                                            </Center>
                                                        </Td>
                                                        <Td>
                                                            <Center>
                                                                {machine?.name?.length >= 25 ? (machine?.name).slice(0,40) + ".." : machine?.name}
                                                            </Center>
                                                        </Td>
                                                        <Td>
                                                            <Center>
                                                                <HStack>
                                                                    {(history.role !== "Superior" && Number(history.idServing) === Number(elem?.sediaan_id)) ?
                                                                        <>
                                                                            <Image
                                                                                src={TableEditIcon}
                                                                                cursor={'pointer'}
                                                                                alt="edit"
                                                                                onClick={() => navigate({
                                                                                    pathname: "/assetManagement/editDepartment",
                                                                                    search: "?id=" + machine?.id,
                                                                                })}
                                                                            />
                                                                            <Image
                                                                                src={TableDeleteIcon}
                                                                                cursor={'pointer'}
                                                                                alt="delete"
                                                                                onClick={() => {
                                                                                    setAssetDeleteIdentification(machine?.id);
                                                                                    onOpenDeleteModal();
                                                                                }}
                                                                            />
                                                                        </>
                                                                        :
                                                                        (history.role === "Superior" ?
                                                                                <>
                                                                                    <Image
                                                                                        src={TableEditIcon}
                                                                                        cursor={'pointer'}
                                                                                        alt="edit"
                                                                                        onClick={() => navigate({
                                                                                            pathname: "/assetManagement/editDepartment",
                                                                                            search: "?id=" + machine?.id,
                                                                                        })}
                                                                                    />
                                                                                    <Image
                                                                                        src={TableDeleteIcon}
                                                                                        cursor={'pointer'}
                                                                                        alt="delete"
                                                                                        onClick={() => {
                                                                                            setAssetDeleteIdentification(machine?.id);
                                                                                            onOpenDeleteModal();
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                                :
                                                                                <Text>Unauthorized</Text>
                                                                        )
                                                                    }
                                                                </HStack>
                                                            </Center>
                                                        </Td>
                                                    </Tr>
                                                ))
                                            ))}
                                        </Tbody>
                                        :
                                        <Tbody>
                                            {dataAssets.map((elem) => (
                                                <Tr key={elem.id}>
                                                    <Td>
                                                        <Center>
                                                            {(dataServings?.find(x => x.value === elem.sediaan_id)?.label)}
                                                        </Center>
                                                    </Td>
                                                    <Td>
                                                        <Center>
                                                            {elem?.name?.length >= 25 ? (elem?.name).slice(0,40) + ".." : elem?.name}
                                                        </Center>
                                                    </Td>
                                                    <Td>
                                                        <Center>
                                                            <HStack>
                                                                {(history.role !== "Superior" && elem.roleName !== "Superior" && Number(history.idServing) === Number(elem?.sediaan_id)) ?
                                                                    <>
                                                                        <Image
                                                                            src={TableEditIcon}
                                                                            cursor={'pointer'}
                                                                            alt="edit"
                                                                            onClick={() => navigate({
                                                                                pathname: "/assetManagement/editPlant",
                                                                                search: "?id=" + elem?.id,
                                                                            })}
                                                                        />
                                                                        <Image
                                                                            src={TableDeleteIcon}
                                                                            cursor={'pointer'}
                                                                            alt="delete"
                                                                            onClick={() => {
                                                                                setAssetDeleteIdentification(elem?.id);
                                                                                onOpenDeleteModal();
                                                                            }}
                                                                        />
                                                                    </>
                                                                    :
                                                                    (history.role === "Superior" ?
                                                                            <>
                                                                                <Image
                                                                                    src={TableEditIcon}
                                                                                    cursor={'pointer'}
                                                                                    alt="edit"
                                                                                    onClick={() => navigate({
                                                                                        pathname: "/assetManagement/editPlant",
                                                                                        search: "?id=" + elem?.id,
                                                                                    })}
                                                                                />
                                                                                <Image
                                                                                    src={TableDeleteIcon}
                                                                                    cursor={'pointer'}
                                                                                    alt="delete"
                                                                                    onClick={() => {
                                                                                        setAssetDeleteIdentification(elem?.id);
                                                                                        onOpenDeleteModal();
                                                                                    }}
                                                                                />
                                                                            </>
                                                                            :
                                                                            <Text>Unauthorized</Text>
                                                                    )
                                                                }
                                                            </HStack>
                                                        </Center>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    }
                                </Table>
                            </TableContainer>
                        </InfiniteScroll>
                    </Box>
                }
            </Container>
            <Footer />
        </>
    );
}

export default AssetManagement;