import React from "react";
import {useMediaQuery} from "react-responsive";

import { Container } from "@chakra-ui/react";

import Cookies from "js-cookie";

import NavbarProfile from "./NavbarItems/NavbarProfile";
import NavbarNotification from "./NavbarItems/NavbarNotification";
import NavbarBox from "./NavbarItems/NavbarBox";
import NavbarRestore from "./NavbarItems/NavbarRestore";

const NavbarItem = (props) => {
    const getUserRole = Cookies.get("role");
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" })

    return (
        <Container padding={'0'}>
            {(getUserRole !== "Teknisi" && !isTabletOrMobile) &&
            <>
                <NavbarBox props={props}/>
                <NavbarRestore props={props}/>
                <NavbarNotification props={props}/>
            </>
            }
            <NavbarProfile />
        </Container>
    );
}

export default NavbarItem;