import { createContext, React, useState } from 'react';

const CheckboxExportContext = createContext();
const CheckboxExportprovider = ({children}) => {
    const [checkboxState, setCheckboxState] = useState([]) 
    return(
        <CheckboxExportContext.Provider value={{ checkboxState, setCheckboxState }}>
            {children}
        </CheckboxExportContext.Provider>
    )
}

const DropdownContext = createContext();
const DropdownContextProvider = ({children}) => {
    const [dropdownState, setDropdownState] = useState({})
    return(
        <DropdownContext.Provider value={{dropdownState, setDropdownState}}>
            {children}
        </DropdownContext.Provider>
    )
}

const FilterContext = createContext()
const FilterContextProvider = ({children}) => {
    const [data,setData] = useState({})
    const [column, setColumn] = useState(["id", "part_code","part_name","brand","type","specification","part_category","qty","qty_min","qty_max","part_status","inventory_type","bin_location","qty_uom","main_supplier","price", "lead_time"])
    return(
        <FilterContext.Provider value={{data,setData,column,setColumn}}>
            {children}
        </FilterContext.Provider>
    )
}

export {CheckboxExportContext, CheckboxExportprovider};
export {DropdownContext, DropdownContextProvider}
export {FilterContext, FilterContextProvider}