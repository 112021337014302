import React from 'react'
import { useState } from 'react';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItemOption,
    MenuOptionGroup,
    Button,
  } from '@chakra-ui/react'
import { useContext } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import HeroFilterIcon from "../../../../assets/icons/hero/Sparepart/Hero_filter.svg"
import { FilterContext } from './ExportSparepart_Context';

const ExportSparepartFilter = () => {
    let filterContext = useContext(FilterContext)
    const [filterList, setFilterList] = useState(["ID","Item Code","Item Name","Brand","Type","Specification","Item Category","Stock","Min Stock","Max Stock","Item Status","Inventory Type","Bin Location","Quantity Unit","Main Supplier","Price", "Lead Time"])
    const [filter, setFilter] = useState([]);

    function translateFilter(elem){
        let ref = ["id","part_code","part_name","brand","type","specification","part_category","qty","qty_min","qty_max","part_status","inventory_type","bin_location","qty_uom","main_supplier","price", "lead_time"]
        return ref[filterList.indexOf(elem)]
    }
    function handleFilter(elem){
        let value = translateFilter(elem.elem)
        let arr = [...filter];
        let index = filter.indexOf(value);
        if (index !== -1){  
            arr.splice(index, 1);
        }else{
            arr.push(value)
        }
        setFilter(arr);
        filterContext.setColumn(arr);
    }
    return (
        <Menu closeOnSelect={false}>
            <MenuButton as={Button} colorScheme='white' shadow={"lg"}>
                <div className='flex'>
                <img src={HeroFilterIcon} alt="" className='mx-2'/>
                <ChevronDownIcon color={process.env.REACT_APP_ENV_COLOR} className="scale-150" />
                </div>
            </MenuButton>
            <MenuList minWidth='240px' style={{margin: 0}}>
                <MenuOptionGroup title='Filter List' type='checkbox'>
                    {filterList.map((elem) => (
                        <MenuItemOption fontSize={"xs"} pb={0} key={elem} value={elem} onClick={()=>handleFilter({elem})}>{elem}</MenuItemOption>
                    ))}
                </MenuOptionGroup>
            </MenuList>
        </Menu>
    );
}
 
export default ExportSparepartFilter;