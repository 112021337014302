import React, {useContext} from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ContentPDF from "./ContentPDF";
import Loader from "../../../components/Loader/Loader";
import {CheckBoxesContext} from "../../../components/CheckBoxes";
import {Button} from "@chakra-ui/react";

const GeneratePDF = (props) => {
    const pdfFileName = "ReOrder: " + props.reOrderIdentifier["case_number"] + " by " + props.reOrderIdentifier["user"];
    const checkboxContext = useContext(CheckBoxesContext);

    return (
        <Button colorScheme={process.env.REACT_APP_BUTTON_COLOR}>
            <a>
                <PDFDownloadLink document={<ContentPDF dataSpareParts={checkboxContext.checkBoxState} dataResume={props.dataResume} dataOrder={props.reOrderIdentifier}/>} fileName={pdfFileName}>
                    {({ blob, url, loading, error }) =>
                        loading ? <Loader/> : "Download: " + pdfFileName
                    }
                </PDFDownloadLink>
            </a>
        </Button>
    );
}

export default GeneratePDF;
