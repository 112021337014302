import React, {useEffect, useState} from "react";

import {Box, Container, useDisclosure} from "@chakra-ui/react";

import Axios from "../../services/api/Axios"
import Cookies from "js-cookie";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import SidebarIncoming from "./SidebarIncoming";
import ListIncoming from "./ListIncoming";
import SparePartPopUp from "../../components/Modal/SparePartPopUp";
import Modal from "../../components/Modal/AreYouSure";
import ModalQR from "../../components/Modal/SparePartQR";

let state = {
    pages: ["list", "archive", "opname"],
    dropDownList : ["part_name", "brand", "type"],
    offset: 25,
    dataOffset : 0,
    dropDownOffset : 25,
    dropDownSelectOffset : 0,
}

const Incoming = () => {
    const [dataSpareParts,setDataSpareParts] = useState([]);
    const [tempDataSpareParts,setTempDataSpareParts] = useState([]);
    const [dataInitialization,setDataInitialization] = useState(false);
    const [fetchMoreData,setFetchMoreData] = useState(false);

    const [dataDropDownSpareParts,setDataDropDownSpareParts] = useState({});
    const [fetchMoreDropDownData,setFetchMoreDropDownData] = useState([false, ""]);
    const [dataDropDownChange,setDataDropDownChange] = useState(false);

    const [confirmAction, setConfirmAction] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);

    const [refreshImage, setRefreshImage] = useState('')
    const [hasMoreCard, setHasMoreCard] = useState(true);
    const [hasMoreDropDown, setHasMoreDropDown] = useState({});

    const [modalData, setModalData] = useState({});
    const [unusedRefreshData, setUnusedRefreshData] = useState({});
    const [refreshPage, setRefreshPage] = useState(false)

    const [modalDataQR, setModalDataQR] = useState({});
    const [showPrintQR, setShowPrintQR] = useState(false);
    const [postLabelQR, setPostLabelQR] = useState({});

    let isIncoming = false;
    const zeroPad = (num, places) => String(num).padStart(places, '0');

    let history = {
        dataSparePartURL : "/sparepart/list?order=id,desc&is_active=true&sediaan_id=" + Cookies.get("idServing") +  "&offset=",
        dataSparePartQR: "/sparepart/item?id=",
        imageSparePartURL : "/image/item?id=",
        dataDropDownSparePartURL : "/sparepart/list?sediaan_id=" + Cookies.get("idServing") + "&is_active=true&dropdown=",
        postIncomingURL : "/incoming/nonPoSubmit",
        dataCartURL : "/request/cart",
        accessToken : Cookies.get("accessToken"),
    }

    const handleGetDataSpareParts = async () => {
        try{
            if (dataInitialization) state.dataOffset += 25;

            let getDataSpareParts = await Axios.get(history.dataSparePartURL + state.dataOffset,
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });

            (getDataSpareParts?.data?.data?.spareparts).map(async (dataSparePart) => {
                if (!dataSparePart["image_bin"] && dataSparePart?.image_id && dataSparePart?.image_id !== 1) {
                    let getDataImage = await Axios.get(history.imageSparePartURL + dataSparePart.image_id,
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });

                    dataSparePart["image_bin"] = getDataImage?.data?.data?.image?.bin;

                    setRefreshImage("fetchData");
                }
            })

            if ((getDataSpareParts?.data?.data?.spareparts)?.length < 25) {
                setHasMoreCard(false);
            }
            else setHasMoreCard(true);

            setDataSpareParts(dataSpareParts.concat(getDataSpareParts?.data?.data?.spareparts));

            if (!dataInitialization) {
                setTempDataSpareParts(getDataSpareParts?.data?.data?.spareparts);
            }
        }
        catch(error){
            console.error(error);
        }
    };

    const handleGetDataDropDownSpareParts = async () => {
        let objectDropDown = {};
        let objectHasMore = {};
        state.dropDownOffset = state.offset;

        state.dropDownList.map(async (dropDownListItem) => {
            let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            objectHasMore[dropDownListItem] = (getDataDropDownSpareParts?.data?.data?.spareparts)[dropDownListItem]?.length >= 25;
            setHasMoreDropDown(objectHasMore);

            if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
            objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
        })

        setDataDropDownSpareParts(objectDropDown);
    };

    const handleGetMoreDataDropDownSpareParts = async (dropDownListItem) => {
        let objectDropDown = JSON.parse(JSON.stringify(dataDropDownSpareParts));
        let objectHasMore = JSON.parse(JSON.stringify(hasMoreDropDown));

        let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + "&offset=" + state.dropDownOffset,
            {
                headers: {"Authorization": "Bearer " + history.accessToken},
            });

        objectHasMore[dropDownListItem] = (getDataDropDownSpareParts?.data?.data?.spareparts)[dropDownListItem]?.length >= 25;
        setHasMoreDropDown(objectHasMore);

        objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem])
        setDataDropDownSpareParts(objectDropDown);

        state.dropDownOffset += state.offset;
    };

    const handleGetDataDependentDropDownSpareParts = async (dropDownList) => {
        let objectDropDown = {};
        let objectHasMore = {};

        let queryString = '';

        Object.keys(dropDownList).map(async (dropDownListItem) => {
            if (dropDownList[dropDownListItem].length) {
                queryString = queryString.concat('&', dropDownListItem, '=', dropDownList[dropDownListItem])
            }
        })

        let searchString = queryString.replace(/ /g, '_');

        Object.keys(dropDownList).map(async (dropDownListItem) => {
            if (dropDownList[dropDownListItem].length === 0) {
                let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + searchString + "&limit=999",
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                objectHasMore[dropDownListItem] = false
                setHasMoreDropDown(objectHasMore);

                if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
                objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
            }
            else {
                let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + "&limit=999",
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                objectHasMore[dropDownListItem] = false
                setHasMoreDropDown(objectHasMore);

                if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
                objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
            }
        })

        setDataDropDownSpareParts(objectDropDown);
    };

    const handleSearchDataDropDownSparePartListItem = async (dropDownListItem, queryString) => {
        let objectDropDown = JSON.parse(JSON.stringify(dataDropDownSpareParts));
        let objectHasMore = JSON.parse(JSON.stringify(hasMoreDropDown));

        let tempString = queryString.replace(/ /g, '_');
        tempString = tempString.replace('.', '_');

        let getDataDropDownSpareParts = await Axios.get(`${history.dataDropDownSparePartURL + dropDownListItem}&${dropDownListItem}=%${tempString}%&limit=9999`,
            {
                headers: {"Authorization": "Bearer " + history.accessToken},
            });

        objectHasMore[dropDownListItem] = false;
        setHasMoreDropDown(objectHasMore);

        objectDropDown[dropDownListItem].splice(0, objectDropDown[dropDownListItem].length);
        objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);

        setDataDropDownSpareParts(objectDropDown);
        setDataDropDownChange(true);
    };

    const handleSearchSpareParts = async (dropDownValue) => {
        let searchQuery = '';

        Object.keys(dropDownValue).map((elem) => {
            if (dropDownValue[elem]) return searchQuery += ("&" + elem + "=" + dropDownValue[elem])
            return ''
        })

        try{
            let getDataSpareParts = await Axios.get(history.dataSparePartURL + 0 + "&limit=9999" + searchQuery,
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });

            (getDataSpareParts?.data?.data?.spareparts).map(async (dataSparePart) => {
                if (!dataSparePart["image_bin"] && dataSparePart?.image_id && dataSparePart?.image_id !== 1) {
                    let getDataImage = await Axios.get(history.imageSparePartURL + dataSparePart.image_id,
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });

                    dataSparePart["image_bin"] = getDataImage?.data?.data?.image?.bin;

                    setRefreshImage("fetchSearchData");
                }
            })

            setHasMoreCard(false);
            setDataSpareParts(getDataSpareParts?.data?.data?.spareparts);
        }
        catch(error){
            console.error(error);
        }
        setIsSearchActive(true);
    };

    const handleUseOldDataSpareParts = () => {
        let tempData = Array.from(tempDataSpareParts);
        if (tempData.length !== 0) setDataSpareParts(tempData);
        state.dataOffset = 0;

        if (tempData.length >= 25) setHasMoreCard(true);
    };

    const handleManipulateLabelQR = (dataSparePart) => {
        const dataQR = dataSparePart?.meta?.labelQR ? dataSparePart?.meta?.labelQR : [];
        const binLabelQR = dataSparePart?.meta?.binQR ? dataSparePart?.meta?.binQR : {};
        const batchQR = dataSparePart?.meta?.batchQR ? dataSparePart?.meta?.batchQR : {};

        const year = new Date().getFullYear();

        let codeQR = '';
        let tempArray = Array.from(dataQR);
        let tempObject = {}

        tempObject["year"] = Number(year)
        tempObject["quantity"] = Number(dataSparePart.qty_confirmedReturn ? dataSparePart.qty_confirmedReturn : 1)

        if (!batchQR[year]) {
            batchQR[year] = 1;
        }
        else {
            batchQR[year] += 1;
        }

        tempObject["sequence"] = batchQR[year];

        codeQR = zeroPad(tempObject["year"] % 100, 2) + '-' + zeroPad(dataSparePart.sediaan_id, 2) + '-' + dataSparePart.part_code + '-' + zeroPad(tempObject["sequence"], 2);

        tempArray.push(tempObject);
        binLabelQR[codeQR] = tempObject["quantity"]

        console.log(tempArray);

        setPostLabelQR({
            [dataSparePart.id]: [tempArray, binLabelQR, batchQR]
        });

        return codeQR
    }

    const {
        isOpen: isOpenSparePartPopUp,
        onOpen: onOpenSparePartPopUp,
        onClose: onCloseSparePartPopUp
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenReportModalQR,
        onOpen: onOpenReportModalQR,
        onClose: onCloseReportModalQR
    } = useDisclosure()

    useEffect(() => {
        if (!isSearchActive) if (fetchMoreData) handleGetDataSpareParts().then(() => setFetchMoreData(false));
        if (!dataInitialization) {
            handleGetDataDropDownSpareParts().then();
            handleGetDataSpareParts().then(() => setDataInitialization(true));
        }
        if (fetchMoreDropDownData[0]) handleGetMoreDataDropDownSpareParts(fetchMoreDropDownData[1]).then(() => setFetchMoreDropDownData([false, '']));
        if (dataDropDownChange) setDataDropDownChange(false);
        if (refreshImage !== '') setRefreshImage('');
        if (!isOpenSuccessModal) setUnusedRefreshData({});
    }, [fetchMoreData, fetchMoreDropDownData, isSearchActive, dataDropDownChange, refreshImage, onCloseSuccessModal]);

    useEffect(() => {
        switch(confirmAction){
            case "Incoming":
                try{
                    if (!isIncoming) {
                        isIncoming = true

                        let postIncoming = Axios.post(history.postIncomingURL,
                            {sparepart_id: modalData.id, qty_receive: (modalData.qty_confirmedReturn ? modalData.qty_confirmedReturn : 1)},
                            {
                                headers: {'Authorization': 'Bearer ' + history.accessToken},
                            });

                        console.log(postIncoming);

                        let tempObjectSparePart = {};

                        let labelQR = handleManipulateLabelQR(modalData)

                        console.log(labelQR);

                        tempObjectSparePart["qr_code"] = labelQR;
                        tempObjectSparePart["qty_print"] = (modalData.qty_confirmedReturn ? modalData.qty_confirmedReturn : 1);
                        tempObjectSparePart["part_code"] = modalData.part_code;
                        tempObjectSparePart["part_name"] = modalData.part_name;
                        tempObjectSparePart["type"] = modalData.type;
                        tempObjectSparePart["bin_location"] = modalData.bin_location;

                        setModalDataQR(tempObjectSparePart);
                        setShowPrintQR(true);

                        onOpenSuccessModal();
                    }
                }
                catch(error){
                    console.error(error);
                }

                setConfirmAction('');
                break;
            case "postQR":
                Object.keys(postLabelQR).map((id) => {
                    return Axios.post(history.dataSparePartQR + id,
                        {meta: {...modalData?.meta, "labelQR": postLabelQR[id][0], "binQR": postLabelQR[id][1], "batchQR": postLabelQR[id][2]}},
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        }).then(() => setRefreshPage(true));
                })

                setConfirmAction('');
                break;
            default:
                break;
        }
    }, [confirmAction])

    useEffect(() => {
        if (showPrintQR && !isOpenSuccessModal) onOpenReportModalQR();
    }, [isOpenSuccessModal])

    useEffect(() => {
        if (refreshPage && !isOpenReportModalQR && !isOpenSuccessModal) window.location.reload();
    }, [refreshPage])

    console.log(modalData);
    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingLeft={"30vw"} paddingRight={"0%"} paddingBottom={"0%"}>
                <SparePartPopUp
                    isOpen={isOpenSparePartPopUp}
                    onOpen={onOpenSparePartPopUp}
                    onClose={onCloseSparePartPopUp}
                    setConfirmAction={setConfirmAction}
                    modalData={modalData}
                    setModalData={setModalData}
                    type={"Incoming"}
                />
                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    type={"Success"}
                />
                <ModalQR
                    isOpen={isOpenReportModalQR}
                    onOpen={onOpenReportModalQR}
                    onClose={onCloseReportModalQR}
                    modalData={modalDataQR}
                    setModalData={setModalDataQR}
                    setConfirmAction={setConfirmAction}
                    setRefreshPage={setRefreshPage}
                    isMultiple={false}
                    type={"Incoming"}
                />

                <Box position={"fixed"} left={'0'} zIndex={1}>
                    <SidebarIncoming
                        dataDropDownSpareParts={dataDropDownSpareParts}
                        handleGetDataDropDownSpareParts={handleGetDataDropDownSpareParts}
                        handleGetDataDependentDropDownSpareParts={handleGetDataDependentDropDownSpareParts}
                        handleSearchSpareParts={handleSearchSpareParts}
                        setIsSearchActive={setIsSearchActive}
                        setDataInitialization={setDataInitialization}
                        handleUseOldDataSpareParts={handleUseOldDataSpareParts}
                        setFetchMoreDropDownData={setFetchMoreDropDownData}
                        handleSearchDataDropDownSparePartListItem={handleSearchDataDropDownSparePartListItem}
                        hasMoreDropDown={hasMoreDropDown}
                    />
                </Box>
                <Box bg={"#F3F8F8"}>
                    <ListIncoming
                        dataSpareParts={dataSpareParts}
                        setFetchMoreData={setFetchMoreData}
                        onOpenSparePartPopUp={onOpenSparePartPopUp}
                        setModalData={setModalData}
                        setRefreshImage={setRefreshImage}
                        hasMoreCard={hasMoreCard}
                    />
                </Box>
            </Container>
            <Footer />
        </>
    );
}

export default Incoming;
