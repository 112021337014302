import React, {useEffect, useState} from 'react';

import {
    Button,
    Text,
    Modal,
    Image,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Container,
    ModalHeader,
    Box,
    HStack,
    VStack,
    Divider,
    Center,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper, NumberDecrementStepper,
} from '@chakra-ui/react'

import QRCode from "react-qr-code";

import TablePrintIcon from "../../assets/img/print.svg";

const SparePartQR = (props) => {
    const [data, setData] = useState({});
    const [finishState, setFinishState] = useState(false);

    const zplCode = `^FX Begin setup
                        ^XA
                        ~TA120
                        ~JSN
                        ^LT0
                        ^MNW
                        ^MTT
                        ^PON
                        ^PMN
                        ^LH0,0
                        ^JMA
                        ^PR4,4
                        ~SD25
                        ^JUS
                        ^LRN
                        ^CI0
                        ^MD0
                        ^XZ
                    ^FX End setup
                    ^XA
                    ^FX QR Code
                        ^FO${props.type === "List" ? "20" : "135"},22^BQN,2,5^FDMA'${props.modalData.qr_code}^FS
                        ^FO${props.type === "List" ? "135" : "125"},15^GB3,139,3^FS
                        ^CF0,${props.type === "List" ? "25" : "14"}
                        ^FB105,3,
                        ^FO${props.type === "List" ? "145" : "20"},22^FD${props.type === "List" ? props.modalData.part_code : props.modalData.part_name}^FS
                        ^FO${props.type === "List" ? "145" : "20"},52^GB95,3,3^FS
                        ^CF0,16
                        ^FB105,3,,
                        ^FO${props.type === "List" ? "145" : "20"},64^FD${props.modalData.type}^FS
                        ^FO${props.type === "List" ? "145" : "20"},118^GB95,3,3^FS
                            ^CF0,${props.type === "List" ? "30" : "18"}
                        ^FB105,3,
                        ^FO${props.type === "List" ? "145" : "20"},129^FD${props.type === "List" ? props.modalData.bin_location : props.modalData.qr_code}^FS
                    ^FX End QR Code
                    ^XZ`

    useEffect(() => {
        handleModalType(props.type);
    }, [props.modalData])

    function handleDownloadZPL(numberOfPrints){
        const data = new Blob([zplCode], { type: 'text/plain' })
        const downloadLink = window.URL.createObjectURL(data)

        const link = document.createElement('a');
        link.href = downloadLink;
        link.setAttribute(
            'download',
            numberOfPrints + `.zpl`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    function handleConfirmSingle(){
        handleDownloadZPL(props.type === "List" ? 1 : props.modalData.qty_print);
        try {
            if (props.type === "List") props.setRefreshPage(true);
            else props.setConfirmAction("postQR")
        }
        catch (error) {}
        props.onClose(true);
    }

    function handleConfirmMultiple(){
        handleDownloadZPL(props.type === "List" ? 1 : props.modalData.qty_print);
        try {
            props.setConfirmAction("postQR");
        }
        catch (error) {
        }
        try {
            let tempPromisesModal = Array.from(props.promisesModal);

            if(!tempPromisesModal.length) props.setCountPromisesModal({
                "now": 0,
                "total": 0,
                "currentID": 0,
            });
            else {
                let tempCountPromisesModal = JSON.parse(JSON.stringify(props.countPromisesModal));
                tempCountPromisesModal["currentID"] = props.modalData.sparepart_id;

                props.setCountPromisesModal(tempCountPromisesModal)
            }
        }
        catch (error) {
        }
        setFinishState(true);
    }

    function handleFinish(){
        let tempPromisesModal = Array.from(props.promisesModal);
        tempPromisesModal.shift();
        // let tempPromisesModal = (Array.from(props.promisesModal)).splice(((props.promisesModal).findIndex(x => x.part_code === props.modalData.part_code)), 1);

        try {
            if(!tempPromisesModal.length) props.setCountPromisesModal({
                "now": 0,
                "total": 0,
                "currentID": 0,
            });
            else {
                let tempCountPromisesModal = JSON.parse(JSON.stringify(props.countPromisesModal));
                console.log(tempCountPromisesModal);
                tempCountPromisesModal["now"] += 1;

                props.setCountPromisesModal(tempCountPromisesModal)
            }
        }
        catch (error) {
        }

        props.setPromisesModal(tempPromisesModal);
        setFinishState(false);
        props.onClose(true);
    }

    function handleModalType(type){
        let data = {}
        switch(type){
            case "List":
                data.header = "Bin Location QR Code";
                data.title = "Do you want to print this QR Code?";
                data.content = "QR Code value: " + props.modalData.qr_code;
                data.confirmation = "Print";
                data.confirmationColor = "gray";
                data.cancel = "Cancel";
                data.handleConfirmation = props.isMultiple ? handleConfirmMultiple : handleConfirmSingle;
                data.logo = TablePrintIcon;
                data.QR = props.modalData.qr_code
                break;
            case "Incoming":
                data.header = "Label QR Code";
                data.title = props.isMultiple ? "Printing " + props.countPromisesModal.now + " of " + props.countPromisesModal.total + " selected QR" : "Do you want to print this QR Code?";
                data.content = "QR Code value: " + props.modalData.qr_code;
                data.confirmation = "Confirm Print";
                data.confirmationColor = "gray";
                data.cancel = "Cancel";
                data.handleConfirmation = props.isMultiple ? handleConfirmMultiple : handleConfirmSingle;
                data.logo = TablePrintIcon;
                data.QR = props.modalData.qr_code
                break;
            default:
                break;
        }
        setData(data);
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            closeOnOverlayClick={false}
            isCentered
            autoFocus={false}
            size={"sm"}
        >
            <ModalOverlay
                bg={"blackAlpha.500"}
                backdropFilter='auto'
                backdropBlur='2px'
            />
            <ModalContent>
                <ModalBody>
                    <ModalCloseButton onClick={() => {
                        try {props.setPromisesModal([])}
                        catch (error) {}
                        try {props.setRefreshPage(true)}
                        catch (error) {}
                        try {props.setCountPromisesModal({
                            "now": 0,
                            "total": 0,
                            "currentID": 0,
                        })}
                        catch (error) {}
                    }}
                    />
                    <ModalHeader>{data.header}</ModalHeader>
                    <Container>
                        <Center>
                            <Box bg='white' w='90%' px={2} color='white' border={"1px solid black"} borderRadius={"lg"}>
                                <HStack>
                                    {props.type === "List" &&
                                        <QRCode
                                            size={112}
                                            value={data.QR ? data.QR : ''}
                                        />
                                    }
                                    <VStack align={"start"} w={"85%"}>
                                        <Text fontSize={"sm"} color={"black"}>{props.type === "List" ? props.modalData.part_code : props.modalData.part_name}</Text>
                                        <Divider variant={"solid"} borderColor={"black"}/>
                                        <Text fontSize={"sm"} color={"black"}>{props.modalData.type}</Text>
                                        <Divider variant={"solid"} borderColor={"black"} />
                                        <Text fontSize={"sm"} color={"black"}>{props.type === "List" ? props.modalData.bin_location : props.modalData.qr_code}</Text>
                                    </VStack>
                                    {props.type === "Incoming" &&
                                        <QRCode
                                            size={112}
                                            value={data.QR ? data.QR : ''}
                                        />
                                    }
                                </HStack>
                            </Box>
                        </Center>
                        <Text fontSize={"sm"} color={"gray.500"} textAlign={"center"} pt={3}>({data.content})</Text>
                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"} pt={3}>{data.title}</Text>
                        {props.type === "Incoming" &&
                            <Center>
                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"} py={3} px={2}>for: </Text>
                                <NumberInput
                                    width={"25%"}
                                    borderColor={process.env.REACT_APP_INPUT_COLOR}
                                    min={1}
                                    max={99}
                                    focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                    value={(Number(props.modalData.qty_print))}
                                    onChange={(e) => {
                                        let tempObjectModalData = JSON.parse(JSON.stringify(props.modalData));
                                        tempObjectModalData["qty_print"] = Number(e);

                                        props.setModalData(tempObjectModalData);
                                    }}
                                >
                                    <NumberInputField _hover={{borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD}} />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"} py={3} px={2}>times</Text>
                            </Center>
                        }
                    </Container>
                </ModalBody>

                <ModalFooter alignSelf={"center"}>
                    <Button rightIcon={<Image src={TablePrintIcon}/>} className='float-right ml-4' colorScheme={data.confirmationColor} mr={3} onClick={data.handleConfirmation}>
                        {data.confirmation}
                    </Button>
                    {finishState &&
                        <Button className='float-right ml-4' colorScheme={"whatsapp"} mr={3} onClick={handleFinish}>
                            Finish
                        </Button>
                    }
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default SparePartQR;