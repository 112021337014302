import React, {useEffect, useState} from 'react';
import {useMediaQuery} from "react-responsive";
import Select from "react-select";

import {
    Button,
    Text,
    Modal,
    Image,
    Select as ChakraSelect,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    VStack,
    HStack,
    Flex,
    Spacer,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper, Box, RadioGroup, Stack, Radio, FormLabel, FormControl,
} from '@chakra-ui/react'

import Axios from "../../services/api/Axios";
import Cookies from "js-cookie";

import DeleteIcon from "../../assets/icons/modal/Modal_delete.svg";
import CartPlus from "../../assets/img/cartPlus.svg";

const SparePartPopUp = (props) => {
    const history = {
        getDataLinesURL : "/line/list?order=name&limit=9999&count=1&sediaan_id=",
        getDataMachinesURL : "/machine/list?limit=9999&order=code&line_id=",
        servingURL : "/sediaan/list",
        accessToken : Cookies.get("accessToken"),
        role : Cookies.get("role"),
        idServing : Cookies.get("idServing"),
        serving : Cookies.get("serving"),
    }

    const [data, setData] = useState({});
    const [stateConfirm, setStateConfirm] = useState(false);
    const [buttonTimeout, setButtonTimeout] = useState(true)

    const [dataInitialization, setDataInitialization] = useState(false);
    const [dataServings,setDataServings] = useState([]);
    const [dataLines,setDataLines] = useState([]);
    const [dataMachines,setDataMachines] = useState([]);

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" })

    const [columnState, setColumnState] = useState({
        //"database_column_name" : ["Name", "inputType", "isDisable", "isRequired", "isInvalid"],
        "sediaan_id" : ["Organizer", "select", (!(history.serving === "MEX" || history.role === "Superior")) , true, false],
        "line" : ["Plant", "select", false, true, false],
        "machine" : ["Department", "select", false, true, false],
    });

    const handleGetDataServing = async () => {
        try{
            let statusServing = await Axios.get(history.servingURL,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let tempStatusServings = [];

            statusServing.data?.data?.sediaans?.map((serving) => {
                let statusServing = {};

                statusServing["label"] = (serving?.nama).charAt(0).toUpperCase() + (serving?.nama).slice(1)
                statusServing["value"] = serving?.id

                tempStatusServings.push(statusServing);
            })

            setDataServings(tempStatusServings);
        }
        catch(error){
            console.error(error);
        }
    }

    const handleGetDataLines = async (id) => {
        try{
            let getDataLines = await Axios.get(history.getDataLinesURL + id,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let tempStatusLines = [];

            getDataLines.data?.data?.lines?.map((line) => {
                let statusLine = {};

                statusLine["label"] = line?.name
                statusLine["value"] = line?.id

                tempStatusLines.push(statusLine);
            })

            setDataLines(tempStatusLines);
            setDataMachines([]);
        }
        catch(error){
            console.error(error);
        }
    }

    const handleGetDataMachines = async (id) => {
        try{
            let getDataMachines = await Axios.get(history.getDataMachinesURL + id,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let tempStatusMachines = [];

            getDataMachines.data?.data?.machines?.map((machine) => {
                let statusMachine = {};

                statusMachine["label"] = machine?.code
                statusMachine["value"] = machine?.id

                tempStatusMachines.push(statusMachine);
            })

            setDataMachines(tempStatusMachines);
        }
        catch(error){
            console.error(error);
        }
    }

    useEffect(() => {
        if (!dataInitialization) handleGetDataServing().then(() => handleGetDataLines(history.idServing).then(() => setDataInitialization(true)));
        handleModalType(props.type);
        setStateConfirm(false);
        setButtonTimeout(true);
        if (!props.isOpen && props.type === "Request") {
            props.setRequestData({});
        }
    }, [props.isOpen, props.onClose, dataServings])

    function handleRequest(){
        props.onClose(true);
        props.setRequestConfirmation("requestNow");
    }

    function handleCart(){
        props.setRequestConfirmation("addToCart");
        props.onClose(true);
    }

    function handleOutgoing(){
        props.setConfirmAction(props.type);
        props.onClose(true);
    }

    function handleIncoming(){
        props.setConfirmAction(props.type);
        props.onClose(true);
    }

    function handleRestore(){
        props.setConfirmAction(props.type);
        props.onClose(true);
    }

    function handleModalType(type){
        let data = {}
        switch(type){
            case "Request":
                data.title = "Are you sure to delete these items?";
                data.content = "This action can not be undone";
                data.confirmation = "Add to Cart";
                data.confirmationColor = "teal";
                data.cancel = "Cancel";
                data.handleConfirmation = handleRequest;
                data.handleMultipleConfirmation = handleCart;
                data.logo = DeleteIcon;
                break;
            case "Outgoing":
                data.title = "Are you sure to delete these items?";
                data.content = "This action can not be undone";
                data.confirmation = "Add to Cart";
                data.confirmationColor = "teal";
                data.cancel = "Cancel";
                data.handleConfirmation = handleOutgoing;
                data.logo = DeleteIcon;
                break;
            case "Incoming":
                data.title = "Are you sure to delete these items?";
                data.content = "This action can not be undone";
                data.confirmation = "Add to Cart";
                data.confirmationColor = "teal";
                data.cancel = "Cancel";
                data.handleConfirmation = handleIncoming;
                data.logo = DeleteIcon;
                break;
            case "Restore":
                data.title = "Are you sure to delete these items?";
                data.content = "This action can not be undone";
                data.confirmation = "Add to Cart";
                data.confirmationColor = "teal";
                data.cancel = "Cancel";
                data.handleConfirmation = handleRestore;
                data.logo = DeleteIcon;
                break;
            case "Return":
                data.title = "Are you sure to delete these items?";
                data.content = "This action can not be undone";
                data.confirmation = "Add to Cart";
                data.confirmationColor = "teal";
                data.cancel = "Cancel";
                data.handleConfirmation = handleRestore;
                data.logo = DeleteIcon;
                break;
            default:
                break;
        }
        setData(data);
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            isCentered
            autoFocus={false}
            size={props.type === "Request" ? "sm" : "md"}
        >
            <ModalOverlay
                bg={"blackAlpha.500"}
                backdropFilter='auto'
                backdropBlur='2px'
            />
            <ModalContent>
                <ModalHeader textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{props.type} Item</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex>
                        <VStack align={"start"} spacing={0}>
                            <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                Item Code
                            </Text>
                            <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} pb={"3"}>
                                {props?.modalData?.part_code?.length > 10 ? props.modalData.part_code.slice(0, 10) + ".." : props.modalData.part_code}
                            </Text>

                            <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                Brand
                            </Text>
                            <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} pb={"3"}>
                                {props?.modalData?.brand?.length > 10 ? props.modalData.brand.slice(0, 10) + ".." : props.modalData.brand}
                            </Text>
                        </VStack>

                        <Spacer/>

                        <VStack align={"end"} spacing={0}>
                            <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                Item Name
                            </Text>
                            <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} pb={"3"}>
                                {props?.modalData?.part_name?.length > 10 ? props.modalData.part_name.slice(0, 10) + ".." : props.modalData.part_name}
                            </Text>

                            <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                Quantity
                            </Text>
                            <NumberInput
                                allowMouseWheel
                                defaultValue={
                                    props.type === "Outgoing" ? Number(props?.requestData?.qty_request)
                                        :
                                        (props.type === "Restore" ? Number(props?.modalData?.qty_return)
                                                :
                                                1
                                        )
                                }
                                min={props.type === "Restore" ? 0 : 0.001}
                                max={
                                    Number(props.type === "Outgoing" ? Number(props?.requestData?.qty_request)
                                        :
                                        (props.type === "Restore" ? Number(props?.modalData?.qty_return)
                                                :
                                                (props.type === "Return" ? Number(props?.modalData?.qty_receive)
                                                        :
                                                        999
                                                )
                                        )
                                    )
                                }
                                size={"sm"}
                                maxW={!isTabletOrMobile ? "4vw" : "75px"}
                                borderColor={process.env.REACT_APP_INPUT_COLOR}
                                focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                isInvalid={props.type === "Outgoing" ? (!isNaN(props.requestData?.qty_requestChange) ? (Number(props?.requestData?.qty_requestChange) > Number(props?.modalData?.qty) || Number(props?.requestData?.qty_requestChange) === 0) : Number(props?.requestData?.qty_request) > Number(props?.modalData?.qty))
                                    : (props.type === "Request" ? (Number(props.requestData?.qty_requestChange) === 0)
                                            :
                                            (props.type === "Return" ? (!isNaN(props.modalData?.qty_confirmedReturn) ? Number(props?.modalData?.qty_confirmedReturn) === 0 : Number(props?.modalData?.qty_receive) === 0)
                                                    :
                                                    (props.type === "Incoming" ? Number(props?.modalData?.qty_confirmedReturn) === 0
                                                            :
                                                            false
                                                    )
                                            )
                                    )}
                                // isDisabled={props.type === "Incoming" ? prop : false}
                                onChange={(e) => {
                                    if ((props.type === "Restore") || (props.type === "Return") || (props.type === "Incoming")) {
                                        let tempRequestData = JSON.parse(JSON.stringify(props.modalData));
                                        tempRequestData["qty_confirmedReturn"] = Number(e.valueOf());
                                        props.setModalData(tempRequestData);
                                    }
                                    else {
                                        let tempRequestData = JSON.parse(JSON.stringify(props.requestData));
                                        tempRequestData["qty_requestChange"] = Number(e.valueOf());
                                        props.setRequestData(tempRequestData);
                                    }

                                    setStateConfirm(false);
                                }}
                            >
                                <NumberInputField color={process.env.REACT_APP_TEXT_COLOR_BOLD} borderRadius={"1rem"} _hover={{borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD}}/>
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </VStack>
                    </Flex>

                    {props.type === "Request" &&
                        <VStack align={"start"}>
                            {Object.keys(columnState).map((elem, index) => (
                                <FormControl
                                    key={index}
                                    isRequired={columnState[elem][3]}
                                    isInvalid={columnState[elem][3] && !props.requestData[elem] && columnState[elem][4]}
                                >
                                    <FormLabel fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>{columnState[elem][0]}</FormLabel>

                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                textAlign: "center",
                                                borderColor: (columnState[elem][3] && !props.requestData[elem] &&  columnState[elem][4]) ? "red" : state.isFocused ? 'peachpuff' : 'rebeccapurple',
                                                borderRadius: 10,
                                                ":hover": {
                                                    borderColor: (columnState[elem][3] && !props.requestData[elem] && columnState[elem][4]) ? "red" : "mediumpurple"
                                                },
                                            }),
                                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                return {
                                                    ...styles,
                                                    backgroundColor: isDisabled
                                                        ? undefined
                                                        : isSelected
                                                            ? data.color
                                                            : isFocused
                                                                ? "lightpink"
                                                                : undefined,
                                                    color: isDisabled
                                                        ? '#ccc'
                                                        : isSelected
                                                            ? "green"
                                                                ? process.env.REACT_APP_BORDER_COLOR
                                                                : 'black'
                                                            : data.color,
                                                    cursor: isDisabled ? 'not-allowed' : 'default',

                                                    ':active': {
                                                        ...styles[':active'],
                                                        backgroundColor: !isDisabled
                                                            ? isSelected
                                                                ? data.color
                                                                : "green"
                                                            : undefined,
                                                    },
                                                };
                                            },
                                        }}
                                        onChange={(e) => {
                                            if (elem === "sediaan_id") {
                                                handleGetDataLines(e.value).then();

                                                let tempRequestData = JSON.parse(JSON.stringify(props.requestData));
                                                tempRequestData["line"] = 0;
                                                tempRequestData["machine"] = 0;
                                                tempRequestData[elem] = (e.value);
                                                props.setRequestData(tempRequestData);
                                            }
                                            else if (elem === "line") {
                                                handleGetDataMachines(e.value).then();

                                                let tempRequestData = JSON.parse(JSON.stringify(props.requestData));
                                                tempRequestData["machine"] = 0;
                                                tempRequestData[elem] = (e.value);
                                                props.setRequestData(tempRequestData);
                                            }
                                            else {
                                                let tempRequestData = JSON.parse(JSON.stringify(props.requestData));
                                                tempRequestData[elem] = (e.value);
                                                props.setRequestData(tempRequestData);
                                            }

                                            columnState[elem][4] = e.value === '';
                                        }}
                                        value={elem === "sediaan_id" ? (dataServings.find(serving => Number(serving.value) === (props.requestData[elem] ? props.requestData[elem] :Number(history.idServing))) || [])
                                            :
                                            (elem === "line" ? (dataLines.find(line => line.value === props.requestData[elem]) || []) : (dataMachines.find(machine => machine.value === props.requestData[elem])) || [])
                                        }
                                        isDisabled={(elem === "machine" ? !props.requestData["line"] : columnState[elem][2])}
                                        options={elem === "sediaan_id" ? dataServings
                                            :
                                            (elem === "line" ? dataLines : dataMachines )
                                        }
                                    />
                                </FormControl>
                            ))}
                        </VStack>
                    }

                    {(props.type === "Outgoing" || props.type === "Return" || props.type === "Restore") &&
                        <VStack align={"start"}>
                            <FormControl>
                                <FormLabel fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>Plant</FormLabel>

                                <ChakraSelect
                                    size='sm'
                                    bg="white"
                                    maxW={"full"}
                                    borderRadius={"lg"}
                                    borderColor={process.env.REACT_APP_INPUT_COLOR}
                                    focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                    _hover={{borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD}}
                                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                    isDisabled={true}
                                    defaultValue={props.modalData.line}
                                >
                                    <option value={props.modalData.line}>{props.modalData.line}</option>
                                </ChakraSelect>
                            </FormControl>

                            <FormControl>
                                <FormLabel fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>Department</FormLabel>

                                <ChakraSelect
                                    size='sm'
                                    bg="white"
                                    maxW={"full"}
                                    borderRadius={"lg"}
                                    borderColor={process.env.REACT_APP_INPUT_COLOR}
                                    focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                    _hover={{borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD}}
                                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                    isDisabled={true}
                                    defaultValue={props.modalData.machine}
                                >
                                    <option value={props.modalData.machine}>{props.modalData.machine}</option>
                                </ChakraSelect>
                            </FormControl>
                        </VStack>
                    }

                    {props.type === "Outgoing" &&
                        <Box bg={"gray.100"} borderRadius={"lg"}>
                            <VStack mt={2}>
                                <HStack>
                                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                        User:
                                    </Text>
                                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                        {props?.requestData?.user}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                        Timestamp:
                                    </Text>
                                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                        {(new Date(props?.requestData?.createdAt)).toString().slice(0, 24)}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                        Total Price:
                                    </Text>
                                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                        Rp {(props?.requestData?.price) * Number(props?.requestData?.qty_requestChange ? props?.requestData?.qty_requestChange : props?.requestData?.qty_request)},00
                                    </Text>
                                </HStack>
                            </VStack>
                        </Box>
                    }

                    {(props.type === "Restore" || props.type === "Return") &&
                        <Box bg={"gray.100"} borderRadius={"lg"}>
                            <VStack mt={2}>
                                <HStack>
                                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                        User:
                                    </Text>
                                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                        {props?.modalData?.user}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                        Timestamp:
                                    </Text>
                                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                        {(new Date(props?.modalData?.createdAt)).toString().slice(0, 24)}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                        Total Price:
                                    </Text>
                                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                        Rp {Number(props?.modalData?.price) * Number((props?.modalData?.qty_confirmedReturn) ? (props?.modalData?.qty_confirmedReturn) : (props.type === "Restore" ? Number(props?.modalData?.qty_return) : 1))},00
                                    </Text>
                                </HStack>
                            </VStack>
                        </Box>
                    }

                    {props.type === "Incoming" &&
                        <Box bg={"gray.100"} borderRadius={"lg"}>
                            <VStack mt={2}>
                                <HStack>
                                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                        Receiver:
                                    </Text>
                                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                        {Cookies.get('name')}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                        Incoming Status:
                                    </Text>
                                    <RadioGroup defaultValue={'2'}>
                                        <Stack direction='row'>
                                            <Radio isReadOnly value='1'>PO</Radio>
                                            <Radio isReadOnly value='2'>Non PO</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </HStack>
                                <HStack pb={1}>
                                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                        Total Price:
                                    </Text>
                                    {/*<NumberInput*/}
                                    {/*    size={"sm"}*/}
                                    {/*    allowMouseWheel*/}
                                    {/*    min={0}*/}
                                    {/*    step={1000}*/}
                                    {/*    value={Number(props?.modalData?.price * (props?.modalData?.qty_confirmedReturn ? props?.modalData?.qty_confirmedReturn : 1))}*/}
                                    {/*    isDisabled={true}*/}
                                    {/*    // onChange={(e) => handleAddDataSpareParts(elem, Number(e)).then(() => columnState[elem][4] = false)}*/}
                                    {/*>*/}
                                    {/*    <NumberInputField*/}
                                    {/*        placeholder={'0'}*/}
                                    {/*        color={process.env.REACT_APP_TEXT_COLOR_BOLD}*/}
                                    {/*        borderColor={process.env.REACT_APP_INPUT_COLOR }*/}
                                    {/*        borderRadius={"xl"}*/}
                                    {/*        _hover={{*/}
                                    {/*            borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )*/}
                                    {/*        }}*/}
                                    {/*        _focusVisible={{*/}
                                    {/*            borderColor: ("#2F855A" ),*/}
                                    {/*            boxShadow: ("0 0 0 1px #2F855A")*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*    <NumberInputStepper>*/}
                                    {/*        <NumberIncrementStepper />*/}
                                    {/*        <NumberDecrementStepper />*/}
                                    {/*    </NumberInputStepper>*/}
                                    {/*</NumberInput>*/}
                                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                        Rp {Number(props?.modalData?.price * (props?.modalData?.qty_confirmedReturn ? props?.modalData?.qty_confirmedReturn : 1))},00
                                    </Text>
                                </HStack>
                            </VStack>
                        </Box>
                    }

                </ModalBody>

                <ModalFooter alignSelf={"center"}>
                    {!stateConfirm ?
                        <HStack spacing={5}>
                            <Button rightIcon={props.type === "Request" ? <Image src={CartPlus} alt={"cart"} /> : ''} variant="solid" colorScheme={process.env.REACT_APP_BUTTON_COLOR} onClick={props.type === "Request" ? data.handleMultipleConfirmation : () => setStateConfirm(true)}
                                    isDisabled={props.type === "Outgoing" ? (!isNaN(props.requestData?.qty_requestChange) ? (Number(props?.requestData?.qty_requestChange) > Number(props?.modalData?.qty) || Number(props?.requestData?.qty_requestChange) === 0) : Number(props?.requestData?.qty_request) > Number(props?.modalData?.qty))
                                        :
                                        (props.type === "Request" ? ((Number(props.requestData?.qty_requestChange) === 0) || !props.requestData["machine"])
                                                :
                                                (props.type === "Return" ? (!isNaN(props.modalData?.qty_confirmedReturn) ? Number(props?.modalData?.qty_confirmedReturn) === 0 : Number(props?.modalData?.qty_receive) === 0)
                                                        :
                                                        (props.type === "Incoming" ? Number(props?.modalData?.qty_confirmedReturn) === 0
                                                                :
                                                                false
                                                        )
                                                )
                                        )}
                            >
                                {props.type === "Request" ? (data.confirmation)
                                    : (props.type === "Incoming" ? "Confirm Incoming!"
                                        : (props.type === "Outgoing" ? "Confirm Outgoing!"
                                                    : (props.type === "Restore" ? "Confirm Restore!"
                                                        : "Confirm Return!"
                                            )))}
                            </Button>
                        </HStack>
                        :
                        <HStack spacing={5}>
                            <Text>Are you sure?</Text>
                            {buttonTimeout && setTimeout(() => {
                                setButtonTimeout(false)
                            }, 1500)}
                            <Button isDisabled={buttonTimeout} size={"md"} variant="solid" colorScheme={process.env.REACT_APP_BORDER_COLOR} onClick={data.handleConfirmation}>
                                Yes
                            </Button>
                            <Button size={"md"} variant="solid" colorScheme={"red"} onClick={() => {
                                setStateConfirm(false);
                                setButtonTimeout(true);
                            }}>
                                No
                            </Button>
                        </HStack>
                    }
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default SparePartPopUp;