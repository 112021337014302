import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ContentPDF from "./ContentPDF";
import Loader from "../../../components/Loader/Loader";
import {Button} from "@chakra-ui/react";

const GeneratePDF = (props) => {
    const pdfFileName = "Opname: " + props.stockOpnameIdentifier;

    return (
        <Button colorScheme={"whatsapp"} size={"sm"}>
            <a>
                <PDFDownloadLink document={<ContentPDF dataOrder={props.dataOrder}/>} fileName={pdfFileName}>
                    {({ blob, url, loading, error }) =>
                        loading ? <Loader/> : "Download: " + pdfFileName
                    }
                </PDFDownloadLink>
            </a>
        </Button>
    );
}

export default GeneratePDF;
