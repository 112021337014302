import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {
    Button,
    ButtonGroup,
    Center,
    Checkbox,
    Container,
    Divider,
    Flex,
    Heading,
    HStack,
    VStack,
    Image,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useDisclosure,
} from "@chakra-ui/react";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import SparePartSubHeader from "./SubHeader";
import Modal from "../../components/Modal/AreYouSure";

import Cookies from "js-cookie";
import Axios from "../../services/api/Axios";

import BackIcon from "../../assets/icons/hero/Sparepart/Hero_back.svg";
import EmptyList from "../../assets/img/empty.svg";
import ModalTextInput from "../../components/Modal/TextInput";

const StockOpname = () => {
    const navigate = useNavigate();

    const [dataOpnameCart, setDataOpnameCart] = useState([]);
    const [checkBoxes, setCheckBoxes] = useState({});
    const [modalData, setModalData] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [indexModal, setIndexModal] = useState(0);
    const [confirmAction, setConfirmAction] = useState('');

    const history = {
        dataOpnameValidator : "/opname/list?count=1&sediaan_id=" + Cookies.get("idServing") + "&is_active=true&order=updatedAt,asc&status=SUBMIT",
        postApproveOpnameCart: "/stockUpdate/submit?id=",
        accessToken : Cookies.get("accessToken"),
    }

    const columnNames = {
        "part_code" : "Part Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "part_category" : "Part Category",
        "qty" : "Stock",
        "real_stock" : "Real Stock",
        "difference" : "Stock Diff",
        "qty_min" : "Min Stock",
        "qty_max" : "Max Stock",
        "bin_location" : "Bin Location"
    }

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenErrorModal,
        onOpen: onOpenErrorModal,
        onClose: onCloseErrorModal
    } = useDisclosure()

    const {
        isOpen: isOpenTextModal,
        onOpen: onOpenTextModal,
        onClose: onCloseTextModal
    } = useDisclosure()

    const {
        isOpen: isOpenModalConfirmation,
        onOpen: onOpenModalConfirmation,
        onClose: onCloseModalConfirmation
    } = useDisclosure()

    const handleGetDataOpnameCartSpareParts = async () => {
        try{
            let tempDataOpnameSpareParts = [];

            let getDataOpnameSpareParts = await Axios.get(history.dataOpnameValidator,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let allData = getDataOpnameSpareParts?.data?.data?.opnames || [];

            allData.map((data) => {
                let tempAllData = JSON.parse(JSON.stringify(data));

                delete tempAllData["opnameSpareparts"];

                tempAllData["opnameSpareparts"] = []

                data.opnameSpareparts.map((datum) => {
                    let tempDatum = JSON.parse(JSON.stringify(datum));

                    tempDatum["part_code"] = datum?.sparepart?.part_code;
                    tempDatum["part_name"] = datum?.sparepart?.part_name;
                    tempDatum["brand"] = datum?.sparepart?.brand;
                    tempDatum["type"] = datum?.sparepart?.type;
                    tempDatum["part_category"] = datum?.sparepart?.part_category;
                    tempDatum["qty"] = datum?.sparepart?.qty;

                    tempAllData["opnameSpareparts"].push(tempDatum);

                    return 0
                })

                tempDataOpnameSpareParts.push(tempAllData);

                return 0
            });

            setDataOpnameCart(tempDataOpnameSpareParts);
        }
        catch(error){
            console.error(error);
        }
    };

    const handleStockOpnameSparePart = async () => {
        let tempModalData = JSON.parse(JSON.stringify(modalData));
        let CAPA = tempModalData["CAPA"] || {"corrective": [], "preventive": []}

        let approvePromiseArray = [];
        let tempDataOpnameCart = JSON.parse(JSON.stringify(dataOpnameCart[indexModal]));

        if (tempDataOpnameCart.hasOwnProperty("opnameSpareparts")) {
            (tempDataOpnameCart.opnameSpareparts).map((datum) => {
                approvePromiseArray.push(new Promise((resolve) => {
                    try{
                        let postApproveOpname = Axios.post(history.postApproveOpnameCart + datum.id,
                            {"meta": { "CAPA": CAPA }},
                            {
                                headers: {'Authorization': 'Bearer ' + history.accessToken},
                            });

                        console.log(postApproveOpname);
                        resolve(postApproveOpname);
                    }
                    catch(error){
                        setErrorMessage(error);
                        onOpenErrorModal();
                        console.error(error);
                    }
                }))

                return 0
            })
        }

        await Promise.all(approvePromiseArray);
        handleGetDataOpnameCartSpareParts().then();
        onOpenSuccessModal();
    }

    useEffect(() => {
        handleGetDataOpnameCartSpareParts().then();
    }, []);

    useEffect(() => {
        switch (confirmAction) {
            case "ApproveWithCAPA":
                handleStockOpnameSparePart().then();
                break;
            case true:
                let isNeedingCAPA = false;
                let tempDataOpname = Array.from(dataOpnameCart[indexModal]?.opnameSpareparts);

                tempDataOpname.map((datumOpname) => {
                    if (Number(datumOpname.qty_difference) !== 0) isNeedingCAPA = true;
                })

                console.log(isNeedingCAPA);

                if (isNeedingCAPA) {
                    onOpenTextModal();
                }
                else {
                    handleStockOpnameSparePart().then();
                }
                break;
            default:
                break;
        }
    }, [confirmAction]);

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingTop={"2vh"}>
                <SparePartSubHeader/>

                <Modal
                    isOpen={isOpenModalConfirmation}
                    onOpen={onOpenModalConfirmation}
                    onClose={onCloseModalConfirmation}
                    setConfirmSentResetPassword={setConfirmAction}
                    type={"Request"}
                />

                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    type={"Success"}
                />
                <Modal
                    isOpen={isOpenErrorModal}
                    onOpen={onOpenErrorModal}
                    onClose={onCloseErrorModal}
                    errorMessage={errorMessage}
                    type={"Error"}
                />
                <ModalTextInput
                    isOpen={isOpenTextModal}
                    onOpen={onOpenTextModal}
                    onClose={onCloseTextModal}
                    data={dataOpnameCart[indexModal]?.opnameSpareparts}
                    setConfirmAction={setConfirmAction}
                    modalData={modalData}
                    setModalData={setModalData}
                    type={"CAPA"}
                />

                <Flex marginTop={"4vh"} marginBottom={1} w={"98%"} position={"fixed"}>
                    <ButtonGroup gap={3}>
                        <Button leftIcon={<Image src={BackIcon} alt="" />} backgroundColor='#5AA799' borderRadius="8px" variant='solid' _hover={{ bg: "#7BBAAF" }} _active={{ bg: "#1F7E6D" }} boxShadow="lg" onClick={() => navigate("/sparepart/list")}>
                            <Text className="text-white font-extrabold tracking-wide ml-2">Back</Text>
                        </Button>
                    </ButtonGroup>

                    <Spacer/>

                    <HStack alignItems={"end"}>
                        <Text fontSize={"2xl"} fontWeight={"bold"} color={"#1F7E6D"}>
                            {dataOpnameCart.length}
                        </Text>
                        <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                            cases need to be reviewed.
                        </Text>
                    </HStack>
                </Flex>

                <Divider variant={"dashed"} borderColor={"green"} margin={2} w={"98%"} marginTop={"9vh"} position={"fixed"}/>

                {dataOpnameCart.length === 0 ?
                    <Center w={"full"} marginTop={"5%"}>
                        <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                        <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                            No items in here..
                        </Text>
                    </Center>
                    :
                    <Container maxW={"full"} position={"relative"} marginTop={"10vh"} maxH={"68vh"} overflowY={"auto"}>
                        {dataOpnameCart.map((datumOpnameCart, index) => (
                            <Accordion
                                key={index + "dataOpnameCart"}
                                allowMultiple
                                defaultIndex={index === 0 ? [0] : [-1]}
                            >
                                <AccordionItem
                                    marginX={"3%"}
                                    marginY={5}
                                    borderRadius={"lg"}
                                    borderColor={process.env.REACT_APP_INPUT_COLOR}
                                    boxShadow={"0 4px 8px 0 rgba(0, 0, 0, 0.2)"}
                                >
                                    <h2>
                                        <AccordionButton
                                            borderRadius={"lg"}
                                            _hover={{
                                                borderRadius: "lg",
                                                backgroundColor: "green.50"
                                            }}
                                        >
                                            <Flex>
                                                <HStack spacing={5}>
                                                    <Heading size='md' color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Stock Opname Case: {datumOpnameCart?.meta?.opname_number}</Heading>
                                                    <Text fontWeight={"bold"} borderRadius={"md"} bg={"blue.100"} color={"royalblue"}>PIC: {datumOpnameCart?.user?.name}</Text>
                                                    <Text fontWeight={"bold"} borderRadius={"md"} bg={"red.100"} color={"mediumvioletred"}>{(new Date(datumOpnameCart?.updatedAt)).toString().slice(0, 33)}</Text>
                                                </HStack>
                                            </Flex>

                                            <Spacer/>

                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <VStack>
                                            <TableContainer overflowY={"scroll"} whiteSpace={"normal"} display={"block"} maxHeight={"45vh"} border={"1px solid #B7D4CF"} w={"full"}>
                                                <Table variant="simple">
                                                    <Thead zIndex={1} pos='sticky' top={0} bg={"#E1F1F3"}>
                                                        <Tr>
                                                            <Th>
                                                                <Text className="m-1 text-center text-[#492472] font-bold text-sm"></Text>
                                                            </Th>
                                                            <Th>
                                                                <Text className="m-1 text-center text-[#492472] font-bold text-sm">ID</Text>
                                                            </Th>
                                                            {Object.keys(columnNames).map((elem) => (
                                                                <Fragment key={columnNames[elem]}>
                                                                    <Th>
                                                                        <Text className="m-1 text-center text-[#492472] font-bold text-sm">{columnNames[elem]}</Text>
                                                                    </Th>
                                                                </Fragment>
                                                            ))}
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {datumOpnameCart?.opnameSpareparts?.map((opnameSparepart) => (
                                                            <Tr key={opnameSparepart.id}>
                                                                <Td>
                                                                    <Checkbox
                                                                        size="lg"
                                                                        colorScheme="green"
                                                                        borderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                                        isChecked={checkBoxes[datumOpnameCart.id] ? checkBoxes[datumOpnameCart.id].includes(opnameSparepart.id) : false}
                                                                        onChange={() => {
                                                                            let tempObject = JSON.parse(JSON.stringify(checkBoxes));
                                                                            if (!tempObject[datumOpnameCart.id]) tempObject[datumOpnameCart.id] = [];


                                                                            if (tempObject[datumOpnameCart.id].includes(opnameSparepart.id)) tempObject[datumOpnameCart.id].splice((tempObject[datumOpnameCart.id].indexOf(opnameSparepart.id)), 1);
                                                                            else tempObject[datumOpnameCart.id].push(opnameSparepart.id);

                                                                            setCheckBoxes(tempObject);
                                                                        }}
                                                                    >
                                                                    </Checkbox>
                                                                </Td>
                                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                                    {opnameSparepart?.sparepart?.id}
                                                                </Td>
                                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                                    {opnameSparepart?.sparepart?.part_code}
                                                                </Td>
                                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                                    {opnameSparepart?.sparepart?.part_name}
                                                                </Td>
                                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                                    {opnameSparepart?.sparepart?.brand}
                                                                </Td>
                                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                                    {opnameSparepart?.sparepart?.type}
                                                                </Td>
                                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                                    {opnameSparepart?.sparepart?.part_category}
                                                                </Td>
                                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                                    {opnameSparepart?.sparepart?.qty}
                                                                </Td>
                                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} w={"11%"}>
                                                                    {Number(opnameSparepart?.sparepart?.qty) + Number(opnameSparepart?.qty_difference)}
                                                                </Td>
                                                                <Td textAlign={"center"} color={Number(opnameSparepart?.qty_difference) !== 0 ? "red" : process.env.REACT_APP_TEXT_COLOR_BOLD} w={"11%"}>
                                                                    {opnameSparepart?.qty_difference}
                                                                </Td>
                                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                                    {opnameSparepart?.sparepart?.qty_min}
                                                                </Td>
                                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                                    {opnameSparepart?.sparepart?.qty_max}
                                                                </Td>
                                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                                    {opnameSparepart?.sparepart?.bin_location}
                                                                </Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </TableContainer>

                                            <HStack w={"full"}>
                                                <Spacer/>
                                                <Button
                                                    variant="solid"
                                                    colorScheme={"yellow"}
                                                    isDisabled={checkBoxes[datumOpnameCart.id] ? checkBoxes[datumOpnameCart.id].length !== datumOpnameCart?.opnameSpareparts?.length : true}
                                                    onClick={() => {
                                                        onOpenModalConfirmation();
                                                        setIndexModal(index);
                                                    }}
                                                >
                                                    Validate
                                                </Button>
                                            </HStack>
                                        </VStack>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        ))}

                    </Container>
                }
            </Container>
            <Footer />
        </>
    );
}

export default StockOpname;