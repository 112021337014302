import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import SparePartSubHeader from "./SubHeader";

import sparePart from "../../assets/img/imageSparePart.svg"

import {
    Button,
    Avatar,
    IconButton,
    AvatarBadge,
    Input,
    FormLabel,
    FormControl,
    Select,
    Container,
    Heading,
    HStack,
    Spacer,
    VStack,
    ButtonGroup,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useDisclosure,
} from "@chakra-ui/react";
import { AttachmentIcon } from '@chakra-ui/icons';

import Axios from "../../services/api/Axios";
import Cookies from "js-cookie";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/AreYouSure";

import ModalQR from "../../components/Modal/SparePartQR";

const AddSparePart = () => {
    let history = {
        dataSparePartItemURL : "/sparepart/item",
        // dataDropdownUOM: "/uom",
        dataDropdownPartCategory: "/partCategory?dropdown=part_category&count=1",
        dataDropdownPartStatus: "/partStatus?dropdown=part_status&count=1",
        dataDropdownInventoryType: "/inventoryType?dropdown=inventory_type&count=1",
        imageSparePartURL : "/image/item",
        accessToken : Cookies.get("accessToken"),
        idServing : Cookies.get("idServing"),
        dataServingURL: "/sediaan/item?id=",
    }

    const navigate = useNavigate();

    const [dataSpareParts, setDataSpareParts] = useState({});
    const [imageSpareParts, setImageSpareParts] = useState({});
    const [confirmSubmitData, setConfirmSubmitData] = useState(false);
    const [navigateBack, setNavigateBack] = useState(false);
    const [showPrintQR, setShowPrintQR] = useState(false);

    const [dataInitialization,setDataInitialization] = useState(false);
    const [dataDropdown, setDataDropdown] = useState({});
    const [serving, setServing] = useState('');
    const [modalData, setModalData] = useState({});

    const inputImageRef = useRef();

    const [columnState, setColumnState] = useState({
        //"database_column_name" : ["Name", "inputType", "isDisable", "isRequired", "isInvalid"],
        "part_category" : ["Item Category", "select", false, true, false],
        "part_name" : ["Item Name", "text", false, true, false],
        "brand" : ["Brand", "text", false, true, false],
        "type" : ["Type", "text", false, true, false],
        "specification" : ["Specification", "text", false, true, false],
        "qty" : ["Stock", "number", true, true, false],
        "qty_min" : ["Min Stock", "number", true, true, false],
        "qty_max" : ["Max Stock", "number", true, true, false],
        "part_status" : ["Item Status", "select", false, true, false],
        "inventory_type" : ["Inventory Type", "select", false, true, false],
        "bin_location" : ["Bin Location", "text", false, true, false],
        "qty_uom" : ["Quantity Unit", "select", false, true, false],
        "main_supplier" : ["Main Supplier", "text", false, true, false],
        "alternative_supplier1" : ["Alternative Supplier I", "text", false, false, false],
        "alternative_supplier2" : ["Alternative Supplier II", "text", false, false, false],
        "price" : ["Price", "number", false, true, false],
        "lead_time" : ["Lead Time", "text", false, false, false],
        "set_image" : ["Set Image", "text", true, false, false],
    });

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleGetServing = async () => {
        try{
            let getServing = await Axios.get(history.dataServingURL + Cookies.get("idServing"),
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            setServing(getServing?.data?.data?.sediaan);
        }
        catch (error){

        }
    }

    const handleGetDropdown = async () => {
        try{
            let tempObject = {};

            // let getDropdownUOM = await Axios.get(history.dataDropdownUOM,
            //     {
            //         headers: {"Authorization": "Bearer " + history.accessToken},
            //     });

            let getDropdownCategory = await Axios.get(history.dataDropdownPartCategory,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let getDropdownStatus = await Axios.get(history.dataDropdownPartStatus,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let getDropdownInventory = await Axios.get(history.dataDropdownInventoryType,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            // tempObject["qty_uom"] = getDropdownUOM?.data?.data?.uoms;
            tempObject["part_category"] = getDropdownCategory?.data?.data?.partCategorys?.part_category;
            tempObject["part_status"] = getDropdownStatus?.data?.data?.partStatuss?.part_status;
            tempObject["inventory_type"] = getDropdownInventory?.data?.data?.inventoryTypes?.inventory_type;

            setDataDropdown(tempObject);
        }
        catch (error){
            console.error(error);
        }
    }

    const handleAddDataSpareParts = async (target, value) => {
        let tempDataAddSpareParts = JSON.parse(JSON.stringify(dataSpareParts));
        tempDataAddSpareParts[target] = value;

        if (target === "qty_max" || target === "qty_min") {
            if (!isNaN(tempDataAddSpareParts["qty_min"]) && !isNaN(tempDataAddSpareParts["qty_max"])) {
                const qty_min = tempDataAddSpareParts["qty_min"];
                const qty_max = tempDataAddSpareParts["qty_max"];

                if (Number(qty_max) < Number(qty_min)) {
                    columnState["qty_min"][4] = true;
                    columnState["qty_max"][4] = true;
                }
                else {
                    columnState["qty_min"][4] = false;
                    columnState["qty_max"][4] = false;
                }
            }
        }

        if (!tempDataAddSpareParts["sediaan_id"]) tempDataAddSpareParts["sediaan_id"] = history.idServing;
        if (!tempDataAddSpareParts["image_id"]) tempDataAddSpareParts["image_id"] = 1;
        setDataSpareParts(tempDataAddSpareParts);
    };

    const handleSetImageSpareParts = async (file) => {
        const acceptedFileType = /image/;
        console.log(file);

        if (acceptedFileType.test(file.type)) {
            let tempObject = {};
            tempObject["bin"] = await toBase64(file);
            tempObject["name"] = file.name;
            tempObject["extension"] = file.name.split(".")[1];

            setImageSpareParts(tempObject);
        }
    }

    const handleSubmitDataSpareParts = async () => {
        let tempColumnState = JSON.parse(JSON.stringify(columnState));
        let isInvalid = false;

        Object.keys(tempColumnState).map((elem) => {
            if (elem === "qty") {
                if (tempColumnState[elem][3] && isNaN(dataSpareParts[elem])) {
                    tempColumnState[elem][4] = true;
                    isInvalid = true;
                }
            }
            else if (elem === "qty_min") {
                if (tempColumnState[elem][3] && isNaN(dataSpareParts[elem])) {
                    tempColumnState[elem][4] = true;
                    isInvalid = true;
                }
                else if (Number(dataSpareParts[elem]) > Number(dataSpareParts["qty_max"])) {
                    tempColumnState["qty_min"][4] = true;
                    tempColumnState["qty_max"][4] = true;
                    isInvalid = true;
                }
            }
            else {
                if (tempColumnState[elem][3] && !dataSpareParts[elem]) {
                    tempColumnState[elem][4] = true;
                    isInvalid = true;
                }
            }
            return ''
        })

        console.log(dataSpareParts);
        if (!isInvalid) {
            let imageIdentifier = 1;
            let tempDataSpareParts = JSON.parse(JSON.stringify(dataSpareParts));

            try{
                if (Object.keys(imageSpareParts).length !== 0) {
                    let submitImageSpareParts = await Axios.post(history.imageSparePartURL,
                        imageSpareParts,
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });

                    imageIdentifier = submitImageSpareParts?.data?.data?.image?.id;
                    tempDataSpareParts["image_id"] = imageIdentifier;

                    console.log(submitImageSpareParts);
                }

                let submitDataSpareParts = await Axios.post(history.dataSparePartItemURL,
                    tempDataSpareParts,
                    {
                        headers: {'Authorization': 'Bearer ' + history.accessToken},
                    });

                console.log(submitDataSpareParts)

                let tempObjectSparePart = {};
                let id = Number((submitDataSpareParts?.data?.data?.sparepart?.part_code).substring(1));

                const zeroPad = (num, places) => String(num).padStart(places, '0');
                let binLocQR = serving?.huruf + "-" + (submitDataSpareParts?.data?.data?.sparepart?.part_code)[0] + zeroPad(id, 5) + "-" + submitDataSpareParts?.data?.data?.sparepart?.bin_location;

                tempObjectSparePart["qr_code"] = binLocQR;
                tempObjectSparePart["part_code"] = submitDataSpareParts?.data?.data?.sparepart?.part_code;
                tempObjectSparePart["part_name"] = submitDataSpareParts?.data?.data?.sparepart?.part_name;
                tempObjectSparePart["type"] = submitDataSpareParts?.data?.data?.sparepart?.type;
                tempObjectSparePart["bin_location"] = submitDataSpareParts?.data?.data?.sparepart?.bin_location;
                setModalData(tempObjectSparePart);

                onOpenSuccessModal();
            }
            catch(error){
                console.error(error);
            }
        }

        setColumnState(tempColumnState);
    };

    const {
        isOpen: isOpenRequestModal,
        onOpen: onOpenRequestModal,
        onClose: onCloseRequestModal
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenReportModalQR,
        onOpen: onOpenReportModalQR,
        onClose: onCloseReportModalQR
    } = useDisclosure()

    useEffect(() => {
        if (!dataInitialization) handleGetServing().then(() => handleGetDropdown().then(() => setDataInitialization(true)));
        if (confirmSubmitData) handleSubmitDataSpareParts().then(() => setConfirmSubmitData(false));
        if (showPrintQR) onOpenReportModalQR();
        if (navigateBack) navigate("/item/list");
    },[dataSpareParts, confirmSubmitData, showPrintQR, navigateBack])

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingBottom={"12vh"} paddingTop={"2vh"}>
                <Modal
                    isOpen={isOpenRequestModal}
                    onOpen={onOpenRequestModal}
                    onClose={onCloseRequestModal}
                    setConfirmSentResetPassword={setConfirmSubmitData}
                    type={"Request"}
                />

                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    setRedirect={setShowPrintQR}
                    type={"Success"}
                />

                <ModalQR
                    isOpen={isOpenReportModalQR}
                    onOpen={onOpenReportModalQR}
                    onClose={onCloseReportModalQR}
                    modalData={modalData}
                    setRefreshPage={setNavigateBack}
                    isMultiple={false}
                    type={"List"}
                />

                <SparePartSubHeader />
                <Container maxW={"50%"} centerContent>
                    <VStack w={"full"} spacing={5}>
                        <Heading color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Add Item</Heading>
                        <Avatar size={"2xl"} src={Object.keys(imageSpareParts).length !== 0 ? imageSpareParts.bin : sparePart} marginBottom={"2%"}>
                            <AvatarBadge
                                as={IconButton}
                                size="sm"
                                rounded="full"
                                bottom="10px"
                                colorScheme={process.env.REACT_APP_BORDER_COLOR}
                                aria-label="remove Image"
                                icon={<AttachmentIcon />}
                                onClick={() => inputImageRef.current.click()}
                            />
                            <Input
                                type='file'
                                accept={"image/*"}
                                ref={inputImageRef}
                                style={{ display: 'none' }}
                                onInput={(e) => handleSetImageSpareParts(e.target.files[0])}
                            />
                        </Avatar>
                        <VStack w={"full"}>
                            {Object.keys(columnState).map((elem, index) => (
                                <FormControl key={elem + index} isRequired={columnState[elem][3]} isInvalid={columnState[elem][3] && ((elem === "qty_max" || elem === "qty_min") ? true : !dataSpareParts[elem]) && columnState[elem][4]}>
                                    <HStack align={"center"}>
                                        <FormLabel
                                            color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                            fontWeight={"bold"}
                                        >
                                            {columnState[elem][0]}
                                        </FormLabel>
                                        <Spacer/>
                                        {columnState[elem][1] === "select" ?
                                            <Select
                                                maxW={"70%"}
                                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                borderColor={process.env.REACT_APP_INPUT_COLOR }
                                                borderRadius={"xl"}
                                                focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                _hover={{
                                                    borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                                }}
                                                defaultValue={"Please select an option"}
                                                onChange={(e) => handleAddDataSpareParts(elem, e.target.value).then(() => columnState[elem][4] = false)}
                                            >
                                                <option disabled>Please select an option</option>
                                                {columnState[elem][0] === "Item Category" &&
                                                  <>
                                                      {dataDropdown["part_category"]?.map((status) => (
                                                        <option key={status}>{status}</option>
                                                      ))
                                                      }
                                                  </>
                                                }
                                                {columnState[elem][0] === "Quantity Unit" &&
                                                  <>
                                                      <option>dus</option>
                                                      <option>galon</option>
                                                      <option>kg</option>
                                                      <option>liter</option>
                                                      <option>pak</option>
                                                      <option>pcs</option>
                                                      <option>rim</option>
                                                      <option>set</option>
                                                  </>
                                                }
                                                {columnState[elem][0] === "Item Status" &&
                                                  <>
                                                      {dataDropdown["part_status"]?.map((status) => (
                                                        <option key={status}>{status}</option>
                                                      ))
                                                      }
                                                  </>
                                                }
                                                {columnState[elem][0] === "Inventory Type" &&
                                                  <>
                                                      {dataDropdown["inventory_type"]?.map((type) => (
                                                        <option key={type}>{type}</option>
                                                      ))
                                                      }
                                                  </>
                                                }
                                            </Select>
                                            : columnState[elem][1] === "number" ?
                                                <NumberInput
                                                    allowMouseWheel
                                                    min={(columnState[elem][0] === "Stock" || columnState[elem][0] === "Min Stock") ? 0 : 1}
                                                    step={elem === "price" ? 1000 : 1}
                                                    w={"70%"}
                                                    onChange={(e) => handleAddDataSpareParts(elem, Number(e)).then(() => columnState[elem][4] = false)}
                                                >
                                                    <NumberInputField
                                                        placeholder={'0'}
                                                        color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                        borderColor={process.env.REACT_APP_INPUT_COLOR}
                                                        borderRadius={"xl"}
                                                        _hover={{
                                                            borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD)
                                                        }}
                                                        _focusVisible={{
                                                            borderColor: (process.env.REACT_APP_ENV_COLOR),
                                                            boxShadow: ("0 0 0 1px " + process.env.REACT_APP_ENV_COLOR)
                                                        }}
                                                    />
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>
                                                :
                                                <Input
                                                    maxW={"70%"}
                                                    type={columnState[elem][1]}
                                                    placeholder={elem !== "set_image" ? (elem !== "sediaan_id" ? columnState[elem][0] : (serving?.nama ? (serving?.nama)?.charAt(0).toUpperCase() + (serving?.nama)?.slice(1) : "Sediaan")) : "Please set an image by clicking the small link icon button above!"}
                                                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                    borderColor={process.env.REACT_APP_INPUT_COLOR }
                                                    borderRadius={"xl"}
                                                    focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                    _hover={{
                                                        borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                                    }}
                                                    onChange={(e) => handleAddDataSpareParts(elem, e.target.value).then(() => columnState[elem][4] = false)}
                                                    disabled={columnState[elem][2]}
                                                />
                                        }
                                    </HStack>
                                </FormControl>
                            ))}
                        </VStack>
                        <ButtonGroup gap={5}>
                            <Button
                                backgroundColor='#D75555'
                                color={"white"}
                                borderRadius={"8px"}
                                boxShadow="lg"
                                _hover={{}}
                                onClick={() => navigate("/item/list")}
                            >
                                Back
                            </Button>
                            <Button
                                colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                                borderRadius={"8px"}
                                boxShadow="lg"
                                _hover={{}}
                                onClick={onOpenRequestModal}
                            >
                                Add
                            </Button>
                            {/*<Button*/}
                            {/*    rightIcon={<Image src={TablePrintIcon}/>}*/}
                            {/*    colorScheme={"gray"}*/}
                            {/*    borderRadius={"8px"}*/}
                            {/*    boxShadow="lg"*/}
                            {/*    _hover={{}}*/}
                            {/*    onClick={() => {*/}
                            {/*        let tempObjectSparePart = {};*/}

                            {/*        const zeroPad = (num, places) => String(num).padStart(places, '0')*/}
                            {/*        let binLocQR = props.serving + "-" + (dataSpareParts.part_category)[0] + zeroPad(dataSpareParts.id, 5);*/}
                            {/*        tempObjectSparePart["binLocQR"] = binLocQR*/}
                            {/*        setModalData(tempObjectSparePart);*/}

                            {/*        onOpenReportModalQR();*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Print*/}
                            {/*</Button>*/}
                        </ButtonGroup>
                    </VStack>
                </Container>
            </Container>
            <Footer />
        </>
    );
}

export default AddSparePart;