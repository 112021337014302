import { useNavigate } from "react-router-dom";

import {
    Flex,
    Spacer,
    Image,
    Text,
    Input,
    InputGroup,
    InputLeftElement
} from "@chakra-ui/react";

import HeaderIcon from "../../assets/icons/hero/HomePage/Hero_request.svg";

const SubHeader = () => {
    const navigate = useNavigate();

    return (
        <Flex>
            <Flex cursor={"pointer"} onClick={() => navigate("/request/list")}>
                <Image boxSize={"60px"} src={HeaderIcon}/>
                <Text paddingTop={"2"} paddingLeft={"5"} fontSize={"3xl"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} _hover={{color : process.env.REACT_APP_CHECKBOX_COLOR}}>Request</Text>
            </Flex>
        </Flex>
    );
}

export default SubHeader;