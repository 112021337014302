import {
    Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { useContext, useEffect } from 'react';
import { ImportDataContext } from './ImportSparepart_Context';
import {useMediaQuery} from "react-responsive";

const ImportSparepartTableMain = (props) => {
    const importDataContext = useContext(ImportDataContext);
    const isResolutionHigh = useMediaQuery({ query: "(min-width: 1920px)" })

    const [data,setData] = useState([]);

    console.log("DATA")
    console.log(data);
    useEffect(() => {
        if (importDataContext.importData){
            console.log("DATA WILL INPUT")
            console.log(importDataContext.importData)
            setData(importDataContext.importData)
        }
        
    }, [importDataContext.importData])

    const columnName = (props.selectedAction === "add" ?
        ["Item Name","Brand","Type","Specification","Item Category","Stock","Min Stock","Max Stock","Quantity Unit","Item Status","Inventory Type","Bin Location","Main Supplier","Price", "Lead Time"]
            :
            ["ID","Item Code","Item Name","Brand","Type","Specification","Item Category","Min Stock","Max Stock","Quantity Unit","Item Status","Inventory Type","Bin Location","Main Supplier","Price", "Lead Time"]
    )

    return (
        <div className='mt-[4vh] mb-[6vh]'>
            {data.length !== 0 &&
                <TableContainer overflowY={"auto"} whiteSpace={"normal"} display={"flex"} maxHeight={isResolutionHigh ? "54vh" : "46vh"} border={"1px solid #B7D4CF"}>
                    <Table variant='simple' size={"lg"}>
                        <Thead zIndex={1} pos='sticky' top={0} bg={process.env.REACT_APP_TABLE_COLOR}>
                            <Tr>
                                <Th>
                                    <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>No</Text>
                                </Th>
                                {columnName.map((elem) => (
                                    <Th key={elem}>
                                        <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem}</Text>
                                    </Th>

                                ))}
                            </Tr>
                        </Thead>

                        <Tbody>
                            {data.map((elem, index) => (
                                <Tr key={index}>
                                    <Td textAlign={"center"}>{index + 1}</Td>
                                    {columnName.map((elem2) => (
                                        <Td key={elem2 + index} textAlign={"center"}>
                                            {data[index][elem2]}
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>

                    </Table>
                </TableContainer>
            }
        </div>
    );
}

export default ImportSparepartTableMain;