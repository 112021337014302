import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
} from "@react-pdf/renderer";

const ContentPDF = (props) => {
    // Styling PDF for React-PDF

    const styles = StyleSheet.create({
        // So Declarative and unDRY 👌
        page: {
            flexDirection: "column",
            backgroundColor: "white",
            paddingTop: "4%",
            paddingBottom: "6%",
            paddingHorizontal: "6%",
        },
        stickerPage: {
            flexDirection: "column",
            backgroundColor: "white",
            paddingTop: "4%",
            paddingBottom: "6%",
        },
        header: {
            fontSize: 7,
            fontFamily: "Helvetica-BoldOblique",
            textAlign: "right",
            marginBottom: 5,
            paddingBottom: 2,
            borderBottom: "0.25px solid black",
        },
        footer: {
            fontSize: 7,
            fontFamily: "Helvetica-BoldOblique",
            textAlign: "right",
            paddingTop: 2,
            borderTop: "0.25px solid black",
            position: "absolute",
            bottom: "4.5%",
            left: "6%",
            right: "6%",
        },
        footerSign: {
            position: "absolute",
            bottom: "6%",
            left: "6%",
            right: "6%",
        },
        newHalfLine: {
            marginBottom: 5
        },
        newLine: {
            marginBottom: 10
        },
        table: {
            marginBottom: 8,
            width: "100%",
        },
        stickerTable: {
            width: "100%",
        },
        row: {
            display: "flex",
            flexDirection: "row",
            position: "relative"
        },
        column: {
            display: "flex",
            flexDirection: "column",
            position: "relative"
        },
        dataTable: {
            fontSize: 9,
            fontFamily: "Helvetica",
            border: "0.5px solid black",
            paddingVertical: 1,
            paddingLeft: 4,
        },
        containerTable: {
            border: "0.5px solid black",
            position: "relative",
        },
        textBold: {
            fontSize: 9,
            fontFamily: "Helvetica-Bold",
            marginBottom: 5
        },
        textTable1: {
            fontSize: 9,
            fontFamily: "Helvetica",
            textAlign: "center",
        },
        textTable2: {
            fontSize: 8,
            fontFamily: "Helvetica",
            textAlign: "center",
        },
        textTable3: {
            fontSize: 20,
            fontFamily: "Helvetica",
            textAlign: "center",
        },
        textTable4: {
            fontSize: 20,
            fontFamily: "Helvetica",
            textAlign: "center",
        },
        textTable5: {
            fontSize: 7,
            fontFamily: "Helvetica-Bold",
            textAlign: "center",
        },
    });

    let isEmptyCorrective = (props.dataOrder?.opnameSpareparts[0]?.meta?.CAPA?.corrective)?.length === 0;
    let isEmptyPreventive = (props.dataOrder?.opnameSpareparts[0]?.meta?.CAPA?.preventive)?.length === 0;

    return (
        <Document>
            <Page size={"A4"} style={styles.page}>
                <Text style={styles.header} fixed>Spare Part Management System (SMS 2.0) - PT Paragon Technology & Innovation</Text>

                <View style={styles.newHalfLine}/>
                <View style={styles.newHalfLine}/>


                <View style={styles.column}>
                    <Text style={[styles.textBold, {fontFamily: "Helvetica-BoldOblique"}, {lineHeight: 1}]}>Date Created: {(new Date(props.dataOrder["createdAt"])).toString().slice(0, 24)}</Text>
                    <Text style={[styles.textBold, {fontFamily: "Helvetica-BoldOblique"}, {lineHeight: 1}]}>Stock Opname Case Number: {props.dataOrder["case_no"]}</Text>

                    <View style={styles.newHalfLine}/>

                    <Text style={[styles.textBold]}>User: {props.dataOrder["user"]}</Text>
                    <Text style={[styles.textBold]}>Approver: {props.dataOrder["approver"]}</Text>
                </View>

                <View style={styles.newHalfLine}/>
                <View style={styles.newHalfLine}/>
                <View style={styles.newHalfLine}/>

                <View style={styles.table} wrap={false}>
                    <View style={styles.row}>
                        <Text style={[styles.dataTable, {width: "5%"}]}>No</Text>
                        <Text style={[styles.dataTable, {width: "26%"}]}>Item Name</Text>
                        <Text style={[styles.dataTable, {width: "26%"}]}>Brand</Text>
                        <Text style={[styles.dataTable, {width: "26%"}]}>Type</Text>
                        <Text style={[styles.dataTable, {width: "9%"}]}>Qty</Text>
                        <Text style={[styles.dataTable, {width: "9%"}]}>Real</Text>
                        <Text style={[styles.dataTable, {width: "9%"}]}>Diff</Text>
                    </View>

                    {(props.dataOrder?.opnameSpareparts).map((dataSparePart, index) => (
                        <View key={dataSparePart.id} style={styles.row}>
                            <Text style={[styles.dataTable, {width: "5%"}]}>{index + 1}</Text>
                            <Text style={[styles.dataTable, {width: "26%"}]}>{dataSparePart?.sparepart?.part_name}</Text>
                            <Text style={[styles.dataTable, {width: "26%"}]}>{dataSparePart?.sparepart?.brand}</Text>
                            {((dataSparePart?.sparepart?.type)?.length >= 14 && (dataSparePart?.sparepart?.type)?.includes('-')) ?
                                <Text style={[styles.dataTable, {width: "26%", fontSize: 7}]}>{((dataSparePart?.sparepart?.type).slice(0, ((dataSparePart?.sparepart?.type).lastIndexOf('-') + 1))) + " " + ((dataSparePart?.sparepart?.type).substr((dataSparePart?.sparepart?.type).lastIndexOf('-') + 1))}</Text>
                                :
                                <Text style={[styles.dataTable, {width: "26%"}]}>{dataSparePart?.sparepart?.type}</Text>
                            }
                            <Text style={[styles.dataTable, {width: "9%"}]}>{dataSparePart?.sparepart?.qty}</Text>
                            <Text style={[styles.dataTable, {width: "9%"}]}>{Number(dataSparePart?.sparepart?.qty) + Number(dataSparePart?.qty_difference)}</Text>
                            <Text style={[styles.dataTable, {width: "9%"}]}>{dataSparePart?.qty_difference}</Text>
                        </View>
                    ))}

                </View>

                <Text style={[styles.textBold]}>Corrective Action: {isEmptyCorrective && "None"}</Text>

                {!isEmptyCorrective &&
                    <View style={styles.table} wrap={false}>
                        <View style={styles.row}>
                            <Text style={[styles.dataTable, {width: "5%"}]}>No</Text>
                            <Text style={[styles.dataTable, {width: "95%"}]}>Corrective Action</Text>
                        </View>

                        {(props.dataOrder?.opnameSpareparts[0]?.meta?.CAPA?.corrective).map((action, index) => (
                            <View key={action?.slice(0, 3) + index} style={styles.row}>
                                <Text style={[styles.dataTable, {width: "5%"}]}>{index + 1}</Text>
                                <Text style={[styles.dataTable, {width: "95%"}]}>{action}</Text>
                            </View>
                        ))}

                    </View>
                }

                <Text style={[styles.textBold]}>Preventive Action: {isEmptyPreventive && "None"}</Text>

                {!isEmptyPreventive &&
                    <View style={styles.table} wrap={false}>
                        <View style={styles.row}>
                            <Text style={[styles.dataTable, {width: "5%"}]}>No</Text>
                            <Text style={[styles.dataTable, {width: "95%"}]}>Preventive Action</Text>
                        </View>

                        {(props.dataOrder?.opnameSpareparts[0]?.meta?.CAPA?.preventive).map((action, index) => (
                            <View key={action?.slice(0, 3) + index} style={styles.row}>
                                <Text style={[styles.dataTable, {width: "5%"}]}>{index + 1}</Text>
                                <Text style={[styles.dataTable, {width: "95%"}]}>{action}</Text>
                            </View>
                        ))}

                    </View>
                }

                <View style={styles.column}>
                    <Text style={[styles.textBold]}>Notes: </Text>
                    <Text style={[styles.textTable2, {lineHeight: 1, textAlign: "left"}]}>Qty: Current System Stock</Text>
                    <Text style={[styles.textTable2, {lineHeight: 1, textAlign: "left"}]}>Real: Current Real Stock</Text>
                    <Text style={[styles.textTable2, {lineHeight: 1, textAlign: "left"}]}>Diff: Difference in Stock between System and Real Stock</Text>

                </View>

                <Text style={styles.footer} fixed>This order is computer generated, no signature required</Text>
            </Page>
        </Document>
    );
};

export default ContentPDF;