import React, {useEffect, useState} from "react";

import {
    Container,
    Divider,
    Flex,
    HStack,
    Spacer,
    Text,
    useDisclosure,
} from "@chakra-ui/react";

import Axios from "../../services/api/Axios";
import Cookies from "js-cookie";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/AreYouSure";
import SubHeader from "./SubHeader";
import ListStorage from "./ListStorage";

import ModalScanner from "../../components/Modal/SparePartScanner";

const BinStorage = () => {
    let history = {
        dataBinStorageURL : "/sparepart/list?order=updatedAt,desc&pending_bin=1&sediaan_id=" + Cookies.get("idServing") + "&count=1",
        postBinStorageURL : "/bin/store?id=",
        imageSparePartURL : "/image/item?id=",
        accessToken : Cookies.get("accessToken"),
    }

    const [dataInitialization,setDataInitialization] = useState(false);
    const [dataBinStorage, setDataBinStorage] = useState([]);
    const [confirmAction, setConfirmAction] = useState('')
    const [modalData, setModalData] = useState({});

    const [refreshImage, setRefreshImage] = useState('')
    const [unusedRefreshData, setUnusedRefreshData] = useState({});

    const [scanType, setScanType] = useState('');
    const [promisesModal, setPromisesModal] = useState({});
    const [countPromisesModal, setCountPromisesModal] = useState({
        "now" : 0,
        "total": 0,
        "current": ''
    });

    const handleBinStorage = async (event) => {
        switch (event) {
            case "getData":
                try{
                    let getDataBinStorage = await Axios.get(history.dataBinStorageURL,
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });

                    (getDataBinStorage?.data?.data?.spareparts).map(async (dataSparePart) => {
                        if (!dataSparePart["image_bin"] && dataSparePart?.image_id && dataSparePart?.image_id !== 1) {
                            let getDataImage = await Axios.get(history.imageSparePartURL + dataSparePart.image_id,
                                {
                                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                                });

                            dataSparePart["image_bin"] = getDataImage?.data?.data?.image?.bin;

                            setRefreshImage("fetchData");
                        }
                    })

                    setDataBinStorage(getDataBinStorage?.data?.data?.spareparts)
                }
                catch(error){
                    console.error(error);
                }
                break;

            case "postData":
                try{
                    let postDataBinStorage = await Axios.post(history.postBinStorageURL + modalData.id,
                        {qty_store: modalData.qty_incoming},
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });
                    console.log(postDataBinStorage);
                    onOpenSuccessModal();
                    setDataInitialization(false);
                }
                catch(error){
                    console.error(error);
                }
                break;

            case "preQR":
                const binLabelQR = modalData?.meta?.binQR ? modalData?.meta?.binQR : {};

                if (Object.keys(binLabelQR).length !== 0) {
                    let tempObjectModalPromises = JSON.parse(JSON.stringify(binLabelQR));

                    setScanType("label");
                    setCountPromisesModal({
                        "now" : 1,
                        "total": tempObjectModalPromises[(Object.keys(tempObjectModalPromises).sort())[0]],
                        "current": (Object.keys(tempObjectModalPromises).sort())[0]
                    })
                    setPromisesModal(tempObjectModalPromises);
                }
                else {
                    setCountPromisesModal({
                        "now" : 0,
                        "total": 0,
                        "current": ''
                    });
                    setPromisesModal({});
                    setScanType("bin");
                    onOpenReportModalScanner();
                }
                break;

            default:
                break;
        }
    };

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenReportModalScanner,
        onOpen: onOpenReportModalScanner,
        onClose: onCloseReportModalScanner
    } = useDisclosure()

    useEffect(() => {
        if (!dataInitialization) handleBinStorage("getData").then(() => setDataInitialization(true));
        if (refreshImage !== '') setRefreshImage('');
        if (!isOpenSuccessModal) setUnusedRefreshData({});
    }, [dataInitialization, dataBinStorage, refreshImage, onCloseSuccessModal])

    useEffect(() => {
        switch(confirmAction){
            case "Bin Storage":
                handleBinStorage("postData").then(() => setConfirmAction(''))
                break;
            case "preQR":
                handleBinStorage("preQR").then(() => setConfirmAction(''))
                break;
            default:
                break;
        }
    }, [confirmAction])

    console.log(promisesModal);

    useEffect(() => {
        if (Object.keys(promisesModal).length) {
            onOpenReportModalScanner();
        }}, [confirmAction, promisesModal])

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingTop={"2vh"}>
                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    type={"Success"}
                />

                <ModalScanner
                    isOpen={isOpenReportModalScanner}
                    onOpen={onOpenReportModalScanner}
                    onClose={onCloseReportModalScanner}
                    setConfirmAction={setConfirmAction}
                    promisesModal={promisesModal}
                    setPromisesModal={setPromisesModal}
                    modalData={modalData}
                    setModalData={setModalData}
                    countPromisesModal={countPromisesModal}
                    setCountPromisesModal={setCountPromisesModal}
                    scanType={scanType}
                    type={"Bin Storage"}
                />

                <SubHeader position={"fixed"}/>

                <Flex marginX={"5%"} marginTop={"4%"} marginBottom={1} w={"90%"} position={"fixed"}>
                    <Spacer/>

                    <HStack alignItems={"end"}>
                        <Text fontSize={"2xl"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                            {dataBinStorage.length}
                        </Text>
                        <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                            items need to be placed to bin.
                        </Text>
                    </HStack>
                </Flex>

                <Divider variant={"dashed"} borderColor={process.env.REACT_APP_BORDER_COLOR} margin={2} w={"98%"} marginTop={"12vh"} position={"fixed"}/>

                <ListStorage
                    dataBinStorage={dataBinStorage}
                    setModalData={setModalData}
                    setRefreshImage={setRefreshImage}
                    setConfirmAction={setConfirmAction}
                />
            </Container>
            <Footer />
        </>
    );
}

export default BinStorage;
