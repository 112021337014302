import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

import {
    Button,
    Flex,
    Input,
    Stack,
    Image,
    Text,
    Menu,
    MenuButton,
    VStack,
    ButtonGroup,
    InputRightElement,
    InputGroup,
} from '@chakra-ui/react';
import {ChevronRightIcon, ExternalLinkIcon} from "@chakra-ui/icons";

import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/Loader/Loader";

import HeaderIcon from "../../assets/icons/hero/HomePage/Hero_incoming.svg";
import SearchIcon from "../../assets/icons/header/Header_search.svg";

const SidebarIncoming = (props) => {
    const navigate = useNavigate();

    const [focus, setFocus] = useState('');

    const [searchString, setSearchString] = useState({
        "part_name" : '',
        "brand" : '',
        "type" : '',
    });

    const dropDownRef = {
        "part_name" : useRef(),
        "brand" : useRef(),
        "type" : useRef(),
    };

    const dropDownName = {
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
    };

    const [dropDownValue, setDropDownValue] = useState({
        "part_name" : '',
        "brand" : '',
        "type" : '',
    })

    const [dropDownState, setDropDownState] = useState({
        "part_name" : false,
        "brand" : false,
        "type" : false,
    })

    useEffect(() => {
        setTimeout(() => {
            if (focus !== '') {
                dropDownRef[focus].current.focus();
                setFocus('');
            }
        }, 100);

    }, [focus])

    useEffect(() => {
    },[props.dataDropDownSpareParts])

    return (
        <>
            <Flex paddingLeft={6}>
                <Stack
                    spacing={4}
                    w={'full'}
                    maxW={'md'}
                    p={10}>
                    <Flex cursor={"pointer"} onClick={() => navigate("/incoming/order")}>
                        <Image boxSize={"60px"} src={HeaderIcon}/>
                        <Text paddingTop={"2"} paddingLeft={"5"} fontSize={"3xl"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} _hover={{color : process.env.REACT_APP_CHECKBOX_COLOR}}>Incoming</Text>
                    </Flex>
                    {Object.keys(dropDownName).map((elem, index) => (
                        <VStack key={index + elem} align={"left"}>
                            <Text className="font-bold mb-1" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{dropDownName[elem]}</Text>
                            <Menu style={{position: "relative"}} autoSelect={false}>
                                <MenuButton
                                    w={300}
                                    py={2}
                                    borderRadius="xl"
                                    borderColor={process.env.REACT_APP_ENV_COLOR}
                                    borderWidth="1px"
                                    _hover={{
                                        borderColor: process.env.REACT_APP_BORDER_COLOR
                                    }}
                                    _active={{
                                        borderColor: process.env.REACT_APP_BORDER_COLOR,
                                        bg: "gray.100"
                                    }}
                                    onClick={() => {
                                        let tempDropDownState = JSON.parse(JSON.stringify(dropDownState));
                                        Object.keys(tempDropDownState).map((value) => (
                                            elem !== value ? tempDropDownState[value] = false : ''
                                        ))
                                        tempDropDownState[elem] = !tempDropDownState[elem];
                                        setDropDownState(tempDropDownState);
                                    }}
                                >
                                    {dropDownValue[elem] === dropDownName[elem] ? dropDownValue[elem] = '' : ''}
                                    {dropDownValue[elem] === '' ? dropDownName[elem] : (dropDownValue[elem].length > 20 ? dropDownValue[elem].slice(0, 20) + ".." : dropDownValue[elem])}
                                    <ChevronRightIcon />
                                </MenuButton>
                                {
                                    dropDownState[elem] && (
                                        <div
                                            id={"scrollableRequest" + elem}
                                            style={{
                                                zIndex: 99,
                                                position: "absolute",
                                                borderRadius: "8px",
                                                boxShadow: " 2px 3px 5px 0px rgba(0,0,0,0.5)",
                                                marginTop: "20px",
                                                marginLeft: "310px",
                                                padding: "1rem 0.5rem",
                                                background: "#ffffff",
                                                width: "300px",
                                                height: "300px",
                                                overflow: "auto",
                                                display: "flex",
                                                flexDirection:"column"
                                            }}
                                        >
                                            <InputGroup>
                                                <Input
                                                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                    borderColor={process.env.REACT_APP_INPUT_COLOR}
                                                    focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                    _hover={{ borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD }}
                                                    marginBottom={"1rem"}
                                                    ref={dropDownRef[elem]}
                                                    type={"text"}
                                                    width={"100%"}
                                                    onClick={() => setFocus(elem)}
                                                    onChange={(e) => {
                                                        props.handleSearchDataDropDownSparePartListItem(elem, e.target.value);
                                                        setSearchString(searchString => ({...searchString, [elem]: e.target.value}));
                                                        setFocus(elem);
                                                    }}
                                                />
                                                <InputRightElement>
                                                    <Image className="scale-25" src={SearchIcon} />
                                                </InputRightElement>
                                            </InputGroup>

                                            {props.dataDropDownSpareParts[elem]?.length === 0 ?
                                                <Text
                                                    color={"red.700"}
                                                    _hover={{color: "red.900"}}
                                                >
                                                    No item
                                                </Text>
                                                :
                                                <InfiniteScroll
                                                    scrollableTarget={"scrollableRequest" + elem}
                                                    dataLength={props.dataDropDownSpareParts[elem] ? props.dataDropDownSpareParts[elem].length : 0}
                                                    next={() => props.setFetchMoreDropDownData([true, elem])}
                                                    hasMore={props.hasMoreDropDown[elem]}
                                                    loader={Loader()}
                                                >
                                                    {searchString[elem].length !== 0 &&
                                                        <Text
                                                            color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                            _hover={{color : process.env.REACT_APP_CHECKBOX_COLOR}}
                                                            style={{cursor: "pointer"}}
                                                            onClick={() => {
                                                                let tempDropDownState = JSON.parse(JSON.stringify(dropDownState));
                                                                tempDropDownState[elem] = !tempDropDownState[elem];
                                                                setDropDownState(tempDropDownState);

                                                                let tempDropDownValue = JSON.parse(JSON.stringify(dropDownValue));
                                                                tempDropDownValue[elem] = searchString[elem];
                                                                setDropDownValue(tempDropDownValue);

                                                                props.handleGetDataDependentDropDownSpareParts(tempDropDownValue);
                                                            }}
                                                        >
                                                            All items containing '{searchString[elem]}'
                                                        </Text>
                                                    }

                                                    {props.dataDropDownSpareParts[elem]?.map((item, indexChild) => (
                                                        <Text
                                                            color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                            _hover={{color : process.env.REACT_APP_CHECKBOX_COLOR}}
                                                            style={{cursor: "pointer"}}
                                                            onClick={() => {
                                                                let tempDropDownState = JSON.parse(JSON.stringify(dropDownState));
                                                                tempDropDownState[elem] = !tempDropDownState[elem];
                                                                setDropDownState(tempDropDownState);

                                                                let tempDropDownValue = JSON.parse(JSON.stringify(dropDownValue));
                                                                tempDropDownValue[elem] = item;
                                                                setDropDownValue(tempDropDownValue);

                                                                props.handleGetDataDependentDropDownSpareParts(tempDropDownValue);
                                                            }}
                                                            key={indexChild + item}
                                                        >
                                                            {item}
                                                        </Text>
                                                    ))}
                                                </InfiniteScroll>
                                            }
                                        </div>
                                    )
                                }
                            </Menu>
                        </VStack>
                    ))}

                    <ButtonGroup>
                        <Button
                            colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                            w="full"
                            onClick={() => {
                                props.handleSearchSpareParts(dropDownValue)
                            }}
                        >
                            Search
                        </Button>
                        <Button
                            colorScheme={"teal"}
                            w="full"
                            onClick={() => {
                                setDropDownValue(dropDownName)
                                props.setIsSearchActive(false);
                                props.handleUseOldDataSpareParts();
                                props.handleGetDataDropDownSpareParts();
                                setSearchString({
                                    "part_name" : '',
                                    "brand" : '',
                                    "type" : '',
                                    "bin_location" : '',
                                });
                            }}
                        >
                            Reset
                        </Button>
                    </ButtonGroup>
                    <Button
                        rightIcon={<ExternalLinkIcon/>}
                        colorScheme={"orange"}
                        w="full"
                        onClick={() => navigate("/incoming/order")}

                    >
                        Case Purchase Order
                    </Button>
                </Stack>
            </Flex>
        </>
    );
}

export default SidebarIncoming;
