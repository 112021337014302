import React, {useEffect, useState} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import {
    Button,
    ButtonGroup, Center,
    Container,
    FormControl,
    FormLabel,
    Image,
    Input,
    Select,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import SubHeader from "./SubHeader";
import {useLocation, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "../../services/api/Axios";
import BackIcon from "../../assets/icons/hero/Sparepart/Hero_back.svg";
import Modal from "../../components/Modal/AreYouSure";
import queryString from "query-string";

const Edit = (props) => {
    const navigate = useNavigate();

    const history = {
        getDataLineURL : "/line/item?id=",
        getDataMachineURL : "/machine/item?id=",
        postDataLineURL : "/line/item?id=",
        postDataMachineURL : "/machine/item?id=",
        getDataLinesURL : "/line/list?order=name&limit=9999&count=1&sediaan_id=",
        servingURL : "/sediaan/list",
        accessToken : Cookies.get("accessToken"),
        role : Cookies.get("role"),
        idServing : Cookies.get("idServing"),
    }

    const {search} = useLocation();
    const params = queryString.parse(search);

    const [dataAssets,setDataAssets] = useState({});
    const [dataInitialization,setDataInitialization] = useState(false);
    const [dataServings,setDataServings] = useState([]);
    const [dataLines,setDataLines] = useState([]);
    const [confirmSubmitData, setConfirmSubmitData] = useState(false);
    const [navigateBack, setNavigateBack] = useState(false);

    const [columnState, setColumnState] = useState({
        //"database_column_name" : ["Name", "inputType", "isDisable", "isRequired", "isInvalid", usedFor],
        "code" : ["Department Code", "text", false, true, false, "machine"],
        "name" : [((props.page === "machine" ? "Department" : "Plant") + " Name"), "text", false, true, false, (props.page)],
        "sediaan_id" : ["Organizer", "select", (history.role !== "Superior") , true, false, (props.page)],
        "line_id" : ["Plant Name", "select", false , true, false, "machine"],
    });

    console.log(dataAssets);
    const handleDataAsset = async (event, target, value) => {
        switch (event) {
            case "get":
                try{
                    let getDataAsset = await Axios.get((props.page === "machine" ? history.getDataMachineURL : history.getDataLineURL) + params?.id,
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    let tempDataObject = (props.page === "machine" ? getDataAsset?.data?.data?.machine : getDataAsset?.data?.data?.line);

                    if(props.page === "machine") {
                        handleGetDataLines(tempDataObject["sediaan_id"]).then();
                        delete tempDataObject["createdAt"];
                        delete tempDataObject["updatedAt"];
                        delete tempDataObject["lineId"];
                    }
                    else {
                        delete tempDataObject["createdAt"];
                        delete tempDataObject["updatedAt"];
                    }

                    setDataAssets(tempDataObject);
                }
                catch(error){
                    console.error(error);
                }

                break;

            case "add":
                let tempDataUser = JSON.parse(JSON.stringify(dataAssets));
                tempDataUser[target] = value;

                columnState[target][4] = tempDataUser[target] === '';

                if (target === "sediaan_id" && props.page === "machine") {
                    if (tempDataUser["line_id"]) delete tempDataUser["line_id"];
                    handleGetDataLines(value).then(() => columnState["line_id"][2] = false);
                }

                setDataAssets(tempDataUser);
                break;

            case "post":
                let tempColumnState = JSON.parse(JSON.stringify(columnState));
                let isInvalid = false;

                Object.keys(tempColumnState).map((elem) => {
                    if (props.page === tempColumnState[elem][5] && tempColumnState[elem][3] && !dataAssets[elem]) {
                        tempColumnState[elem][4] = true;
                        isInvalid = true;
                    }
                    return ''
                })

                if (!isInvalid) {
                    try{
                        let postDataUsers = await Axios.post((props.page === "machine" ? history.postDataMachineURL : history.postDataLineURL) + params?.id,
                            dataAssets,
                            {
                                headers: {"Authorization": "Bearer " + history.accessToken},
                            });

                        console.log(postDataUsers);
                        onOpenSuccessModal();

                    }
                    catch(error){
                        console.error(error);
                    }
                }

                setColumnState(tempColumnState);
                break;
            default:
                break;
        }
    }

    const handleGetDataServing = async () => {
        try{
            let statusServing = await Axios.get(history.servingURL,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            // console.log(statusServing);
            setDataServings(statusServing.data?.data?.sediaans);
        }
        catch(error){
            console.error(error);
        }
    }

    const handleGetDataLines = async (id) => {
        try{
            let getDataLines = await Axios.get(history.getDataLinesURL + id,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            // console.log(getDataLines);
            setDataLines(getDataLines.data?.data?.lines);
        }
        catch(error){
            console.error(error);
        }
    }

    const {
        isOpen: isOpenRequestModal,
        onOpen: onOpenRequestModal,
        onClose: onCloseRequestModal
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    useEffect(() => {
        if (!dataInitialization) handleDataAsset("get").then(() => handleGetDataServing().then(() => setDataInitialization(true)));
        if (confirmSubmitData) handleDataAsset("post").then(() => setConfirmSubmitData(false));
        if (navigateBack) {
            if (props.page === "machine") navigate("/assetManagement/listDepartment");
            else navigate("/assetManagement/listPlant");
        }
    }, [dataAssets, confirmSubmitData, navigateBack])

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingBottom={"12vh"} paddingTop={"2vh"}>
                <Modal
                    isOpen={isOpenRequestModal}
                    onOpen={onOpenRequestModal}
                    onClose={onCloseRequestModal}
                    setConfirmSentResetPassword={setConfirmSubmitData}
                    type={"Request"}
                />

                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    setRedirect={setNavigateBack}
                    type={"Success"}
                />

                <SubHeader/>

                <ButtonGroup pt={"2%"}>
                    <Button
                        leftIcon={<Image src={BackIcon} alt="" />}
                        variant='solid'
                        colorScheme='facebook'
                        position={"initial"}
                        onClick={props.page === "machine" ? () => navigate('/assetManagement/listDepartment') : () => navigate('/assetManagement/listPlant')}
                    >
                        Back
                    </Button>
                </ButtonGroup>

                <VStack w={"full"} px={"30%"} align={"center"}  spacing={"1%"}>
                    <Text fontSize={"xl"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"} paddingBottom={5}>Edit {props.page === "machine" ? "Department" : "Plant"}</Text>
                    {Object.keys(columnState).map((elem, index) => (
                        columnState[elem][5] === props.page &&
                        <FormControl
                            key={index}
                            isRequired={columnState[elem][3]}
                            isInvalid={columnState[elem][3] && !dataAssets[elem] && columnState[elem][4]}
                        >
                            <FormLabel fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnState[elem][0]}</FormLabel>
                            {columnState[elem][1] === "select" ?
                                <Select
                                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                    borderColor={process.env.REACT_APP_INPUT_COLOR }
                                    borderRadius={"xl"}
                                    focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                    _hover={{
                                        borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                    }}
                                    defaultValue={dataAssets[elem]}
                                    disabled={columnState[elem][2]}
                                    onChange={(e) => handleDataAsset("add", elem, e.target.value)}
                                >
                                    <option value={''}>Please select an option</option>
                                    {elem === "sediaan_id" &&
                                        <>
                                            {dataServings.map((serving) => (
                                                <option selected={Number(serving?.id) === Number(dataAssets[elem])} key={Number(serving?.id)} value={Number(serving?.id)}>{(serving?.nama).charAt(0).toUpperCase() + (serving?.nama).slice(1)}</option>
                                            ))}
                                        </>
                                    }
                                    {elem === "line_id" &&
                                        <>
                                            {dataLines.map((line) => (
                                                <option selected={Number(line?.id) === Number(dataAssets[elem])} key={Number(line?.id)} value={Number(line?.id)}>{line?.name}</option>
                                            ))}
                                        </>
                                    }
                                </Select>
                                :
                                <Input
                                    type={columnState[elem][1]}
                                    placeholder={"Please fill in " + columnState[elem][0]}
                                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                    borderColor={process.env.REACT_APP_INPUT_COLOR }
                                    borderRadius={"xl"}
                                    focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                    _hover={{
                                        borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                    }}
                                    defaultValue={dataAssets[elem]}
                                    onChange={(e) => handleDataAsset("add", elem, e.target.value)}
                                    disabled={columnState[elem][2]}
                                />
                            }
                        </FormControl>
                    ))}
                </VStack>

                <Center pt={"2%"}>
                    <Button
                        colorScheme={"teal"}
                        size='lg'
                        borderRadius={"8px"}
                        onClick={onOpenRequestModal}
                        isDisabled={history.role !== "Superior" ? (Number(dataAssets["sediaan_id"]) !== Number(history.idServing)) : false}
                    >
                        Submit
                    </Button>
                </Center>

            </Container>
            <Footer />
        </>
    );
}

export default Edit;