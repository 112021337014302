import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { useMediaQuery } from "react-responsive"

import {
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Image, MenuDivider, Text, VStack, HStack, Divider, Badge,
} from "@chakra-ui/react";

import Axios from "../../../services/api/Axios"
import Cookies from "js-cookie";

import NotificationIcon from "../../../assets/icons/navbar/Notification.svg";

const NavbarNotification = (props) => {
    const history = {
        accessToken : Cookies.get("accessToken"),
        role : Cookies.get("role"),
        notificationURL: "/request/list?count=1&status=SUBMIT&sediaan_id=" + Cookies.get("idServing") + "&limit=3",
        navigate : useNavigate(),
    }

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" })

    const [dataInitialization,setDataInitialization] = useState(false);

    const [notifications, setNotifications] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);

    const handleNotification = async () => {
        if (history.role !== "Teknisi") {
            try{
                let getNotifications = await Axios.get(history.notificationURL,
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });
                setNotificationCount(getNotifications.data?.data?.count);
                setNotifications(getNotifications.data?.data?.requests);
            }
            catch(error){
                console.error(error);
                setNotifications([]);
            }
        }
    }

    useEffect(() => {
        setInterval(handleNotification, 10000);
    }, [])

    useEffect(() => {
        if (props.props.changeServing) handleNotification().then(() => props.props.setChangeServing(false));
        if (!dataInitialization) handleNotification().then(() => setDataInitialization(true));
    }, [props.props.changeServing])

    return (
        <Menu autoSelect={false}>
            <MenuButton
                as={Button}
                className={"mx-4"}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
            >
                <HStack>
                    <Badge visibility={notificationCount === 0 ? "hidden" : "visible"} borderRadius='md' bg={process.env.REACT_APP_INPUT_COLOR} color={"white"}>{notificationCount}</Badge>
                    <Image src={NotificationIcon} alt={"box"} />
                </HStack>
            </MenuButton>
            <MenuList maxW={isTabletOrMobile ? "40px" : ''}>
                <MenuItem isDisabled={true} _disabled={{
                    color: 'rgb(50, 50, 50)'
                }}>
                    {notificationCount !== 0
                        ?
                        <>
                            <HStack spacing={1}>
                                <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                    You have
                                </Text>
                                <Text fontSize={"lg"} fontWeight={"bold"} color={"blue"}>
                                    {notificationCount}
                                </Text>
                                <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                    notification{notificationCount > 1 ? "s" : ''}!
                                </Text>
                            </HStack>

                            <MenuDivider borderColor={process.env.REACT_APP_TEXT_COLOR_BOLD} borderBottom={"2px double"}/>
                        </>

                        :
                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                            You don't have any notification yet!
                        </Text>
                    }
                </MenuItem>

                {notifications?.map((notification, index) => (
                    <Fragment key={notification.meta?.request_number}>
                        <MenuItem onClick={() => history.navigate("/outgoing/list")}>
                            <VStack alignItems={"start"}>
                                <HStack>
                                    <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{notification.meta?.request_number}</Text>
                                    <Text fontWeight={"bold"} borderRadius={"md"} bg={"blue.100"} color={"royalblue"}>{notification.user?.name}</Text>
                                    <Text fontWeight={"bold"} borderRadius={"md"} bg={"red.100"} color={"mediumvioletred"}>{(new Date(notification.createdAt)).toString().slice(0, 24)}</Text>
                                </HStack>
                                <VStack alignItems={"start"}>
                                    {notification.requestSpareparts?.map((item, indexItem) => (
                                        <Fragment key={notification.meta?.request_number + indexItem}>
                                            <HStack>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{item.sparepart?.part_code} |</Text>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{item.sparepart?.part_name} |</Text>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{item.sparepart?.type} |</Text>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{item.sparepart?.brand} |</Text>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{item.sparepart?.bin_location}</Text>
                                            </HStack>

                                            <Text fontSize={"md"} fontWeight={"bold"} color={"black"}>{item.qty_request} {item.sparepart?.qty_uom}</Text>

                                            {(notification.requestSpareparts.length !== 1 && indexItem !== notification.requestSpareparts.length -1) ?
                                                <Divider variant={"dashed"} borderColor={process.env.REACT_APP_BORDER_COLOR}/>
                                                :
                                                ''
                                            }
                                        </Fragment>
                                    ))}
                                </VStack>
                            </VStack>
                        </MenuItem>

                        <MenuDivider borderColor={process.env.REACT_APP_TEXT_COLOR_BOLD} borderBottom={index !== notifications.length -1 ? '1px solid' : "2px double"}/>
                    </Fragment>
                ))}
                {notificationCount !== 0
                    ?
                    <MenuItem onClick={() => history.navigate("/outgoing/list")}>
                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                            View all
                        </Text>
                    </MenuItem>
                    :
                    ''
                }
            </MenuList>
        </Menu>
    );
}

export default NavbarNotification;