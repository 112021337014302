import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SparePartSubHeader from "./SubHeader";

import {
    Button,
    Avatar,
    IconButton,
    AvatarBadge,
    Input,
    FormLabel,
    FormControl,
    Select,
    Container,
    Heading,
    HStack,
    Spacer,
    VStack,
    ButtonGroup,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useDisclosure,
} from "@chakra-ui/react";
import { AttachmentIcon } from '@chakra-ui/icons';

import Axios from "../../services/api/Axios";
import Cookies from "js-cookie";
import queryString from 'query-string'

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/AreYouSure";
import ModalQR from "../../components/Modal/SparePartQR";

const EditSparePart = () => {
    let history = {
        dataSparePartItemURL : "/sparepart/item?id=",
        // dataDropdownUOM: "/uom",
        dataDropdownPartCategory: "/partCategory?dropdown=part_category&count=1",
        dataDropdownPartStatus: "/partStatus?dropdown=part_status&count=1",
        dataDropdownInventoryType: "/inventoryType?dropdown=inventory_type&count=1",
        imageSparePartURL : "/image/item?id=",
        accessToken : Cookies.get("accessToken"),
        dataServingURL: "/sediaan/item?id=",
        role : Cookies.get("role"),
    }

    const {search} = useLocation();
    const params = queryString.parse(search);
    const navigate = useNavigate();

    const [navigateBack, setNavigateBack] = useState(false);
    const [showPrintQR, setShowPrintQR] = useState(false);

    const [dataSparePart, setDataSparePart] = useState({});
    const [dataEditSpareParts, setDataEditSpareParts] = useState({});
    const [imageSpareParts, setImageSpareParts] = useState({});

    const [dataInitialization, setDataInitialization] = useState(false);
    const [dataDropdown, setDataDropdown] = useState({});
    const [serving, setServing] = useState('');
    const [confirmSubmitData, setConfirmSubmitData] = useState(false)
    const [priceValue, setPriceValue] = useState(0);
    const [minValue, setMinValue] = useState(-1);
    const [maxValue, setMaxValue] = useState(0);

    const [modalData, setModalData] = useState({});

    const inputImageRef = useRef();

    const [columnState, setColumnState] = useState({
        //"database_column_name" : ["Name", "inputType", "isDisable", "isRequired", "isInvalid"],
        "part_name" : ["Item Name", "text", false, true, false],
        "brand" : ["Brand", "text", false, true, false],
        "type" : ["Type", "text", false, true, false],
        "specification" : ["Specification", "text", false, true, false],
        "part_category" : ["Item Category", "select", false, true, false],
        "qty" : ["Stock", "number", (history.role !== "Superior"), true, false],
        "qty_min" : ["Min Stock", "number", (history.role !== "Superior"), true, false],
        "qty_max" : ["Max Stock", "number", (history.role !== "Superior"), true, false],
        "part_status" : ["Item Status", "select", false, true, false],
        "inventory_type" : ["Inventory Type", "select", false, true, false],
        "bin_location" : ["Bin Loct", "text", false, true, false],
        "qty_uom" : ["Quantity Unit", "select", false, true, false],
        "main_supplier" : ["Main Supplier", "text", false, true, false],
        "alternative_supplier1" : ["Alternative Supplier I", "text", false, false, false],
        "alternative_supplier2" : ["Alternative Supplier II", "text", false, false, false],
        "price" : ["Price", "number", false, true, false],
        "lead_time" : ["Lead Time", "text", false, false, false],
        "set_image" : ["Set Image", "text", true, false, false],
    });

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleGetServing = async () => {
        try{
            let getServing = await Axios.get(history.dataServingURL + Cookies.get("idServing"),
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            setServing(getServing?.data?.data?.sediaan);
        }
        catch (error){

        }
    }

    const handleGetDropdown = async () => {
        try{
            let tempObject = {};

            // let getDropdownUOM = await Axios.get(history.dataDropdownUOM,
            //     {
            //         headers: {"Authorization": "Bearer " + history.accessToken},
            //     });

            let getDropdownCategory = await Axios.get(history.dataDropdownPartCategory,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let getDropdownStatus = await Axios.get(history.dataDropdownPartStatus,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let getDropdownInventory = await Axios.get(history.dataDropdownInventoryType,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            // tempObject["qty_uom"] = getDropdownUOM?.data?.data?.uoms;
            tempObject["part_category"] = getDropdownCategory?.data?.data?.partCategorys?.part_category;
            tempObject["part_status"] = getDropdownStatus?.data?.data?.partStatuss?.part_status;
            tempObject["inventory_type"] = getDropdownInventory?.data?.data?.inventoryTypes?.inventory_type;

            setDataDropdown(tempObject);
        }
        catch (error){
            console.error(error);
        }
    }

    const handleSetImageSpareParts = async (file) => {
        const acceptedFileType = /image/;
        console.log(file);

        if (acceptedFileType.test(file.type)) {
            let tempObject = {};
            tempObject["bin"] = await toBase64(file);
            tempObject["name"] = file.name;
            tempObject["extension"] = file.name.split(".")[1];

            setImageSpareParts(tempObject);
        }
    }

    const handleGetDataSpareParts = async (sparePart) => {
        let getDataSpareParts = await Axios.get(history.dataSparePartItemURL + sparePart,
            {
                headers: {'Authorization': 'Bearer ' + history.accessToken},
            });

        let getDataImage = await Axios.get(history.imageSparePartURL + getDataSpareParts?.data?.data?.sparepart.image_id,
            {
                headers: {'Authorization': 'Bearer ' + history.accessToken},
            });

        getDataSpareParts["data"]["data"]["sparepart"]["image_bin"] = getDataImage?.data?.data?.image?.bin;

        setDataSparePart(getDataSpareParts?.data?.data?.sparepart);
    };

    const handleEditDataSpareParts = async (target, value) => {
        let tempDataEditSpareParts = JSON.parse(JSON.stringify(dataEditSpareParts));
        tempDataEditSpareParts[target] = value;

        if (!value) columnState[target][4] = true
        else columnState[target][4] = false

        if (target === "qty_max" || target === "qty_min") {
            const qty_min = !isNaN(dataEditSpareParts["qty_min"]) ? dataEditSpareParts["qty_min"] : dataSparePart["qty_min"];
            const qty_max = !isNaN(dataEditSpareParts["qty_max"]) ? dataEditSpareParts["qty_max"] : dataSparePart["qty_max"];

            if (Number(qty_max) < Number(qty_min)) {
                columnState["qty_min"][4] = true;
                columnState["qty_max"][4] = true;
            }
            else {
                columnState["qty_min"][4] = false;
                columnState["qty_max"][4] = false;
            }
        }

        if (target === 'qty') {
            if (Number(value) < 0) tempDataEditSpareParts[target] = dataSparePart['qty'];
            columnState['qty'][4] = false
        }

        setDataEditSpareParts(tempDataEditSpareParts);
    };

    const handleSubmitEditDataSpareParts = async (sparePart) => {
        let tempColumnState = JSON.parse(JSON.stringify(columnState));
        let isInvalid = false;

        Object.keys(tempColumnState).map((elem) => {
            if (tempColumnState[elem][3] && tempColumnState[elem][4]) {
                isInvalid = true;
            }

            if (elem === "qty_max") {
                const qty_min = !isNaN(dataEditSpareParts["qty_min"]) ? dataEditSpareParts["qty_min"] : dataSparePart["qty_min"];
                const qty_max = !isNaN(dataEditSpareParts["qty_max"]) ? dataEditSpareParts["qty_max"] : dataSparePart["qty_max"];

                if (Number(qty_max) < Number(qty_min)) {
                    isInvalid = true;
                }

            }
            return ''
        })

        if (!isInvalid) {
            let imageIdentifier = 1;
            let tempDataEditSpareParts = JSON.parse(JSON.stringify(dataEditSpareParts));

            try{
                if (Object.keys(imageSpareParts).length !== 0) {
                    let submitImageSpareParts = await Axios.post(history.imageSparePartURL,
                        imageSpareParts,
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });

                    imageIdentifier = submitImageSpareParts?.data?.data?.image?.id;
                    tempDataEditSpareParts["image_id"] = imageIdentifier;

                    console.log(submitImageSpareParts);
                }

                let submitDataSpareParts = await Axios.post(history.dataSparePartItemURL + sparePart,
                    tempDataEditSpareParts,
                    {
                        headers: {'Authorization': 'Bearer ' + history.accessToken},
                    });

                let tempObjectSparePart = {};
                let id = Number((submitDataSpareParts?.data?.data?.sparepart?.part_code).substring(1));

                const zeroPad = (num, places) => String(num).padStart(places, '0');
                let binLocQR = serving?.huruf + "-" + (submitDataSpareParts?.data?.data?.sparepart?.part_code)[0] + zeroPad(id, 5) + "-" + submitDataSpareParts?.data?.data?.sparepart?.bin_location;

                tempObjectSparePart["qr_code"] = binLocQR;
                tempObjectSparePart["part_code"] = submitDataSpareParts?.data?.data?.sparepart?.part_code;
                tempObjectSparePart["part_name"] = submitDataSpareParts?.data?.data?.sparepart?.part_name;
                tempObjectSparePart["type"] = submitDataSpareParts?.data?.data?.sparepart?.type;
                tempObjectSparePart["bin_location"] = submitDataSpareParts?.data?.data?.sparepart?.bin_location;
                setModalData(tempObjectSparePart);

                onOpenSuccessModal();
            }
            catch(error){
                console.error(error);
            }
        }
    };

    const {
        isOpen: isOpenRequestModal,
        onOpen: onOpenRequestModal,
        onClose: onCloseRequestModal
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenReportModalQR,
        onOpen: onOpenReportModalQR,
        onClose: onCloseReportModalQR
    } = useDisclosure()

    useEffect(() => {
        if (!dataInitialization) handleGetDataSpareParts(params.id).then(() => handleGetServing().then(() => handleGetDropdown().then(() => setDataInitialization(true))));
        if (confirmSubmitData) handleSubmitEditDataSpareParts(params.id).then(() => setConfirmSubmitData(false));
        if (navigateBack) navigate("/item/list");
        if (showPrintQR) onOpenReportModalQR();
    },[dataEditSpareParts, confirmSubmitData, showPrintQR, navigateBack])

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingBottom={"12vh"} paddingTop={"2vh"}>
                <Modal
                    isOpen={isOpenRequestModal}
                    onOpen={onOpenRequestModal}
                    onClose={onCloseRequestModal}
                    setConfirmSentResetPassword={setConfirmSubmitData}
                    type={"Request"}
                />

                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    setRedirect={setShowPrintQR}
                    type={"Success"}
                />

                <ModalQR
                    isOpen={isOpenReportModalQR}
                    onOpen={onOpenReportModalQR}
                    onClose={onCloseReportModalQR}
                    modalData={modalData}
                    setRefreshPage={setNavigateBack}
                    isMultiple={false}
                    type={"List"}
                />

                <SparePartSubHeader />
                <Container maxW={"50%"} centerContent>
                    <VStack w={"full"} spacing={5}>
                        <Heading color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Edit Item</Heading>
                        <Avatar size={"2xl"} src={Object.keys(imageSpareParts).length !== 0 ? imageSpareParts.bin : dataSparePart["image_bin"]} marginBottom={"2%"}>
                            <Input type='file' accept={"image/*"} ref={inputImageRef} style={{ display: 'none' }} onInput={(e) => handleSetImageSpareParts(e.target.files[0])}></Input>
                            <AvatarBadge
                                as={IconButton}
                                size="sm"
                                rounded="full"
                                bottom="10px"
                                colorScheme={process.env.REACT_APP_BORDER_COLOR}
                                aria-label="remove Image"
                                icon={<AttachmentIcon />}
                                onClick={() => inputImageRef.current.click()}
                            />
                        </Avatar>
                        <VStack w={"full"}>
                            {Object.keys(columnState).map((elem, index) => (
                                <FormControl key={elem + index} isRequired={columnState[elem][3]} isInvalid={columnState[elem][3]  && columnState[elem][4]}>
                                    <HStack align={"center"}>
                                        <FormLabel
                                            color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                            fontWeight={"bold"}
                                        >
                                            {columnState[elem][0]}
                                        </FormLabel>
                                        <Spacer/>
                                        {columnState[elem][1] === "select" ?
                                            <Select
                                                maxW={"70%"}
                                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                borderColor={process.env.REACT_APP_INPUT_COLOR }
                                                borderRadius={"xl"}
                                                focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                _hover={{
                                                    borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                                }}
                                                required
                                                defaultValue={dataSparePart[elem]}
                                                isDisabled={columnState[elem][2]}
                                                onChange={(e) => handleEditDataSpareParts(elem, e.target.value)}
                                            >
                                                <option disabled>Please select an option</option>
                                                {columnState[elem][0] === "Item Category" &&
                                                  <>
                                                      {dataDropdown["part_category"]?.map((status) => (
                                                        <option selected={dataSparePart[elem] === status} key={status}>{status}</option>
                                                      ))
                                                      }
                                                  </>
                                                }
                                                {columnState[elem][0] === "Quantity Unit" &&
                                                  <>
                                                      <option selected={dataSparePart[elem] === "dus"} value={"dus"}>dus</option>
                                                      <option selected={dataSparePart[elem] === "galon"} value={"galon"}>galon</option>
                                                      <option selected={dataSparePart[elem] === "kg"} value={"kg"}>kg</option>
                                                      <option selected={dataSparePart[elem] === "liter"} value={"liter"}>liter</option>
                                                      <option selected={dataSparePart[elem] === "pak"} value={"pak"}>pak</option>
                                                      <option selected={dataSparePart[elem] === "pcs"} value={"pcs"}>pcs</option>
                                                      <option selected={dataSparePart[elem] === "rim"} value={"rim"}>rim</option>
                                                      <option selected={dataSparePart[elem] === "set"} value={"set"}>set</option>
                                                  </>
                                                }
                                                {columnState[elem][0] === "Item Status" &&
                                                  <>
                                                      {dataDropdown["part_status"]?.map((status) => (
                                                        <option selected={dataSparePart[elem] === status} key={status}>{status}</option>
                                                      ))
                                                      }
                                                  </>
                                                }
                                                {columnState[elem][0] === "Inventory Type" &&
                                                  <>
                                                      {dataDropdown["inventory_type"]?.map((type) => (
                                                        <option selected={dataSparePart[elem] === type} key={type}>{type}</option>
                                                      ))
                                                      }
                                                  </>
                                                }
                                            </Select>
                                          : columnState[elem][1] === "number" ?
                                                <NumberInput
                                                    allowMouseWheel
                                                    step={elem === "price" ? 1000 : 1}
                                                    value={
                                                        elem === "price" ? (priceValue === 0 ? (Number(dataSparePart[elem]) ? Number(dataSparePart[elem]) : 0) : priceValue)
                                                          : (elem === "qty_min" ? (minValue === -1 ? (Number(dataSparePart[elem]) ? Number(dataSparePart[elem]) : 0) : minValue)
                                                            : (elem === "qty_max" ? (maxValue === 0 ? (Number(dataSparePart[elem]) ? Number(dataSparePart[elem]) : 0) : maxValue)
                                                                : (elem === "qty" ? (!isNaN(dataEditSpareParts[elem]) ? Number(dataEditSpareParts[elem]) : (Number(dataSparePart[elem]) ? Number(dataSparePart[elem]) : 0))
                                                                  : Number(dataSparePart[elem]) ? Number(dataSparePart[elem]) : 0)
                                                            ))}
                                                    isDisabled={columnState[elem][2]}
                                                    w={"70%"}
                                                    onChange={elem === "price" ?
                                                      (e) => {handleEditDataSpareParts(elem, Number(e)).then(() => setPriceValue(Number(e)))}
                                                      :
                                                      (elem === "qty_min" ? (e) => {handleEditDataSpareParts(elem, Number(e)).then(() => setMinValue(Number(e)))}
                                                        : (elem === "qty_max" ? (e) => {handleEditDataSpareParts(elem, Number(e)).then(() => setMaxValue(Number(e)))}
                                                          : (elem === "qty" ? (e) => {handleEditDataSpareParts(elem, Number(e)).then()} : '') ))}
                                                >
                                                    <NumberInputField
                                                        color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                        borderColor={process.env.REACT_APP_INPUT_COLOR }
                                                        borderRadius={"xl"}
                                                        _hover={{
                                                            borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                                        }}
                                                        _focusVisible={{
                                                            borderColor: (process.env.REACT_APP_ENV_COLOR),
                                                            boxShadow: ("0 0 0 1px " + process.env.REACT_APP_ENV_COLOR)
                                                        }}
                                                    />
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>
                                                :
                                                <Input
                                                    maxW={"70%"}
                                                    type={columnState[elem][1]}
                                                    placeholder={elem !== "set_image" ? (elem !== "sediaan_id" ? columnState[elem][0] : (serving?.nama ? (serving?.nama)?.charAt(0).toUpperCase() + (serving?.nama)?.slice(1) : "Sediaan")) : "Please set an image by clicking the small link icon button above!"}
                                                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                    borderColor={process.env.REACT_APP_INPUT_COLOR}
                                                    borderRadius={"xl"}
                                                    focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                    _hover={{
                                                        borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                                    }}
                                                    defaultValue={dataSparePart[elem]}
                                                    onChange={(e) => handleEditDataSpareParts(elem, e.target.value)}
                                                    required
                                                    disabled={columnState[elem][2]}
                                                />
                                        }
                                    </HStack>
                                </FormControl>
                            ))}
                        </VStack>
                        <ButtonGroup gap={5}>
                            <Button
                                backgroundColor='#D75555'
                                color={"white"}
                                borderRadius={"8px"}
                                boxShadow="lg"
                                _hover={{}}
                                onClick={() => navigate("/item/list")}
                            >
                                Back
                            </Button>
                            <Button
                                colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                                borderRadius={"8px"}
                                boxShadow="lg"
                                _hover={{}}
                                onClick={onOpenRequestModal}
                                disabled={!dataSparePart?.qty}
                            >
                                Edit
                            </Button>
                        </ButtonGroup>
                    </VStack>
                </Container>
            </Container>
            <Footer />
        </>
    );
}

export default EditSparePart;