import React, {useCallback} from "react";


import ProfileCircleIcon from "../../../assets/icons/hero/ProfileSetting/Hero_profile_circle.svg";
import ProfileSettingIcon from "../../../assets/icons/hero/ProfileSetting/Hero_profile_setting.svg";
import LockIcon from "../../../assets/icons/hero/ProfileSetting/Hero_lock.svg";
import ChevronRight from "../../../assets/icons/hero/ProfileSetting/Hero_chevron_right.svg";

import {useNavigate} from 'react-router-dom';
import {Container, Divider, HStack, Image, Spacer, Text, VStack} from "@chakra-ui/react";
import {useMediaQuery} from "react-responsive";

const ProfileSettingLeftSide = () => {
    const navigate = useNavigate();
    const handleEditProfile = useCallback(() => navigate('/user/profile', {replace: true}), [navigate]);
    const handleChangePassword = useCallback(() => navigate('/user/changePassword', {replace: true}), [navigate]);
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" })

    return (
        <Container w={"full"} p={"5%"}>
            <HStack spacing={"5%"} pt={"5vh"}>
                <Image src={ProfileCircleIcon} />
                <Text fontSize={!isTabletOrMobile ? "2xl" : "lg"} visibility={!isTabletOrMobile ? "visible" : "hidden"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Profile Setting</Text>
            </HStack>

            <VStack pt={"15%"} align={"start"} spacing={"5%"}>
                <HStack w={"full"} spacing={"6%"} cursor={"pointer"} onClick={handleEditProfile}>
                    <Image boxSize={!isTabletOrMobile ? "10%" : "5vw"} src={ProfileSettingIcon} />
                    <Text _hover={{textColor: process.env.REACT_APP_TEXT_COLOR_BOLD}} fontSize={!isTabletOrMobile ? "2xl" : "lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Edit Profile</Text>
                    <Spacer/>
                    <Image src={ChevronRight} visibility={!isTabletOrMobile ? "visible" : "hidden"} />
                </HStack>
                <Divider border={"2px solid"} borderColor={"#B7D4CF"} />

                <HStack w={"full"} spacing={"9%"} cursor={"pointer"} onClick={handleChangePassword}>
                    <Image boxSize={!isTabletOrMobile ? "7%" : "4vw"} src={LockIcon} />
                    <Text _hover={{textColor: process.env.REACT_APP_TEXT_COLOR_BOLD}} fontSize={!isTabletOrMobile ? "2xl" : "md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Change Password</Text>
                    <Spacer/>
                    <Image src={ChevronRight} visibility={!isTabletOrMobile ? "visible" : "hidden"} />
                </HStack>
                <Divider border={"2px solid"} borderColor={"#B7D4CF"} />
            </VStack>
        </Container>
    );
}
 
export default ProfileSettingLeftSide;