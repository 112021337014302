import React, {Fragment} from "react";
import { useState, useContext, useEffect } from "react";

import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Checkbox,
    Image,
    Text,
    Center,
    Flex,
    TableCaption,
    Box,
} from "@chakra-ui/react";

import InfiniteScroll from "react-infinite-scroll-component";

import { CheckBoxesContext } from "../../components/CheckBoxes";
import Loader from "../../components/Loader/Loader";

import SortIcon from "../../assets/icons/table/TableSort.svg";
import Cookies from "js-cookie";
import Axios from "../../services/api/Axios";
import EmptyList from "../../assets/img/empty.svg";
import {useMediaQuery} from "react-responsive";

const Tables = (props) => {
    const history = {
        dataSparePartURL : "/sparepart/list?reorder=1&order=id,desc&sediaan_id=" + Cookies.get("idServing") + "&is_active=true&offset=",
        accessToken : Cookies.get("accessToken"),
    }

    const checkboxContext = useContext(CheckBoxesContext);
    const isResolutionHigh = useMediaQuery({ query: "(min-width: 1920px)" })

    const columnNames = {
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "part_category" : "Item Category",
        "qty" : "Stock",
        "reorder" : "ReOrder",
        "qty_min" : "Min Stock",
        "qty_max" : "Max Stock",
        "main_supplier" : "Main Supplier",
    }

    const [dataSpareParts,setDataSpareParts] = useState([]);
    const [dataInitialization, setDataInitialization] = useState(false);
    const [dataOffset, setDataOffset] = useState(25);

    const [sortState, setSortState] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [hasMoreTable, setHasMoreTable] = useState(true);

    function handleCheckbox(sparePart){
        let index = checkboxContext.checkBoxState.map(item => item.id).indexOf(sparePart.id);
        let selectedArray = [...checkboxContext.checkBoxState]
        if (index !== -1){
            selectedArray.splice(index, 1);
        }else{
            selectedArray.push(sparePart)
        }
        checkboxContext.setCheckBoxState(selectedArray);
    }

    const handleGetDataSpareParts = async (event) => {
        switch (event){
            case "getFirst":
                try{
                    let getDataSpareParts = await Axios.get(history.dataSparePartURL + 0,
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    if ((getDataSpareParts?.data?.data?.spareparts)?.length < 25) {
                        setHasMoreTable(false);
                    }
                    else setHasMoreTable(true);

                    setDataSpareParts(getDataSpareParts?.data?.data?.spareparts);
                }
                catch(error){
                    console.error(error);
                }

                break;

            case "getMore":
                if (!isSearchActive) {
                    try{
                        let getDataSpareParts = await Axios.get(history.dataSparePartURL + dataOffset,
                            {
                                headers: {"Authorization": "Bearer " + history.accessToken},
                            });

                        if ((getDataSpareParts?.data?.data?.spareparts)?.length < 25) {
                            setHasMoreTable(false);
                        }
                        else setHasMoreTable(true);

                        setDataSpareParts(dataSpareParts.concat(getDataSpareParts?.data?.data?.spareparts));
                        setDataOffset(dataOffset => dataOffset + 25);
                    }
                    catch(error){
                        console.error(error);
                    }
                }

                break;

            case "getSearch":
                let searchQuery = '';

                Object.keys(props.searchItems).map((elem) => {
                    if (props.searchItems[elem]) return searchQuery += ("&" + elem + "=" + props.searchItems[elem])
                    return ''
                })

                try{
                    let getDataSpareParts = await Axios.get(history.dataSparePartURL + 0 + "&limit=9999" + searchQuery,
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });

                    setHasMoreTable(false);

                    setDataSpareParts(getDataSpareParts?.data?.data?.spareparts);
                }
                catch(error){
                    console.error(error);
                }

                break;

            default:
                break;
        }
    };

    const handleSortDataSpareParts = async (columnName, isAsc) => {
        let newDataSpareParts = Array.from(dataSpareParts);

        if (columnName === "reorder") columnName = "qty_min";

        newDataSpareParts.sort((a, b) => {
            if (a[columnName] && b[columnName]) {
                if (isNaN(a[columnName]) || isNaN(b[columnName])) {
                    let fa = a[columnName].toLowerCase(),
                        fb = b[columnName].toLowerCase();

                    if (fa < fb) {
                        if (isAsc) return -1;
                        else return 1;
                    }
                    if (fa > fb) {
                        if (isAsc) return 1;
                        else return -1
                    }
                    return 0;
                }
                else {
                    if (isAsc) return a[columnName] - b[columnName];
                    else return b[columnName] - a[columnName];
                }
            }
            return 0
        });

        setDataSpareParts(newDataSpareParts);
    };

    function getReOrderPoint(qty, qty_min, qty_max) {
        let qty_rop = Math.ceil(qty_min + qty_min / 10);
        let qty_final;

        if ((qty_min === qty_max) && (qty_min < qty_rop)) {
            qty_final = (Number((qty_max - 1).toFixed(2)));
        }
        else if ((qty_min < qty_rop) && (qty_rop === qty_max)) {
            qty_final = (Number((qty_max - 1).toFixed(2)));
        }
        else if ((qty_min < qty_max) && (qty_max < qty_rop)) {
            qty_final = (Number((qty_max - 1).toFixed(2)));
        }
        else {
            qty_final = (Number((qty_rop).toFixed(2)));
        }

        return qty_final
    }

    useEffect(() => {
        if (!dataInitialization || props.isReset) handleGetDataSpareParts("getFirst").then(() => {
            setIsSearchActive(false);
            props.setIsReset(false)
            setDataOffset(25);
            setDataInitialization(true);
        });
        else if (Object.keys(props.searchItems).length !== 0) handleGetDataSpareParts("getSearch").then(() => {
            setIsSearchActive(true);
            props.setSearchItems({});
        });
    },[props.searchItems, props.isReset]);

    return (
        <Flex paddingTop={5}>
            {(dataSpareParts)?.length === 0 ?
                <Center w={"full"}>
                    <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                    <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                        No items in here..
                    </Text>
                </Center>
                :
                <Box w={"full"}>
                    <InfiniteScroll scrollableTarget={"tableSparePartReOrder"} dataLength={dataSpareParts.length} next={() => handleGetDataSpareParts("getMore")} hasMore={hasMoreTable} loader={''}>
                        <TableContainer id={"tableSparePartReOrder"} overflowY={"scroll"} whiteSpace={"normal"} display={"block"} maxHeight={isResolutionHigh ? "58vh" : "48vh"} border={"1px solid #B7D4CF"} w={"full"}>
                            <Table variant="simple">
                                <TableCaption>
                                    {hasMoreTable ?
                                        <Loader/>
                                        :
                                        <Text className='text-center font-bold text-sm' textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>End of Line (No more data)</Text>
                                    }
                                </TableCaption>
                                <Thead zIndex={1} pos='sticky' top={0} bg={process.env.REACT_APP_TABLE_COLOR}>
                                    <Tr>
                                        <Th>
                                            <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}></Text>
                                        </Th>
                                        <Th>
                                            <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>No</Text>
                                        </Th>
                                        {Object.keys(columnNames).map((elem) => (
                                            <Fragment key={columnNames[elem]}>
                                                <Th>
                                                    <Center>
                                                        <Image className="cursor-pointer" src={SortIcon} alt="" onClick={() => {
                                                            handleSortDataSpareParts(elem, sortState).then(() => setSortState(!sortState));
                                                        }}
                                                        />
                                                        <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnNames[elem]}</Text>
                                                    </Center>
                                                </Th>
                                            </Fragment>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {dataSpareParts.map((dataSparePart, index) => (
                                        <Tr key={dataSparePart.id}>
                                            <Td>
                                                <Checkbox size="lg" colorScheme={process.env.REACT_APP_BORDER_COLOR} borderColor={process.env.REACT_APP_CHECKBOX_COLOR} isChecked={checkboxContext.checkBoxState.some(o => o.id === dataSparePart.id)} onChange={() => handleCheckbox(dataSparePart)}>
                                                </Checkbox>
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {index + 1}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_code}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_name}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.brand}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.type}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_category}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {getReOrderPoint(Number(dataSparePart.qty), Number(dataSparePart.qty_min), Number(dataSparePart.qty_max))}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty_min}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty_max}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.main_supplier}
                                            </Td>

                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </InfiniteScroll>
                </Box>
            }
        </Flex>
    );
}

export default Tables;