import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';

import {
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Image,
    Text,
    Flex, Badge, HStack,
} from '@chakra-ui/react';

import Cookies from "js-cookie";

import ProfileIcon from "../../../assets/icons/navbar/Profile.svg";
import ProfileCircleIcon from "../../../assets/icons/navbar/Profile_circle.svg";
import LogoutIcon from "../../../assets/icons/navbar/Logout.svg";

const NavbarProfile = () => {
    const history = {
        routeProfile : "/user/profile",
        routeLogin : "/auth/login",
        navigate : useNavigate(),
    }

    const [navigateProfile, setNavigateProfile] = useState(false);
    const [logOutStatus, setLogOutStatus] = useState(false);

    useEffect(() => {
        if (navigateProfile) history.navigate(history.routeProfile);
        if (logOutStatus) {
            Cookies.remove("accessToken");
            Cookies.remove("name");
            Cookies.remove("role");
            Cookies.remove("idServing");
            Cookies.remove("serving");
            history.navigate(history.routeLogin);
        }
    }, [navigateProfile, logOutStatus])

    return (
        <Menu autoSelect={false}>
            <MenuButton
                as={Button}
                className={"mx-4"}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}
            >
                <HStack>
                    <Badge visibility={"hidden"} borderRadius='md' bg={"red"} color={"white"}>1</Badge>
                    <Image src={ProfileIcon} alt={"box"} />
                </HStack>
            </MenuButton>
            <MenuList alignItems={'center'} className={"my-2"}>
                <MenuItem onClick={() => setNavigateProfile(true)}>
                    <Flex>
                        <Image src={ProfileCircleIcon} className={"scale-125 mx-2"} alt={''} />
                        <Text className={"ml-2"} textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>Profile Setting</Text>
                    </Flex>
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => setLogOutStatus(true)}>
                    <Flex>
                        <Image src={LogoutIcon} className={"scale-125 mx-2"} alt={''} />
                        <Text className={"ml-2"} textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>Logout</Text>
                    </Flex>
                </MenuItem>
            </MenuList>
        </Menu>
    );
}

export default NavbarProfile;