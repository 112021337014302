import {
    Flex, Image
} from '@chakra-ui/react';

import loadingGIF from "../../assets/img/loading.svg"

const Loader = () => (
    <Flex
        style={{
            marginBottom: "24px",
            height: "24px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
        }}
    >
        <Image
            src={loadingGIF}
            alt="loading"
        />
    </Flex>
);

export default Loader;