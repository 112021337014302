import React from 'react'
import SparepartLogoWithSearchBar from '../../../../pages/SparePart/SubHeader';
import ExportSparepartButtonCollection from './ExportSparepart_ButtonCollection';
import { CheckboxExportprovider, DropdownContextProvider, FilterContextProvider } from './ExportSparepart_Context';
import SparePartDropDowns from "../../../../pages/SparePart/DropDowns";
import SparePartTables from "../Export/Tables";

import { useEffect, useState } from "react";

import Axios from "../../../../services/api/Axios"
import Cookies from "js-cookie";


let state = {
    pages: ["list", "archive", "opname"],
    dropDownList : ["part_name", "brand", "type", "bin_location"],
    offset: 25,
    dataOffset : 0,
    dropDownOffset : 25,
    dropDownSelectOffset : 0,
}

const Hero_ExportSparepart = (props) => {
    const [dataSpareParts,setDataSpareParts] = useState([]);
    const [countAllData, setCountAllData] = useState(99999);
    const [allDataSpareParts, setAllDataSpareParts] = useState([]);
    const [tempDataSpareParts,setTempDataSpareParts] = useState([]);
    const [dataInitialization,setDataInitialization] = useState(false);

    const [dataDropDownSpareParts,setDataDropDownSpareParts] = useState({});
    const [dataDropDownChange,setDataDropDownChange] = useState(false);
    const [fetchMoreData,setFetchMoreData] = useState(true);
    const [fetchMoreDropDownData,setFetchMoreDropDownData] = useState([false, ""]);
    const [isSearchActive, setIsSearchActive] = useState(false);

    const [hasMoreTable, setHasMoreTable] = useState(true);
    const [hasMoreDropDown, setHasMoreDropDown] = useState({});

    const [selectedFormatFile, setSelectedFormatFile] = useState('excel')

    if (!dataInitialization) {
        state = {
            pages: ["list", "archive", "opname"],
            dropDownList : ["part_name", "brand", "type", "bin_location"],
            offset: 25,
            dataOffset : 0,
            dropDownOffset : 25,
            dropDownSelectOffset : 0,
        }
    }

    const history = {
        dataSparePartURL : "/sparepart/list?order=id,desc&sediaan_id=" + Cookies.get("idServing") + "&is_active=" + ((props.page !== state.pages[1])) + "&count=1&offset=",
        allDataSparePartURL : "/sparepart/list?order=id,desc&sediaan_id=" + Cookies.get("idServing") + "&is_active=" + ((props.page !== state.pages[1])) + "&limit=",
        dataDropDownSparePartURL : "/sparepart/list?sediaan_id=" + Cookies.get("idServing") + "&is_active=" + ((props.page !== state.pages[1])) + "&dropdown=",
        postSparePartItemURL : "/sparepart/item?id=",
        accessToken : Cookies.get("accessToken"),
    }

    const handleGetDataSpareParts = async () => {
        try{
            if (dataInitialization) state.dataOffset += state.offset;

            let getDataSpareParts = await Axios.get(history.dataSparePartURL + state.dataOffset,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            if ((getDataSpareParts?.data?.data?.spareparts)?.length < 25) {
                setHasMoreTable(false);
            }
            else setHasMoreTable(true);

            setDataSpareParts(dataSpareParts.concat(getDataSpareParts?.data?.data?.spareparts));

            if (!dataInitialization) {
                setCountAllData(getDataSpareParts?.data?.data?.count)
                setTempDataSpareParts(getDataSpareParts?.data?.data?.spareparts);
            }
        }
        catch(error){
            console.error(error);
        }
    };

    const handleGetAllDataSpareParts = async () => {
        if (allDataSpareParts.length === 0) {
            try{
                let getDataSpareParts = await Axios.get(history.allDataSparePartURL + countAllData,
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                setAllDataSpareParts(allDataSpareParts.concat(getDataSpareParts?.data?.data?.spareparts));
            }
            catch(error){
                console.error(error);
            }
        }
    };

    const handleGetDataDropDownSpareParts = async () => {
        let objectDropDown = {};
        let objectHasMore = {};
        state.dropDownOffset = state.offset;

        state.dropDownList.map(async (dropDownListItem) => {
            let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            objectHasMore[dropDownListItem] = (getDataDropDownSpareParts?.data?.data?.spareparts)[dropDownListItem]?.length >= 25;
            setHasMoreDropDown(objectHasMore);

            if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
            objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
        })

        setDataDropDownSpareParts(objectDropDown);
    };

    const handleGetMoreDataDropDownSpareParts = async (dropDownListItem) => {
        let objectDropDown = JSON.parse(JSON.stringify(dataDropDownSpareParts));
        let objectHasMore = JSON.parse(JSON.stringify(hasMoreDropDown));

        let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + "&offset=" + state.dropDownOffset,
            {
                headers: {"Authorization": "Bearer " + history.accessToken},
            });

        objectHasMore[dropDownListItem] = (getDataDropDownSpareParts?.data?.data?.spareparts)[dropDownListItem]?.length >= 25;
        setHasMoreDropDown(objectHasMore);

        objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem])
        setDataDropDownSpareParts(objectDropDown);

        state.dropDownOffset += state.offset;
    };

    const handleGetDataDependentDropDownSpareParts = async (dropDownList) => {
        let objectDropDown = {};
        let objectHasMore = {};

        let queryString = '';

        Object.keys(dropDownList).map(async (dropDownListItem) => {
            if (dropDownList[dropDownListItem].length) {
                queryString = queryString.concat('&', dropDownListItem, '=', dropDownList[dropDownListItem])
            }
        })

        let searchString = queryString.replace(/ /g, '_');

        Object.keys(dropDownList).map(async (dropDownListItem) => {
            if (dropDownList[dropDownListItem].length === 0) {
                let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + searchString + "&limit=999",
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                objectHasMore[dropDownListItem] = false
                setHasMoreDropDown(objectHasMore);

                if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
                objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
            }
            else {
                let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + "&limit=999",
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                objectHasMore[dropDownListItem] = false
                setHasMoreDropDown(objectHasMore);

                if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
                objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
            }
        })

        setDataDropDownSpareParts(objectDropDown);
    };

    const handleSearchDataDropDownSparePartListItem = async (dropDownListItem, queryString) => {
        let objectDropDown = JSON.parse(JSON.stringify(dataDropDownSpareParts));
        let objectHasMore = JSON.parse(JSON.stringify(hasMoreDropDown));

        let tempString = queryString.replace(/ /g, '_');
        tempString = tempString.replace('.', '_');

        let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + "&" + dropDownListItem + "=%" + tempString + "%" + "&limit=9999",
            {
                headers: {"Authorization": "Bearer " + history.accessToken},
            });

        objectHasMore[dropDownListItem] = false;
        setHasMoreDropDown(objectHasMore);

        objectDropDown[dropDownListItem].splice(0, objectDropDown[dropDownListItem].length);
        objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);

        setDataDropDownSpareParts(objectDropDown);
        setDataDropDownChange(true);
    };

    const handleSearchSpareParts = async (dropDownValue) => {
        let searchQuery = '';

        Object.keys(dropDownValue).map((elem) => {
            if (dropDownValue[elem]) return searchQuery += ("&" + elem + "=" + dropDownValue[elem])
            return ''
        })

        try{
            let getDataSpareParts = await Axios.get(history.dataSparePartURL + 0 + "&limit=9999" + searchQuery,
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });

            setHasMoreTable(false);
            setDataSpareParts(getDataSpareParts?.data?.data?.spareparts);
        }
        catch(error){
            console.error(error);
        }
        setIsSearchActive(true);
    };

    const handleUseOldDataSpareParts = () => {
        let tempData = Array.from(tempDataSpareParts);
        if (tempData.length !== 0) setDataSpareParts(tempData);
        state.dataOffset = 0;

        if (tempData.length >= 25) setHasMoreTable(true);
    };

    const handleSortDataDropDownSparePartListItem = async (dropDownListItem, isAsc) => {
        let newDataSpareParts = Array.from(dataSpareParts);

        newDataSpareParts.sort((a, b) => {
            if (a[dropDownListItem] && b[dropDownListItem]) {
                if (isNaN(a[dropDownListItem]) || isNaN(b[dropDownListItem])) {
                    let fa = a[dropDownListItem].toLowerCase(),
                        fb = b[dropDownListItem].toLowerCase();

                    if (fa < fb) {
                        if (isAsc) return -1;
                        else return 1;
                    }
                    if (fa > fb) {
                        if (isAsc) return 1;
                        else return -1
                    }
                    return 0;
                }
                else {
                    if (isAsc) return a[dropDownListItem] - b[dropDownListItem];
                    else return b[dropDownListItem] - a[dropDownListItem];
                }
            }
            return 0
        });

        setDataSpareParts(newDataSpareParts);
    };

    const handleArchiveSparePart = async (sparePartList) => {
        let newDataSpareParts = Array.from(dataSpareParts);
        let promiseArray = [];

        sparePartList.map((sparePartItem) => {
            promiseArray.push(new Promise((resolve, reject) => {
                Axios.post(history.postSparePartItemURL + sparePartItem,
                    {is_active: false},
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    }
                );

                newDataSpareParts.splice(newDataSpareParts.findIndex(((e) => e.id === sparePartItem)), 1);
                resolve("done");
            }))
        })

        await Promise.all(promiseArray);

        setDataSpareParts(newDataSpareParts);
    }

    const handleDeleteSparePart = async (sparePartList) => {
        let newDataSpareParts = Array.from(dataSpareParts);
        let promiseArray = [];

        sparePartList.map((sparePartItem) => {
            promiseArray.push(new Promise((resolve, reject) => {
                let indexSparePart = newDataSpareParts.findIndex(((e) => e.id === sparePartItem));

                Axios.delete(history.postSparePartItemURL + sparePartItem,
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    }
                );

                if (newDataSpareParts[indexSparePart].image_id !== 1) {
                    Axios.delete(history.deleteImageURL + newDataSpareParts[indexSparePart].image_id,
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        }
                    );
                }

                newDataSpareParts.splice(indexSparePart, 1);
                resolve("done");
            }))
            return ''
        })

        await Promise.all(promiseArray);

        setDataSpareParts(newDataSpareParts);
    }

    useEffect(() => {
        if (!isSearchActive) if (fetchMoreData) handleGetDataSpareParts().then(() => setFetchMoreData(false));
        if (!dataInitialization) handleGetDataDropDownSpareParts().then(() => setDataInitialization(true));
        if (fetchMoreDropDownData[0]) handleGetMoreDataDropDownSpareParts(fetchMoreDropDownData[1]).then(() => setFetchMoreDropDownData([false, '']));
        if (dataDropDownChange) setDataDropDownChange(false);
    }, [dataSpareParts, fetchMoreData, fetchMoreDropDownData, dataDropDownChange, isSearchActive]);

    return (
        <FilterContextProvider>
            <CheckboxExportprovider>
                <SparepartLogoWithSearchBar />
                <DropdownContextProvider>
                    <SparePartDropDowns
                        props={props} pages={state.pages}
                        dataDropDownSpareParts={dataDropDownSpareParts}
                        handleGetDataDropDownSpareParts={handleGetDataDropDownSpareParts}
                        handleGetDataDependentDropDownSpareParts={handleGetDataDependentDropDownSpareParts}
                        handleSearchDataDropDownSparePartListItem={handleSearchDataDropDownSparePartListItem}
                        setFetchMoreDropDownData={setFetchMoreDropDownData}
                        handleSearchSpareParts={handleSearchSpareParts}
                        handleUseOldDataSpareParts={handleUseOldDataSpareParts}
                        setIsSearchActive={setIsSearchActive}
                        hasMoreDropDown={hasMoreDropDown}
                        role={history.role}
                    />
                    <ExportSparepartButtonCollection
                        data={dataSpareParts}
                        handleGetAllDataSpareParts={handleGetAllDataSpareParts}
                        allDataSpareParts={allDataSpareParts}
                        selectedFormatFile={selectedFormatFile}
                        setSelectedFormatFile={setSelectedFormatFile}
                    />
                    <SparePartTables
                        props={props} pages={state.pages}
                        dataSpareParts={dataSpareParts}
                        setFetchMoreData={setFetchMoreData}
                        handleSortDataDropDownSparePartListItem={handleSortDataDropDownSparePartListItem}
                        handleArchiveSparePart={handleArchiveSparePart}
                        handleDeleteSparePart={handleDeleteSparePart}
                        hasMoreTable={hasMoreTable}
                    />
                </DropdownContextProvider>
            </CheckboxExportprovider>
        </FilterContextProvider>
    );
}
 
export default Hero_ExportSparepart;