import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {
    Button,
    Container,
    Divider,
    Flex,
    HStack, Image,
    Spacer,
    Text,
    useDisclosure,
} from "@chakra-ui/react";

import Axios from "../../../services/api/Axios";
import Cookies from "js-cookie";

import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import SubHeader from "../SubHeader";
import ListRestore from "./ListRestore";

import Modal from "../../../components/Modal/AreYouSure";
import SparePartPopUp from "../../../components/Modal/SparePartPopUp";
import ModalScanner from "../../../components/Modal/SparePartScanner";

import BackIcon from "../../../assets/icons/hero/Sparepart/Hero_back.svg";

const Restore = () => {
    const navigate = useNavigate();
    let history = {
        dataSparePartQR: "/sparepart/item?id=",
        listRestoreURL : "/outgoing/returnList?is_returned=false&order=updatedAt,desc&sediaan_id=" + Cookies.get("idServing") + "&count=1",
        postRestoreURL : "/outgoing/accept?id=",
        imageSparePartURL : "/image/item?id=",
        accessToken : Cookies.get("accessToken"),
    }

    const [dataInitialization,setDataInitialization] = useState(false);
    const [dataRestore, setDataRestore] = useState([]);
    const [confirmAction, setConfirmAction] = useState('')

    const [refreshImage, setRefreshImage] = useState('')
    const [unusedRefreshData, setUnusedRefreshData] = useState({});

    const [modalData, setModalData] = useState({});
    const [scanType, setScanType] = useState('');
    const [quantityToScan, setQuantityToScan] = useState(-1);

    const [countPromisesModal, setCountPromisesModal] = useState({
        "now" : 0,
        "total": 0,
    });

    const handleRestore = async (event) => {
        switch (event) {
            case "getData":
                try{
                    let getDataRestore = await Axios.get(history.listRestoreURL,
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });

                    (getDataRestore?.data?.data?.returnList).map(async (dataSparePart) => {
                        if (!dataSparePart["image_bin"] && dataSparePart?.sparepart?.image_id && dataSparePart?.sparepart?.image_id !== 1) {
                            let getDataImage = await Axios.get(history.imageSparePartURL + dataSparePart?.sparepart?.image_id,
                                {
                                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                                });

                            dataSparePart["image_bin"] = getDataImage?.data?.data?.image?.bin;

                            setRefreshImage("fetchData");
                        }
                    })

                    setDataRestore(getDataRestore?.data?.data?.returnList)
                }
                catch(error){
                    console.error(error);
                }
                break;

            case "postData":
                try{
                    let postDataRestore = await Axios.post(history.postRestoreURL + modalData.id,
                        {qty_accept: (!isNaN(modalData.qty_confirmedReturn) ? modalData.qty_confirmedReturn : modalData.qty_return)},
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });
                    console.log(postDataRestore);
                    setModalData({});
                    setDataInitialization(false);
                    onOpenSuccessModal();
                }
                catch(error){
                    console.error(error);
                }
                break;

            default:
                break;
        }
    };

    const handleCheckLabelSparePartQR = () => {
        if (Number(quantityToScan) < 1 && Number(quantityToScan) > -1) {
            Axios.post(history.dataSparePartQR + modalData.sparepart_id,
                {meta: modalData.meta},
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                }).then((response) => console.log(response));

            setQuantityToScan(-1);
            setConfirmAction("Label");
            setCountPromisesModal({
                "now" : 0,
                "total": 0,
            });
        }
        else onOpenReportModalScanner();

    }

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenSparePartPopUp,
        onOpen: onOpenSparePartPopUp,
        onClose: onCloseSparePartPopUp
    } = useDisclosure()

    const {
        isOpen: isOpenReportModalScanner,
        onOpen: onOpenReportModalScanner,
        onClose: onCloseReportModalScanner
    } = useDisclosure()

    useEffect(() => {
        if (!dataInitialization) handleRestore("getData").then(() => setDataInitialization(true));
        if (refreshImage !== '') setRefreshImage('');
        if (!isOpenSuccessModal) setUnusedRefreshData({});
    }, [dataInitialization, dataRestore, refreshImage, onCloseSuccessModal])

    useEffect(() => {
        switch(confirmAction){
            case "Restore":
                setScanType("label");
                setQuantityToScan(modalData?.qty_confirmedReturn ? modalData?.qty_confirmedReturn : modalData?.qty_return);
                setCountPromisesModal({
                    "now" : 1,
                    "total": modalData?.qty_confirmedReturn ? modalData?.qty_confirmedReturn : modalData?.qty_return,
                });
                setConfirmAction('');
                break;
            case "Label":
                setScanType("bin");
                onOpenReportModalScanner();
                setConfirmAction('');
                break;
            case "Bin Storage":
                handleRestore("postData").then(() => setConfirmAction(''));
                break;
            default:
                break;
        }
    }, [confirmAction])

    console.log(modalData);

    useEffect(() => {
        if (quantityToScan >= 0) handleCheckLabelSparePartQR();
    }, [quantityToScan, confirmAction]);

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingTop={"2vh"}>
                <SparePartPopUp
                    isOpen={isOpenSparePartPopUp}
                    onOpen={onOpenSparePartPopUp}
                    onClose={onCloseSparePartPopUp}
                    setConfirmAction={setConfirmAction}
                    modalData={modalData}
                    setModalData={setModalData}
                    type={"Restore"}
                />

                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    type={"Success"}
                />

                <ModalScanner
                    isOpen={isOpenReportModalScanner}
                    onOpen={onOpenReportModalScanner}
                    onClose={onCloseReportModalScanner}
                    setConfirmAction={setConfirmAction}
                    modalData={modalData}
                    setModalData={setModalData}
                    quantityToScan={quantityToScan}
                    setQuantityToScan={setQuantityToScan}
                    countPromisesModal={countPromisesModal}
                    setCountPromisesModal={setCountPromisesModal}
                    scanType={scanType}
                    type={"Restore"}
                />

                <SubHeader position={"fixed"}/>

                <Flex marginX={"5%"} marginTop={"4%"} marginBottom={1} w={"90%"} position={"fixed"}>
                    <Button leftIcon={<Image src={BackIcon} alt="" />} backgroundColor={process.env.REACT_APP_ENV_COLOR} borderRadius="8px" variant='solid' _hover={{ bg: process.env.REACT_APP_BUTTON_HOVER_COLOR }} _active={{ bg: process.env.REACT_APP_TEXT_COLOR_LIGHT }} boxShadow="lg" onClick={() => navigate("/history/listOutgoing")}>
                        <Text className="text-white font-extrabold tracking-wide ml-2">Back</Text>
                    </Button>

                    <Spacer/>

                    <HStack alignItems={"end"}>
                        <Text fontSize={"2xl"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                            {dataRestore.length}
                        </Text>
                        <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                            items need to be returned.
                        </Text>
                    </HStack>
                </Flex>

                <Divider variant={"dashed"} borderColor={process.env.REACT_APP_BORDER_COLOR} margin={2} w={"98%"} marginTop={"20vh"} position={"fixed"}/>

                <ListRestore
                    dataRestore={dataRestore}
                    setModalData={setModalData}
                    onOpenSparePartPopUp={onOpenSparePartPopUp}
                    setRefreshImage={setRefreshImage}
                />
            </Container>
            <Footer />
        </>
    );
}

export default Restore;
