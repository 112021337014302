import React  from "react";
import { useState, createContext } from 'react';

const CheckBoxesContext = createContext();

const CheckBoxesProvider = ({children}) => {
    const [checkBoxState, setCheckBoxState] = useState([])
    return(
        <CheckBoxesContext.Provider value={{ checkBoxState, setCheckBoxState }}>
            {children}
        </CheckBoxesContext.Provider>
    )
}

export {CheckBoxesContext, CheckBoxesProvider};