import { createContext, React, useState } from 'react';

const CheckboxImportContext = createContext();
const CheckboxImportProvider = ({children}) => {
    const [checkboxState, setCheckboxState] = useState([]) 
    return(
        <CheckboxImportContext.Provider value={{ checkboxState, setCheckboxState }}>
            {children}
        </CheckboxImportContext.Provider>
    )
}

const ImportDataContext = createContext();
const ImportDataProvider = ({children}) => {
    const [importData, setImportData] = useState(null)
    return(
        <ImportDataContext.Provider value={{importData, setImportData}}>
            {children}
        </ImportDataContext.Provider>
    )
}

export {CheckboxImportContext, CheckboxImportProvider};
export {ImportDataContext, ImportDataProvider}