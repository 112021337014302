import React, {Fragment, useEffect, useState} from 'react';

import {
    Button,
    Text,
    Modal,
    Image,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    TableContainer,
    Table,
    TableCaption,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    NumberInput,
    NumberInputField,
    NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Center, Checkbox, HStack, Highlight,
} from '@chakra-ui/react'

import DeleteIcon from "../../assets/icons/modal/Modal_delete.svg";
import EmptyList from "../../assets/img/empty.svg"
import {useMediaQuery} from "react-responsive";
import TablePrintIcon from "../../assets/img/print.svg";

let checkBoxAll = false;

const SparePartCart = (props) => {
    const [data, setData] = useState({});
    const [checkBoxData, setCheckBoxData] = useState([]);
    const [requestData, setRequestData] = useState([]);
    const [requestQuantityChange, setRequestQuantityChange] = useState({});
    const [stateConfirm, setStateConfirm] = useState('');
    const [buttonTimeout, setButtonTimeout] = useState(true);
    const [invalidState, setInvalidState] = useState([]);

    // console.log(requestData);
    // console.log(checkBoxData);

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" })

    const columnName = {
        "checkbox" : "",
        "no" : "No",
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "qty" : "Stock",
        "qty_request" : (props.type !== "Incoming" ? "Request Quantity" : "Incoming Quantity"),
        "price" : "Price/pcs",
        "bin_location" : "Bin Location",
    }

    useEffect(() => {
        handleModalType(props.type);
        setStateConfirm('');
        setButtonTimeout(true);
        if (!props.isOpen) {
            checkBoxAll = false
            if (props.type === "Outgoing" && checkBoxData.length !== 0) {
                setRequestData([]);
                setCheckBoxData([]);
            }
            setInvalidState([]);
        }
    }, [props.isOpen, props.onClose, checkBoxData, requestData, requestQuantityChange])

    useEffect(() => {
        setRequestQuantityChange({});
    }, [!props.isOpen])

    function handleRequest(){
        props.handleChangeQuantityRequestCart(requestQuantityChange, checkBoxData);
        props.handleRequestCart(checkBoxData);
        props.onClose(true);
    }

    function handleOutgoing(){
        let tempObjectCartData = JSON.parse(JSON.stringify(props.cartData));
        tempObjectCartData["outgoingData"] = [requestData, requestQuantityChange];

        props.setCartData(tempObjectCartData);
        props.setConfirmAction("Cart");
        props.onClose(true);
    }

    function handleIncoming(){
        let tempCheckBoxes = JSON.parse(JSON.stringify(props.cartData.checkBoxesData));

        Object.keys(tempCheckBoxes).map((card) => {
            Object.keys(tempCheckBoxes[card]).map((item) => {
                if (tempCheckBoxes[card][item]["isChecked"]) {
                    if (checkBoxData.some(part => part === tempCheckBoxes[card][item]["id"])) tempCheckBoxes[card][item]["isToBePrinted"] = true
                }
                return 0
            })
            return 0
        })

        console.log(tempCheckBoxes);
        props.setCheckBoxes(tempCheckBoxes);
        props.setConfirmAction("QR");
        props.onClose(true);
    }

    function handleDelete(){
        props.handleDeleteItemCart(checkBoxData);
        props.onClose(true);
    }

    function handleReject(){
        props.handleDeleteOutgoing(requestData);
        props.onClose(true);
    }

    function handleModalType(type){
        let data = {}
        switch(type){
            case "Request":
                data.title = "Are you sure to delete these items?";
                data.content = "This action can not be undone";
                data.confirmation = "Request";
                data.confirmationColor = process.env.REACT_APP_BUTTON_COLOR;
                data.cancel = "Delete";
                data.handleConfirmation = handleRequest;
                data.handleDelete = handleDelete;
                data.logo = DeleteIcon;
                data.closeOnOverlayClick = true;
                break;
            case "Outgoing":
                data.title = "Are you sure to delete these items?";
                data.content = "This action can not be undone";
                data.confirmation = "Outgoing";
                data.confirmationColor = process.env.REACT_APP_BUTTON_COLOR;
                data.cancel = "Reject";
                data.handleConfirmation = handleOutgoing;
                data.handleDelete = handleReject;
                data.logo = DeleteIcon;
                data.closeOnOverlayClick = true;
                break;
            case "Incoming":
                data.title = "Are you sure to delete these items?";
                data.content = "This action can not be undone";
                data.confirmation = "Print";
                data.confirmationColor = "gray";
                data.cancel = "Cancel";
                data.handleConfirmation = handleIncoming;
                data.logo = DeleteIcon;
                data.closeOnOverlayClick = false;
                break;
            default:
                break;
        }
        setData(data);
    }

    function handleInvalidState(id, quantity, request, receive){
        if (props.type === "Outgoing" && receive === null) {
            if (!isNaN(requestQuantityChange[id])) {
                if (Number(requestQuantityChange[id]) > Number(quantity) || Number(requestQuantityChange[id]) === 0) {
                    let tempArray = [];
                    tempArray.push(id);
                    if (!invalidState.includes(id)) setInvalidState(invalidState.concat(tempArray));
                }
                else {
                    if (invalidState.includes(id)) {
                        let index = invalidState.indexOf(id);
                        invalidState.splice(index, 1);
                    }
                }
            }
            else {
                if (Number(request) > Number(quantity)) {
                    let tempArray = [];
                    tempArray.push(id);
                    if (!invalidState.includes(id)) setInvalidState(invalidState.concat(tempArray));
                }
                else {
                    if (invalidState.includes(id)) {
                        let index = invalidState.indexOf(id);
                        invalidState.splice(index, 1);
                    }
                }
            }
        }
        else if (props.type === "Request") {
            if (Number(requestQuantityChange[id]) === 0) {
                let tempArray = [];
                tempArray.push(id);
                if (!invalidState.includes(id)) setInvalidState(invalidState.concat(tempArray));
            }
            else {
                if (invalidState.includes(id)) {
                    let index = invalidState.indexOf(id);
                    invalidState.splice(index, 1);
                }
            }
        }
        return ''
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            isCentered
            autoFocus={false}
            closeOnOverlayClick={data.closeOnOverlayClick}
            size={"6xl"}
        >
            <ModalOverlay
                bg={"blackAlpha.500"}
                backdropFilter='auto'
                backdropBlur='2px'
            />
            <ModalContent>
                <ModalHeader textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{props.type} List</ModalHeader>
                <ModalCloseButton/>
                {props.cartData ?
                    <>
                        <ModalBody>
                            <TableContainer overflowY={"auto"} whiteSpace={"normal"} display={"flex"} maxHeight={"50vh"} border={"1px solid #B7D4CF"} borderRadius={"lg"}>
                                <Table variant="simple" size={"sm"}>
                                    <TableCaption color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                        {props.type === "Outgoing" ?
                                            <>
                                                <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD} fontSize={"md"} fontWeight={"bold"}>Requested by: {props.cartData?.user?.name}</Text>
                                                <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD} fontSize={"md"} fontWeight={"bold"}>Timestamp: {(new Date(props.cartData?.createdAt)).toString().slice(0, 34)}</Text>
                                                <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD} fontSize={"sm"} fontStyle={"italic"}>(Disabled item means item must be scanned manually with Label ID or item has been processed)</Text>
                                            </>
                                            :
                                            (props.type === "Incoming" ? <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD} fontSize={"md"} fontStyle={"italic"}>Please checklist all items that will have Label ID generated and printed!</Text>
                                                :
                                                "Please check the items first before proceeding!\n"
                                            )
                                        }
                                    </TableCaption>
                                    <Thead zIndex={1} pos='sticky' top={0} bg={process.env.REACT_APP_TABLE_COLOR}>
                                        <Tr>
                                            {Object.keys(columnName).map((elem) => (
                                                <Fragment key={columnName[elem]}>
                                                    {elem === "checkbox" ?
                                                        <Th>
                                                            <Checkbox
                                                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                                colorScheme={process.env.REACT_APP_BORDER_COLOR}
                                                                borderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                                onChange={() => {
                                                                    let tempCheckBoxData = [];
                                                                    let tempRequestData = [];
                                                                    checkBoxAll = !checkBoxAll;

                                                                    if (checkBoxAll) {
                                                                        (props.cartData?.requestSpareparts).map((elem) => {
                                                                            if (props.type === "Outgoing") (elem?.qty_receive === null && (elem?.sparepart?.meta?.labelQR?.length ? elem?.sparepart?.meta?.labelQR?.length : 0) === 0) && tempCheckBoxData.push(elem?.id) && (props.type === "Outgoing" && tempRequestData.push(elem?.id))
                                                                            else if (props.type === "Request") tempCheckBoxData.push(elem?.id)
                                                                            else tempCheckBoxData.push(elem?.sparepart?.id)
                                                                            return 0
                                                                        })
                                                                    }

                                                                    if (props.type === "Outgoing") {
                                                                        setRequestData(tempRequestData);
                                                                    }

                                                                    setCheckBoxData(tempCheckBoxData);
                                                                    console.log(tempCheckBoxData);
                                                                }}
                                                            />
                                                        </Th>
                                                        :
                                                        <Th>
                                                            <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnName[elem]}</Text>
                                                        </Th>
                                                    }
                                                </Fragment>
                                            ))}
                                            {props.type !== "Incoming" ?
                                                <>
                                                    <Th>
                                                        <Text className="m-1 text-center text-[#492472] font-bold text-sm">Plant</Text>
                                                    </Th>
                                                    <Th>
                                                        <Text className="m-1 text-center text-[#492472] font-bold text-sm">Department</Text>
                                                    </Th>
                                                </>
                                                :
                                                <Th>
                                                    <Text className="m-1 text-center text-[#492472] font-bold text-sm">Case No</Text>
                                                </Th>
                                            }
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {!props.cartData.requestSpareparts ? '' : (props.cartData?.requestSpareparts).map((elem, index) => (
                                            <Tr key={elem + index}>
                                                <Td>
                                                    <Checkbox
                                                        color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                        colorScheme={process.env.REACT_APP_BORDER_COLOR}
                                                        borderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                        isChecked={props.type === "Incoming" ? checkBoxData.includes(elem?.sparepart?.id) : checkBoxData.includes(elem?.id)}
                                                        isDisabled={(props.type !== "Outgoing" ? false : (elem?.qty_receive !== null || (elem?.sparepart?.meta?.labelQR?.length ? elem?.sparepart?.meta?.labelQR?.length : 0) !== 0))}
                                                        onChange={() => {
                                                            let tempCheckBoxData = Array.from(checkBoxData);

                                                            if (props.type === "Incoming") {
                                                                if (tempCheckBoxData.includes(elem?.sparepart?.id)) {
                                                                    let index = tempCheckBoxData.indexOf(elem?.sparepart?.id);
                                                                    tempCheckBoxData.splice(index, 1);
                                                                }
                                                                else tempCheckBoxData.push(elem?.sparepart?.id)
                                                            }
                                                            else if (props.type === "Outgoing") {
                                                                let tempRequestData = Array.from(requestData);
                                                                if (tempRequestData.includes(elem?.id)) {
                                                                    let index = tempRequestData.indexOf(elem?.id);
                                                                    tempRequestData.splice(index, 1);
                                                                }
                                                                else tempRequestData.push(elem?.id)

                                                                setRequestData(tempRequestData);

                                                                if (tempCheckBoxData.includes(elem?.id)) {
                                                                    let index = tempCheckBoxData.indexOf(elem?.id);
                                                                    tempCheckBoxData.splice(index, 1);
                                                                }
                                                                else tempCheckBoxData.push(elem?.id)
                                                            }
                                                            else {
                                                                if (tempCheckBoxData.includes(elem?.id)) {
                                                                    let index = tempCheckBoxData.indexOf(elem?.id);
                                                                    tempCheckBoxData.splice(index, 1);
                                                                }
                                                                else tempCheckBoxData.push(elem?.id)
                                                            }

                                                            setCheckBoxData(tempCheckBoxData);
                                                        }}
                                                    />
                                                </Td>
                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{index + 1}</Td>
                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem?.sparepart?.part_code}</Td>
                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem?.sparepart?.part_name}</Td>
                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem?.sparepart?.brand}</Td>
                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem?.sparepart?.type}</Td>
                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem?.sparepart?.qty}</Td>
                                                <Td>
                                                    <Center>
                                                        <NumberInput
                                                            allowMouseWheel
                                                            defaultValue={props.type !== "Incoming" ? (props.type === "Request" ? (!isNaN(requestQuantityChange[elem?.sparepart_id]) ? requestQuantityChange[elem?.sparepart_id] : elem?.qty_request) : elem?.qty_request) : elem?.qty_receive}
                                                            min={0.001}
                                                            max={Number(props.type === "Outgoing" ? Number(elem?.qty_request) : 999)}
                                                            size={"sm"}
                                                            maxW={!isTabletOrMobile ? "4vw" : "75px"}
                                                            borderColor={process.env.REACT_APP_INPUT_COLOR}
                                                            focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                            isDisabled={props.type === "Outgoing" ? (elem?.qty_receive !== null || (elem?.sparepart?.meta?.labelQR?.length ? elem?.sparepart?.meta?.labelQR?.length : 0) !== 0) : (props.type === "Incoming")}
                                                            isInvalid={props.type === "Outgoing" ? (!isNaN(requestQuantityChange[elem?.id]) ? (Number(requestQuantityChange[elem?.id]) > Number(elem?.sparepart?.qty) || Number(requestQuantityChange[elem?.id]) === 0) : Number(elem?.qty_request) > Number(elem?.sparepart?.qty)) : (props.type === "Request" ? (Number(requestQuantityChange[elem?.id]) === 0) : false)}
                                                            onChange={(e) => {
                                                                let tempQuantityChange = JSON.parse(JSON.stringify(requestQuantityChange));

                                                                tempQuantityChange[elem?.id] = Number(e.valueOf());
                                                                setRequestQuantityChange(tempQuantityChange);
                                                            }}
                                                        >
                                                            {props.isOpen && (props.type === "Outgoing" ? handleInvalidState(elem?.id, elem?.sparepart?.qty, elem?.qty_request, elem?.qty_receive) : handleInvalidState(elem?.id, 0, 0, 0))}
                                                            <NumberInputField color={process.env.REACT_APP_TEXT_COLOR_BOLD} borderRadius={"1rem"} _hover={{borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD}}/>
                                                            <NumberInputStepper>
                                                                <NumberIncrementStepper />
                                                                <NumberDecrementStepper />
                                                            </NumberInputStepper>
                                                        </NumberInput>
                                                    </Center>
                                                </Td>
                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Rp {elem?.sparepart?.price},00</Td>
                                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem?.sparepart?.bin_location}</Td>
                                                {props.type !== "Incoming" ?
                                                    <>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem?.machine?.line?.name}</Td>
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem?.machine?.code}</Td>
                                                    </>
                                                    :
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem?.case_number}</Td>
                                                }
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </ModalBody>
                        <ModalFooter alignSelf={"center"}>
                            {!stateConfirm ?
                                <HStack>
                                    <Button variant="solid" colorScheme={data.confirmationColor} onClick={() => setStateConfirm(data.confirmation)} isDisabled={(checkBoxData.length === 0 || requestData.some(r => invalidState.indexOf(r) >= 0) || (props.type === "Request" ? invalidState.length : false))}>
                                        {data.confirmation}
                                        {props.type === "Incoming" &&
                                            <Image pl={2} src={TablePrintIcon}/>
                                        }
                                    </Button>
                                    <Button hidden={props.type === "Incoming"} variant="solid" colorScheme={"red"} onClick={() => setStateConfirm("delete")} isDisabled={checkBoxData.length === 0}>
                                        {data.cancel}
                                    </Button>
                                </HStack>
                                :
                                <HStack spacing={5}>
                                    <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                        Are you sure to {" "}
                                        {stateConfirm === data.confirmation ?
                                            <Highlight query={data.confirmation} styles={{ color: "green" }}>
                                                {data.confirmation}
                                            </Highlight>
                                            :
                                            <Highlight query={"delete"} styles={{ color: "red" }}>
                                                Delete
                                            </Highlight>
                                        }
                                        {" "} selected item(s)?
                                    </Text>
                                    {buttonTimeout && setTimeout(() => {
                                        setButtonTimeout(false)
                                    }, 1500)}
                                    <Button isDisabled={buttonTimeout} size={"sm"} variant="solid" colorScheme={"green"} onClick={() => {
                                        if (stateConfirm === data.confirmation) data.handleConfirmation();
                                        else data.handleDelete();
                                        setRequestData([]);
                                        setCheckBoxData([]);
                                    }}
                                    >
                                        Yes
                                    </Button>
                                    <Button size={"sm"} variant="solid" colorScheme={"red"} onClick={() => {
                                        setStateConfirm('');
                                        setButtonTimeout(true);
                                    }}>
                                        No
                                    </Button>
                                </HStack>
                            }
                        </ModalFooter>
                    </>
                    :
                    <>
                        <ModalBody alignSelf={"center"}>
                            <Image src={EmptyList} alt={"empty"}/>
                        </ModalBody>
                        <ModalFooter alignSelf={"center"}>
                            <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                No items in here..
                            </Text>
                        </ModalFooter>
                    </>
                }
            </ModalContent>
        </Modal>
    );
}

export default SparePartCart;