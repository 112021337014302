import React, {useEffect, useState} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import {CheckBoxesProvider} from "../../components/CheckBoxes";
import SparePartSubHeader from "../ReOrderPoint/SubHeader";
import SparePartMenus from "./ActionMenu";
import SparePartDropDowns from "./DropDowns";
import SparePartTables from "./Tables";
import {Container, useDisclosure} from "@chakra-ui/react";
import Resume from "./Resume/Resume";
import Modal from "../../components/Modal/AreYouSure";
import {useNavigate} from "react-router-dom";

const ReOrderPoint = () => {
    const navigate = useNavigate();

    const [searchItems, setSearchItems] = useState({});
    const [isReset, setIsReset] = useState(false);
    const [resumeState, setResumeState] = useState(false);
    const [submitResume, setSubmitResume] = useState(false);
    const [navigateBack, setNavigateBack] = useState(false);

    const {
        isOpen: isOpenRequestModal,
        onOpen: onOpenRequestModal,
        onClose: onCloseRequestModal
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    useEffect(() => {
        if (navigateBack) navigate("/reOrderPoint/order");
    }, [navigateBack])

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingTop={"2vh"}>
                <CheckBoxesProvider>
                    <Modal
                        isOpen={isOpenRequestModal}
                        onOpen={onOpenRequestModal}
                        onClose={onCloseRequestModal}
                        setConfirmSentResetPassword={setSubmitResume}
                        type={"Request"}
                    />

                    <Modal
                        isOpen={isOpenSuccessModal}
                        onOpen={onOpenSuccessModal}
                        onClose={onCloseSuccessModal}
                        setRedirect={setNavigateBack}
                        type={"Success"}
                    />

                    <SparePartSubHeader
                        setResumeState={setResumeState}
                    />
                    {!resumeState ?
                        <>
                            <SparePartMenus
                                setResumeState={setResumeState}
                            />
                            <SparePartDropDowns
                                setSearchItems={setSearchItems}
                                setIsReset={setIsReset}
                            />
                            <SparePartTables
                                searchItems={searchItems}
                                setSearchItems={setSearchItems}
                                isReset={isReset}
                                setIsReset={setIsReset}
                            />
                        </>
                        :
                        <Resume
                            setResumeState={setResumeState}
                            submitResume={submitResume}
                            setSubmitResume={setSubmitResume}
                            onOpenRequestModal={onOpenRequestModal}
                            onOpenSuccessModal={onOpenSuccessModal}
                        />
                    }
                </CheckBoxesProvider>
            </Container>
            <Footer />
        </>
    );
}

export default ReOrderPoint;
