import React from "react";
import {useNavigate} from "react-router-dom";

import {
    Button,
    Flex,
    Stack,
    Image,
    Text,
    HStack,
} from '@chakra-ui/react';

import HeaderIcon from "../../../assets/icons/hero/HomePage/Hero_history.svg";
import Cookies from "js-cookie";

const HeaderHistory = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <Flex>
                <Stack
                    spacing={1}
                    w={'full'}
                    pt={10}
                    pb={3}
                    px={5}
                >
                    <Flex cursor={"pointer"} onClick={() => navigate("/history/list")} mb={"2vh"}>
                        <Image boxSize={"60px"} src={HeaderIcon}/>
                        <Text paddingTop={"2"} paddingLeft={"5"} fontSize={"3xl"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} _hover={{color : process.env.REACT_APP_CHECKBOX_COLOR}}>History</Text>
                    </Flex>

                    <HStack pt={"2"} direction={['column', 'row']}>
                        <Button
                            colorScheme={props.props.page === "request" ? "teal" : "blackAlpha"}
                            w="full"
                            onClick={() => navigate("/history/listRequest")}
                        >
                            Request
                        </Button>
                        <Button
                            colorScheme={props.props.page === "outgoing" ? "teal" : "blackAlpha"}
                            w="full"
                            onClick={() => navigate("/history/listOutgoing")}

                        >
                            Outgoing
                        </Button>
                        {Cookies.get("role") !== "Teknisi" &&
                            <Button
                                colorScheme={props.props.page === "incoming" ? "teal" : "blackAlpha"}
                                w="full"
                                onClick={() => navigate("/history/listIncoming")}
                            >
                                Incoming
                            </Button>
                        }

                        {Cookies.get("role") !== "Teknisi" &&
                            <Button
                                colorScheme={props.props.page === "opname" ? "teal" : "blackAlpha"}
                                w="full"
                                onClick={() => navigate("/history/listOpname")}
                            >
                                Opname
                            </Button>
                        }
                    </HStack>
                </Stack>
            </Flex>
        </>
    );
}

export default HeaderHistory;
