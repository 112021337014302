import React, {Fragment, useContext, useState, useEffect} from "react";
import {useMediaQuery} from "react-responsive";

import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Image,
    Text,
    Center,
    Flex,
    Box,
    HStack,
    useDisclosure,
    TableCaption,
} from "@chakra-ui/react";
import {ViewIcon} from "@chakra-ui/icons";

import Axios from "../../services/api/Axios";
import Cookies from "js-cookie";

import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/Loader/Loader";
import { HistoryContext } from "./HistoryContext";

import Modal from "../../components/Modal/AreYouSure";
import SparePartPopUp from "../../components/Modal/SparePartPopUp";

import SortIcon from "../../assets/icons/table/TableSort.svg";
import TableEditIcon from "../../assets/icons/table/TableEdit.svg";
import TablePrintIcon from "../../assets/img/print.svg";
import EmptyList from "../../assets/img/empty.svg";
import ModalTextInput from "../../components/Modal/TextInput";
import GeneratePDF from "./Resume/GeneratePDF";

const Tables = (props) => {
    const isResolutionHigh = useMediaQuery({ query: "(min-width: 1920px)" })

    const [modalData, setModalData] = useState({});
    const [confirmAction, setConfirmAction] = useState('');
    const [dataSpareParts,setDataSpareParts] = useState([]);
    const [sortState, setSortState] = useState(false);

    const [selectedData, setSelectedData] = useState({});
    const [generatePDF, setGeneratePDF] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const [isGetMore, setIsGetMore] = useState(false);
    const [isHasMore, setIsHasMore] = useState(true);
    const [getOffset, setGetOffset] = useState(25);

    const history = {
        dataRequestURL : "/request/list?count=1&sediaan_id=" + Cookies.get("idServing") + "&order=id,desc&offset=",
        listReOrderURL : "/reorder/list?status=CLOSE&sediaan_id=" + Cookies.get("idServing") + "&offset=",
        dataOpnameURL : "/opname/list?count=1&status=CLOSE&sediaan_id=" + Cookies.get("idServing") + "&order=updatedAt,desc&offset=",
        postReturnURL : "/outgoing/return?id=",
        accessToken : Cookies.get("accessToken"),
        role : Cookies.get("role"),
    }

    let historyContext = useContext(HistoryContext)

    const columnNames = (props.props.page === "request" ?
        {
            "createdAt" : "Timestamp",
            "part_code" : "Item Code",
            "part_name" : "Item Name",
            "brand" : "Brand",
            "type" : "Type",
            "part_category" : "Item Category",
            "line" : "Plant",
            "machine" : "Department",
            "case_no" : "Case Number",
            "user" : "User",
            "qty_request" : "Request Quantity",
            "price" : "Item Price",
            "total_price" : "Total Price"
        }
        :
        (props.props.page === "outgoing" ?
                {
                    "createdAt" : "Timestamp",
                    "part_code" : "Item Code",
                    "part_name" : "Item Name",
                    "brand" : "Brand",
                    "type" : "Type",
                    "part_category" : "Item Category",
                    "line" : "Plant",
                    "machine" : "Department",
                    "case_no" : "Case Number",
                    "user" : "User",
                    "qty_request" : "Request Quantity",
                    "qty_receive" : "Received Quantity",
                    "price" : "Item Price",
                    "total_price" : "Total Price"
                }
                :
                (props.props.page === "incoming" ?
                        {
                            "createdAt" : "Timestamp",
                            "part_code" : "Item Code",
                            "part_name" : "Item Name",
                            "brand" : "Brand",
                            "type" : "Type",
                            "part_category" : "Item Category",
                            "main_supplier" : "Supplier",
                            "bin_location" : "Bin Loct",
                            "case_no" : "Case Number",
                            "user" : "Person in Charge",
                            "qty_incoming" : "Incoming Quantity",
                            "price" : "Item Price",
                            "total_price" : "Total Price"
                        }
                        :
                        {
                            "createdAt" : "Timestamp",
                            "case_no" : "Case Number",
                            "user" : "Person in Charge",
                            "approver" : "Approved by",
                        }
                )
        ))

    const handleDataSpareParts = async (event, isFirst) => {
        switch (event){
            case "request":
                try{
                    let getDataRequests = await Axios.get(history.dataRequestURL + (isFirst ? 0 : getOffset),
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    let listAllRequest = [];

                    if (getDataRequests?.data?.data?.requests?.length < 25) setIsHasMore(false);

                    (getDataRequests?.data?.data?.requests).map((elem) => {
                        (elem.requestSpareparts).map((value) => {
                            let tempObjectData = {};

                            tempObjectData["id"] = value.id
                            tempObjectData["createdAt"] = value.createdAt
                            tempObjectData["part_code"] = value.sparepart?.part_code
                            tempObjectData["part_name"] = value.sparepart?.part_name
                            tempObjectData["brand"] = value.sparepart?.brand
                            tempObjectData["type"] = value.sparepart?.type
                            tempObjectData["part_category"] = value.sparepart?.part_category
                            tempObjectData["line"] = value?.machine?.line?.name
                            tempObjectData["machine"] = value?.machine?.code
                            tempObjectData["case_no"] = elem.meta?.request_number
                            tempObjectData["user"] = elem.user?.name
                            tempObjectData["qty_request"] = value.qty_request
                            tempObjectData["price"] = value.sparepart?.price
                            tempObjectData["total_price"] = (Number(value.sparepart?.price) * Number(value.qty_request))

                            listAllRequest.push(tempObjectData);
                        })
                    })
                    if (isFirst) {
                        setDataSpareParts(listAllRequest);
                        historyContext.setData(listAllRequest);
                    }
                    else {
                        setDataSpareParts(dataSpareParts.concat(listAllRequest));
                        historyContext.setData(dataSpareParts.concat(listAllRequest));
                    }
                    if (!isFirst) setGetOffset(getOffset => getOffset + 25);
                    setIsGetMore(false);
                }
                catch(error){
                    console.error(error);
                }

                break;

            case "outgoing":
                try{
                    let getDataOutgoings = await Axios.get(history.dataRequestURL + (isFirst ? 0 : getOffset),
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    let listAllRequest = [];

                    if (getDataOutgoings?.data?.data?.requests?.length < 25) setIsHasMore(false);

                    (getDataOutgoings?.data?.data?.requests).map((elem) => {
                        (elem.requestSpareparts).map((value) => {
                            if (value.qty_receive !== null) {
                                let tempObjectData = {};

                                tempObjectData["id"] = value.id
                                tempObjectData["createdAt"] = value.updatedAt
                                tempObjectData["part_code"] = value.sparepart?.part_code
                                tempObjectData["part_name"] = value.sparepart?.part_name
                                tempObjectData["brand"] = value.sparepart?.brand
                                tempObjectData["type"] = value.sparepart?.type
                                tempObjectData["part_category"] = value.sparepart?.part_category
                                tempObjectData["line"] = value?.machine?.line?.name
                                tempObjectData["machine"] = value?.machine?.code
                                tempObjectData["case_no"] = elem.meta?.request_number
                                tempObjectData["user"] = elem.user?.name
                                tempObjectData["qty_request"] = Number(value.qty_request)
                                tempObjectData["qty_receive"] = Number(value.is_returned ? (value.qty_receive - value.qty_return) : value.qty_receive)
                                tempObjectData["qty_return"] = Number(value.qty_return)
                                tempObjectData["is_returned"] = value.is_returned
                                tempObjectData["price"] = value.sparepart?.price
                                tempObjectData["total_price"] = (Number(value.sparepart?.price) * Number(value.is_returned ? (value.qty_receive - value.qty_return) : value.qty_receive))

                                listAllRequest.push(tempObjectData);
                            }

                            return 0
                        })

                        return 0
                    })

                    if (isFirst) {
                        setDataSpareParts(listAllRequest);
                        historyContext.setData(listAllRequest);
                    }
                    else {
                        setDataSpareParts(dataSpareParts.concat(listAllRequest));
                        historyContext.setData(dataSpareParts.concat(listAllRequest));
                    }
                    if (!isFirst) setGetOffset(getOffset => getOffset + 25);
                    setIsGetMore(false);
                }
                catch(error){
                    console.error(error);
                }

                break;

            case "incoming":
                try{
                    let getDataIncomings = await Axios.get(history.listReOrderURL + (isFirst ? 0 : getOffset),
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    let listAllRequest = [];

                    if (getDataIncomings?.data?.data?.reorders?.length < 25) setIsHasMore(false);

                    (getDataIncomings?.data?.data?.reorders).map((elem) => {
                        (elem.reorderSpareparts).map((value) => {
                            let tempObjectData = {};

                            tempObjectData["id"] = value.id
                            tempObjectData["createdAt"] = value.createdAt
                            tempObjectData["part_code"] = value.sparepart?.part_code
                            tempObjectData["part_name"] = value.sparepart?.part_name
                            tempObjectData["brand"] = value.sparepart?.brand
                            tempObjectData["type"] = value.sparepart?.type
                            tempObjectData["part_category"] = value.sparepart?.part_category
                            tempObjectData["main_supplier"] = value.sparepart?.main_supplier
                            tempObjectData["case_no"] = elem.case_number
                            tempObjectData["user"] = elem.user?.name
                            tempObjectData["qty_incoming"] = value.qty_incoming
                            tempObjectData["bin_location"] = value.sparepart?.bin_location
                            tempObjectData["price"] = value.sparepart?.price
                            tempObjectData["total_price"] = (Number(value.sparepart?.price) * Number(value.qty_incoming))

                            listAllRequest.push(tempObjectData);
                        })
                    })

                    if (isFirst) {
                        setDataSpareParts(listAllRequest);
                        historyContext.setData(listAllRequest);
                    }
                    else {
                        setDataSpareParts(dataSpareParts.concat(listAllRequest));
                        historyContext.setData(dataSpareParts.concat(listAllRequest));
                    }
                    if (!isFirst) setGetOffset(getOffset => getOffset + 25);
                    setIsGetMore(false);
                }
                catch(error){
                    console.error(error);
                }

                break;

            case "opname":
                try{
                    let getDataOpnames = await Axios.get(history.dataOpnameURL + (isFirst ? 0 : getOffset),
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    let listAllRequest = [];

                    if (getDataOpnames?.data?.data?.opnames?.length < 25) setIsHasMore(false);

                    (getDataOpnames?.data?.data?.opnames).map((elem) => {
                        let tempObjectData = {};

                        tempObjectData["id"] = elem.id
                        tempObjectData["createdAt"] = elem.updatedAt
                        tempObjectData["case_no"] = elem.meta?.opname_number
                        tempObjectData["user"] = elem.user?.name
                        tempObjectData["approver"] = elem.approver?.name
                        tempObjectData["opnameSpareparts"] = elem.opnameSpareparts

                        listAllRequest.push(tempObjectData);

                        return 0
                    })

                    if (isFirst) {
                        setDataSpareParts(listAllRequest);
                        historyContext.setData(listAllRequest);
                    }
                    else {
                        setDataSpareParts(dataSpareParts.concat(listAllRequest));
                        historyContext.setData(dataSpareParts.concat(listAllRequest));
                    }
                    if (!isFirst) setGetOffset(getOffset => getOffset + 25);
                    setIsGetMore(false);
                }
                catch(error){
                    console.error(error);
                }
                break;

            case "return":
                try{
                    let postDataReturn = await Axios.post(history.postReturnURL + modalData.id,
                        {qty_return: (modalData.qty_confirmedReturn ? modalData.qty_confirmedReturn : 1)},
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });
                    console.log(postDataReturn);
                    setModalData({});
                    onOpenSuccessModal();
                }
                catch(error){
                    console.error(error);
                }
                break;

            default:
                break;
        }
    };

    const handleSortDataSpareParts = async (columnName, isAsc) => {
        let newDataSpareParts = Array.from(dataSpareParts);

        if (columnName === "total_price") {
            columnName = "price";

            newDataSpareParts.sort((a, b) => {
                if (a[columnName] && b[columnName]) {
                    if (isAsc) return (a[columnName] * (props.props.page === "request" ? a.qty_request : (props.props.page === "outgoing" ? a.qty_receive : a.qty_incoming))) - (b[columnName] * (props.props.page === "request" ? b.qty_request : (props.props.page === "outgoing" ? b.qty_receive : b.qty_incoming)));
                    else return (b[columnName] * (props.props.page === "request" ? b.qty_request : (props.props.page === "outgoing" ? b.qty_receive : b.qty_incoming))) - (a[columnName] * (props.props.page === "request" ? a.qty_request : (props.props.page === "outgoing" ? a.qty_receive : a.qty_incoming)));
                }
                return 0
            });
        }
        else {
            newDataSpareParts.sort((a, b) => {
                if (a[columnName] && b[columnName]) {
                    if (isNaN(a[columnName]) || isNaN(b[columnName])) {
                        let fa = a[columnName].toLowerCase(),
                            fb = b[columnName].toLowerCase();

                        if (fa < fb) {
                            if (isAsc) return -1;
                            else return 1;
                        }
                        if (fa > fb) {
                            if (isAsc) return 1;
                            else return -1
                        }
                        return 0;
                    }
                    else {
                        if (isAsc) return a[columnName] - b[columnName];
                        else return b[columnName] - a[columnName];
                    }
                }
                return 0
            });
        }

        setDataSpareParts(newDataSpareParts);
        historyContext.setData(newDataSpareParts);
    };

    function doFilter(){
        let newData = []
        let date1 = historyContext.date1? historyContext.date1 : Date.now();
        let date2 = historyContext.date2? historyContext.date2 : Date.now();

        for (let i = 0; i < dataSpareParts.length; i++){
            let cond1 = new Date(dataSpareParts[i].createdAt) > new Date(date1)
            let cond2 = new Date(dataSpareParts[i].createdAt) < new Date(date2)
            if (cond1 && cond2){
                newData.push(dataSpareParts[i])
            }
        }
        setDataSpareParts(newData)
        historyContext.setData(newData)
        setGetOffset(25);
    }

    useEffect(() => {
        historyContext.setColumnNames(columnNames)
    }, [props.props.page])

    useEffect(() => {
        if (props.doFilter){
            doFilter()
        }
        else {
            if (props.props.page === "request") handleDataSpareParts("request", true).then();
            else if (props.props.page === "outgoing") handleDataSpareParts("outgoing", true).then();
            else if (props.props.page === "incoming") handleDataSpareParts("incoming", true).then();
            else if (props.props.page === "opname") handleDataSpareParts("opname", true).then();
        }
    }, [props.doFilter])

    useEffect(() => {
        if (props.props.page === "request") handleDataSpareParts("request", true).then();
        else if (props.props.page === "outgoing") handleDataSpareParts("outgoing", true).then();
        else if (props.props.page === "incoming") handleDataSpareParts("incoming", true).then();
        else if (props.props.page === "opname") handleDataSpareParts("opname", true).then();

        if (confirmAction !== '') handleDataSpareParts("return").then(() => setConfirmAction(''));
    },[confirmAction]);

    useEffect(() => {
        if (isGetMore) {
            if (props.props.page === "request") handleDataSpareParts("request", false).then();
            else if (props.props.page === "outgoing") handleDataSpareParts("outgoing", false).then();
            else if (props.props.page === "incoming") handleDataSpareParts("incoming", false).then();
            else if (props.props.page === "opname") handleDataSpareParts("opname", false).then();
        }
    }, [isGetMore]);

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenSparePartPopUp,
        onOpen: onOpenSparePartPopUp,
        onClose: onCloseSparePartPopUp
    } = useDisclosure()

    const {
        isOpen: isOpenTextModal,
        onOpen: onOpenTextModal,
        onClose: onCloseTextModal
    } = useDisclosure()

    const {
        isOpen: isOpenErrorModal,
        onOpen: onOpenErrorModal,
        onClose: onCloseErrorModal
    } = useDisclosure()

    return (
        <Flex paddingTop={5}>
            <SparePartPopUp
                isOpen={isOpenSparePartPopUp}
                onOpen={onOpenSparePartPopUp}
                onClose={onCloseSparePartPopUp}
                setConfirmAction={setConfirmAction}
                modalData={modalData}
                setModalData={setModalData}
                type={"Return"}
            />

            <Modal
                isOpen={isOpenSuccessModal}
                onOpen={onOpenSuccessModal}
                onClose={onCloseSuccessModal}
                type={"Success"}
            />

            <ModalTextInput
                isOpen={isOpenTextModal}
                onOpen={onOpenTextModal}
                onClose={onCloseTextModal}
                data={selectedData}
                type={"History"}
            />

            <Modal
                isOpen={isOpenErrorModal}
                onOpen={onOpenErrorModal}
                onClose={onCloseErrorModal}
                errorMessage={errorMessage}
                type={"Error"}
            />

            {(dataSpareParts)?.length === 0 ?
                <Center w={"full"}>
                    <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                    <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                        No items in here..
                    </Text>
                </Center>
                :
                <Box w={"full"}>
                    <InfiniteScroll scrollableTarget={"tableSparePartReOrder"} dataLength={dataSpareParts.length} next={() => setIsGetMore(true)} hasMore={isHasMore && !props.doFilter} loader={''}>
                        <TableContainer id={"tableSparePartReOrder"} overflowY={"scroll"} whiteSpace={"normal"} display={"flex"} maxHeight={isResolutionHigh ? "68vh" : "62vh"} border={"1px solid #B7D4CF"}>
                            <Table variant="simple">
                                <TableCaption>
                                    {isHasMore && !props.doFilter ?
                                      <Loader/>
                                      :
                                      <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>End of Line (No more data)</Text>
                                    }
                                </TableCaption>
                                <Thead zIndex={1} pos='sticky' top={0} bg={process.env.REACT_APP_TABLE_COLOR}>
                                    <Tr>
                                        <Th>
                                            <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>No</Text>
                                        </Th>
                                        {(props.props.page === "outgoing" || props.props.page === "opname") &&
                                            <Th>
                                                <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{props.props.page === "outgoing" ? "Return" : "Action"}</Text>
                                            </Th>
                                        }
                                        {Object.keys(columnNames).map((elem) => (
                                            <Fragment key={columnNames[elem]}>
                                                <Th>
                                                    <Center>
                                                        <Image className="cursor-pointer" src={SortIcon} alt="" onClick={() => {
                                                            handleSortDataSpareParts(elem, sortState).then(() => setSortState(!sortState));
                                                        }}
                                                        />
                                                        <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnNames[elem]}</Text>
                                                    </Center>
                                                </Th>
                                            </Fragment>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {dataSpareParts.map((dataSparePart, index) => (
                                        <Tr key={dataSparePart.id}>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {index + 1}
                                            </Td>
                                            {props.props.page === "outgoing" &&
                                                <Td>
                                                    <Center>
                                                        <Image src={TableEditIcon} className="cursor-pointer" alt=""
                                                               onClick={() => {
                                                                   if (!dataSparePart.is_returned) {
                                                                       if (dataSparePart.qty_return > 0) {
                                                                           setErrorMessage(" Item is still processed to be returned!");
                                                                           onOpenErrorModal();
                                                                       }
                                                                       else {
                                                                           setModalData(dataSparePart);
                                                                           onOpenSparePartPopUp();
                                                                       }
                                                                   }
                                                                   else {
                                                                       setErrorMessage(" Item had already been returned!");
                                                                       onOpenErrorModal();
                                                                   }
                                                               }}
                                                        />
                                                    </Center>
                                                </Td>
                                            }
                                            {props.props.page !== "opname" ?
                                                <Fragment>
                                                    <Td>
                                                        {(new Date(dataSparePart.createdAt)).toString().slice(0, 34)}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {dataSparePart.part_code}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {dataSparePart.part_name}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {dataSparePart.brand}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {dataSparePart.type}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {dataSparePart.part_category}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {props.props.page === "incoming" ? dataSparePart.main_supplier : dataSparePart.line}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {props.props.page === "incoming" ? dataSparePart.bin_location : dataSparePart.machine}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {dataSparePart.case_no}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {dataSparePart.user}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {props.props.page === "incoming" ? dataSparePart.qty_incoming : dataSparePart.qty_request}
                                                    </Td>
                                                    {props.props.page === "outgoing" &&
                                                        <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                            {dataSparePart.qty_receive}
                                                        </Td>
                                                    }
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        Rp {dataSparePart.price},00
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        Rp {dataSparePart.total_price},00
                                                    </Td>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <Td>
                                                        <Center>
                                                            <HStack spacing={5}>
                                                                {!generatePDF.includes(dataSparePart.id) ?
                                                                    <Image src={TablePrintIcon} className="cursor-pointer" alt=""
                                                                           onClick={() => {
                                                                               let tempGeneratePDFArray = Array.from(generatePDF);

                                                                               tempGeneratePDFArray.push(dataSparePart.id);
                                                                               setGeneratePDF(tempGeneratePDFArray);
                                                                           }}
                                                                    />
                                                                    :
                                                                    <GeneratePDF dataOrder={dataSparePart} stockOpnameIdentifier={dataSparePart.case_no}/>
                                                                }
                                                                <ViewIcon color={"green"} className="cursor-pointer" alt=""
                                                                          onClick={() => {
                                                                              setSelectedData(dataSparePart);
                                                                              onOpenTextModal();
                                                                          }}
                                                                />
                                                            </HStack>
                                                        </Center>
                                                    </Td>
                                                    <Td textAlign={"center"}>
                                                        {(new Date(dataSparePart.createdAt)).toString().slice(0, 34)}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {dataSparePart.case_no}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {dataSparePart.user}
                                                    </Td>
                                                    <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                        {dataSparePart.approver}
                                                    </Td>
                                                </Fragment>
                                            }
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </InfiniteScroll>
                </Box>
            }
        </Flex>
    );
}

export default Tables;