import DashboardTableMain from "./TableMain";
import React, {useEffect, useState} from "react";

import {Container, Grid, GridItem, Select, Text, Center, Input, Button, ButtonGroup} from "@chakra-ui/react";
import Cookies from "js-cookie";
import Axios from "../../services/api/Axios";

const DashboardTableContainer = () => {
    const [dataFilter, setDataFilter] = useState({});

    const [dataInitialization, setDataInitialization] = useState(false);
    const [dataLines,setDataLines] = useState([]);
    const [dataMachines,setDataMachines] = useState([]);
    const [confirmAction, setConfirmAction] = useState('');

    let history = {
        getDataLinesURL : "/line/list?order=name&limit=9999&count=1&sediaan_id=",
        getDataMachinesURL : "/machine/list?limit=9999&order=code&line_id=",
        servingURL : "/sediaan/list",
        accessToken : Cookies.get("accessToken"),
        idServing : Cookies.get("idServing"),
        serving : Cookies.get("serving"),
    }

    const handleDataFilter = async (target, value) => {
        let tempDataFilter = JSON.parse(JSON.stringify(dataFilter));
        tempDataFilter[target] = value

        if (target === "line_id") handleGetDataMachines(value).then(() => tempDataFilter["machine_id"] = '');

        setDataFilter(tempDataFilter);
    }

    const handleGetDataLines = async (id) => {
        try{
            let getDataLines = await Axios.get(history.getDataLinesURL + id,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            setDataLines(getDataLines.data?.data?.lines);
            setDataMachines([]);
        }
        catch(error){
            console.error(error);
        }
    }

    const handleGetDataMachines = async (id) => {
        try{
            let getDataMachines = await Axios.get(history.getDataMachinesURL + id,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            setDataMachines(getDataMachines.data?.data?.machines);
        }
        catch(error){
            console.error(error);
        }
    }

    useEffect(() => {
        if (!dataInitialization) handleGetDataLines(history.idServing).then(() => setDataInitialization(true));
    }, [])

    return (
        <Container boxShadow="xl" maxW={"full"} className="m-4 rounded-lg pt-[4vh] pb-[4vh]">
            <Text className="font-bold text-xl text-center p-2 whitespace-nowrap"  textColor={process.env.REACT_APP_TEXT_COLOR_LIGHT}>Item Usage per Department</Text>

            <Grid className="" templateColumns='repeat(6, 1fr)' gap={3} mb={5}>
                <GridItem w='23vw' className="rounded-xl">
                    <Center className="font-semibold text-lg mt-5 mb-2 text-center">Start Date</Center>
                    <Input
                        textAlign={"center"}
                        size='md'
                        borderColor={process.env.REACT_APP_ENV_COLOR}
                        bg="white"
                        color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                        fontWeight={"semibold"}
                        placeholder="Select Date and Time"
                        type="date"
                        onChange={(e) => handleDataFilter("date_start", e.target.value)}
                        />
                </GridItem>
                <GridItem w='23vw' className="rounded-xl">
                    <Center className="font-semibold text-lg mt-5 mb-2 text-center">End Date</Center>
                    <Input
                        textAlign={"center"}
                        size='md'
                        borderColor={process.env.REACT_APP_ENV_COLOR}
                        bg="white"
                        color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                        fontWeight={"semibold"}
                        placeholder="Select Date and Time"
                        type="date"
                        onChange={(e) => handleDataFilter("date_end", e.target.value)}
                    />
                </GridItem>
                <GridItem w='23vw' className="rounded-xl">
                    <Center className="font-semibold text-lg mt-5 mb-2 text-center">Plant</Center>

                    <Select
                        textAlign={"center"}
                        size='md'
                        borderColor={process.env.REACT_APP_ENV_COLOR}
                        bg="white"
                        color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                        fontWeight={"semibold"}
                        defaultValue={''}
                        onChange={(e) => handleDataFilter("line_id", Number(e.target.value))}
                    >
                        <option disabled value={''}>Please select an option</option>
                        {dataLines.map((line) => (
                            <option key={line?.id} value={line?.id}>{line.name}</option>
                        ))}
                    </Select>
                </GridItem>
                <GridItem w='23vw' className="rounded-xl">
                    <Text className="font-semibold text-lg mt-5 mb-2 text-center">Department</Text>
                    <Select
                        textAlign={"center"}
                        size='md'
                        borderColor={process.env.REACT_APP_ENV_COLOR}
                        bg="white"
                        color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                        fontWeight={"semibold"}
                        defaultValue={''}
                        onChange={(e) => handleDataFilter("machine_id", Number(e.target.value))}
                        isDisabled={!dataFilter["line_id"]}
                    >
                        <option value={''}>Please select an option</option>
                        {dataMachines.map((machine) => (
                            <option key={machine?.id} value={machine?.id}>{machine.code}</option>
                        ))}
                    </Select>
                </GridItem>
            </Grid>

            <Center>
                <ButtonGroup mb={5}>
                    <Button
                        variant='solid'
                        colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                        position={"initial"}
                        onClick={() => setConfirmAction('search')}
                    >
                        Search
                    </Button>
                    <Button
                        variant='solid'
                        colorScheme='teal'
                        position={"initial"}
                        onClick={() => setConfirmAction('reset')}
                    >
                        Reset
                    </Button>
                </ButtonGroup>
            </Center>

            <DashboardTableMain dataFilter={dataFilter} confirmAction={confirmAction} setConfirmAction={setConfirmAction}/>
        </Container>
    );
}
 
export default DashboardTableContainer;