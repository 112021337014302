import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { useMediaQuery } from "react-responsive"

import {
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Image, MenuDivider, Text, VStack, HStack, Badge,
} from "@chakra-ui/react";

import Axios from "../../../services/api/Axios";
import Cookies from "js-cookie";

import BoxIcon from "../../../assets/icons/navbar/Box.svg";

const NavbarBox = (props) => {
    const history = {
        accessToken : Cookies.get("accessToken"),
        role : Cookies.get("role"),
        reOrderPointURL : "/sparepart/list?reorder=1&order=id,desc&sediaan_id=" + Cookies.get("idServing") + "&is_active=true&limit=3&count=1",
        navigate : useNavigate(),
    }

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" })

    const [dataInitialization,setDataInitialization] = useState(false);

    const [reOrderPointItems, setReOrderPointItems] = useState([]);
    const [reOrderPointCount, setReOrderPointCount] = useState(0);

    const handleReOrderPoint = async () => {
        if (history.role !== "Teknisi") {
            try{
                let getNotifications = await Axios.get(history.reOrderPointURL,
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                setReOrderPointCount(getNotifications.data?.data?.count);
                setReOrderPointItems(getNotifications.data?.data?.spareparts);
            }
            catch(error){
                setReOrderPointItems([]);
            }
        }
    }

    useEffect(() => {
        if (props.props.changeServing) handleReOrderPoint().then(() => props.props.setChangeServing(false));
        if (!dataInitialization) handleReOrderPoint().then(() => setDataInitialization(true));
    }, [props.props.changeServing])

    return (
        <Menu autoSelect={false}>
            <MenuButton
                as={Button}
                className={"mx-4"}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
            >
                <HStack>
                    <Badge visibility={reOrderPointCount === 0 ? "hidden" : "visible"} borderRadius='md' bg={"red.500"} color={"white"}>{reOrderPointCount}</Badge>
                    <Image src={BoxIcon} alt={"box"} />
                </HStack>
            </MenuButton>
            <MenuList maxW={isTabletOrMobile ? "40px" : ''}>
                <MenuItem isDisabled={true} _disabled={{
                    color: 'rgb(50, 50, 50)'
                }}>
                    {reOrderPointCount !== 0
                        ?
                        <>
                            <HStack spacing={1}>
                                <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                    You have
                                </Text>
                                <Text fontSize={"lg"} fontWeight={"bold"} color={"red"}>
                                    {reOrderPointCount}
                                </Text>
                                <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                    item{reOrderPointCount > 1 ? "s" : ''} reaching re-order point!
                                </Text>
                            </HStack>

                            <MenuDivider borderColor={process.env.REACT_APP_TEXT_COLOR_BOLD} borderBottom={"2px double"}/>
                        </>
                        :
                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                            You don't have any item reaching re-order point yet!
                        </Text>
                    }
                </MenuItem>

                {reOrderPointItems?.map((reOrderPointItem, index) => (
                    <Fragment key={reOrderPointItem.id}>
                        <MenuItem onClick={() => history.navigate("/reOrderPoint/list")}>
                            <VStack alignItems={"start"}>
                                <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{reOrderPointItem.part_code}</Text>

                                <HStack>
                                    <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{reOrderPointItem.part_name} |</Text>
                                    <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{reOrderPointItem.type} |</Text>
                                    <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{reOrderPointItem.brand}</Text>
                                </HStack>
                                <HStack spacing={1}>
                                    <Text fontSize={"md"} fontWeight={"bold"} color={"red"}>{reOrderPointItem.qty} {reOrderPointItem.qty_uom}</Text>
                                    <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>left</Text>
                                </HStack>
                            </VStack>
                        </MenuItem>

                        <MenuDivider borderColor={process.env.REACT_APP_TEXT_COLOR_BOLD} borderBottom={index !== reOrderPointItems.length -1 ? '1px solid' : "2px double"}/>
                    </Fragment>
                ))}
                {reOrderPointCount !== 0
                    ?
                    <MenuItem onClick={() => history.navigate("/reOrderPoint/list")}>
                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                            View all
                        </Text>
                    </MenuItem>
                    :
                    ''
                }
            </MenuList>
        </Menu>
    );
}

export default NavbarBox;