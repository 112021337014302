import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import './App.css';

import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Profile from "./pages/Home/Profile/Profile";
import ResetPassword from "./pages/Login/ResetPassword";

import SparePart from "./pages/SparePart/SparePart";
import SparePartAdd from "./pages/SparePart/Add";
import SparePartEdit from "./pages/SparePart/Edit";
import SparePartOpname from "./pages/SparePart/StockOpname";
import SparePartImport from "./pages/SparePart/SparePartList/SparePartImport";
import SparePartExport from "./pages/SparePart/SparePartList/SparePartExport";

import Request from "./pages/Request/Request";
import RequestNow from "./pages/Request/RequestNow";

import Outgoing from "./pages/Outgoing/Outgoing";

import ReOrderPoint from "./pages/ReOrderPoint/ReOrderPoint";
import ProgressOrder from "./pages/ReOrderPoint/ProgressOrder";

import Incoming from "./pages/Incoming/Incoming";
import IncomingOrder from "./pages/Incoming/IncomingOrder";

import BinStorage from "./pages/BinStorage/BinStorage";

import History from "./pages/History/History";
import Restore from "./pages/History/Restore/Restore";

import UserManagement from "./pages/UserManagement/UserManagement";
import UserAdd from "./pages/UserManagement/Add"
import UserEdit from "./pages/UserManagement/Edit";

import AssetManagement from "./pages/AssetManagement/AssetManagement";
import AssetAdd from "./pages/AssetManagement/Add";
import AssetEdit from "./pages/AssetManagement/Edit";

import Dashboard from "./pages/Dashboard/Dashboard";

import Scanner from "./pages/Home/Scanner";

import Cookies from "js-cookie";

function App() {
    const history = {
        accessToken : Cookies.get("accessToken"),
        role : Cookies.get("role"),
        idServing : Cookies.get("idServing"),
    }

    return (
        <BrowserRouter>
            <Routes path="/">
                <Route path="home" element={<Home />} />

                <Route path="auth" >
                    <Route index element={<Navigate from='*' to="login" replace={true}/>} />
                    <Route path="login" element={<Login />} />
                    <Route path="resetPassword" element={<ResetPassword />} />
                    <Route path="*" element={<Navigate from='*' to="login" replace={true}/>} />
                </Route>

                <Route path="user" >
                    <Route index element={<Navigate from='*' to="profile" replace={true}/>} />
                    <Route path="profile" element={<Profile page={"profile"} key={"profile"}/>} />
                    <Route path="changePassword" element={<Profile page={"password"} key={"password"}/>} />
                    <Route path="*" element={<Navigate from='*' to="profile" replace={true}/>} />
                </Route>

                <Route path="request" >
                    <Route index element={<Navigate from='*' to="list" replace={true}/>} />
                    <Route path="list" element={<Request />} />
                    <Route path="requestNow" element={<RequestNow />} />
                    <Route path="*" element={<Navigate from='*' to="list" replace={true}/>} />
                </Route>

                <Route path="history" >
                    <Route index element={<Navigate from='*' to="listRequest" replace={true}/>} />
                    <Route path="listRequest" element={<History page={"request"} key={"request"}/>} />
                    <Route path="listOutgoing" element={<History page={"outgoing"} key={"outgoing"}/>} />
                    <Route path="listIncoming" element={<History page={"incoming"} key={"incoming"}/>} />
                    <Route path="listOpname" element={<History page={"opname"} key={"opname"}/>} />
                    <Route path="restore" element={<Restore />} />
                    <Route path="*" element={<Navigate from='*' to="listRequest" replace={true}/>} />
                </Route>

                <Route path="scan" >
                    <Route index element={<Navigate from='*' to="scanner" replace={true}/>} />
                    <Route path="scanner" element={<Scanner/>} />
                </Route>

                {history.role !== "Teknisi" &&
                    <>
                        <Route path="item" >
                            <Route index element={<Navigate from='*' to="list" replace={true}/>} />
                            <Route path="list" element={<SparePart page={"list"} key={"list"}/>} />
                            <Route path="archive" element={<SparePart page={"archive"} key={"archive"}/>} />
                            <Route path="opname" element={<SparePart page={"opname"} key={"opname"}/>} />
                            <Route path="stockOpname" element={<SparePartOpname />} />
                            <Route path="create" element={<SparePartAdd />} />
                            <Route path="edit" element={<SparePartEdit />} />
                            <Route path="import" element={<SparePartImport/>} />
                            <Route path="export" element={<SparePartExport/>} />
                            <Route path="*" element={<Navigate from='*' to="list" replace={true}/>} />
                        </Route>

                        <Route path="incoming" >
                            <Route index element={<Navigate from='*' to="list" replace={true}/>} />
                            <Route path="list" element={<Incoming />} />
                            <Route path="order" element={<IncomingOrder />} />
                            <Route path="*" element={<Navigate from='*' to="list" replace={true}/>} />
                        </Route>

                        <Route path="outgoing" >
                            <Route index element={<Navigate from='*' to="list" replace={true}/>} />
                            <Route path="list" element={<Outgoing />} />
                            <Route path="*" element={<Navigate from='*' to="list" replace={true}/>} />
                        </Route>

                        <Route path="reOrderPoint" >
                            <Route index element={<Navigate from='*' to="list" replace={true}/>} />
                            <Route path="list" element={<ReOrderPoint />} />
                            <Route path="order" element={<ProgressOrder />} />
                            <Route path="*" element={<Navigate from='*' to="list" replace={true}/>} />
                        </Route>

                        <Route path="binStorage" >
                            <Route index element={<Navigate from='*' to="list" replace={true}/>} />
                            <Route path="list" element={<BinStorage />} />
                            <Route path="*" element={<Navigate from='*' to="list" replace={true}/>} />
                        </Route>

                        <Route path="userManagement" >
                            <Route index element={<Navigate from='*' to="list" replace={true}/>} />
                            <Route path="list" element={<UserManagement />} />
                            <Route path="create" element={<UserAdd />} />
                            <Route path="edit" element={<UserEdit />} />
                            <Route path="*" element={<Navigate from='*' to="list" replace={true}/>} />
                        </Route>

                        <Route path="assetManagement" >
                            <Route index element={<Navigate from='*' to="list" replace={true}/>} />
                            <Route path="listDepartment" element={<AssetManagement page={"machine"} key={"machine"}/>} />
                            <Route path="createDepartment" element={<AssetAdd page={"machine"} key={"machine"}/>} />
                            <Route path="editDepartment" element={<AssetEdit page={"machine"} key={"machine"}/>} />
                            <Route path="listPlant" element={<AssetManagement page={"line"} key={"line"}/>} />
                            <Route path="createPlant" element={<AssetAdd page={"line"} key={"line"}/>} />
                            <Route path="editPlant" element={<AssetEdit page={"line"} key={"line"}/>} />
                            <Route path="*" element={<Navigate from='*' to="listDepartment" replace={true}/>} />
                        </Route>

                        <Route path="dashboard" >
                            <Route index element={<Navigate from='*' to="list" replace={true}/>} />
                            <Route path="list" element={<Dashboard />} />
                            <Route path="*" element={<Navigate from='*' to="list" replace={true}/>} />
                        </Route>
                    </>
                }

                <Route path="*" element={<Navigate from='*' to="home" replace={true}/>} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
