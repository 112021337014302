import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  useColorModeValue
} from '@chakra-ui/react';

import { useMediaQuery } from "react-responsive"

import FooterIcon from "../../assets/icons/footer/FooterWPM.svg";

const Footer = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" })

  return (
      <Box
          zIndex={99}
          height={"5vh"}
          position={"fixed"}
          left={'0'}
          right={'0'}
          bottom={'0'}
          bg={useColorModeValue(process.env.REACT_APP_ENV_COLOR, 'green.900')}>
        {!isTabletOrMobile ?
            <Container
                as={Stack}
                maxW={'8xl'}
                py={3}
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
            >
              <Image src={FooterIcon} alt={"footer logo"} />
              <Text className='text-white'>V2.4.1 2022-2023. Developed by IS Paragon Technology & Innovation</Text>
              <Stack direction={'row'} spacing={6}>
              </Stack>
            </Container>
            :
            <Stack direction={"row"} spacing={"24px"}>
              <Image paddingLeft={"15%"} src={FooterIcon} alt={"Footer Logo"} />
              <Text fontSize={"13px"} className='text-white'>V2.4.1 2022-2023. Developed by IS Paragon Technology & Innovation</Text>
            </Stack>
        }
      </Box>
  );
}

export default Footer;