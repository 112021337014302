import React, {Fragment} from "react";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Checkbox,
    Image,
    Text,
    Center,
    Flex,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Input,
    TableCaption,
    Box,
    useDisclosure,
} from "@chakra-ui/react";

import InfiniteScroll from "react-infinite-scroll-component";

import { CheckBoxesContext } from "../../components/CheckBoxes";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/AreYouSure";
import ModalQR from "../../components/Modal/SparePartQR";

import ArchiveIcon from "../../assets/icons/table/TableArchive.svg";
import RestoreIcon from "../../assets/icons/table/TableRestore.svg";
import DeleteIcon from "../../assets/icons/table/TableDelete.svg";
import TableEditIcon from "../../assets/icons/table/TableEdit.svg";
import TablePrintIcon from "../../assets/img/print.svg";
import SortIcon from "../../assets/icons/table/TableSort.svg";
import EmptyList from "../../assets/img/empty.svg";
import {useMediaQuery} from "react-responsive";

const Tables = (props) => {
    const navigate = useNavigate();
    const checkboxContext = useContext(CheckBoxesContext);
    const isResolutionHigh = useMediaQuery({ query: "(min-width: 1920px)" })

    const columnName = {
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "part_category" : "Item Category",
        "qty" : "Stock",
        "real_stock" : "Real Stock",
        "difference" : "Stock Diff",
        "qty_min" : "Min Stock",
        "qty_max" : "Max Stock",
        "bin_location" : "Bin Loct"
    }

    const [sparePartList, setSparePartList] = useState([]);
    const [confirmAction, setConfirmAction] = useState('');
    const [sortState, setSortState] = useState(false);
    const [modalData, setModalData] = useState({});

    const [checkBoxOpname, setCheckBoxOpname] = useState([]);

    const {
        isOpen: isOpenReportModalArchive,
        onOpen: onOpenReportModalArchive,
        onClose: onCloseReportModalArchive
    } = useDisclosure()

    const {
        isOpen: isOpenReportModalRestore,
        onOpen: onOpenReportModalRestore,
        onClose: onCloseReportModalRestore
    } = useDisclosure()

    const {
        isOpen: isOpenReportModalDelete,
        onOpen: onOpenReportModalDelete,
        onClose: onCloseReportModalDelete
    } = useDisclosure()

    const {
        isOpen: isOpenReportModalQR,
        onOpen: onOpenReportModalQR,
        onClose: onCloseReportModalQR
    } = useDisclosure()

    function handleRealStockChange(id, stock, difference){
        let tempStockDifference = JSON.parse(JSON.stringify(props.stockDifference));

        if (difference.length !== 0) tempStockDifference[id] = Number((Math.round((difference - stock) * 100)/100).toFixed(2));

        props.setStockDifference(tempStockDifference);
    }

    function handleCheckbox(sparePart){
        let tempArray = Array.from(checkboxContext.checkBoxState);
        let indexItem = tempArray.findIndex(item => item.id === sparePart.id);
        if (indexItem !== -1) {
            tempArray.splice(indexItem, 1);
        }
        else {
            let tempObject = {};
            tempObject["id"] = sparePart.id
            tempObject["part_code"] = sparePart.part_code
            tempObject["part_name"] = sparePart.part_name
            tempObject["type"] = sparePart.type
            tempObject["bin_location"] = sparePart.bin_location

            tempArray.push(tempObject);
        }

        checkboxContext.setCheckBoxState(tempArray);
    }

    function handleCheckboxOpname(id) {
        let tempCheckBox = Array.from(checkBoxOpname);
        let countOpname = props.dataOpnameSpareParts?.length

        if (tempCheckBox.includes(id)) {
            var index = tempCheckBox.indexOf(id);
            if (index !== -1) {
                tempCheckBox.splice(index, 1);
            }
        }
        else {
            tempCheckBox.push(id);
        }

        if (Number(countOpname) === Number(tempCheckBox.length)) props.setButtonOpnameDisabled(false);
        else props.setButtonOpnameDisabled(true);

        setCheckBoxOpname(tempCheckBox);
    }

    useEffect(() => {
        switch(confirmAction){
            case "Archive":
                props.handleArchiveSparePart(sparePartList);
                setConfirmAction('');
                break;
            case "Restore":
                props.handleRestoreSparePart(sparePartList);
                setConfirmAction('');
                break;
            case "Delete":
                props.handleDeleteSparePart(sparePartList);
                setConfirmAction('');
                break;
            default:
                break;
        }
    },[confirmAction]);

    return (
        <Flex paddingTop={5}>
            <Modal
                isOpen={isOpenReportModalArchive}
                onOpen={onOpenReportModalArchive}
                onClose={onCloseReportModalArchive}
                setConfirmAction={setConfirmAction}
                type={"Archive"}
            />
            <Modal
                isOpen={isOpenReportModalRestore}
                onOpen={onOpenReportModalRestore}
                onClose={onCloseReportModalRestore}
                setConfirmAction={setConfirmAction}
                type={"Restore"}
            />
            <Modal
                isOpen={isOpenReportModalDelete}
                onOpen={onOpenReportModalDelete}
                onClose={onCloseReportModalDelete}
                setConfirmAction={setConfirmAction}
                type={"Delete"}
            />
            <ModalQR
                isOpen={isOpenReportModalQR}
                onOpen={onOpenReportModalQR}
                onClose={onCloseReportModalQR}
                modalData={modalData}
                type={"List"}
            />
            {(props.dataSpareParts)?.length === 0 && (props.dataOpnameSpareParts)?.length === 0  ?
                <Center w={"full"}>
                    <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                    <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                        No items in here..
                    </Text>
                </Center>
                :
                <Box w={"full"}>
                    <InfiniteScroll scrollableTarget={"tableSparePart" + props.props.page} dataLength={props.dataSpareParts.length} next={() => props.setFetchMoreData(true)} hasMore={props.hasMoreTable} loader={''}>
                        <TableContainer id={"tableSparePart" + props.props.page} overflowY={"scroll"} whiteSpace={"normal"} display={"block"} maxHeight={isResolutionHigh ? "58vh" : "48vh"} border={"1px solid #B7D4CF"} w={"full"}>
                            <Table variant="simple">
                                <TableCaption>
                                    {props.hasMoreTable ?
                                        <Loader/>
                                        :
                                        <Text className='text-center font-bold text-sm' textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>End of Line (No more data)</Text>
                                    }
                                </TableCaption>
                                <Thead zIndex={1} pos='sticky' top={0} bg={process.env.REACT_APP_TABLE_COLOR}>
                                    <Tr>
                                        {(props.props.page !== props.pages[2] || props.role === "Superior") &&
                                            <>
                                                <Th>
                                                    <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}></Text>
                                                </Th>
                                            </>
                                        }
                                        <Th>
                                            <Center>
                                                {(props.props.page !== props.pages[2] || props.role !== "Superior") &&
                                                    <Image className="cursor-pointer" src={SortIcon} alt="" onClick={() => {
                                                        setSortState(!sortState);
                                                        props.handleSortDataDropDownSparePartListItem("id", sortState);
                                                    }}
                                                    />
                                                }
                                                <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>ID</Text>
                                            </Center>
                                        </Th>
                                        {props.props.page !== props.pages[2] &&
                                            <>
                                                <Th>
                                                    <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>Action</Text>
                                                </Th>
                                            </>
                                        }
                                        {Object.keys(columnName).map((elem, index) => (
                                            <Fragment key={columnName[elem]}>
                                                {props.props.page === props.pages[2] ?
                                                    <Th>
                                                        <Center>
                                                            {(props.props.page !== props.pages[2] || props.role !== "Superior") &&
                                                                <Image className="cursor-pointer" src={SortIcon} alt="" onClick={() => {
                                                                    setSortState(!sortState);
                                                                    props.handleSortDataDropDownSparePartListItem(elem, sortState);
                                                                }}
                                                                />
                                                            }
                                                            <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnName[elem]}</Text>
                                                        </Center>
                                                    </Th>
                                                    :
                                                    (index !== 6 && index !== 7) &&
                                                    <Th key={columnName[elem]}>
                                                        <Center>
                                                            <Image className="cursor-pointer" src={SortIcon} alt="" onClick={() => {
                                                                setSortState(!sortState);
                                                                props.handleSortDataDropDownSparePartListItem(elem, sortState);
                                                            }}
                                                            />
                                                            <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnName[elem]}</Text>
                                                        </Center>
                                                    </Th>
                                                }
                                            </Fragment>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {props.dataOpnameSpareParts.map((dataSparePart) => (
                                        <Tr key={dataSparePart.id}>
                                            {(props.role === "Superior") &&
                                                <>
                                                    <Td>
                                                        <Checkbox isChecked={checkBoxOpname.includes(dataSparePart.id)} size="lg" colorScheme="green" borderColor={process.env.REACT_APP_CHECKBOX_COLOR} onChange={() => handleCheckboxOpname(dataSparePart.id)}>
                                                        </Checkbox>
                                                    </Td>
                                                </>
                                            }
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{dataSparePart.id}</Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_code}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_name}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.brand}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.type}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_category}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty}
                                            </Td>
                                            <Td>
                                                <NumberInput borderColor={!isNaN(props.stockDifference[dataSparePart.id]) ? "orange.500" : process.env.REACT_APP_INPUT_COLOR} min={0} focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                             onChange={(e) => handleRealStockChange(dataSparePart.id, dataSparePart.qty, e)}
                                                             defaultValue={dataSparePart.opname ? (Number(dataSparePart.qty) + Number(dataSparePart.qty_difference)).toFixed(2) : ''}
                                                             isDisabled={props.role === "Superior"}
                                                >
                                                    <NumberInputField _hover={{borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD}} />
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>
                                            </Td>
                                            <Td>
                                                <Input isReadOnly={true} borderColor={!isNaN(props.stockDifference[dataSparePart.id]) ? "orange.500" : process.env.REACT_APP_INPUT_COLOR} _hover={{borderColor: process.env.REACT_APP_CHECKBOX_COLOR}} focusBorderColor={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                       value={!isNaN(props.stockDifference[dataSparePart.id]) ? (props.stockDifference[dataSparePart.id] === 0 ? "zero" : props.stockDifference[dataSparePart.id]) : (dataSparePart.opname ? (Number(dataSparePart.qty_difference) === 0 ? "zero" : Number(dataSparePart.qty_difference)) : '')}
                                                />
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty_min}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty_max}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.bin_location}
                                            </Td>
                                        </Tr>
                                    ))}

                                    {(props.props.page !== props.pages[2] || props.role !== "Superior") && props.dataSpareParts.map((dataSparePart) => (
                                        <Tr key={dataSparePart.id}>
                                            {props.props.page !== props.pages[2] &&
                                                <>
                                                    <Td>
                                                        <Checkbox size="lg" colorScheme="green" borderColor={process.env.REACT_APP_CHECKBOX_COLOR} onChange={() => handleCheckbox(dataSparePart)}>
                                                        </Checkbox>
                                                    </Td>
                                                </>
                                            }
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{dataSparePart.id}</Td>
                                            {props.props.page !== props.pages[2] &&
                                                <>
                                                    <Td>
                                                        <Center>
                                                            <Image src={TableEditIcon} className="cursor-pointer" alt="" onClick={() => navigate({
                                                                pathname: "/item/edit",
                                                                search: "?id=" + dataSparePart.id,
                                                            })}
                                                            />
                                                            <Image src={props.props.page !== props.pages[1] ? ArchiveIcon : RestoreIcon} className="cursor-pointer mx-2" alt="" onClick={() => {
                                                                let tempArraySparePart = [];
                                                                tempArraySparePart.push(dataSparePart);
                                                                setSparePartList(tempArraySparePart);

                                                                if (props.props.page !== props.pages[1]) {
                                                                    onOpenReportModalArchive();
                                                                }
                                                                else {
                                                                    onOpenReportModalRestore();
                                                                }

                                                            }}
                                                            />
                                                            {props.props.page === props.pages[0] &&
                                                                <Image src={TablePrintIcon} className="cursor-pointer" alt="" onClick={() => {
                                                                    let tempObjectSparePart = {};
                                                                    let id = Number((dataSparePart.part_code).substring(1));

                                                                    tempObjectSparePart["part_code"] = dataSparePart.part_code
                                                                    tempObjectSparePart["part_name"] = dataSparePart.part_name
                                                                    tempObjectSparePart["type"] = dataSparePart.type
                                                                    tempObjectSparePart["bin_location"] = dataSparePart.bin_location

                                                                    const zeroPad = (num, places) => String(num).padStart(places, '0')
                                                                    let binLocQR = props.serving + "-" + (dataSparePart.part_code)[0] + zeroPad(id, 5) + "-" + dataSparePart?.bin_location;
                                                                    tempObjectSparePart["qr_code"] = binLocQR
                                                                    setModalData(tempObjectSparePart);

                                                                    onOpenReportModalQR();
                                                                }}
                                                                />
                                                            }
                                                            {props.props.page === props.pages[1] &&
                                                                <Image src={DeleteIcon} className="cursor-pointer" alt="" onClick={() => {
                                                                    let tempArraySparePart = [];
                                                                    tempArraySparePart.push(dataSparePart);
                                                                    setSparePartList(tempArraySparePart);

                                                                    onOpenReportModalDelete();
                                                                }}
                                                                />
                                                            }
                                                        </Center>
                                                    </Td>
                                                </>
                                            }
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_code}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_name}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.brand}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.type}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_category}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty}
                                            </Td>
                                            {props.props.page === props.pages[2] &&
                                                <>
                                                    <Td>
                                                        <NumberInput borderColor={!isNaN(props.stockDifference[dataSparePart.id]) ? "orange.500" : process.env.REACT_APP_INPUT_COLOR} min={0} focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                                     onChange={(e) => handleRealStockChange(dataSparePart.id, dataSparePart.qty, e)}
                                                                     defaultValue={dataSparePart.opname ? (Number(dataSparePart.qty) + Number(dataSparePart.qty_difference)) : ''}>
                                                            <NumberInputField _hover={{borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD}} />
                                                            <NumberInputStepper>
                                                                <NumberIncrementStepper />
                                                                <NumberDecrementStepper />
                                                            </NumberInputStepper>
                                                        </NumberInput>
                                                    </Td>
                                                    <Td>
                                                        <Input isReadOnly={true} borderColor={!isNaN(props.stockDifference[dataSparePart.id]) ? "orange.500" : process.env.REACT_APP_INPUT_COLOR} _hover={{borderColor: process.env.REACT_APP_CHECKBOX_COLOR}} focusBorderColor={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                               value={!isNaN(props.stockDifference[dataSparePart.id]) ? (props.stockDifference[dataSparePart.id] === 0 ? "zero" : props.stockDifference[dataSparePart.id]) : (dataSparePart.opname ? (Number(dataSparePart.qty_difference) === 0 ? "zero" : Number(dataSparePart.qty_difference)) : '')}
                                                        />
                                                    </Td>
                                                </>
                                            }
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty_min}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty_max}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.bin_location}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </InfiniteScroll>
                </Box>
            }
        </Flex>
    );
}

export default Tables;