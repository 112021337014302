import { useNavigate } from "react-router-dom";

import {
    Flex,
    Spacer,
    Image, 
    Text,
    Input,
    InputGroup,
    InputLeftElement
} from "@chakra-ui/react";

import SearchIcon from "../../assets/icons/header/Header_search.svg";
import Outgoing from "../../assets/icons/hero/HomePage/Hero_outgoing.svg";

const SubHeader = (props) => {
    const navigate = useNavigate();

    return (
        <Flex w={"full"} position={props.position ? props.position : 'auto'} paddingRight={props.position ? "2%" : ''}>
            <Flex cursor={"pointer"} onClick={() => navigate("/outgoing/list")}>
                <Image boxSize={"60px"} src={Outgoing}/>
                <Text paddingTop={"2"} paddingLeft={"5"} fontSize={"3xl"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} _hover={{color : process.env.REACT_APP_CHECKBOX_COLOR}}>Outgoing</Text>
            </Flex>

            <Spacer/>

            <InputGroup w={"20%"}>
                <Input
                    placeholder="Press Enter to Search"
                    size="lg"
                    bg="white"
                    borderRadius={"20px"}
                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                    fontWeight={"medium"}
                    boxShadow="xl"
                    _active={{
                        borderColor: process.env.REACT_APP_BORDER_COLOR
                    }}
                    _focusVisible={{
                        zIndex: 1,
                        borderColor: process.env.REACT_APP_BORDER_COLOR,
                        boxShadow: ("0 0 0 1px " + process.env.REACT_APP_BORDER_COLOR)
                    }}
                    onKeyPress={((e) => e.key === "Enter" ? window.find(e.target.value) : '')}
                />
            </InputGroup>
        </Flex>
    );
}

export default SubHeader;