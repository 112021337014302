import Cookies from "js-cookie";
import { Navigate } from 'react-router-dom';

const navigationGuard = (Component) => {
    return (props) => {
        if (typeof window !== "undefined") {
            const accessToken = Cookies.get('accessToken');
            if (!accessToken) {
                return <Navigate from='*' to="/auth/login" replace={true}/>
            }
            else {
                return <Component {...props} />;
            }
        }
    }
}

export default navigationGuard;