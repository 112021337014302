import React from "react";
import { useEffect, useState } from "react";

import {Container, useDisclosure} from "@chakra-ui/react";

import Axios from "../../services/api/Axios"
import Cookies from "js-cookie";

import { CheckBoxesProvider } from "../../components/CheckBoxes";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import SparePartSubHeader from "./SubHeader";
import SparePartButtons from "./Buttons";
import SparePartDropDowns from "./DropDowns";
import SparePartTables from "./Tables";

import Modal from "../../components/Modal/AreYouSure";
import ModalTextInput from "../../components/Modal/TextInput";
import {useNavigate} from "react-router-dom";

let state = {
    pages: ["list", "archive", "opname"],
    dropDownList : ["part_name", "brand", "type", "bin_location"],
    offset: 25,
    dataOffset : 0,
    dropDownOffset : 25,
    dropDownSelectOffset : 0,
}

const SparePart = (props) => {
    const [dataSpareParts,setDataSpareParts] = useState([]);
    const [dataOpnameSpareParts,setDataOpnameSpareParts] = useState([]);
    const [tempDataSpareParts,setTempDataSpareParts] = useState([]);
    const [dataInitialization,setDataInitialization] = useState(false);

    const [dataDropDownSpareParts,setDataDropDownSpareParts] = useState({});
    const [dataDropDownChange,setDataDropDownChange] = useState(false);
    const [fetchMoreData,setFetchMoreData] = useState(true);
    const [fetchMoreDropDownData,setFetchMoreDropDownData] = useState([false, ""]);
    const [isSearchActive, setIsSearchActive] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const [serving, setServing] = useState('');

    const [navigateBack, setNavigateBack] = useState(false);

    const [stockDifference,setStockDifference] = useState({});

    const [hasMoreTable, setHasMoreTable] = useState(true);
    const [hasMoreDropDown, setHasMoreDropDown] = useState({});

    const [confirmAction, setConfirmAction] = useState('');
    const [modalData, setModalData] = useState({});
    const [dataOpnameCart, setDataOpnameCart] = useState([]);

    const [buttonOpnameDisabled, setButtonOpnameDisabled] = useState(true);

    const navigate = useNavigate();

    if (!dataInitialization) {
        state = {
            pages: ["list", "archive", "opname"],
            dropDownList : ["part_name", "brand", "type", "bin_location"],
            offset: 25,
            dataOffset : 0,
            dropDownOffset : 25,
            dropDownSelectOffset : 0,
        }
    }

    const history = {
        dataSparePartURL : "/sparepart/list?order=id,desc&sediaan_id=" + Cookies.get("idServing") + "&is_active=" + ((props.page !== state.pages[1])) + ((props.page === state.pages[2]) ? "&last_opname=30&opname=false" : '') + "&offset=",
        dataOpnameCart : "/opname/cart",
        dataOpnameValidator : "/opname/list?count=1&sediaan_id=" + Cookies.get("idServing") + "&is_active=" + ((props.page !== state.pages[1])) + "&order=updatedAt,desc&status=SUBMIT",
        postOpnameCart : "/opname/submit",
        removeOpnameCart: "/opname/cart?sparepart_id=",
        postApproveOpnameCart: "/stockUpdate/submit?id=",
        // dataStockOpnameURL : "/sparepart/stockOpname?id=",
        // listStockOpnameURL : "/sparepart/stockOpname?sediaan_id=" + Cookies.get("idServing") + "&count=1&dropdown=id",
        // postStockOpnameURL : "/sparepart/stockOpname/submit",
        dataDropDownSparePartURL : "/sparepart/list?sediaan_id=" + Cookies.get("idServing") + "&is_active=" + ((props.page !== state.pages[1])) + ((props.page === state.pages[2]) ? "&last_opname=30&opname=false" : '') + "&dropdown=",
        postSparePartItemURL : "/sparepart/item?id=",
        deleteImageURL : "/image/item?id=",
        dataServingURL: "/sediaan/item?id=",
        accessToken : Cookies.get("accessToken"),
        role : Cookies.get("role"),
    }

    const handleGetServing = async () => {
        try{
            let getServing = await Axios.get(history.dataServingURL + Cookies.get("idServing"),
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            setServing(getServing?.data?.data?.sediaan?.huruf);
        }
        catch (error){

        }
    }

    const handleGetDataSpareParts = async () => {
        try{
            if (dataInitialization) state.dataOffset += state.offset;

            handleGetServing();

            let getDataSpareParts = await Axios.get(history.dataSparePartURL + state.dataOffset,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            if ((getDataSpareParts?.data?.data?.spareparts)?.length < 25) {
                setHasMoreTable(false);
            }
            else setHasMoreTable(true);

            setDataSpareParts(dataSpareParts.concat(getDataSpareParts?.data?.data?.spareparts));

            if (!dataInitialization) {
                setTempDataSpareParts(getDataSpareParts?.data?.data?.spareparts);
            }
        }
        catch(error){
            console.error(error);
        }
    };

    const handleGetDataDropDownSpareParts = async () => {
        let objectDropDown = {};
        let objectHasMore = {};
        state.dropDownOffset = state.offset;

        state.dropDownList.map(async (dropDownListItem) => {
            let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            objectHasMore[dropDownListItem] = (getDataDropDownSpareParts?.data?.data?.spareparts)[dropDownListItem]?.length >= 25;
            setHasMoreDropDown(objectHasMore);

            if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
            objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
        })

        setDataDropDownSpareParts(objectDropDown);
    };

    const handleGetMoreDataDropDownSpareParts = async (dropDownListItem) => {
        let objectDropDown = JSON.parse(JSON.stringify(dataDropDownSpareParts));
        let objectHasMore = JSON.parse(JSON.stringify(hasMoreDropDown));

        let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + "&offset=" + state.dropDownOffset,
            {
                headers: {"Authorization": "Bearer " + history.accessToken},
            });

        objectHasMore[dropDownListItem] = (getDataDropDownSpareParts?.data?.data?.spareparts)[dropDownListItem]?.length >= 25;
        setHasMoreDropDown(objectHasMore);

        objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem])
        setDataDropDownSpareParts(objectDropDown);

        state.dropDownOffset += state.offset;
    };

    const handleGetDataOpnameCartSpareParts = async (event) => {
        if (props.page === state.pages[2]) {
            try{
                let tempDataOpnameSpareParts = [];

                if (history.role !== "Superior") {
                    let getDataOpnameSpareParts = await Axios.get(history.dataOpnameCart,
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    tempDataOpnameSpareParts = getDataOpnameSpareParts?.data?.data?.opname?.opnameSpareparts || [];
                }
                else {
                    let getDataOpnameSpareParts = await Axios.get(history.dataOpnameValidator,
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    let allData = getDataOpnameSpareParts?.data?.data?.opnames || [];

                    allData.map((data) => {
                        data.opnameSpareparts.map((datum) => {
                            tempDataOpnameSpareParts.push(datum);

                            return 0
                        })

                        return 0
                    })

                    setDataOpnameCart(allData);
                    setHasMoreTable(false);
                }

                if (tempDataOpnameSpareParts.length !== 0) {
                    let tempSetDataOpnameSpareParts = tempDataOpnameSpareParts.map((datum) => {
                        return datum.sparepart
                    })

                    setDataOpnameSpareParts(tempSetDataOpnameSpareParts);
                }

                switch (event) {
                    case "Initial":
                        break;
                    case "Save":
                        let tempDataSpareParts = Array.from(dataSpareParts);

                        tempDataOpnameSpareParts.map((datumOpname) => {
                            let tempIndex = tempDataSpareParts.findIndex(datum => datum.id === datumOpname.sparepart.id);
                            if (tempIndex >= 0) {
                                tempDataSpareParts.splice(tempIndex, 1);
                            }
                        })

                        setDataSpareParts(tempDataSpareParts);
                        break;
                    case "Submit":
                        setDataOpnameSpareParts([]);
                        break;
                    default:
                        break;
                }

            }
            catch(error){
                console.error(error);
            }
        }
    };

    const handleGetDataDependentDropDownSpareParts = async (dropDownList) => {
        let objectDropDown = {};
        let objectHasMore = {};

        let queryString = '';

        Object.keys(dropDownList).map(async (dropDownListItem) => {
            if (dropDownList[dropDownListItem].length) {
                queryString = queryString.concat('&', dropDownListItem, '=', dropDownList[dropDownListItem])
            }
        })

        let searchString = queryString.replace(/ /g, '_');

        Object.keys(dropDownList).map(async (dropDownListItem) => {
            if (dropDownList[dropDownListItem].length === 0) {
                let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + searchString + "&limit=999",
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                objectHasMore[dropDownListItem] = false
                setHasMoreDropDown(objectHasMore);

                if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
                objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
            }
            else {
                let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + "&limit=999",
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                objectHasMore[dropDownListItem] = false
                setHasMoreDropDown(objectHasMore);

                if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
                objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
            }
        })

        setDataDropDownSpareParts(objectDropDown);
    };

    const handleSearchDataDropDownSparePartListItem = async (dropDownListItem, queryString) => {
        let objectDropDown = JSON.parse(JSON.stringify(dataDropDownSpareParts));
        let objectHasMore = JSON.parse(JSON.stringify(hasMoreDropDown));

        let tempString = queryString.replace(/ /g, '_');
        tempString = tempString.replace('.', '_');

        let getDataDropDownSpareParts = await Axios.get(`${history.dataDropDownSparePartURL + dropDownListItem}&${dropDownListItem}=%${tempString}%&limit=9999`,
            {
                headers: {"Authorization": "Bearer " + history.accessToken},
            });

        objectHasMore[dropDownListItem] = false;
        setHasMoreDropDown(objectHasMore);

        objectDropDown[dropDownListItem].splice(0, objectDropDown[dropDownListItem].length);
        objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);

        setDataDropDownSpareParts(objectDropDown);
        setDataDropDownChange(true);
    };

    const handleSearchSpareParts = async (dropDownValue) => {
        let searchQuery = '';

        Object.keys(dropDownValue).map((elem) => {
            if (dropDownValue[elem]) return searchQuery += ("&" + elem + "=" + dropDownValue[elem])
            return ''
        })

        console.log(searchQuery);

        try{
            let getDataSpareParts = await Axios.get(history.dataSparePartURL + 0 + "&limit=9999" + searchQuery,
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });

            setHasMoreTable(false);
            setDataSpareParts(getDataSpareParts?.data?.data?.spareparts);
        }
        catch(error){
            console.error(error);
        }
        setIsSearchActive(true);
    };

    const handleUseOldDataSpareParts = () => {
        let tempData = Array.from(tempDataSpareParts);
        if (tempData.length !== 0) setDataSpareParts(tempData);
        state.dataOffset = 0;

        if (tempData.length >= 25) setHasMoreTable(true);
    };

    const handleSortDataDropDownSparePartListItem = async (dropDownListItem, isAsc) => {
        let newDataSpareParts = Array.from(dataSpareParts);

        newDataSpareParts.sort((a, b) => {
            if (a[dropDownListItem] && b[dropDownListItem]) {
                if (isNaN(a[dropDownListItem]) || isNaN(b[dropDownListItem])) {
                    let fa = a[dropDownListItem].toLowerCase(),
                        fb = b[dropDownListItem].toLowerCase();

                    if (fa < fb) {
                        if (isAsc) return -1;
                        else return 1;
                    }
                    if (fa > fb) {
                        if (isAsc) return 1;
                        else return -1
                    }
                    return 0;
                }
                else {
                    if (isAsc) return a[dropDownListItem] - b[dropDownListItem];
                    else return b[dropDownListItem] - a[dropDownListItem];
                }
            }
            return 0
        });

        setDataSpareParts(newDataSpareParts);
    };

    const handleArchiveSparePart = async (sparePartList) => {
        let newDataSpareParts = Array.from(dataSpareParts);
        let promiseArray = [];

        sparePartList.map((sparePartItem) => {
            promiseArray.push(new Promise((resolve, reject) => {
                Axios.post(history.postSparePartItemURL + sparePartItem.id,
                    {is_active: false},
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    }
                );

                newDataSpareParts.splice(newDataSpareParts.findIndex(((e) => e.id === sparePartItem.id)), 1);
                resolve("done");
            }))
        })

        await Promise.all(promiseArray);

        setDataSpareParts(newDataSpareParts);
    }

    const handleRestoreSparePart = async (sparePartList) => {
        let newDataSpareParts = Array.from(dataSpareParts);
        let promiseArray = [];

        sparePartList.map((sparePartItem) => {
            promiseArray.push(new Promise((resolve, reject) => {
                Axios.post(history.postSparePartItemURL + sparePartItem.id,
                    {is_active: true},
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    }
                );

                newDataSpareParts.splice(newDataSpareParts.findIndex(((e) => e.id === sparePartItem.id)), 1);
                resolve("done");
            }))
        })

        await Promise.all(promiseArray);

        setDataSpareParts(newDataSpareParts);
    }

    const handleDeleteSparePart = async (sparePartList) => {
        let newDataSpareParts = Array.from(dataSpareParts);
        let promiseArray = [];

        sparePartList.map((sparePartItem) => {
            promiseArray.push(new Promise((resolve, reject) => {
                let indexSparePart = newDataSpareParts.findIndex(((e) => e.id === sparePartItem.id));
                console.log(newDataSpareParts[indexSparePart]);

                Axios.delete(history.postSparePartItemURL + sparePartItem.id,
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    }
                );

                if (newDataSpareParts[indexSparePart].image_id !== 1) {
                    Axios.delete(history.deleteImageURL + newDataSpareParts[indexSparePart].image_id,
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        }
                    );
                }

                newDataSpareParts.splice(indexSparePart, 1);
                resolve("done");
            }))
            return ''
        })

        await Promise.all(promiseArray);

        setDataSpareParts(newDataSpareParts);
    }

    const handleStockOpnameSparePart = async (event) => {
        switch (event) {
            case "Save":
                let promiseArray = [];

                Object.keys(stockDifference).map((elem) => {
                    promiseArray.push(new Promise((resolve) => {
                        try{
                            let postStockOpname = Axios.post(history.dataOpnameCart,
                                {sparepart_id: elem, qty_difference: stockDifference[elem]},
                                {
                                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                                });
                            console.log(postStockOpname);
                            resolve(postStockOpname);
                        }
                        catch(error){
                            console.error(error);
                        }
                    }))

                    return ''
                })

                await Promise.all(promiseArray);
                setStockDifference({});
                handleGetDataOpnameCartSpareParts("Save").then(() => onOpenSuccessModal());
                break;

            case "Submit":
                if (Object.keys(stockDifference).length === 0) {
                    try{
                        let tempDataOpnameSpareParts = Array.from(dataOpnameSpareParts);

                        if (tempDataOpnameSpareParts.length !== 0) {
                            try{
                                let listStockOpname = tempDataOpnameSpareParts.map((datum) => {
                                    return datum.id
                                })

                                console.log(listStockOpname);

                                if (listStockOpname.length !== 0) {
                                    let postStockOpname = Axios.post(history.postOpnameCart,
                                        {sparepart_id: listStockOpname},
                                        {
                                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                                        });

                                    console.log(postStockOpname);

                                    handleGetDataOpnameCartSpareParts("Submit").then(() => onOpenSuccessModal());
                                }
                            }
                            catch(error){
                                console.error(error);
                            }
                            onOpenSuccessModal();
                        }
                        else {
                            setErrorMessage("There is no opname data!");
                            onOpenErrorModal();
                        }
                    }
                    catch(error){
                        console.error(error);
                    }
                }
                else {
                    setErrorMessage("There is unsaved changes!");
                    onOpenErrorModal();
                }
                break;

            case "Approve":
                let tempModalData = JSON.parse(JSON.stringify(modalData));
                let CAPA = tempModalData["CAPA"] || {"corrective": [], "preventive": []}

                let approvePromiseArray = [];
                let tempDataOpnameCart = Array.from(dataOpnameCart);

                if (tempDataOpnameCart.length !== 0) {
                    tempDataOpnameCart.map((cart) => {
                        if (cart.hasOwnProperty("opnameSpareparts")) {
                            (cart.opnameSpareparts).map((datum) => {
                                approvePromiseArray.push(new Promise((resolve) => {
                                    try{
                                        let postApproveOpname = Axios.post(history.postApproveOpnameCart + datum.id,
                                            {"meta": { "CAPA": CAPA }},
                                            {
                                                headers: {'Authorization': 'Bearer ' + history.accessToken},
                                            });

                                        console.log(postApproveOpname);
                                        resolve(postApproveOpname);
                                    }
                                    catch(error){
                                        setErrorMessage(error);
                                        onOpenErrorModal();
                                        console.error(error);
                                    }
                                }))

                                return 0
                            })
                        }
                        return 0
                    })

                    await Promise.all(approvePromiseArray);
                    handleGetDataOpnameCartSpareParts("Submit").then(() => onOpenSuccessModal());
                }
                else {
                    setErrorMessage("No data opname to be validated!");
                    onOpenErrorModal();
                }

                break;
        }
    }

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenErrorModal,
        onOpen: onOpenErrorModal,
        onClose: onCloseErrorModal
    } = useDisclosure()

    const {
        isOpen: isOpenTextModal,
        onOpen: onOpenTextModal,
        onClose: onCloseTextModal
    } = useDisclosure()

    useEffect(() => {
        if (!isSearchActive) if (fetchMoreData) handleGetDataSpareParts().then(() => handleGetDataOpnameCartSpareParts("Initial").then(() => setFetchMoreData(false)));
        if (!dataInitialization) handleGetDataDropDownSpareParts().then(() => setDataInitialization(true));
        if (fetchMoreDropDownData[0]) handleGetMoreDataDropDownSpareParts(fetchMoreDropDownData[1]).then(() => setFetchMoreDropDownData([false, '']));
        if (dataDropDownChange) setDataDropDownChange(false);
        if (navigateBack && (props.page !== state.pages[2])) navigate("/item/list");
    }, [dataSpareParts, fetchMoreData, fetchMoreDropDownData, dataDropDownChange, isSearchActive, navigateBack]);

    useEffect(() => {
    }, [dataOpnameSpareParts]);

    useEffect(() => {
        switch (confirmAction) {
            case "ApproveWithCAPA":
                handleStockOpnameSparePart("Approve");
                break;
            default:
                break;
        }
    }, [confirmAction]);

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingTop={"2vh"}>
                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    setRedirect={setNavigateBack}
                    type={"Success"}
                />
                <Modal
                    isOpen={isOpenErrorModal}
                    onOpen={onOpenErrorModal}
                    onClose={onCloseErrorModal}
                    errorMessage={errorMessage}
                    type={"Error"}
                />
                <ModalTextInput
                    isOpen={isOpenTextModal}
                    onOpen={onOpenTextModal}
                    onClose={onCloseTextModal}
                    data={dataOpnameSpareParts}
                    setConfirmAction={setConfirmAction}
                    modalData={modalData}
                    setModalData={setModalData}
                    type={"CAPA"}
                />

                <CheckBoxesProvider>
                    <SparePartSubHeader />
                    <SparePartButtons
                        props={props}
                        pages={state.pages}
                        dataOpnameSpareParts={dataOpnameSpareParts}
                        onOpenTextModal={onOpenTextModal}
                        handleArchiveSparePart={handleArchiveSparePart}
                        handleRestoreSparePart={handleRestoreSparePart}
                        handleDeleteSparePart={handleDeleteSparePart}
                        handleStockOpnameSparePart={handleStockOpnameSparePart}
                        serving={serving}
                        role={history.role}
                        buttonOpnameDisabled={buttonOpnameDisabled}
                    />
                    <SparePartDropDowns
                        props={props} pages={state.pages}
                        dataDropDownSpareParts={dataDropDownSpareParts}
                        handleGetDataDropDownSpareParts={handleGetDataDropDownSpareParts}
                        handleGetDataDependentDropDownSpareParts={handleGetDataDependentDropDownSpareParts}
                        handleSearchDataDropDownSparePartListItem={handleSearchDataDropDownSparePartListItem}
                        setFetchMoreDropDownData={setFetchMoreDropDownData}
                        handleSearchSpareParts={handleSearchSpareParts}
                        handleUseOldDataSpareParts={handleUseOldDataSpareParts}
                        setIsSearchActive={setIsSearchActive}
                        hasMoreDropDown={hasMoreDropDown}
                        role={history.role}
                    />
                    <SparePartTables
                        props={props} pages={state.pages}
                        dataSpareParts={dataSpareParts}
                        dataOpnameSpareParts={dataOpnameSpareParts}
                        setFetchMoreData={setFetchMoreData}
                        handleSortDataDropDownSparePartListItem={handleSortDataDropDownSparePartListItem}
                        handleArchiveSparePart={handleArchiveSparePart}
                        handleRestoreSparePart={handleRestoreSparePart}
                        handleDeleteSparePart={handleDeleteSparePart}
                        stockDifference={stockDifference}
                        setStockDifference={setStockDifference}
                        hasMoreTable={hasMoreTable}
                        serving={serving}
                        role={history.role}
                        setButtonOpnameDisabled={setButtonOpnameDisabled}
                    />
                </CheckBoxesProvider>
            </Container>
            <Footer />
        </>
    );
}

export default SparePart;
