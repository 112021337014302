import React from "react";
import {
    Avatar,
    Button,
    Card,
    CardBody, Center,
    Container, Grid, GridItem,
    Image,
    SimpleGrid,
    Stack,
    Text,
    VStack
} from "@chakra-ui/react";

import sparePart from "../../../assets/img/imageSparePart.svg";
import EmptyList from "../../../assets/img/empty.svg";

const ListRestore = (props) => {
    const columnName = {
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "specification" : "Specification",
        "price" : "Price / pcs",
        "bin_location" : "Bin Location",
        "qty_return" : "Quantity to Restore",
    }

       return (
           <>
               {(props.dataRestore)?.length === 0 ?
                   <Center w={"full"} marginTop={"10%"}>
                       <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                       <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                           No items in here..
                       </Text>
                   </Center>
                   :
                   <Container maxW={"full"} position={"relative"} marginTop={"12.5vh"} maxH={"71vh"} overflowY={"auto"}>
                       <SimpleGrid py={"1%"} px={"6%"} columns={2} spacingX='40px' spacingY='20px'>
                           {props.dataRestore.map((dataSparePart) => (
                               <Card
                                   key={dataSparePart.id}
                                   bg={"white"}
                                   borderRadius={"xl"}
                                   direction={{ base: 'column', sm: 'row' }}
                                   overflow='hidden'
                                   variant='outline'
                                   position={"initial"}
                               >
                                   <VStack m={10} spacing='3'>
                                       <Text fontWeight={"bold"} borderRadius={"md"} bg={"blue.100"} color={"royalblue"}>{dataSparePart?.request?.user?.name} at {(new Date(dataSparePart.updatedAt)).toString().slice(0, 24)}</Text>
                                       <Avatar cursor={"pointer"} onClick={() => props.setRefreshImage("onClick")} size={"2xl"} src={dataSparePart["image_bin"]} icon={<Image src={sparePart} />} marginBottom={"2%"}/>

                                       <Button
                                           variant='solid'
                                           colorScheme='orange'
                                           position={"initial"}
                                           onClick={() => {
                                               let tempModalData = {};
                                               tempModalData["sparepart_id"] = dataSparePart?.sparepart?.id
                                               tempModalData["sediaan_id"] = dataSparePart?.sparepart?.sediaan_id
                                               tempModalData["part_code"] = dataSparePart?.sparepart?.part_code
                                               tempModalData["part_name"] = dataSparePart?.sparepart?.part_name
                                               tempModalData["brand"] = dataSparePart?.sparepart?.brand
                                               tempModalData["type"] = dataSparePart?.sparepart?.type
                                               tempModalData["part_category"] = dataSparePart?.sparepart?.part_category
                                               tempModalData["bin_location"] = dataSparePart?.sparepart?.bin_location
                                               tempModalData["meta"] = dataSparePart?.sparepart?.meta

                                               tempModalData["id"] = dataSparePart.id;
                                               tempModalData["user"] = dataSparePart?.request?.user?.name;
                                               tempModalData["createdAt"] = dataSparePart.updatedAt;
                                               tempModalData["price"] = (dataSparePart.price ? dataSparePart.price : 0)
                                               tempModalData["qty_return"] = dataSparePart.qty_return;
                                               tempModalData["line"] = dataSparePart?.machine?.line?.name
                                               tempModalData["machine"] = dataSparePart?.machine?.code
                                               props.setModalData(tempModalData);
                                               props.onOpenSparePartPopUp();
                                           }}
                                       >
                                           Restore
                                       </Button>
                                   </VStack>

                                   <Stack paddingLeft={4} w={"full"}>
                                       <CardBody paddingX={"10%"} paddingY={"10%"}>
                                           <Grid templateColumns='repeat(2, 1fr)' gap={3} py={2} w={"full"}>
                                               {Object.keys(columnName).map((elem, index) => (
                                                   <GridItem key={dataSparePart.id + index} w={"full"} borderRadius={"lg"}>
                                                       <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnName[elem]}</Text>
                                                       {elem !== "qty_return" ?
                                                           <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem === "price" && "Rp "}{(dataSparePart?.sparepart ? ((dataSparePart?.sparepart[elem])?.length > 18 ? (dataSparePart?.sparepart[elem])?.slice(0, 18) + ".." : (dataSparePart?.sparepart[elem])) : '')}{elem === "price" && ",00"}</Text>
                                                           :
                                                           <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{dataSparePart[elem] ? dataSparePart[elem] : 0}</Text>
                                                       }
                                                   </GridItem>
                                               ))}
                                           </Grid>
                                       </CardBody>
                                   </Stack>
                               </Card>
                           ))}
                       </SimpleGrid>
                   </Container>
               }
           </>
    );
}

export default ListRestore;
