import React, {useEffect, useState} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import {
    Button,
    ButtonGroup, Center,
    Container,
    FormControl,
    FormLabel,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Select, Switch, Text, useDisclosure, VStack,
} from "@chakra-ui/react";
import SubHeader from "./SubHeader";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "../../services/api/Axios";
import BackIcon from "../../assets/icons/hero/Sparepart/Hero_back.svg";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
import Modal from "../../components/Modal/AreYouSure";

const Add = () => {
    const navigate = useNavigate();

    const history = {
        postDataUsersURL : "/admin/user",
        servingURL : "/sediaan/list",
        accessToken : Cookies.get("accessToken"),
        role : Cookies.get("role"),
        idServing : Cookies.get("idServing"),
    }

    const [columnState, setColumnState] = useState({
        //"database_column_name" : ["Name", "inputType", "isDisable", "isRequired", "isInvalid"],
        "name" : ["Profile Name", "text", false, true, false],
        "email" : ["Email", "email", false, true, false],
        "password" : ["Password", "password", false, true, false],
        "username" : ["Username", "text", false, true, false],
        "roleName" : ["Role", "select", false, true, false],
        "sediaan_id" : ["Organizer", "select", (history.role !== "Superior") , true, false],
    });

    const [showPassword, setShowPassword] = useState(false);
    const [confirmSubmitData, setConfirmSubmitData] = useState(false);

    const [dataUsers,setDataUsers] = useState({});

    const [dataInitialization,setDataInitialization] = useState(false);
    const [dataServings,setDataServings] = useState([]);
    const [navigateBack, setNavigateBack] = useState(false);

    console.log(dataUsers);
    const handleDataUser = async (event, target, value) => {
        switch (event) {
            case "add":
                let tempDataUser = JSON.parse(JSON.stringify(dataUsers));
                tempDataUser[target] = value;

                columnState[target][4] = tempDataUser[target] === '';

                if (target === "email") {
                    if (!tempDataUser["email"].includes("@")) tempDataUser["username"] = value
                }

                if (target === "roleName") {
                    tempDataUser["isSuperAdmin"] = value !== "Teknisi";
                }

                // setColumnState(columnState => {...columnState, target: ([...columnState[target]])})

                setDataUsers(tempDataUser);
                break;

            case "post":
                let tempColumnState = JSON.parse(JSON.stringify(columnState));
                let isInvalid = false;

                Object.keys(tempColumnState).map((elem) => {
                    if (tempColumnState[elem][3] && !dataUsers[elem]) {
                        tempColumnState[elem][4] = true;
                        isInvalid = true;
                    }
                    return ''
                })

                if (!isInvalid) {
                    try{
                        let postDataUsers = await Axios.post(history.postDataUsersURL,
                            dataUsers,
                            {
                                headers: {"Authorization": "Bearer " + history.accessToken},
                            });

                        console.log(postDataUsers);
                        onOpenSuccessModal();

                    }
                    catch(error){
                        console.error(error);
                    }
                }

                setColumnState(tempColumnState);
                break;
            default:
                break;
        }
    }

    const handleGetDataServing = async () => {
        try{
            let statusServing = await Axios.get(history.servingURL,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            // console.log(statusServing);
            setDataServings(statusServing.data?.data?.sediaans);
        }
        catch(error){
            console.error(error);
        }
    }

    const {
        isOpen: isOpenRequestModal,
        onOpen: onOpenRequestModal,
        onClose: onCloseRequestModal
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    useEffect(() => {
        if (!dataInitialization) handleGetDataServing().then(() => {
            setDataUsers({
                "password" : "ptiuser1234",
                "sediaan_id" : (history.role !== "Superior" ? history.idServing : ''),
                "isEmailNotification" : false,
                "isSuperAdmin" : false,
                "isLdap" : false,
            });
            setDataInitialization(true);
        })
        if (confirmSubmitData) handleDataUser("post").then(() => setConfirmSubmitData(false));
        if (navigateBack) navigate("/userManagement/list");
    }, [dataUsers, confirmSubmitData, navigateBack])

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingBottom={"12vh"} paddingTop={"2vh"}>
                <Modal
                    isOpen={isOpenRequestModal}
                    onOpen={onOpenRequestModal}
                    onClose={onCloseRequestModal}
                    setConfirmSentResetPassword={setConfirmSubmitData}
                    type={"Request"}
                />

                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    setRedirect={setNavigateBack}
                    type={"Success"}
                />

                <SubHeader/>

                <ButtonGroup pt={"2%"}>
                    <Button
                        leftIcon={<Image src={BackIcon} alt="" />}
                        variant='solid'
                        colorScheme='facebook'
                        position={"initial"}
                        onClick={() => navigate('/userManagement/list')}
                    >
                        Back
                    </Button>
                </ButtonGroup>

                <VStack w={"full"} px={"30%"} align={"center"}  spacing={"1%"}>
                    <Text fontSize={"xl"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"} paddingBottom={5}>Add New User</Text>
                    {Object.keys(columnState).map((elem, index) => (
                        <FormControl
                            key={index}
                            isRequired={columnState[elem][3]}
                            isInvalid={columnState[elem][3] && !dataUsers[elem] && columnState[elem][4]}
                        >
                            <FormLabel fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnState[elem][0]}</FormLabel>
                            {columnState[elem][1] === "select" ?
                                <Select
                                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                    borderColor={process.env.REACT_APP_INPUT_COLOR }
                                    borderRadius={"xl"}
                                    focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                    _hover={{
                                        borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                    }}
                                    defaultValue={elem === "sediaan_id" ? (history.role !== "Superior" ? Number(history.idServing) : "Please select an option") : "Please select an option"}
                                    disabled={columnState[elem][2]}
                                    onChange={(e) => handleDataUser("add", elem, e.target.value)}
                                >
                                    <option disabled>Please select an option</option>
                                    {columnState[elem][0] === "Role" &&
                                        <>
                                            <option value={"Teknisi"}>User</option>
                                            <option>Admin</option>
                                            {history.role === "Superior" &&
                                                <option>Superior</option>
                                            }
                                        </>
                                    }
                                    {columnState[elem][0] === "Organizer" &&
                                        <>
                                            {dataServings.map((serving) => (
                                                <option selected={history.role !== "Superior" ? (Number(serving?.id) === Number(history.idServing)) : ''} key={Number(serving?.id)} value={Number(serving?.id)}>{(serving?.nama).charAt(0).toUpperCase() + (serving?.nama).slice(1)}</option>
                                            ))}
                                        </>
                                    }
                                </Select>
                                : (columnState[elem][1] === "password" ?
                                        <InputGroup>
                                            <Input
                                                type={showPassword ? "text" : "password"}
                                                placeholder={"***********"}
                                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                borderColor={process.env.REACT_APP_INPUT_COLOR}
                                                borderRadius={"xl"}
                                                focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                _hover={{
                                                    borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                                }}
                                                isDisabled={columnState[elem][2]}
                                                defaultValue={'ptiuser1234'}
                                                onChange={(e) => handleDataUser("add", elem, e.target.value)}
                                                required
                                            />
                                            <InputRightElement h={"full"}>
                                                <Button
                                                    variant={"ghost"}
                                                    color={process.env.REACT_APP_BORDER_COLOR}
                                                    onClick={() =>
                                                        setShowPassword((showPassword) => !showPassword)
                                                    }
                                                    _hover={{
                                                        bg: "transparent"
                                                    }}
                                                    _active={{
                                                        bg: "transparent"
                                                    }}
                                                >
                                                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                        :
                                        <Input
                                            type={columnState[elem][1]}
                                            placeholder={"Please fill in " + columnState[elem][0]}
                                            color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                            borderColor={process.env.REACT_APP_INPUT_COLOR }
                                            borderRadius={"xl"}
                                            focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                            _hover={{
                                                borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                            }}
                                            defaultValue={columnState[elem][0] === "Username" ? (dataUsers["username"] ? dataUsers["username"] : '') : ''}
                                            onChange={(e) => handleDataUser("add", elem, e.target.value)}
                                            disabled={columnState[elem][2]}
                                        />
                                )
                            }
                        </FormControl>
                    ))}

                    <FormControl display='flex' alignItems='center'>
                        <FormLabel htmlFor='email-alerts' mb='0' fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                            Enable email alerts?
                        </FormLabel>
                        <Switch
                            id='email-alerts'
                            colorScheme={process.env.REACT_APP_BORDER_COLOR}
                            onChange={() => {
                                setDataUsers((dataUsers => ({...dataUsers, "isEmailNotification": !(dataUsers["isEmailNotification"])})))
                            }}
                        />
                    </FormControl>
                </VStack>

                <Center pt={"2%"}>
                    <Button
                        colorScheme={"teal"}
                        size='lg'
                        borderRadius={"8px"}
                        onClick={onOpenRequestModal}
                    >
                        Submit
                    </Button>
                </Center>

            </Container>
            <Footer />
        </>
    );
}

export default Add;
