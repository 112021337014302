import DashboardTableMain from "./TableMain";
import React, {Component, useState} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import NavigationGuard from "../../services/hooks/NavigationGuard";
import DashboardSubHeader from "./SubHeader";
import DashboardSumContainer from "./SumContainer";
import DashboardTableDataService from "./TableDataService";

import { Container, Grid, GridItem, Select, Flex, Text, Center, Input } from "@chakra-ui/react";

const DashboardDataServiceContainer = () => {
    const getCurrentYear = new Date().getFullYear();

    const [selectedYear, setSelectedYear] = useState(getCurrentYear);
    const [confirmChange, setConfirmChange] = useState(false);

    return (
        <Container boxShadow="xl" maxW={"full"} className="bg-gray-100 m-4 rounded-lg pt-[2vh] pb-[4vh]">
            <Text className="font-bold text-xl text-center p-2 whitespace-nowrap" textColor={process.env.REACT_APP_TEXT_COLOR_LIGHT}>Data Service Level per Month</Text>
            <Flex maxW={"md"} className="p-4">
                <Text className="font-bold text-center p-2 whitespace-nowrap" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>Year</Text>
                <Select
                    onChange={(e) => {
                        setSelectedYear(e.target.selectedOptions[0].value)
                        setConfirmChange(true);
                    }}
                >
                    <option value={getCurrentYear}>{getCurrentYear}</option>
                    <option value={getCurrentYear - 1}>{getCurrentYear - 1}</option>
                    <option value={getCurrentYear - 2}>{getCurrentYear - 2}</option>
                    <option value={getCurrentYear - 3}>{getCurrentYear - 3}</option>
                </Select>
            </Flex>
            
            
            <DashboardTableDataService selectedYear={selectedYear} confirmChange={confirmChange} setConfirmChange={setConfirmChange}/>
        </Container>
    );
}
 
export default DashboardDataServiceContainer;