import React from "react";

import {
    Button,
    Stack,
    CardBody,
    Text,
    Image,
    Card,
    VStack,
    HStack,
    Avatar,
} from '@chakra-ui/react';

import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../src/components/Loader/Loader";

import CartPlus from "../../../assets/img/cartPlus.svg";
import {useNavigate} from "react-router-dom";
import sparePart from "../../../assets/img/imageSparePart.svg";
import EmptyList from "../../../assets/img/empty.svg";

const ListRequest = (props) => {
    const columnName = {
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "qty" : "Stock",
        "qty_inrequest" : "In Request",
    }

    const navigate = useNavigate();

    return (
        <>
            {(props.dataSpareParts)?.length === 0 ?
                <VStack paddingBottom={"6vh"}>
                    <Image boxSize={"sm"} src={EmptyList} alt={"empty"}/>
                    <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                        No items in here..
                    </Text>
                </VStack>
                :
                <Stack id={"listRequestMobile"} w={"full"} position={"relative"} px={"2vw"} maxH={"50vh"} overflowY={"auto"} display={"flex"} paddingBottom={"6vh"}>
                    <InfiniteScroll scrollableTarget={"listRequestMobile"} dataLength={props.dataSpareParts.length} next={() => props.setFetchMoreData(true)} hasMore={props.hasMoreCard} loader={Loader()}>
                        {props.dataSpareParts.map((dataSparePart) => (
                            <Card
                                key={dataSparePart.id + 1000}
                                w={"full"}
                                bg={"white"}
                                borderRadius={"xl"}
                                direction={{ base: 'column', sm: 'row' }}
                                overflow='hidden'
                                variant='outline'
                                position={"initial"}
                                mt={"1vh"}
                            >
                                <HStack w={"full"} spacing={"2vw"} align={"center"}>
                                    <VStack m={"1vh"} spacing='1'>
                                        <Avatar cursor={"pointer"} onClick={() => props.setRefreshImage("onClick")} size={"2xl"} src={dataSparePart["image_bin"]} icon={<Image src={sparePart} />} marginBottom={"2%"}/>

                                        <Button
                                            maxW={"full"}
                                            variant='solid'
                                            colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                                            position={"initial"}
                                            onClick={() => {
                                                if ((props.dataBinStorage).some(binPartCode => binPartCode === dataSparePart?.part_code)) {
                                                    props.setErrorMessage("Part Code: " + dataSparePart?.part_code + " needs to be put on bin first, please contact admin!");
                                                    props.onOpenErrorModal();
                                                }
                                                else {
                                                    navigate({
                                                        pathname: "/request/requestNow",
                                                        search: "?id=" + dataSparePart?.id,
                                                    });
                                                }
                                            }}
                                        >
                                            <Text fontSize={"xs"} fontWeight={"bold"}>Request Now!</Text>
                                        </Button>
                                        <Button
                                            maxW={"full"}
                                            variant='solid'
                                            colorScheme='orange'
                                            position={"initial"}
                                            onClick={() => {
                                                if ((props.dataBinStorage).some(binPartCode => binPartCode === dataSparePart?.part_code)) {
                                                    props.setErrorMessage("Part Code: " + dataSparePart?.part_code + " needs to be put on bin first, please contact admin!");
                                                    props.onOpenErrorModal();
                                                }
                                                else {
                                                    let tempModalData = {};
                                                    Object.keys(columnName).map((elem) => tempModalData[elem] = dataSparePart[elem]);
                                                    tempModalData["id"] = dataSparePart["id"];
                                                    tempModalData["qty_request"] = 1;
                                                    props.setModalData(tempModalData);
                                                    props.onOpenSparePartPopUp();
                                                }
                                            }}
                                            rightIcon={<Image src={CartPlus} alt={"cart"} />}
                                        >
                                            <Text fontSize={"xs"} fontWeight={"bold"}>Add to cart</Text>
                                        </Button>
                                    </VStack>
                                    <CardBody w={"full"}>
                                        {Object.keys(columnName).map((elem, index) => (
                                            <Stack key={dataSparePart.id + index} spacing={0} align={"center"} wrap={false}>
                                                <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} pt={2}>
                                                    {columnName[elem]}
                                                </Text>
                                                <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} pb={2}>
                                                    {(dataSparePart[elem])?.length > 30 ? (dataSparePart[elem]).slice(0, 30) + ".." : (dataSparePart[elem])}
                                                </Text>
                                            </Stack>
                                        ))}
                                    </CardBody>

                                </HStack>

                            </Card>
                        ))}
                    </InfiniteScroll>
                </Stack>
            }
        </>
    );
}

export default ListRequest;