import {
    Button, Center, FormControl, FormLabel, Input, InputGroup, InputRightElement, Text, useDisclosure, VStack
} from '@chakra-ui/react';
import React, {useEffect, useState} from "react";

import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
import Cookies from "js-cookie";
import Axios from "../../../services/api/Axios";
import Modal from "../../../components/Modal/AreYouSure";
import {useMediaQuery} from "react-responsive";

const Form = (props) => {
    let history = {
        dataProfile : "/user",
        postProfile : "/user/profile",
        postPassword : "/user/password",
        accessToken : Cookies.get("accessToken"),
        idServing : Cookies.get("idServing"),
        serving : Cookies.get("serving"),
    }

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" })

    const [showPassword, setShowPassword] = useState('');
    const [dataInitialization, setDataInitialization] = useState(false);
    const [dataProfile, setDataProfile] = useState({});
    const [confirmSubmitData, setConfirmSubmitData] = useState(false);

    const [columnStateProfile, setColumnStateProfile] = useState({
        //"database_column_name" : ["Name", "inputType", "isDisable", "isRequired", "isInvalid"],
        "username" : ["Username", "text", true, true, false],
        "name" : ["Profile Name", "text", false, true, false],
        "email" : ["Email", "text", false, true, false],
        "role" : ["Role", "text", true, false, false],
        "serving" : ["Plant", "text", true, false, false],
        "description" : ["Description", "select", true, false, false],
    });

    const [columnStatePassword, setColumnStatePassword] = useState({
        //"database_column_name" : ["Name", "inputType", "isDisable", "isRequired", "isInvalid"],
        "password" : ["Current Password", "password", false, true, false],
        "new_password" : ["New Password", "password", false, true, false],
        "confirm_password" : ["Confirm New Password", "password", false, true, false],
    });

    const handleDataProfile = async (event, target, value) => {
        switch (event) {
            case "get":
                try {
                    let getDataProfile = await Axios.get(history.dataProfile,
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });
                    let tempDataProfile = getDataProfile?.data?.data?.user;

                    tempDataProfile["serving"] = (Cookies.get("serving"))[0].toUpperCase() + (Cookies.get("serving")).slice(1)
                    tempDataProfile["role"] = Cookies.get("role") === "Teknisi" ? "User" : Cookies.get("role");
                    tempDataProfile["description"] = "Access only to " + tempDataProfile["serving"]

                    setDataProfile(tempDataProfile)
                }
                catch (error) {
                    console.error(error)
                }
                break;

            case "edit":
                if (props.props.page === "profile") {
                    let tempDataProfile = JSON.parse(JSON.stringify(dataProfile));
                    tempDataProfile[target] = value;

                    columnStateProfile[target][4] = tempDataProfile[target] === '';

                    // setColumnState(columnState => {...columnState, target: ([...columnState[target]])})

                    setDataProfile(tempDataProfile);
                }
                else {
                    let tempDataProfile = JSON.parse(JSON.stringify(dataProfile));
                    tempDataProfile[target] = value;

                    columnStatePassword[target][4] = tempDataProfile[target] === '';
                    // setColumnState(columnState => {...columnState, target: ([...columnState[target]])})

                    setDataProfile(tempDataProfile);
                }
                break;

            case "post":
                if (props.props.page === "profile") {
                    let tempColumnState = JSON.parse(JSON.stringify(columnStateProfile));
                    let isInvalid = false;

                    Object.keys(tempColumnState).map((elem) => {
                        if (tempColumnState[elem][3] && !dataProfile[elem]) {
                            tempColumnState[elem][4] = true;
                            isInvalid = true;
                        }
                        return ''
                    })

                    if (!isInvalid) {
                        let tempDataProfile = {};
                        tempDataProfile["name"] = dataProfile["name"];
                        tempDataProfile["email"] = dataProfile["email"];

                        try {
                            let postDataProfile = await Axios.post(history.postProfile,
                                tempDataProfile,
                                {
                                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                                });

                            onOpenSuccessModal();
                            console.log(postDataProfile);
                        }
                        catch (error) {
                            console.error(error)
                        }
                    }

                    setColumnStateProfile(tempColumnState);
                }

                else {
                    let tempColumnState = JSON.parse(JSON.stringify(columnStatePassword));
                    let isInvalid = false;

                    Object.keys(tempColumnState).map((elem) => {
                        if (tempColumnState[elem][3] && !dataProfile[elem]) {
                            tempColumnState[elem][4] = true;
                            isInvalid = true;
                        }
                        return ''
                    })

                    if (dataProfile["new_password"] !== dataProfile["confirm_password"]) {
                        dataProfile["new_password"] = '';
                        dataProfile["confirm_password"] = '';
                        tempColumnState["new_password"][4] = true;
                        tempColumnState["confirm_password"][4] = true;
                        isInvalid = true
                    }

                    if (!isInvalid) {
                        let tempDataProfile = {};
                        tempDataProfile["password"] = dataProfile["password"];
                        tempDataProfile["new_password"] = dataProfile["new_password"];

                        console.log(tempDataProfile)
                        try {
                            let postDataProfile = await Axios.post(history.postPassword,
                                tempDataProfile,
                                {
                                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                                });

                            onOpenSuccessModal();
                            console.log(postDataProfile);
                        }
                        catch (error) {
                            console.error(error);
                            dataProfile["password"] = '';
                            tempColumnState["password"][4] = true;
                        }
                    }
                    setColumnStatePassword(tempColumnState);
                }
                break;
            default:
                break;
        }
    }

    const {
        isOpen: isOpenRequestModal,
        onOpen: onOpenRequestModal,
        onClose: onCloseRequestModal
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    useEffect(() => {
        if (!dataInitialization) handleDataProfile("get").then(() => setDataInitialization(true))
        if (confirmSubmitData) handleDataProfile("post").then(() => setConfirmSubmitData(false));
    }, [dataProfile, confirmSubmitData])
    return (
        <div className={!isTabletOrMobile ? "col-span-2 bg-[#F3F8F8] py-[6vh] px-[8vh]" : "col-span-2 bg-[#F3F8F8] pt-[2vh] pb-[6vh] px-[2vh]"}>
            <Modal
                isOpen={isOpenRequestModal}
                onOpen={onOpenRequestModal}
                onClose={onCloseRequestModal}
                setConfirmSentResetPassword={setConfirmSubmitData}
                type={"Request"}
            />

            <Modal
                isOpen={isOpenSuccessModal}
                onOpen={onOpenSuccessModal}
                onClose={onCloseSuccessModal}
                type={"Success"}
            />

            <Text textAlign={"center"} fontSize={!isTabletOrMobile ? "2xl" : "lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} mb={!isTabletOrMobile ? '' : "2vh"}>Edit Profile</Text>
            <VStack align={"start"} spacing={"1%"}>
                {Object.keys(props.props.page === "profile" ? columnStateProfile : columnStatePassword).map((elem) => (
                    <FormControl key={elem}
                                 isRequired={(props.props.page === "profile" ? columnStateProfile[elem][3] : columnStatePassword[elem][3])}
                                 isInvalid={(props.props.page === "profile" ? columnStateProfile[elem][3] : columnStatePassword[elem][3]) && !dataProfile[elem] && (props.props.page === "profile" ? columnStateProfile[elem][4] : columnStatePassword[elem][4])}
                    >
                        <FormLabel fontSize={!isTabletOrMobile ? "xl" : "md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(props.props.page === "profile" ? columnStateProfile[elem][0] : columnStatePassword[elem][0])}</FormLabel>
                        {(props.props.page === "profile" ? columnStateProfile[elem][1] : columnStatePassword[elem][1]) !== "password" ?
                            <Input
                                size={"lg"}
                                bg={"white"}
                                type={(props.props.page === "profile" ? columnStateProfile[elem][1] : columnStatePassword[elem][1])}
                                placeholder={(props.props.page === "profile" ? columnStateProfile[elem][0] : columnStatePassword[elem][0])}
                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                borderColor={process.env.REACT_APP_INPUT_COLOR }
                                borderRadius={"xl"}
                                focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                _hover={{
                                    borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                }}
                                isDisabled={(props.props.page === "profile" ? columnStateProfile[elem][2] : columnStatePassword[elem][2])}
                                defaultValue={dataProfile[elem]}
                                onChange={(e) => handleDataProfile("edit", elem, e.target.value)}
                            />
                            :
                            <InputGroup>
                                <Input
                                    size={"lg"}
                                    bg={"white"}
                                    type={showPassword === elem ? "text" : "password"}
                                    placeholder={(props.props.page === "profile" ? columnStateProfile[elem][0] : columnStatePassword[elem][0])}
                                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                    borderColor={process.env.REACT_APP_INPUT_COLOR}
                                    borderRadius={"xl"}
                                    focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                    _hover={{
                                        borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                    }}
                                    isDisabled={(props.props.page === "profile" ? columnStateProfile[elem][2] : columnStatePassword[elem][2])}
                                    defaultValue={''}
                                    onChange={(e) => handleDataProfile("edit", elem, e.target.value)}
                                    required
                                />
                                <InputRightElement h={"full"}>
                                    <Button
                                        variant={"ghost"}
                                        color={process.env.REACT_APP_BORDER_COLOR}
                                        onClick={() => {
                                            if (showPassword === elem) setShowPassword('');
                                            else setShowPassword(elem);
                                        }}
                                        _hover={{
                                            bg: "transparent"
                                        }}
                                        _active={{
                                            bg: "transparent"
                                        }}
                                    >
                                        {showPassword === elem ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        }
                    </FormControl>
                ))}
            </VStack>

            <Center pt={"5%"}>
                <Button
                    colorScheme={"teal"}
                    size='lg'
                    borderRadius={"8px"}
                    onClick={onOpenRequestModal}
                >
                    Submit
                </Button>
            </Center>
        </div>
    );
}
 
export default Form;