import React, {useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive"
import queryString from "query-string"

import Axios from "../../services/api/Axios"

import {
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    InputRightElement,
    InputGroup,
    Text,
    Link,
    Button,
    Heading,
    Image, useDisclosure,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import iconSparePart from "../../assets/img/wpm.svg";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/AreYouSure";

const ResetPassword = () => {
    const history = {
        loginURL : "/auth/Login?appSource=WPM Warehouse",
        resetURL : "/auth/reset?reset=",
        changeURL : "/auth/change?userId=",
        navigate : useNavigate(),
        location : useLocation(),
    }

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" })

    const [focusedResetPassword, setFocusedResetPassword] = useState('');
    const [focusedConfirmResetPassword, setFocusedConfirmResetPassword] = useState('');

    const [focusedError, setFocusedError] = useState('');
    const [navigateLogin, setNavigateLogin] = useState(false);

    const [showResetPassword, setShowResetPassword] = useState(false);
    const [showConfirmResetPassword, setShowConfirmResetPassword] = useState(false);

    const handleResetPassword = async () => {
        try{
            const searchParams = queryString.parse(history.location.search);

            if(!focusedResetPassword || !focusedConfirmResetPassword) throw new Error("One or more password field is empty!");
            if(focusedResetPassword !== focusedConfirmResetPassword) throw new Error("Password confirmation does not match!");
            if(searchParams.reset) {
                let dataResetPassword = await Axios.get(history.resetURL + searchParams.reset);

                let idUser = await dataResetPassword?.data?.data?.id;

                if (idUser) {
                    let resetPassword = await Axios.post(history.changeURL + idUser + "&reset=" + searchParams.reset,
                        {password: focusedResetPassword},
                    );

                    console.log(resetPassword);
                    onOpenSuccessModal();
                }
            }
            else throw new Error("Invalid reset token, check your reset URL!");
        }
        catch(error){
            setFocusedError(error.message)
        }
    }

    useEffect(() => {
        if(navigateLogin) history.navigate("/auth/login");
    }, [navigateLogin])

    return (
        <>
            <Modal
                isOpen={isOpenSuccessModal}
                onOpen={onOpenSuccessModal}
                onClose={onCloseSuccessModal}
                setRedirect={setNavigateLogin}
                type={"Success"}
            />

            <Flex
                minH={"94vh"}
                align={"center"}
                justify={"center"}
                bg="white"
            >
                <Stack style={{padding: "0px"}} spacing={4} mx={"auto"} width={isTabletOrMobile ? "300px" : "500px"} py={12} px={6}>
                    <Stack align={"center"}>
                        <Image
                            alt={"Spare Part Icon"}
                            objectFit={"cover"}
                            src={iconSparePart}
                        />
                        <Heading
                            fontWeight={"bold"}
                            fontSize={"3xl"}
                            textAlign={"center"}
                            background={"linear-gradient(180deg, " + process.env.REACT_APP_ENV_COLOR + " 0%, #193D2D 100%)"}
                            backgroundClip={"text"}
                            style={{marginBottom:"1rem"}}
                        >
                            WPM WAREHOUSE MANAGEMENT
                        </Heading>
                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT} lineHeight={'1'}>
                            Please set your new password!
                        </Text>
                    </Stack>
                    <Stack mx={"auto"}>
                        <FormControl id="resetPassword">
                            <FormLabel
                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                fontWeight={"bold"}
                            >
                                New Password
                            </FormLabel>
                            <InputGroup>
                                <Input
                                    width={"100%"}
                                    type={showResetPassword ? "text" : "password"}
                                    placeholder="***********"
                                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                    borderColor={!(!!focusedError) ? process.env.REACT_APP_INPUT_COLOR : "red.500"}
                                    focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                    _hover={{
                                        borderColor: (!(!!focusedError) ? process.env.REACT_APP_TEXT_COLOR_BOLD : "red.700")
                                    }}
                                    onClick={() => setFocusedError('')}
                                    onChange={(e) => setFocusedResetPassword(e.target.value)}
                                    onKeyPress={(e) => e.key === "Enter" ? handleResetPassword().then(e.currentTarget.blur()) : ''}
                                    value={focusedResetPassword}
                                    required
                                />
                                <InputRightElement h={"full"}>
                                    <Button
                                        variant={"ghost"}
                                        color={process.env.REACT_APP_BORDER_COLOR}
                                        onClick={() =>
                                            setShowResetPassword((showResetPassword) => !showResetPassword)
                                        }
                                        _hover={{
                                            bg: "transparent"
                                        }}
                                        _active={{
                                            bg: "transparent"
                                        }}
                                    >
                                        {showResetPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <FormControl id="confirmResetPassword">
                            <FormLabel
                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                fontWeight={"bold"}
                            >
                                Confirm New Password
                            </FormLabel>
                            <InputGroup>
                                <Input
                                    width={"100%"}
                                    type={showConfirmResetPassword ? "text" : "password"}
                                    placeholder="***********"
                                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                    borderColor={!(!!focusedError) ? process.env.REACT_APP_INPUT_COLOR : "red.500"}
                                    focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                    _hover={{
                                        borderColor: (!(!!focusedError) ? process.env.REACT_APP_TEXT_COLOR_BOLD : "red.700")
                                    }}
                                    onClick={() => setFocusedError('')}
                                    onChange={(e) => setFocusedConfirmResetPassword(e.target.value)}
                                    onKeyPress={(e) => e.key === "Enter" ? handleResetPassword().then(e.currentTarget.blur()) : ''}
                                    value={focusedConfirmResetPassword}
                                    required
                                />
                                <InputRightElement h={"full"}>
                                    <Button
                                        variant={"ghost"}
                                        color={process.env.REACT_APP_BORDER_COLOR}
                                        onClick={() =>
                                            setShowConfirmResetPassword((showConfirmResetPassword) => !showConfirmResetPassword)
                                        }
                                        _hover={{
                                            bg: "transparent"
                                        }}
                                        _active={{
                                            bg: "transparent"
                                        }}
                                    >
                                        {showConfirmResetPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        {!!focusedError ?
                            <>
                                <Text fontSize={"sm"} color={"red"}>
                                    {focusedError}
                                </Text>
                            </>
                            :
                            ''
                        }
                        <Stack spacing={4}>
                            <Stack
                                direction={{ base: "column", sm: "row" }}
                                align={"start"}
                                justify={"end"}
                            >
                                <Link
                                    color={process.env.REACT_APP_TEXT_COLOR_LIGHT}
                                    fontWeight={"bold"}
                                    onClick={() => setNavigateLogin(true)}
                                >
                                    Back to Login
                                </Link>
                            </Stack>
                            <Button
                                bg={process.env.REACT_APP_ENV_COLOR}
                                color={"white"}
                                _hover={{
                                    bg: process.env.REACT_APP_BUTTON_HOVER_COLOR,
                                }}
                                _active={{
                                    bg: process.env.REACT_APP_TEXT_COLOR_LIGHT
                                }}
                                onClick={handleResetPassword}
                                disabled={!(!!focusedResetPassword && !!focusedConfirmResetPassword)}
                            >
                                Create New Password
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Flex>
            <Footer />
        </>
    );
}

export default ResetPassword;