import React from "react";
import {
    Avatar,
    Button,
    Card,
    CardBody, Center,
    Container, Grid, GridItem,
    Image,
    SimpleGrid,
    Stack,
    Text,
    VStack
} from "@chakra-ui/react";

import sparePart from "../../assets/img/imageSparePart.svg";
import EmptyList from "../../assets/img/empty.svg";

const ListStorage = (props) => {
    const columnName = {
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "specification" : "Specification",
        "price" : "Price / pcs",
        "bin_location" : "Bin Location",
        "qty_incoming" : "Quantity to Bin",
    }

       return (
           <>
               {(props.dataBinStorage)?.length === 0 ?
                   <Center w={"full"} marginTop={"6.2%"}>
                       <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                       <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                           No items in here..
                       </Text>
                   </Center>
                   :
                   <Container maxW={"full"} position={"relative"} marginTop={"12.5vh"} maxH={"71vh"} overflowY={"auto"}>
                       <SimpleGrid py={"1%"} px={"6%"} columns={2} spacingX='40px' spacingY='20px'>
                           {props.dataBinStorage.map((dataSparePart) => (
                               <Card
                                   key={dataSparePart.id}
                                   bg={"white"}
                                   borderRadius={"xl"}
                                   direction={{ base: 'column', sm: 'row' }}
                                   overflow='hidden'
                                   variant='outline'
                                   position={"initial"}
                               >
                                   <VStack m={10} spacing='3'>
                                       <Text fontWeight={"bold"} borderRadius={"md"} bg={"red.100"} color={"mediumvioletred"}>{(new Date(dataSparePart.updatedAt)).toString().slice(0, 24)}</Text>
                                       <Avatar cursor={"pointer"} onClick={() => props.setRefreshImage("onClick")} size={"2xl"} src={dataSparePart["image_bin"]} icon={<Image src={sparePart} />} marginBottom={"2%"}/>

                                       <Button
                                           variant='solid'
                                           colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                                           position={"initial"}
                                           onClick={() => {
                                               props.setModalData(dataSparePart);
                                               props.setConfirmAction("preQR");
                                           }}
                                       >
                                           Add to Bin
                                       </Button>
                                   </VStack>

                                   <Stack paddingLeft={4} w={"full"}>
                                       <CardBody paddingX={"10%"} paddingY={"10%"}>
                                           <Grid templateColumns='repeat(2, 1fr)' gap={3} w={"full"}>
                                               {Object.keys(columnName).map((elem, index) => (
                                                   <GridItem key={dataSparePart.id + index} w={"full"} borderRadius={"lg"}>
                                                       <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnName[elem]}</Text>
                                                       <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{elem === "price" && "Rp "}{(dataSparePart[elem])?.length > 20 ? (dataSparePart[elem])?.slice(0, 20) + ".." : (dataSparePart[elem])}{elem === "price" && ",00"}</Text>
                                                   </GridItem>
                                               ))}
                                           </Grid>
                                       </CardBody>
                                   </Stack>
                               </Card>
                           ))}
                       </SimpleGrid>
                   </Container>
               }
           </>
    );
}

export default ListStorage;
