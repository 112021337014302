import React, {useState} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Hero_Homepage from "../../components/Hero/HomePage/Homepage_Hero";
import Footer from "../../components/Footer/Footer";
import NavigationGuard from "../../services/hooks/NavigationGuard";

const Home = () => {
    const [currentServing, setCurrentServing] = useState('');

    return (
        <div>
            <Navbar setCurrentServing={setCurrentServing} />
            <Hero_Homepage currentServing={currentServing} />
            <Footer />
        </div>
    );
}

export default NavigationGuard(Home);