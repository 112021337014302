import React, {useEffect, useState} from 'react'
import SparepartLogoWithSearchBar from '../../../../pages/SparePart/SubHeader';
import ImportSparepartButtonCollection from './ImportSparepart_ButtonCollection';
import ImportSparepartTableMain from './ImportSparepart_Table_Main';
import { ImportDataProvider } from './ImportSparepart_Context';
import {Container} from "@chakra-ui/react";
import Cookies from "js-cookie";
import Axios from "../../../../services/api/Axios";

const Hero_ImportSparepart = () => {
    const [selectedFormatFile, setSelectedFormatFile] = useState('excel')
    const [selectedAction, setSelectedAction] = useState('add')

    const [dataDropdown, setDataDropdown] = useState({});

    let history = {
        // dataDropdownUOM: "/uom",
        dataDropdownPartCategory: "/partCategory?dropdown=part_category&count=1",
        dataDropdownPartStatus: "/partStatus?dropdown=part_status&count=1",
        dataDropdownInventoryType: "/inventoryType?dropdown=inventory_type&count=1",
        accessToken : Cookies.get("accessToken"),
    }

    const handleGetDropdown = async () => {
        try{
            let tempObject = {};

            // let getDropdownUOM = await Axios.get(history.dataDropdownUOM,
            //     {
            //         headers: {"Authorization": "Bearer " + history.accessToken},
            //     });

            let getDropdownCategory = await Axios.get(history.dataDropdownPartCategory,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let getDropdownStatus = await Axios.get(history.dataDropdownPartStatus,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let getDropdownInventory = await Axios.get(history.dataDropdownInventoryType,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            // tempObject["qty_uom"] = getDropdownUOM?.data?.data?.uoms;
            tempObject["part_category"] = getDropdownCategory?.data?.data?.partCategorys?.part_category;
            tempObject["part_status"] = getDropdownStatus?.data?.data?.partStatuss?.part_status;
            tempObject["inventory_type"] = getDropdownInventory?.data?.data?.inventoryTypes?.inventory_type;

            setDataDropdown(tempObject);
        }
        catch (error){
            console.error(error);
        }
    }

    useEffect(() => {
        handleGetDropdown().then();
    }, [])

    return (
        <Container maxW={"full"} paddingTop={"2vh"}>
            <SparepartLogoWithSearchBar />
                <ImportDataProvider>
                    <ImportSparepartButtonCollection
                        selectedFormatFile={selectedFormatFile}
                        setSelectedFormatFile={setSelectedFormatFile}
                        selectedAction={selectedAction}
                        setSelectedAction={setSelectedAction}
                        dataDropdown={dataDropdown}
                    />
                    <ImportSparepartTableMain
                        selectedAction={selectedAction}
                    />
                </ImportDataProvider>
        </Container>
    );
}
 
export default Hero_ImportSparepart;