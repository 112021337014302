import React, {Fragment, useEffect, useState} from 'react';

import {
    Button,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    TableContainer,
    TableCaption,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    VStack,
    Input,
    HStack,
    ButtonGroup,
} from '@chakra-ui/react'


import CheckIcon from "../../assets/img/iconCheck.svg";
import {AddIcon, DeleteIcon} from "@chakra-ui/icons";

const TextInput = (props) => {
    const [modalConfig, setModalConfig] = useState({});
    const [modalData, setModalData] = useState({"arrayData": [], "columnData": {}});
    const [additionalData, setAdditionalData] = useState({});

    const [errorMessage, setErrorMessage] = useState({});
    const [stateConfirm, setStateConfirm] = useState(false);
    const [buttonTimeout, setButtonTimeout] = useState(true)

    useEffect(() => {
        handleModalType(props.type);
    }, [props.isOpen, additionalData]);

    useEffect(() => {
        handleLoadData(props.type);
    }, [props.data]);

    useEffect(() => {
        setStateConfirm(false);
        setButtonTimeout(true);
    }, [errorMessage, additionalData, modalData, modalConfig]);

    useEffect(() => {
    }, [stateConfirm, buttonTimeout])

    function handleLoadData(type){
        switch (type){
            case "CAPA":
            {
                let tempData = props.data || [];
                let filteredData = [];

                const columnData = {
                    "part_code" : "Part Code",
                    "part_name" : "Item Name",
                    "brand" : "Brand",
                    "type" : "Type",
                    "part_category" : "Part Category",
                    "qty" : "Stock",
                    "real_stock" : "Real Stock",
                    "qty_difference" : "Stock Diff",
                }

                tempData.map((datum) => {
                    if (Number(datum.qty_difference) !== 0) {
                        filteredData.push(datum);
                    }
                    return 0
                })

                setModalData(modalData => ({...modalData, "arrayData": filteredData, "columnData": columnData}));
                setAdditionalData({
                    "corrective": [null],
                    "preventive": [null],
                })
            }
                break;

            case "History":
            {
                let tempData = props.data?.opnameSpareparts || [];
                let filteredData = [];

                const columnData = {
                    "part_code" : "Part Code",
                    "part_name" : "Item Name",
                    "brand" : "Brand",
                    "type" : "Type",
                    "part_category" : "Part Category",
                    "qty" : "Stock",
                    "real_stock" : "Real Stock",
                    "qty_difference" : "Stock Diff",
                }

                tempData.map((datum) => {
                    let tempData = datum?.sparepart;
                    tempData["qty_difference"] = Number(datum?.qty_difference);

                    filteredData.push(tempData);
                    return 0
                })

                setModalData(modalData => ({...modalData, "arrayData": filteredData, "columnData": columnData}));
                if (tempData[0] && tempData[0].hasOwnProperty("meta") && tempData[0]?.meta?.hasOwnProperty("CAPA")) {
                    console.log("Here");
                    setAdditionalData(tempData[0]?.meta?.CAPA);
                }
                else {
                    setAdditionalData({
                        "corrective": [null],
                        "preventive": [null],
                    })
                }
            }
                break;

            default:
                break;
        }
    }

    function handleAction(type){
        switch (type){
            case "CAPA":
                let isErrorPreventive = false;
                let isErrorCorrective = false;
                let tempAdditionalData = JSON.parse(JSON.stringify(additionalData));
                let modalData = JSON.parse(JSON.stringify(props.modalData));

                Object.keys(tempAdditionalData).map((obj) => {
                    tempAdditionalData[obj].map((datum) => {
                        if (!datum && obj === "corrective") isErrorCorrective = true;
                        if (!datum && obj === "preventive") isErrorPreventive = true;
                        return 0
                    })
                    return 0
                })

                if (!isErrorCorrective && !isErrorPreventive) {
                    modalData["CAPA"] = tempAdditionalData;
                    props.setModalData(modalData);
                    props.setConfirmAction("ApproveWithCAPA");
                    props.onClose(true);
                }
                else {
                    let tempErrorMessage = {};
                    if (isErrorCorrective) tempErrorMessage["corrective"] = "There is an empty field to be filled!";
                    if (isErrorPreventive) tempErrorMessage["preventive"] = "There is an empty field to be filled!";

                    setErrorMessage(tempErrorMessage);
                }

                break;
            default:
                break;
        }
        // props.setConfirmAction(type);
    }

    function handleModalType(type){
        let data = {}
        switch(type){
            case "CAPA":
                data.title = "CAPA Field";
                data.content = "Operation is done successfully!";
                data.confirmation = "OK";
                data.confirmationColor = "#38CA46";
                data.cancel = "Back";
                data.handleConfirmation = () => handleAction(type);
                data.logo = CheckIcon;
                break;
            case "History":
                data.title = "History Opname: " + props.data?.case_no;
                data.content = "Operation is done successfully!";
                data.confirmation = "OK";
                data.confirmationColor = "#38CA46";
                data.cancel = "Back";
                data.handleConfirmation = () => handleAction(type);
                data.logo = CheckIcon;
                break;
            default:
                break;
        }
        setModalConfig(data);
    }

    function handleInputChange(text, index, event) {
        switch (props.type) {
            case "CAPA":
                let tempAdditionalData = JSON.parse(JSON.stringify(additionalData));

                if (Object.keys(tempAdditionalData).length !== 0) {
                    tempAdditionalData[event][index] = text;

                    setAdditionalData(tempAdditionalData);
                }
                break;
            default:
                break;
        }
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            isCentered
            autoFocus={false}
            size={"6xl"}
            scrollBehavior={"inside"}
        >
            <ModalOverlay
                bg={"blackAlpha.500"}
                backdropFilter='auto'
                backdropBlur='2px'
            />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    <VStack w={"full"} spacing={5}>
                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"}>{modalConfig.title}</Text>

                        <TableContainer overflowY={"auto"} whiteSpace={"normal"} display={"flex"}  w={"full"} maxHeight={"30vh"} border={"1px solid #B7D4CF"} borderRadius={"lg"}>
                            <Table>
                                <TableCaption color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    Please check the items first before proceeding!
                                </TableCaption>
                                <Thead zIndex={1} pos='sticky' top={0} bg={"#E1F1F3"}>
                                    <Tr>
                                        {Object.keys(modalData.columnData).map((columnDatum) => (
                                            <Th key={modalData.columnData[columnDatum]} textAlign={"center"}>{modalData.columnData[columnDatum]}</Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {(modalData.arrayData).map((arrayDatum) => (
                                        <Tr key={arrayDatum.id}>
                                            {Object.keys(modalData.columnData).map((columnDatum, index) => (
                                                <Td key={arrayDatum?.part_code + index} textAlign={"center"} color={columnDatum === "qty_difference" ? (Number(arrayDatum[columnDatum]) !== 0 ? "red" : process.env.REACT_APP_TEXT_COLOR_BOLD) : process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                    {columnDatum === "real_stock" ? (Number(arrayDatum?.qty) + Number(arrayDatum?.qty_difference)).toFixed(2) : arrayDatum[columnDatum]}
                                                </Td>
                                            ))}
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>

                        {(props.type === "CAPA" || props.type === "History") &&
                            <Fragment>
                                <TableContainer overflowY={"auto"} whiteSpace={"normal"} display={"flex"} w={"full"} maxHeight={"50vh"} border={"1px solid #B7D4CF"} borderRadius={"lg"}>
                                    <Table>
                                        <TableCaption color={"red"}>
                                            {Object.keys(errorMessage).length !== 0 && errorMessage["corrective"]}
                                        </TableCaption>
                                        <Thead zIndex={1} pos='sticky' top={0} bg={"#E1F1F3"}>
                                            <Tr>
                                                <Th textAlign={"center"}>No</Th>
                                                <Th w={"full"} textAlign={"center"}>Corrective Action</Th>
                                                <Th textAlign={"center"}>
                                                    <Button leftIcon={<AddIcon/>} colorScheme={"yellow"} size={"sm"}
                                                            onClick={() => {
                                                                let tempAdditionalData = JSON.parse(JSON.stringify(additionalData));

                                                                if (Object.keys(tempAdditionalData).length !== 0) {
                                                                    if (tempAdditionalData?.corrective[tempAdditionalData?.corrective?.length - 1]) {
                                                                        tempAdditionalData["corrective"].push(null);
                                                                        setErrorMessage(errorMessage => ({...errorMessage, "corrective": ''}));
                                                                    }
                                                                    else {
                                                                        setErrorMessage(errorMessage => ({...errorMessage, "corrective": "Please fill the the last action first!"}));
                                                                    }

                                                                    setAdditionalData(tempAdditionalData);
                                                                }
                                                            }}
                                                            display={props.type === "CAPA" ? "flex" : "none"}
                                                    >
                                                        <Text className="text-[#492472] ml-2">Add</Text>
                                                    </Button>
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {additionalData?.corrective?.map((datum, index) => (
                                                <Tr key={index}>
                                                    <Td color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"}>
                                                        {index + 1}
                                                    </Td>
                                                    <Td color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"}>
                                                        <Input
                                                            onChange={(e) => handleInputChange(e.target.value, index, "corrective")}
                                                            borderRadius={"lg"}
                                                            borderColor={process.env.REACT_APP_INPUT_COLOR}
                                                            focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                            _hover={{
                                                                borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD
                                                            }}
                                                            placeholder='Please state the corrective action'
                                                            size='sm'
                                                            value={datum ? datum : ''}
                                                            isDisabled={props.type !== "CAPA"}
                                                        />
                                                    </Td>
                                                    <Td color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"}>
                                                        <DeleteIcon boxSize={"1.2em"} className="cursor-pointer"
                                                                    onClick={() => {
                                                                        let tempAdditionalData = JSON.parse(JSON.stringify(additionalData));

                                                                        if (Object.keys(tempAdditionalData).length !== 0) {
                                                                            if (tempAdditionalData.corrective.length !== 1) {
                                                                                tempAdditionalData.corrective.splice(index, 1);
                                                                                setAdditionalData(tempAdditionalData);
                                                                                setErrorMessage(errorMessage => ({...errorMessage, "corrective": ''}));
                                                                            }
                                                                            else setErrorMessage(errorMessage => ({...errorMessage, "corrective": "There should be at least one action left!"}));
                                                                        }
                                                                    }}
                                                                    display={props.type === "CAPA" ? "flex" : "none"}
                                                        />
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>

                                <TableContainer overflowY={"auto"} whiteSpace={"normal"} display={"flex"} w={"full"} maxHeight={"50vh"} border={"1px solid #B7D4CF"} borderRadius={"lg"}>
                                    <Table>
                                        <TableCaption color={"red"}>
                                            {Object.keys(errorMessage).length !== 0 && errorMessage["preventive"]}
                                        </TableCaption>
                                        <Thead zIndex={1} pos='sticky' top={0} bg={"#E1F1F3"}>
                                            <Tr>
                                                <Th textAlign={"center"}>No</Th>
                                                <Th w={"full"} textAlign={"center"}>Preventive Action</Th>
                                                <Th textAlign={"center"}>
                                                    <Button leftIcon={<AddIcon/>} colorScheme={"yellow"} size={"sm"}
                                                            onClick={() => {
                                                                let tempAdditionalData = JSON.parse(JSON.stringify(additionalData));

                                                                if (Object.keys(tempAdditionalData).length !== 0) {
                                                                    if (tempAdditionalData?.preventive[tempAdditionalData?.preventive?.length - 1]) {
                                                                        tempAdditionalData["preventive"].push(null);
                                                                        setErrorMessage(errorMessage => ({...errorMessage, "preventive": ''}));
                                                                    }
                                                                    else {
                                                                        setErrorMessage(errorMessage => ({...errorMessage, "preventive": "Please fill the the last action first!"}));
                                                                    }

                                                                    setAdditionalData(tempAdditionalData);
                                                                }
                                                            }}
                                                            display={props.type === "CAPA" ? "flex" : "none"}
                                                    >
                                                        <Text className="text-[#492472] ml-2">Add</Text>
                                                    </Button>
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {additionalData?.preventive?.map((datum, index) => (
                                                <Tr key={index}>
                                                    <Td color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"}>
                                                        {index + 1}
                                                    </Td>
                                                    <Td color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"}>
                                                        <Input
                                                            onChange={(e) => handleInputChange(e.target.value, index, "preventive")}
                                                            borderRadius={"lg"}
                                                            borderColor={process.env.REACT_APP_INPUT_COLOR}
                                                            focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                            _hover={{
                                                                borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD
                                                            }}
                                                            placeholder='Please state the preventive action'
                                                            size='sm'
                                                            value={datum ? datum : ''}
                                                            isDisabled={props.type !== "CAPA"}
                                                        />
                                                    </Td>
                                                    <Td color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"}>
                                                        <DeleteIcon boxSize={"1.2em"} className="cursor-pointer"
                                                                    onClick={() => {
                                                                        let tempAdditionalData = JSON.parse(JSON.stringify(additionalData));

                                                                        if (Object.keys(tempAdditionalData).length !== 0) {
                                                                            if (tempAdditionalData.preventive.length !== 1) {
                                                                                tempAdditionalData.preventive.splice(index, 1);
                                                                                setAdditionalData(tempAdditionalData);
                                                                                setErrorMessage(errorMessage => ({...errorMessage, "preventive": ''}));
                                                                            }
                                                                            else setErrorMessage(errorMessage => ({...errorMessage, "preventive": "There should be at least one action left!"}));
                                                                        }
                                                                    }}
                                                                    display={props.type === "CAPA" ? "flex" : "none"}
                                                        />
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>

                            </Fragment>
                        }
                    </VStack>
                </ModalBody>

                <ModalFooter alignSelf={"center"}>
                    {!stateConfirm ?
                        <ButtonGroup>
                            <Button className='float-left mr-4' variant='ghost' backgroundColor={"#9D9D9D"} _hover={{ bg: "#7BBAAF" }} color="white" onClick={props.onClose}>{modalConfig.cancel}</Button>

                            <Button className='float-right ml-4' backgroundColor={modalConfig.confirmationColor} _hover={{ bg: "#7BBAAF" }} color="white" mr={3}
                                    onClick={() => setStateConfirm(true)}
                                    display={props.type === "CAPA" ? "flex" : "none"}
                            >
                                {modalConfig.confirmation}
                            </Button>
                        </ButtonGroup>
                        :
                        <HStack spacing={5}>
                            <Text>Are you sure?</Text>
                            {buttonTimeout && setTimeout(() => {
                                setButtonTimeout(false)
                            }, 1500)}
                            <Button isDisabled={buttonTimeout} size={"md"} variant="solid" colorScheme={"green"} onClick={modalConfig.handleConfirmation}>
                                Yes
                            </Button>
                            <Button size={"md"} variant="solid" colorScheme={"red"} onClick={() => {
                                setStateConfirm(false);
                                setButtonTimeout(true);
                            }}>
                                No
                            </Button>
                        </HStack>
                    }
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default TextInput;
