import {Button, Icon, Input, InputGroup, InputLeftElement, useDisclosure, Text} from '@chakra-ui/react';
import {FiFile} from "react-icons/fi";
import {useContext, useEffect, useRef, useState} from "react";
import Axios, {baseURL, getCookie} from '../../../../services/api/Axios';
import Modal from '../../../Modal/AreYouSure';
import {ImportDataContext} from './ImportSparepart_Context';
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {read, utils} from 'xlsx';

const ImportSparepartFileInputBuilder = (props) => {
    const [navigateBack, setNavigateBack] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const {
        isOpen: isOpenErrorModal,
        onOpen: onOpenErrorModal,
        onClose: onCloseErrorModal
    } = useDisclosure()

    const {
        isOpen: isOpenProgressModal,
        onOpen: onOpenProgressModal,
        onClose: onCloseProgressModal
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const headerObject = {
        "ID" : "id",
        "Item Code" : "part_code",
        "Item Name" : "part_name",
        "Brand" : "brand",
        "Type" : "type",
        "Specification" :  "specification",
        "Item Category" : "part_category",
        "Stock" : "qty",
        "Quantity Unit" : "qty_uom",
        "Min Stock" : "qty_min",
        "Max Stock" : "qty_max",
        "Item Status": "part_status",
        "Inventory Type": "inventory_type",
        "Bin Location" : "bin_location",
        "Main Supplier" : "main_supplier",
        "Price" : "price",
        "Lead Time" : "lead_time"
    }

    const inputRef = useRef();
    const classEachInput = "text-md text-[#492472] mb-2 mt-4";

    const fileReader = new FileReader();
    const importDataContext = useContext(ImportDataContext);

    const csvFileToArray = string => {
        let delimiter = ((string.slice(0, string.indexOf("\n"))).includes(';') ? ';' : ',')

        let csvHeader = string.slice(0, string.indexOf("\n") !== -1 ? string.indexOf("\n") : string.length).split(delimiter);
        const csvRows = string.indexOf("\n") !== -1 ? string.slice(string.indexOf("\n") + 1).split("\n") : [];

        csvHeader = csvHeader.map((header, index) => {
            if (header.includes("\r")) {
                return csvHeader[index].substring(0, header.indexOf("\r"));
            }
            return header;
        })

        let rows = [];

        csvRows.map((row) => {
            let arrayRow = row.split(delimiter);

            if (row.length !== 0) {
                let tempObject = {};

                arrayRow.map((data, index) => {
                    tempObject[csvHeader[index]] = data;

                    return 0
                })

                rows.push(tempObject);
            }

            return 0
        })

        let isValidateError = isValidatedDataError(rows);

        if (!isValidateError) {
            const arrays = csvRows.map(i => {
                const values = i.split(delimiter);
                const needInteger = ["Stock","Min Stock","Max Stock","Price", "Lead Time"]
                return csvHeader.reduce((object, header, index) => {
                    if (values[index]) {
                        if (needInteger.includes(header.trim())) {
                            object[header.trim()] = parseFloat(values[index].trim());
                        } else {
                            if (values[index].trim() !== "") {
                                object[header.trim()] = values[index].trim();
                            }
                        }
                    }

                    return object;
                }, {});
            });

            let newArray = arrays.filter(value => Object.keys(value).length !== 0)

            importDataContext.setImportData(newArray)}
    };

    function handleImportFile(e){
        let extension = (e.target.files[0].name)?.substr((e.target.files[0].name)?.lastIndexOf(".") + 1);

        if (extension !== "csv") {
            setErrorMessage(" Format file must be in .csv!");
            onOpenErrorModal();

            return 0
        }
        else {
            e.preventDefault();
            onOpenProgressModal();

            if (e.target.files[0]) {
                fileReader.onload = function (event) {
                    const text = event.target.result;
                    csvFileToArray(text);
                };

                fileReader.readAsText(e.target.files[0]);
            }
        }

        onCloseProgressModal(true);
    }

    async function handleUploadImportFile(){
        let promiseArray = [];

        if ((importDataContext?.importData) ? (importDataContext?.importData).length !== 0 : false) onOpenProgressModal();
        else {
            setErrorMessage("No data provided!");
            onOpenErrorModal();

            return 0
        }

        for (let elements in (importDataContext.importData)){
            promiseArray.push(new Promise((resolve) => {
                try {
                    let tempObject = {};

                    Object.keys((importDataContext.importData)[elements]).map((elem) => {
                        let newHeader = headerObject[elem];
                        tempObject[newHeader] = (importDataContext.importData)[elements][elem]
                    })

                    if (props.selectedAction === "add") {
                        tempObject["sediaan_id"] = Number(Cookies.get("idServing"));
                        tempObject["image_id"] = 1;

                        delete tempObject["part_code"];
                        delete tempObject["id"];
                        delete tempObject["undefined"];

                        const data = JSON.stringify(tempObject)
                        const config = {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + getCookie("accessToken")
                            },
                        }

                        const res = Axios.post(baseURL+"/sparepart/import", data, config)
                        resolve(res)
                    }
                    else {
                        delete tempObject["undefined"];
                        delete tempObject["qty"];
                        delete tempObject["part_code"];

                        let submittedTempObject = JSON.parse(JSON.stringify(tempObject));
                        delete submittedTempObject["id"];

                        const data = JSON.stringify(submittedTempObject)
                        const config = {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + getCookie("accessToken")
                            },
                        }

                        const res = Axios.post(baseURL+"/sparepart/item?id=" + tempObject["id"], data, config)
                        resolve(res)
                    }
                }
                catch (error) {
                    console.error(error);
                }
            }))
        }

        await Promise.all(promiseArray);

        if ((importDataContext?.importData) ? (importDataContext?.importData).length !== 0 : false) {
            console.log((importDataContext?.importData).length !== 0)
            onOpenSuccessModal();
        }

        onCloseProgressModal(true);
    }

    const handleImportExcel = (importedData) => {
        let extension = (importedData.target.files[0].name)?.substr((importedData.target.files[0].name)?.lastIndexOf(".") + 1);

        if (extension !== "xlsx") {
            setErrorMessage(" Format file must be in .xlsx!");
            onOpenErrorModal();

            return 0
        }
        else {
            onOpenProgressModal();
            const files = importedData.target.files
            if (files.length) {
                const file = files[0];
                const reader = new FileReader();
                reader.onload = (event) => {
                    const wb = read(event.target.result);
                    const sheets = wb.SheetNames;

                    if (sheets.length) {
                        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

                        let isValidateError = isValidatedDataError(rows);

                        if (!isValidateError) {
                            importDataContext.setImportData(rows);
                        }
                    }
                }
                reader.readAsArrayBuffer(file);
            }
        }

        onCloseProgressModal(true);
    }

    const handleUploadExcel = async () => {
        let promiseArray = [];

        if ((importDataContext?.importData) ? (importDataContext?.importData).length !== 0 : false) onOpenProgressModal();
        else {
            setErrorMessage("No data provided!");
            onOpenErrorModal();

            return 0
        }

        (importDataContext.importData).map((data) => {
            promiseArray.push(new Promise((resolve) => {
                try {
                    let tempObject = {}

                    Object.keys(data).map((elem) => {
                        let newHeader = headerObject[elem];
                        tempObject[newHeader] = data[elem]

                        return 0
                    })

                    if (props.selectedAction === "add") {
                        tempObject["sediaan_id"] = Number(Cookies.get("idServing"));
                        tempObject["image_id"] = 1;

                        delete tempObject["part_code"];
                        delete tempObject["id"];
                        delete tempObject["undefined"];

                        let submitDataSparePart = Axios.post(baseURL + "/sparepart/import",
                            tempObject,
                            {
                                headers: {'Authorization': 'Bearer ' + getCookie("accessToken")},
                            });

                        resolve(submitDataSparePart)
                    }
                    else {
                        delete tempObject["undefined"];
                        delete tempObject["part_code"];
                        delete tempObject["qty"];

                        let submittedTempObject = JSON.parse(JSON.stringify(tempObject));
                        delete submittedTempObject["id"];

                        let submitDataSparePart = Axios.post(baseURL + "/sparepart/item?id=" + tempObject["id"],
                            submittedTempObject,
                            {
                                headers: {'Authorization': 'Bearer ' + getCookie("accessToken")},
                            });

                        resolve(submitDataSparePart)
                    }
                }
                catch (error) {
                    console.error(error);
                }
            }))

            return 0
        })

        await Promise.all(promiseArray);

        if ((importDataContext?.importData) ? (importDataContext?.importData).length !== 0 : false) {
            console.log((importDataContext?.importData).length !== 0)
            onOpenSuccessModal();
        }


        onCloseProgressModal(true);

    }

    function isValidatedDataError (data) {
        let dataSpareParts = data || [];
        let dataDropdown = props.dataDropdown || {};
        let validateCategory = Object.keys(dataDropdown).map((datumDropdown) => {
            return datumDropdown
        })
        let dataInteger = ["qty", "qty_min", "qty_max", "price", "lead_time"];
        let dataIdentity = ["id", "part_code"];
        let dataProtected = ["qty"];

        let isError = false;

        dataDropdown["qty_uom"] = ["dus", "galon", "kg", "liter", "pak", "pcs", "rim", "set"];
        validateCategory.push("qty_uom");

        dataSpareParts.map((dataSparePart) => {
            Object.keys(headerObject).map((column) => {
                let header = headerObject[column];

                if (isError) return 0;

                if (props.selectedAction === "add") {
                    if (!dataSparePart[column] && !dataIdentity.includes(header) && !dataInteger.includes(header)) {
                        isError = true;
                        setErrorMessage(" Empty value on column: {" + column + "} with Item Name: {" + dataSparePart["Item Name"] + "}!");
                    }
                    else if (dataSparePart[column] && dataIdentity.includes(header)  && !dataInteger.includes(header)) {
                        isError = true;
                        setErrorMessage(" Column: {" + column + "} with Item Name: {" + dataSparePart["Item Name"] + "}, must not be included on Add Data!")
                    }

                    if (dataInteger.includes(header)) {
                        if (isNaN(dataSparePart[column]) || dataSparePart[column].length === 0) {
                            isError = true;
                            setErrorMessage(" Invalid data (must be number): {" + dataSparePart[column] + "} on column {" + column + "} with Item Name: {" + dataSparePart["Item Name"] + "}!");
                        }
                        else if (Number(dataSparePart[column]) < 0) {
                            isError = true;
                            setErrorMessage(" Invalid data (Less than zero): {" + dataSparePart[column] + "} on column {" + column + "} with Item Name: {" + dataSparePart["Item Name"] + "}!");
                        }
                    }
                }
                else if (props.selectedAction === "edit") {
                    if (!dataSparePart[column] && !dataInteger.includes(header)) {
                        isError = true;
                        setErrorMessage(" Empty value on column: {" + column + "} with Item Name: {" + dataSparePart["Item Name"] + "}!");
                    }

                    if (!isNaN(dataSparePart[column]) && dataProtected.includes(header)) {
                        isError = true;
                        setErrorMessage(" Column: {" + column + "} with Item Name: {" + dataSparePart["Item Name"] + "}, must not be included on Edit Data!");
                    }

                    if (dataInteger.includes(header) && !dataProtected.includes(header)) {
                        if (isNaN(dataSparePart[column]) || dataSparePart[column].length === 0) {
                            isError = true;
                            setErrorMessage(" Invalid data (must be number): {" + dataSparePart[column] + "} on column {" + column + "} with Item Name: {" + dataSparePart["Item Name"] + "}!");
                        }
                        else if (Number(dataSparePart[column]) < 0) {
                            isError = true;
                            setErrorMessage(" Invalid data (Less than zero): {" + dataSparePart[column] + "} on column {" + column + "} with Item Name: {" + dataSparePart["Item Name"] + "}!");
                        }
                    }
                }

                if (validateCategory.includes(header)) {
                    if (dataDropdown[header] && !dataDropdown[header].includes(dataSparePart[column])) {
                        isError = true;
                        setErrorMessage(" Invalid data: {" + dataSparePart[column] + "} on column {" + column + "} with Item Name: {" + dataSparePart["Item Name"] + "}!");
                    }
                }

                return column
            })

            return 1
        })

        if(isError) onOpenErrorModal();

        return isError;
    }

    useEffect(() => {
        if (navigateBack) {
            navigate("/item/list");
        }
    }, [navigateBack])

    return (
        <div>
            <Modal
                isOpen={isOpenErrorModal}
                onOpen={onOpenErrorModal}
                onClose={onCloseErrorModal}
                errorMessage={errorMessage}
                type={"Error"}
            />
            <Modal
                isOpen={isOpenProgressModal}
                onOpen={onOpenProgressModal}
                onClose={onCloseProgressModal}
                type={"Progress"}
            />
            <Modal
                isOpen={isOpenSuccessModal}
                onOpen={onOpenSuccessModal}
                onClose={onCloseSuccessModal}
                setRedirect={setNavigateBack}
                type={"Success"}
            />
            <Text className="text-md mb-2 mt-4" textColor={process.env.APP_TEXT_COLOR_BOLD}>{props.name}</Text>
            <InputGroup>
                <InputLeftElement
                pointerEvents="none"
                children={<Icon as={FiFile} />}
                />
                <input type='file' accept={props.selectedFormatFile === "csv" ? ".csv" : ".xlsx"} ref={inputRef} style={{ display: 'none' }} onInput={(e) => (props.selectedFormatFile === "csv" ? handleImportFile(e) : handleImportExcel(e))}></input>
                <Input
                placeholder={"Add file ..."}
                onClick={() => inputRef.current.click()}
                />
                <Button onClick={() => (props.selectedFormatFile === "csv" ? handleUploadImportFile() : handleUploadExcel())} className='mx-4' backgroundColor={process.env.REACT_APP_BUTTON_COLOR} borderRadius="8px" variant='solid' _hover={{}} boxShadow="lg">
                    <h2 className='text-white font-extrabold tracking-wide px-4'>Upload</h2>
                </Button>
            </InputGroup>
        </div>
    );
}
 
export default ImportSparepartFileInputBuilder;