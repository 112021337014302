import React, {useEffect, useState} from "react";

import {
    Button,
    Stack,
    CardBody,
    Text,
    Image,
    Card,
    VStack,
    Center,
    Grid,
    GridItem,
    ButtonGroup,
    useDisclosure,
} from '@chakra-ui/react';

import Axios from "../../../services/api/Axios";
import Cookies from "js-cookie";

import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../src/components/Loader/Loader";

import SparePartPopUp from "../../../components/Modal/SparePartPopUp";
import Modal from "../../../components/Modal/AreYouSure";
import ModalTextInput from "../../../components/Modal/TextInput";

import EmptyList from "../../../assets/img/empty.svg";
import GeneratePDF from "../Resume/GeneratePDF";

const ListHistory = (props) => {
    const history = {
        dataRequestURL : "/request/list?count=1&sediaan_id=" + Cookies.get("idServing") + "&limit=200&order=id,desc",
        dataOutgoingURL : "/request/list?count=1&sediaan_id=" + Cookies.get("idServing") + "&limit=200&status=CLOSE",
        dataOpnameURL : "/opname/list?count=1&status=CLOSE&sediaan_id=" + Cookies.get("idServing") + "&order=updatedAt,desc",
        postReturnURL : "/outgoing/return?id=",
        listReOrderURL : "/reorder/list?status=CLOSE&sediaan_id=" + Cookies.get("idServing") + "&limit=200",
        accessToken : Cookies.get("accessToken"),
        role : Cookies.get("role"),
    }

    const [dataSpareParts,setDataSpareParts] = useState([]);
    const [confirmAction, setConfirmAction] = useState('')

    const [modalData, setModalData] = useState({});
    const [generatePDF, setGeneratePDF] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const handleDataSpareParts = async (event) => {
        switch (event){
            case "request":
                try{
                    let getDataRequests = await Axios.get(history.dataRequestURL,
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    let listAllRequest = [];

                    (getDataRequests?.data?.data?.requests).map((elem) => {
                        (elem.requestSpareparts).map((value) => {
                            let tempObjectData = {};

                            tempObjectData["id"] = value.id
                            tempObjectData["createdAt"] = value.createdAt
                            tempObjectData["part_code"] = value.sparepart?.part_code
                            tempObjectData["part_name"] = value.sparepart?.part_name
                            tempObjectData["brand"] = value.sparepart?.brand
                            tempObjectData["type"] = value.sparepart?.type
                            tempObjectData["part_category"] = value.sparepart?.part_category
                            tempObjectData["bin_location"] = value.sparepart?.bin_location
                            tempObjectData["line"] = value?.machine?.line?.name
                            tempObjectData["machine"] = value?.machine?.code

                            tempObjectData["case_no"] = elem.meta?.request_number
                            tempObjectData["user"] = elem?.user?.name;
                            tempObjectData["qty_request"] = value.qty_request
                            tempObjectData["price"] = value.sparepart?.price

                            listAllRequest.push(tempObjectData);
                        })
                    })

                    setDataSpareParts(listAllRequest);
                }
                catch(error){
                    console.error(error);
                }

                break;

            case "outgoing":
                try{
                    let getDataOutgoings = await Axios.get(history.dataRequestURL,
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    let listAllRequest = [];

                    (getDataOutgoings?.data?.data?.requests).map((elem) => {
                        (elem.requestSpareparts).map((value) => {
                            if (value.qty_receive !== null) {
                                let tempObjectData = {};

                                tempObjectData["id"] = value.id
                                tempObjectData["createdAt"] = value.updatedAt
                                tempObjectData["part_code"] = value.sparepart?.part_code
                                tempObjectData["part_name"] = value.sparepart?.part_name
                                tempObjectData["brand"] = value.sparepart?.brand
                                tempObjectData["type"] = value.sparepart?.type
                                tempObjectData["part_category"] = value.sparepart?.part_category
                                tempObjectData["bin_location"] = value.sparepart?.bin_location
                                tempObjectData["line"] = value?.machine?.line?.name
                                tempObjectData["machine"] = value?.machine?.code

                                tempObjectData["case_no"] = elem.meta?.request_number
                                tempObjectData["user"] = elem?.user?.name;
                                tempObjectData["qty_request"] = Number(value.qty_request)
                                tempObjectData["qty_receive"] = Number(value.qty_receive)
                                tempObjectData["qty_return"] = Number(value.qty_return)
                                tempObjectData["is_returned"] = value.is_returned
                                tempObjectData["price"] = value.sparepart?.price

                                listAllRequest.push(tempObjectData);
                            }

                            return 0
                        })

                        return 0
                    })

                    setDataSpareParts(listAllRequest);
                }
                catch(error){
                    console.error(error);
                }

                break;

            case "incoming":
                try{
                    let getDataIncomings = await Axios.get(history.listReOrderURL,
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    let listAllRequest = [];

                    (getDataIncomings?.data?.data?.reorders).map((elem) => {
                        (elem.reorderSpareparts).map((value) => {
                            let tempObjectData = {};

                            tempObjectData["id"] = value.id
                            tempObjectData["createdAt"] = value.createdAt
                            tempObjectData["part_code"] = value.sparepart?.part_code
                            tempObjectData["part_name"] = value.sparepart?.part_name
                            tempObjectData["brand"] = value.sparepart?.brand
                            tempObjectData["type"] = value.sparepart?.type
                            tempObjectData["part_category"] = value.sparepart?.part_category
                            tempObjectData["main_supplier"] = value.sparepart?.main_supplier

                            tempObjectData["case_no"] = elem.case_number
                            tempObjectData["user"] = elem?.user?.name;
                            tempObjectData["qty_incoming"] = value.qty_incoming
                            tempObjectData["bin_location"] = value.sparepart?.bin_location
                            tempObjectData["price"] = value.sparepart?.price

                            listAllRequest.push(tempObjectData);
                        })
                    })

                    setDataSpareParts(listAllRequest);
                }
                catch(error){
                    console.error(error);
                }

                break;

            case "opname":
                try{
                    let getDataOpnames = await Axios.get(history.dataOpnameURL,
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    let listAllRequest = [];

                    (getDataOpnames?.data?.data?.opnames).map((elem) => {
                        let tempObjectData = {};

                        tempObjectData["id"] = elem.id
                        tempObjectData["createdAt"] = elem.updatedAt
                        tempObjectData["case_no"] = elem.meta?.opname_number
                        tempObjectData["user"] = elem.user?.name
                        tempObjectData["approver"] = elem.approver?.name
                        tempObjectData["opnameSpareparts"] = elem.opnameSpareparts

                        listAllRequest.push(tempObjectData);

                        return 0
                    })

                    setDataSpareParts(listAllRequest);
                }
                catch(error){
                    console.error(error);
                }
                break;

            case "return":
                console.log(modalData)
                try{
                    let postDataReturn = await Axios.post(history.postReturnURL + modalData.id,
                        {qty_return: (modalData.qty_confirmedReturn ? modalData.qty_confirmedReturn : 1)},
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });
                    console.log(postDataReturn);
                    setModalData({});
                    onOpenSuccessModal();
                }
                catch(error){
                    console.error(error);
                }
                break;

            default:
                break;
        }
    };

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    const {
        isOpen: isOpenSparePartPopUp,
        onOpen: onOpenSparePartPopUp,
        onClose: onCloseSparePartPopUp
    } = useDisclosure()

    const {
        isOpen: isOpenTextModal,
        onOpen: onOpenTextModal,
        onClose: onCloseTextModal
    } = useDisclosure()

    const {
        isOpen: isOpenErrorModal,
        onOpen: onOpenErrorModal,
        onClose: onCloseErrorModal
    } = useDisclosure()

    useEffect(() => {
        if (props.props.page === "request") handleDataSpareParts("request").then();
        else if (props.props.page === "outgoing") handleDataSpareParts("outgoing").then();
        else if (props.props.page === "incoming" && history.role !== "Teknisi") handleDataSpareParts("incoming").then();
        else if (props.props.page === "opname" && history.role !== "Teknisi") handleDataSpareParts("opname").then();
        if (confirmAction !== '') handleDataSpareParts("return").then(() => setConfirmAction(''));
    },[confirmAction]);

    return (
        <>
            <SparePartPopUp
                isOpen={isOpenSparePartPopUp}
                onOpen={onOpenSparePartPopUp}
                onClose={onCloseSparePartPopUp}
                setConfirmAction={setConfirmAction}
                modalData={modalData}
                setModalData={setModalData}
                type={"Return"}
            />

            <Modal
                isOpen={isOpenSuccessModal}
                onOpen={onOpenSuccessModal}
                onClose={onCloseSuccessModal}
                type={"Success"}
            />

            <ModalTextInput
                isOpen={isOpenTextModal}
                onOpen={onOpenTextModal}
                onClose={onCloseTextModal}
                data={modalData}
                type={"History"}
            />

            <Modal
                isOpen={isOpenErrorModal}
                onOpen={onOpenErrorModal}
                onClose={onCloseErrorModal}
                errorMessage={errorMessage}
                type={"Error"}
            />

            {(dataSpareParts)?.length === 0 ?
                <VStack paddingBottom={"6vh"}>
                    <Image boxSize={"sm"} src={EmptyList} alt={"empty"}/>
                    <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                        No items in here..
                    </Text>
                </VStack>
                :
                <Stack id={"listHistoryMobile"} w={"full"} position={"relative"} px={"2vw"} maxH={"67vh"} overflowY={"auto"} display={"flex"} paddingBottom={"6vh"}>
                    <InfiniteScroll scrollableTarget={"listHistoryMobile"} dataLength={dataSpareParts.length} next={() => ''} hasMore={false} loader={Loader()}>
                        {dataSpareParts.map((dataSparePart, index) => (
                            <Card
                                key={index}
                                w={"full"}
                                bg={"white"}
                                borderRadius={"xl"}
                                direction={{ base: 'column', sm: 'row' }}
                                overflow='hidden'
                                variant='outline'
                                position={"initial"}
                                mt={"1vh"}
                            >
                                <CardBody w={"full"}>
                                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} py={2}>
                                        Case Number: {dataSparePart.case_no}
                                    </Text>
                                    <Text fontWeight={"bold"} border={"1px"} borderRadius={"md"} bg={"gray.100"} color={"royalblue"}>{(new Date(dataSparePart.createdAt)).toString().slice(0, 33)}</Text>
                                    {props.props.page === "opname" ?
                                        <Grid templateColumns='repeat(2, 1fr)' gap={2} py={5}>
                                            <GridItem w='100%' h='12'>
                                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Person in Charge</Text>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(dataSparePart.user)?.length > 15 ? (dataSparePart.user).slice(0, 15) + ".." : (dataSparePart.user)}</Text>
                                            </GridItem>
                                            <GridItem w='100%' h='12'>
                                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Approved by</Text>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(dataSparePart.approver)?.length > 15 ? (dataSparePart.approver).slice(0, 15) + ".." : (dataSparePart.approver)}</Text>
                                            </GridItem>
                                        </Grid>
                                        :
                                        <Grid templateColumns='repeat(2, 1fr)' gap={2} py={5}>
                                            <GridItem w='100%' h='12'>
                                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Item Code</Text>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(dataSparePart.part_code)?.length > 15 ? (dataSparePart.part_code).slice(0, 15) + ".." : (dataSparePart.part_code)}</Text>
                                            </GridItem>
                                            <GridItem w='100%' h='12'>
                                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Item Name</Text>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(dataSparePart.part_name)?.length > 15 ? (dataSparePart.part_name).slice(0, 15) + ".." : (dataSparePart.part_name)}</Text>
                                            </GridItem>

                                            <GridItem w='100%' h='12'>
                                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Brand</Text>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(dataSparePart.brand)?.length > 15 ? (dataSparePart.brand).slice(0, 15) + ".." : (dataSparePart.brand)}</Text>
                                            </GridItem>
                                            <GridItem w='100%' h='12'>
                                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Type</Text>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(dataSparePart.type)?.length > 15 ? (dataSparePart.type).slice(0, 15) + ".." : (dataSparePart.type)}</Text>
                                            </GridItem>

                                            <GridItem w='100%' h='12'>
                                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(props.props.page === "request" || props.props.page === "incoming") ? "Bin Location" : "Quantity Receive"}</Text>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(props.props.page === "request" || props.props.page === "incoming") ? dataSparePart.bin_location : dataSparePart.qty_receive}</Text>
                                            </GridItem>
                                            <GridItem w='100%' h='12'>
                                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(props.props.page === "incoming") ? "Quantity Incoming" : "Quantity Request"}</Text>
                                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(props.props.page === "incoming") ? dataSparePart.qty_incoming : dataSparePart.qty_request}</Text>
                                            </GridItem>
                                        </Grid>
                                    }
                                    <Center>
                                        <ButtonGroup>
                                            <Button
                                                size={"sm"}
                                                variant='solid'
                                                colorScheme='orange'
                                                position={"initial"}
                                                display={(props.props.page === "outgoing" || props.props.page === "opname") ? "flex" : "none"}
                                                onClick={() => {
                                                    setModalData(dataSparePart);
                                                    if (props.props.page === "outgoing") {
                                                        if (!dataSparePart.is_returned) {
                                                            if (dataSparePart.qty_return > 0) {
                                                                setErrorMessage(" Sparepart is still processed to be returned!");
                                                                onOpenErrorModal();
                                                            }
                                                            else {
                                                                setModalData(dataSparePart);
                                                                onOpenSparePartPopUp();
                                                            }
                                                        }
                                                        else {
                                                            setErrorMessage(" Sparepart had already been returned!");
                                                            onOpenErrorModal();
                                                        }
                                                    }
                                                    else onOpenTextModal();
                                                }}
                                            >
                                                <Text fontSize={"sm"} fontWeight={"bold"}>{props.props.page === "outgoing" ? "Return" : "View"}</Text>
                                            </Button>

                                            {props.props.page === "opname" &&
                                                <>
                                                {!generatePDF.includes(dataSparePart.id) ?
                                                    <Button
                                                        size={"sm"}
                                                        variant='solid'
                                                        colorScheme='blue'
                                                        position={"initial"}
                                                        onClick={() => {
                                                            let tempGeneratePDFArray = Array.from(generatePDF);

                                                            tempGeneratePDFArray.push(dataSparePart.id);
                                                            setGeneratePDF(tempGeneratePDFArray);
                                                        }}
                                                    >
                                                        <Text fontSize={"sm"} fontWeight={"bold"}>Download</Text>
                                                    </Button>
                                                    :
                                                    <GeneratePDF dataOrder={dataSparePart} stockOpnameIdentifier={dataSparePart.case_no}/>
                                                }
                                                </>
                                            }

                                        </ButtonGroup>
                                    </Center>
                                </CardBody>
                            </Card>
                        ))}
                    </InfiniteScroll>
                </Stack>
            }
        </>
    );
}

export default ListHistory;