import React, { useState, useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Select from "react-select";

import SparePartSubHeader from "./SubHeader";

import {
    Button,
    Avatar,
    FormLabel,
    FormControl,
    Container,
    VStack,
    ButtonGroup,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    GridItem,
    Text,
    Grid,
    Image,
    useDisclosure,
} from "@chakra-ui/react";

import Axios from "../../services/api/Axios";
import Cookies from "js-cookie";
import queryString from "query-string";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/AreYouSure";

import sparePart from "../../assets/img/imageSparePart.svg"
import {useMediaQuery} from "react-responsive";

const RequestNow = () => {
    let history = {
        dataSparePartItemURL : "/sparepart/item?id=",
        imageSparePartURL : "/image/item?id=",
        getDataLinesURL : "/line/list?order=name&limit=9999&count=1&sediaan_id=",
        getDataMachinesURL : "/machine/list?limit=9999&order=code&line_id=",
        servingURL : "/sediaan/list",
        postRequestNow : "/request/now",
        accessToken : Cookies.get("accessToken"),
        idServing : Cookies.get("idServing"),
        serving : Cookies.get("serving"),
        role : Cookies.get("role"),
    }

    const navigate = useNavigate();

    const {search} = useLocation();
    const params = queryString.parse(search);

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" })

    const [dataInitialization, setDataInitialization] = useState(false);
    const [dataSparePart, setDataSparePart] = useState({});
    const [dataServings, setDataServings] = useState([]);
    const [dataLines,setDataLines] = useState([]);
    const [dataMachines,setDataMachines] = useState([]);

    const [confirmSubmitData, setConfirmSubmitData] = useState(false);
    const [navigateBack, setNavigateBack] = useState(false);

    const [columnState, setColumnState] = useState({
        //"database_column_name" : ["Name", "inputType", "isDisable", "isRequired", "isInvalid"],
        "sediaan_id" : ["Organizer", "select", (!(history.serving === "MEX" || history.role === "Superior")) , true, false],
        "line" : ["Plant", "select", false, true, false],
        "machine" : ["Department", "select", false, true, false],
        "qty_request" : ["Quantity Request", "number", false, true, false],
    });

    const handleDataSparePart = async (event, target, value) => {
        switch (event) {
            case "get":
                try{
                    let getDataSparePart = await Axios.get(history.dataSparePartItemURL + value,
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });

                    let getDataImage = await Axios.get(history.imageSparePartURL + getDataSparePart?.data?.data?.sparepart.image_id,
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });

                    getDataSparePart["data"]["data"]["sparepart"]["image_bin"] = getDataImage?.data?.data?.image?.bin;

                    setDataSparePart(getDataSparePart?.data?.data?.sparepart);
                }
                catch(error){
                    console.error(error);
                }

                break;

            case "add":
                let tempDataAddSpareParts = JSON.parse(JSON.stringify(dataSparePart));

                tempDataAddSpareParts[target] = value;

                if (target === "sediaan_id") {
                    handleGetDataLines(value).then(() => {
                        tempDataAddSpareParts["line"] = 0;
                        tempDataAddSpareParts["machine"] = 0;
                    });
                }
                else if (target === "line") {
                    handleGetDataMachines(value).then(() => tempDataAddSpareParts["machine"] = 0);
                }

                setDataSparePart(tempDataAddSpareParts);

                break;

            case "post":
                let tempColumnState = JSON.parse(JSON.stringify(columnState));
                let isInvalid = false;

                Object.keys(tempColumnState).map((elem) => {
                    if (tempColumnState[elem][3] && !dataSparePart[elem]) {
                        tempColumnState[elem][4] = true;
                        isInvalid = true;
                    }
                    return ''
                })

                console.log(dataSparePart);
                if (!isInvalid) {
                    let postRequestData = {};
                    postRequestData["sparepart_id"] = Number(value);
                    postRequestData["qty_request"] = Number(dataSparePart["qty_request"]);
                    postRequestData["machine_id"] = Number(dataSparePart["machine"]);

                    console.log(postRequestData);
                    try{
                        let postRequest = await Axios.post(history.postRequestNow + (history.role === "Superior" ? ("?sediaan_id=" + history.idServing)  : ''),
                            postRequestData,
                            {
                                headers: {'Authorization': 'Bearer ' + history.accessToken},
                            });

                        console.log(postRequest)
                        onOpenSuccessModal();
                    }
                    catch(error){
                        console.error(error);
                    }
                }

                setColumnState(tempColumnState);
                break;
            default:
                break;
        }
    }

    const handleGetDataServing = async () => {
        try{
            let statusServing = await Axios.get(history.servingURL,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let tempStatusServings = [];

            statusServing.data?.data?.sediaans?.map((serving) => {
                let statusServing = {};

                statusServing["label"] = (serving?.nama).charAt(0).toUpperCase() + (serving?.nama).slice(1)
                statusServing["value"] = serving?.id

                tempStatusServings.push(statusServing);
            })

            setDataServings(tempStatusServings);
        }
        catch(error){
            console.error(error);
        }
    }

    const handleGetDataLines = async (id) => {
        try{
            let getDataLines = await Axios.get(history.getDataLinesURL + id,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let tempStatusLines = [];

            getDataLines.data?.data?.lines?.map((line) => {
                let statusLine = {};

                statusLine["label"] = line?.name
                statusLine["value"] = line?.id

                tempStatusLines.push(statusLine);
            })

            setDataLines(tempStatusLines);
            setDataMachines([]);
        }
        catch(error){
            console.error(error);
        }
    }

    const handleGetDataMachines = async (id) => {
        try{
            let getDataMachines = await Axios.get(history.getDataMachinesURL + id,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let tempStatusMachines = [];

            getDataMachines.data?.data?.machines?.map((machine) => {
                let statusMachine = {};

                statusMachine["label"] = machine?.code
                statusMachine["value"] = machine?.id

                tempStatusMachines.push(statusMachine);
            })

            setDataMachines(tempStatusMachines);
        }
        catch(error){
            console.error(error);
        }
    }

    const {
        isOpen: isOpenRequestModal,
        onOpen: onOpenRequestModal,
        onClose: onCloseRequestModal
    } = useDisclosure()

    const {
        isOpen: isOpenSuccessModal,
        onOpen: onOpenSuccessModal,
        onClose: onCloseSuccessModal
    } = useDisclosure()

    useEffect(() => {
        if (!dataInitialization) handleDataSparePart("get", '', params.id).then(() => handleGetDataServing().then(() =>  handleGetDataLines(history.idServing).then(() => setDataInitialization(true))));
        if (confirmSubmitData) handleDataSparePart("post", '', params.id).then(() => setConfirmSubmitData(false));
        if (navigateBack) navigate("/request/list");
    },[dataSparePart, confirmSubmitData, navigateBack])

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingBottom={!isTabletOrMobile ? "5%" : "10vh"} paddingTop={5}>
                <Modal
                    isOpen={isOpenRequestModal}
                    onOpen={onOpenRequestModal}
                    onClose={onCloseRequestModal}
                    setConfirmSentResetPassword={setConfirmSubmitData}
                    type={"Request"}
                />

                <Modal
                    isOpen={isOpenSuccessModal}
                    onOpen={onOpenSuccessModal}
                    onClose={onCloseSuccessModal}
                    setRedirect={setNavigateBack}
                    type={"Success"}
                />

                <SparePartSubHeader />

                <Container maxW={!isTabletOrMobile ? "50%" : "full"} centerContent>
                    <VStack w={"full"} spacing={5}>
                        <Avatar size={"2xl"} src={dataSparePart["image_bin"]} icon={<Image src={sparePart} />} marginBottom={"2%"}/>

                        <Grid templateColumns='repeat(2, 1fr)' gap={2} py={2} w={"75%"}>
                            <GridItem w='100%' bg={"whitesmoke"} borderRadius={"lg"}>
                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Item Code</Text>
                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{dataSparePart.part_code}</Text>
                            </GridItem>
                            <GridItem w='100%' bg={"whitesmoke"} borderRadius={"lg"}>
                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Item Name</Text>
                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(dataSparePart.part_name)?.length > (!isTabletOrMobile ? 33 : 14) ? (dataSparePart.part_name).slice(0, (!isTabletOrMobile ? 33 : 14)) + ".." : (dataSparePart.part_name)}</Text>
                            </GridItem>

                            <GridItem w='100%' bg={"whitesmoke"} borderRadius={"lg"}>
                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Brand</Text>
                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(dataSparePart.brand)?.length > (!isTabletOrMobile ? 33 : 14) ? (dataSparePart.brand).slice(0, (!isTabletOrMobile ? 33 : 14)) + ".." : (dataSparePart.brand)}</Text>
                            </GridItem>
                            <GridItem w='100%' bg={"whitesmoke"} borderRadius={"lg"}>
                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Type</Text>
                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{(dataSparePart.type)?.length > (!isTabletOrMobile ? 33 : 14) ? (dataSparePart.type).slice(0, (!isTabletOrMobile ? 33 : 14)) + ".." : (dataSparePart.type)}</Text>
                            </GridItem>

                            <GridItem w='100%' bg={"whitesmoke"} borderRadius={"lg"}>
                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Stock</Text>
                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{dataSparePart.qty}</Text>
                            </GridItem>
                            <GridItem w='100%' bg={"whitesmoke"} borderRadius={"lg"}>
                                <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>Quantity in Request</Text>
                                <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{dataSparePart.qty_inrequest}</Text>
                            </GridItem>
                        </Grid>

                        <VStack w={"full"}>
                            {Object.keys(columnState).map((elem, index) => (
                                <FormControl key={elem + index} isRequired={columnState[elem][3]} isInvalid={columnState[elem][3] && !dataSparePart[elem] && columnState[elem][4]}>
                                    <Grid templateColumns='repeat(2, 1fr)' gap={2} py={2} w={"100%"}>
                                        <GridItem w='100%' borderRadius={"lg"}>
                                            <FormLabel
                                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                textAlign={"center"}
                                                fontWeight={"bold"}
                                            >
                                                {columnState[elem][0]}
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem w='100%' borderRadius={"lg"}>
                                            {columnState[elem][1] === "select" ?
                                                <Select
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            textAlign: "center",
                                                            borderColor: (columnState[elem][3] && !dataSparePart[elem] && columnState[elem][4]) ? "red" : state.isFocused ? 'peachpuff' : 'rebeccapurple',
                                                            borderRadius: 10,
                                                            ":hover": {
                                                                borderColor: (columnState[elem][3] && !dataSparePart[elem] && columnState[elem][4]) ? "red" : "mediumpurple"
                                                            },
                                                        }),
                                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                            return {
                                                                ...styles,
                                                                backgroundColor: isDisabled
                                                                    ? undefined
                                                                    : isSelected
                                                                        ? data.color
                                                                        : isFocused
                                                                            ? "lightpink"
                                                                            : undefined,
                                                                color: isDisabled
                                                                    ? '#ccc'
                                                                    : isSelected
                                                                        ? "green"
                                                                            ? process.env.REACT_APP_BORDER_COLOR
                                                                            : 'black'
                                                                        : data.color,
                                                                cursor: isDisabled ? 'not-allowed' : 'default',

                                                                ':active': {
                                                                    ...styles[':active'],
                                                                    backgroundColor: !isDisabled
                                                                        ? isSelected
                                                                            ? data.color
                                                                            : "green"
                                                                        : undefined,
                                                                },
                                                            };
                                                        },
                                                    }}
                                                    value={elem === "sediaan_id" ? (dataServings.find(serving => serving.value === dataSparePart[elem]) || [])
                                                        :
                                                        (elem === "line" ? (dataLines.find(line => line.value === dataSparePart[elem]) || []) : (dataMachines.find(machine => machine.value === dataSparePart[elem])) || [])
                                                    }
                                                    onChange={(e) => handleDataSparePart("add", elem, e.value).then(() => columnState[elem][4] = e.value === '')}
                                                    isDisabled={(elem === "machine" ? !dataSparePart["line"] : columnState[elem][2])}
                                                    options={elem === "sediaan_id" ? dataServings
                                                        :
                                                        (elem === "line" ? dataLines : dataMachines )
                                                    }
                                                />
                                                : columnState[elem][1] === "number" &&
                                                <NumberInput
                                                    allowMouseWheel
                                                    min={0.001}
                                                    max={999}
                                                    step={1}
                                                    onChange={(e) => handleDataSparePart("add", elem, Number(e)).then(() => columnState[elem][4] = Number(e) === 0)}
                                                >
                                                    <NumberInputField
                                                        placeholder={'0'}
                                                        color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                        borderColor={process.env.REACT_APP_INPUT_COLOR }
                                                        borderRadius={"xl"}
                                                        _hover={{
                                                            borderColor: (process.env.REACT_APP_TEXT_COLOR_BOLD )
                                                        }}
                                                        _focusVisible={{
                                                            borderColor: (process.env.REACT_APP_ENV_COLOR),
                                                            boxShadow: ("0 0 0 1px " + process.env.REACT_APP_ENV_COLOR)
                                                        }}
                                                    />
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>
                                            }
                                        </GridItem>
                                    </Grid>
                                </FormControl>
                            ))}
                        </VStack>
                        <ButtonGroup gap={5}>
                            <Button
                                colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                                borderRadius={"8px"}
                                boxShadow="lg"
                                _hover={{}}
                                onClick={onOpenRequestModal}
                                disabled={!dataSparePart?.qty}
                            >
                                Request Now!
                            </Button>
                        </ButtonGroup>
                    </VStack>
                </Container>
            </Container>
            <Footer />
        </>
    );
}

export default RequestNow;