import axios from 'axios'

export default axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL
});

export function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
}

export const baseURL = process.env.REACT_APP_BASE_API_URL;
