import {
    Box,
    Button,
    Flex,
    Image,
    Spacer,
    Text,
    useMediaQuery,
    SimpleGrid,
    Menu,
    MenuButton, Input, AvatarBadge, IconButton, Avatar, Center
} from '@chakra-ui/react';
import { Grid, GridItem } from '@chakra-ui/react'
import { React, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {AttachmentIcon} from "@chakra-ui/icons";


const HeroMenus = (props) => {
    const navigate = useNavigate();
    // const handleNavigate = useCallback((path) => navigate(path, {replace: true}), [navigate]);

    return (
        <GridItem w='100%' h='auto' onClick={() => navigate(props.path)}>
            <Center>
                <Avatar className={"cursor-pointer"} size={"20vw"} src={props.icon} marginBottom={"2%"}/>
            </Center>
            <Text className='text-center font-bold text-xl mt-2 cursor-pointer' textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{props.title}</Text>
        </GridItem>
    );
}

export default HeroMenus;