import {Button, Radio, RadioGroup, Stack, Text} from '@chakra-ui/react';
import {useContext, useEffect, useState} from 'react';
import { utils, writeFile } from 'xlsx';

import SparepartImportIcon from "../../../../assets/icons/hero/Sparepart/Hero_import.svg";

import { CheckboxExportContext } from './ExportSparepart_Context';

import ExportSparepartFilter from './ExportSparepart_Filter';
import { FilterContext } from './ExportSparepart_Context';

const ExportSparepartButtonCollection = (props) => {
    const [dataInitialization, setDataInitialization] = useState(false);
    const checkbox_export_context = useContext(CheckboxExportContext);
    const filterContext = useContext(FilterContext)

    useEffect(() => {
        if (!dataInitialization) props.handleGetAllDataSpareParts().then(() => setDataInitialization(true));
    }, [])

    function detranslateFilter(elem){
        let base = ["ID","Item Code","Item Name","Brand","Type","Specification","Item Category","Stock","Min Stock","Max Stock","Item Status","Inventory Type","Bin Location","Quantity Unit","Main Supplier","Price", "Lead Time"]
        let ref = ["id","part_code","part_name","brand","type","specification","part_category","qty","qty_min","qty_max","part_status","inventory_type","bin_location","qty_uom","main_supplier","price", "lead_time"]
        return base[ref.indexOf(elem)]
    }

    function handleExportButton(){
        let array = [];

        if (checkbox_export_context.checkboxState.length === 0){
            array = [Object.keys(props.allDataSpareParts[0])].concat(props.allDataSpareParts)
        }else{
            array = [Object.keys(checkbox_export_context.checkboxState[0])].concat(checkbox_export_context.checkboxState)
        }

        if (Object.keys(filterContext.column).length !== 0){
            array[0] = []
            for (let i = 0; i < filterContext.column.length; i++){
                array[0].push(detranslateFilter(filterContext.column[i]))
            }
            for (let i = 1; i < array.length; i++){
                let temp = array[i]
                array[i] = []
                for (let j = 0; j < filterContext.column.length; j++){
                    array[i].push(temp[filterContext.column[j]])
                }
            }
        }

        let boi =  array.map(it => {
            return Object.values(it).join(';');
        }).join('\n')


        const jsonString = `data:text/csv;chatset=utf-8,${encodeURIComponent(
            boi
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "Sparepart_Export.csv";

        link.click();

    }

    const handleExportExcel = () => {
        const headerObject = {
            "id" : "ID",
            "part_code" : "Item Code",
            "part_name" : "Item Name",
            "brand" : "Brand",
            "type" : "Type",
            "specification" : "Specification",
            "part_category" : "Item Category",
            "qty" : "Stock",
            "qty_uom" : "Quantity Unit",
            "qty_min" : "Min Stock",
            "qty_max" : "Max Stock",
            "part_status" : "Item Status",
            "inventory_type" : "Inventory Type",
            "bin_location" : "Bin Location",
            "main_supplier" : "Main Supplier",
            "price" : "Price",
            "lead_time" : "Lead Time"
        }

        const headings = [];
        let heading = [];

        (filterContext.column).map((header) => (
            heading.push(headerObject[header])
        ))

        headings.push(heading);

        let array = [];
        let exportExcelSparePart = [];

        if (checkbox_export_context.checkboxState.length === 0){
            array = [Object.keys(props.allDataSpareParts[0])].concat(props.allDataSpareParts)
        }else{
            array = [Object.keys(checkbox_export_context.checkboxState[0])].concat(checkbox_export_context.checkboxState)
        }

        if (Object.keys(filterContext.column).length !== 0){
            array[0] = []
            for (let i = 0; i < filterContext.column.length; i++){
                array[0].push(detranslateFilter(filterContext.column[i]))
            }
            for (let i = 1; i < array.length; i++){
                let temp = array[i]
                array[i] = []
                for (let j = 0; j < filterContext.column.length; j++){
                    array[i].push(temp[filterContext.column[j]])
                }
            }
        }

        array.map((elem, index) => {
            if (index !== 0) {
                let tempObject = {};

                array[0].map((header, indexHeader) => (
                    tempObject[header] = elem[indexHeader]
                ))

                exportExcelSparePart.push(tempObject);
            }
            return exportExcelSparePart;
        })

        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, exportExcelSparePart, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Item');
        writeFile(wb, 'Export Item.xlsx');
    }

    return (
        <div className='mt-[1vh] flow-root'>
            <Stack direction='row' spacing={4} align='center' className='mx-auto float-right'>
                <Text className='font-extrabold tracking-wide' textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>Choose Format File</Text>
                <RadioGroup defaultValue={"csv"} onChange={props.setSelectedFormatFile} value={props.selectedFormatFile}>
                    <Stack spacing={5} direction={"row"}>
                        <Radio colorScheme={process.env.REACT_APP_BORDER_COLOR} value={"excel"}>
                            Microsoft Excel (.xlsx)
                        </Radio>
                        <Radio colorScheme={"red"} value={"csv"}>
                            CSV (.csv)
                        </Radio>
                    </Stack>
                </RadioGroup>

                <ExportSparepartFilter />
                <Button onClick={() => (props.selectedFormatFile === "csv" ? handleExportButton() : handleExportExcel())} className='bottom-0' backgroundColor='#19C4E3' borderRadius="8px" variant='solid' _hover={{}} boxShadow="lg">
                    <img src={SparepartImportIcon} alt="" />
                    <h2 className='text-white font-extrabold tracking-wide ml-2'>Export</h2>
                </Button>
            </Stack>
        </div>
    );
}
 
export default ExportSparepartButtonCollection;