import React, {useState} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import SparePartSubHeader from "./SubHeader";
import SparePartMenus from "./ActionMenu";
import SparePartTables from "./Tables";
import {Container, Divider} from "@chakra-ui/react";
import {useMediaQuery} from "react-responsive";
import HeaderHistoryMobile from "./Mobile/HeaderHistory";
import ListHistoryMobile from "./Mobile/ListHistory";

import { HistoryProvider } from "./HistoryContext";

const History = (props) => {
    const [searchItems, setSearchItems] = useState({});
    const [isReset, setIsReset] = useState(false);
    const [doFilter, setDoFilter] = useState(false);

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" })

    return (
        <HistoryProvider>
            <Navbar />
            {!isTabletOrMobile ?
                <Container maxW={"full"} paddingTop={"2vh"}>
                    <SparePartSubHeader/>

                    <SparePartMenus
                        props={props}
                        doFilter={doFilter}
                        setDoFilter={setDoFilter}
                    />

                    <SparePartTables
                        searchItems={searchItems}
                        setSearchItems={setSearchItems}
                        isReset={isReset}
                        setIsReset={setIsReset}
                        props={props}
                        doFilter={doFilter}
                        setDoFilter={setDoFilter}
                    />
                </Container>
                :
                <>
                <Container width={"100vw"}>
                    <HeaderHistoryMobile props={props}/>
                </Container>

                <Divider variant={"dashed"} borderColor={process.env.REACT_APP_BORDER_COLOR}/>

                <ListHistoryMobile
                    props={props}
                />
                </>
            }

            <Footer />
        </HistoryProvider>
    );
}

export default History;
