import {Button, Divider, HStack, Radio, RadioGroup, Spacer, Stack, Text} from '@chakra-ui/react';
import { useContext } from "react";
import { ImportDataContext } from './ImportSparepart_Context';
import ImportSparepartFileInputBuilder from './ImportSparepart_FileInputBuilder';

const ImportSparepartButtonCollection = (props) => {
    const importDataContext = useContext(ImportDataContext);

    return (
        <div className='mt-[4vh] flow-root'>
            <HStack spacing={5} height={"50px"} align={"center"} w={"full"} bg={"purple.50"} borderRadius={"lg"}>
                <Spacer/>

                <Text className='font-extrabold tracking-wide' textColor={process.env.SPAREPART_REACT_APP_TEXT_COLOR_BOLD}>Choose Action</Text>
                <RadioGroup defaultValue={"add"} onChange={(e) => {
                    importDataContext.setImportData([]);
                    props.setSelectedAction(e);
                }} value={props.selectedAction}>
                    <Stack spacing={5} direction='row'>
                        <Radio colorScheme={process.env.REACT_APP_BORDER_COLOR} value={"add"}>
                            Add new data
                        </Radio>
                        <Radio colorScheme={"red"} value={"edit"}>
                            Edit existing data
                        </Radio>
                    </Stack>
                </RadioGroup>

                <Divider orientation={"vertical"} variant={"solid"} borderColor={process.env.REACT_APP_BORDER_COLOR}/>

                <Text className='font-extrabold tracking-wide' textColor={process.env.SPAREPART_REACT_APP_TEXT_COLOR_BOLD}>Choose Format File</Text>
                <RadioGroup defaultValue={"excel"} onChange={(e) => {
                    importDataContext.setImportData([]);
                    props.setSelectedFormatFile(e);
                }} value={props.selectedFormatFile}>
                    <Stack spacing={5} direction={"row"}>
                        <Radio colorScheme={process.env.REACT_APP_BORDER_COLOR} value={"excel"}>
                            Microsoft Excel (.xlsx)
                        </Radio>
                        <Radio colorScheme={"red"} value={"csv"}>
                            CSV (.csv)
                        </Radio>
                    </Stack>
                </RadioGroup>

                <Spacer/>
            </HStack>

            <Stack direction='row' spacing={4} align='center' className='float-left'>
                <ImportSparepartFileInputBuilder name="Choose file to be imported" selectedFormatFile={props.selectedFormatFile} selectedAction={props.selectedAction} dataDropdown={props.dataDropdown}/>
            </Stack>
            <Stack direction='row' spacing={4} align='center' className='mx-auto float-right mt-12'>
                <Button className='bottom-0' backgroundColor={process.env.REACT_APP_ENV_COLOR} borderRadius="8px" variant='solid' _hover={{}} boxShadow="lg">
                    <a href={props.selectedFormatFile === "csv" ? "/template.csv" : "/templateExcel.xlsx"} download={props.selectedFormatFile === "csv" ? "Template WPM Warehouse Import.csv" : "Template WPM Warehouse Import.xlsx"}>
                        <h2 className='text-white font-extrabold tracking-wide'>Download Format File</h2>
                    </a>
                </Button>
            </Stack>
        </div>
    );
}

export default ImportSparepartButtonCollection;