import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Center,
    Image,
    Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import Cookies from "js-cookie";
import Axios from "../../services/api/Axios";

import EmptyList from "../../assets/img/empty.svg";

const DashboardTableMain = (props) => {
    const [dataInitialization, setDataInitialization] = useState(false);
    const [dataUsages, setDataUsages] = useState([]);

    const columnName = [
        "Plant","Department","Total Qty Outgoing","Total Outgoing Value"
    ];
    
    const history = {
        dataUsagesURL : "/request/rekapLine?sediaan_id=" + Cookies.get("idServing") + "&status=CLOSE",
        accessToken : Cookies.get("accessToken"),
    }

    const handleGetDataUsage = async (isFilter) => {
        let queryString = ''
        if (isFilter) {
            Object.keys(props.dataFilter).map((data) => {
                queryString += '&' + data + '=' + props.dataFilter[data];
            })
        }

        try{
            let getDataServices = await Axios.get(history.dataUsagesURL + queryString,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            setDataUsages(getDataServices?.data?.data?.rekapLine);
        }
        catch(error){
            console.error(error);
        }
    }

    useEffect(() => {
        if (!dataInitialization) handleGetDataUsage(false).then(() => setDataInitialization(true));
        if (props.confirmAction === "search") handleGetDataUsage(true).then(() => props.setConfirmAction(''));
        if (props.confirmAction === "reset") {
            setDataInitialization(false);
            props.setConfirmAction('');
        }
    }, [props.confirmAction])

    return (
        <div>
            {(dataUsages)?.length === 0 ?
                <Center w={"full"}>
                    <Image boxSize={"sm"} src={EmptyList} alt={"empty"}/>
                    <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                        No items in here..
                    </Text>
                </Center>
                :
                <TableContainer className='border-solid border-[1px] border-[#B7D4CF]' maxHeight={"58vh"} overflowY={"auto"} whiteSpace={"normal"} display={"flex"}>
                    <Table variant='simple' size={"lg"}>
                        <Thead zIndex={1} pos='sticky' top={0} bg={process.env.REACT_APP_TABLE_COLOR}>
                            <Tr>
                                {columnName.map((elem) => (
                                    <Th key={elem}>
                                        <h2 className='text-center font-bold text-sm'>{elem}</h2>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>

                        <Tbody>
                            {dataUsages.map((elem) => (
                                <Tr key={elem?.machine_id?.id}>
                                    <Td><Center>{elem?.machine_id?.line?.name}</Center></Td>
                                    <Td><Center>{elem?.machine_id?.code}</Center></Td>
                                    <Td><Center>{elem?.total_qty_receive}</Center></Td>
                                    <Td><Center>Rp {elem?.total_value},00</Center></Td>
                                </Tr>
                            ))}
                        </Tbody>

                    </Table>
                </TableContainer>
            }
        </div>
    );
}
 
export default DashboardTableMain;