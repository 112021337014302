import React  from "react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Text,
    Button,
    ButtonGroup,
    Flex,
    Spacer,
} from "@chakra-ui/react";

import { CheckBoxesContext } from "../../components/CheckBoxes";
import Cookies from "js-cookie";
import Axios from "../../services/api/Axios";

const ActionMenu = (props) => {
    let history = {
        itemServingURL : "/sediaan/item?id=",
        accessToken : Cookies.get("accessToken"),
        idServing : Cookies.get("idServing"),
        serving : Cookies.get("serving"),
    }

    const navigate = useNavigate();
    const checkboxContext = useContext(CheckBoxesContext);

    const [dataInitialization,setDataInitialization] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [isCurrentServing, setIsCurrentServing] = useState(true);

    const handleCheckServing = async () => {
        try {
            let getDataServing = await Axios.get(history.itemServingURL + history.idServing,
                {
                    headers: {'Authorization': 'Bearer ' + history.accessToken},
                });

            if (getDataServing?.data?.data?.sediaan?.nama !== history.serving) setIsCurrentServing(false);
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (!dataInitialization) handleCheckServing().then(() => setDataInitialization(true));

        if (checkboxContext.checkBoxState.length !== 0){
            setShowButton(true)
        }else{
            setShowButton(false)
        }
    }, [checkboxContext.checkBoxState])

    return (
        <Flex paddingTop={5}>
            <Button colorScheme={"purple"} onClick={() => navigate("/reOrderPoint/order")}>
                <Text className="text-white font-extrabold tracking-wide">Order Progress</Text>
            </Button>

            <Spacer/>

            <ButtonGroup gap={3}>
                <Button
                    borderRadius="8px"
                    colorScheme={"twitter"}
                    isDisabled={!showButton}
                    onClick={() => props.setResumeState(true)}
                >
                    <Text className="text-white font-extrabold tracking-wide">Create Resume</Text>
                </Button>
            </ButtonGroup>
        </Flex>
    );
}
 
export default ActionMenu;