import React  from "react";
import {useEffect, useRef, useState} from "react";

import {
    Flex,
    HStack,
    VStack,
    Text,
    Input,
    Menu,
    MenuButton, Button, Spacer, ButtonGroup, InputRightElement, Image, InputGroup,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import InfiniteScroll from "react-infinite-scroll-component";

import Loader from "../../components/Loader/Loader";
import Axios from "../../services/api/Axios";
import Cookies from "js-cookie";
import SearchIcon from "../../assets/icons/header/Header_search.svg";

const DropDowns = (props) => {
    const history = {
        dataDropDownSparePartURL : "/sparepart/list?reorder=1&sediaan_id=" + Cookies.get("idServing") + "&is_active=true&dropdown=",
        accessToken : Cookies.get("accessToken"),
    }
    
    let state = {
        offset: 25,
    }
    
    const [dataDropDownSpareParts,setDataDropDownSpareParts] = useState({});
    const [focus, setFocus] = useState('');
    const [dataInitialization,setDataInitialization] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [hasMoreDropDown, setHasMoreDropDown] = useState({});

    const [searchString, setSearchString] = useState({
        "part_name" : '',
        "brand" : '',
        "type" : '',
        "main_supplier" : '',
    });

    const [dropDownState, setDropDownState] = useState({
        //"code_name" : ["Dropdown Name", "Dropdown Value", "isActive", "offset"]
        "part_name" : ["Item Name", '', false, 25],
        "brand" : ["Brand", '', false, 25],
        "type" : ["Type", '', false, 25],
        "main_supplier" : ["Supplier", '', false, 25],
    });

    const dropDownRef = {
        "part_name" : useRef(),
        "brand" : useRef(),
        "type" : useRef(),
        "main_supplier" : useRef(),
    };

    const handleGetDataDropDownSpareParts = async (event, selectedDropDown, searchString) => {
        let objectDropDown = JSON.parse(JSON.stringify(dataDropDownSpareParts));
        let objectHasMore = JSON.parse(JSON.stringify(hasMoreDropDown));

        switch (event){
            case "getFirst":
                objectDropDown = {};
                objectHasMore = {};

                Object.keys(dropDownState).map(async (dropDownItem) => {
                    let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownItem,
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    objectHasMore[dropDownItem] = (getDataDropDownSpareParts?.data?.data?.spareparts)[dropDownItem]?.length >= 25;
                    setHasMoreDropDown(objectHasMore);

                    if (!objectDropDown[dropDownItem]) objectDropDown[dropDownItem] = [];
                    objectDropDown[dropDownItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownItem]);
                })

                break;

            case "getMore":
                if (!isSearchActive) {
                    let getMoreDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + selectedDropDown + "&offset=" + dropDownState[selectedDropDown][3],
                        {
                            headers: {"Authorization": "Bearer " + history.accessToken},
                        });

                    objectHasMore[selectedDropDown] = (getMoreDataDropDownSpareParts?.data?.data?.spareparts)[selectedDropDown]?.length >= 25;
                    setHasMoreDropDown(objectHasMore);

                    objectDropDown[selectedDropDown].push(...getMoreDataDropDownSpareParts?.data?.data?.spareparts[selectedDropDown])

                    let tempDropDownState = JSON.parse(JSON.stringify(dropDownState));
                    tempDropDownState[selectedDropDown][3] = tempDropDownState[selectedDropDown][3] + state.offset;
                    setDropDownState(tempDropDownState);
                }

                break;

            case "getSearch":
                let tempString = searchString.replace(/ /g, '_');
                tempString = tempString.replace('.', '_');

                let getSearchDataDropDownSpareParts = await Axios.get(`${history.dataDropDownSparePartURL + selectedDropDown}&${selectedDropDown}=%${tempString}%&limit=9999`,
                    {
                        headers: {"Authorization": "Bearer " + history.accessToken},
                    });

                objectHasMore[selectedDropDown] = false;
                setHasMoreDropDown(objectHasMore);

                objectDropDown[selectedDropDown].splice(0, objectDropDown[selectedDropDown].length);
                objectDropDown[selectedDropDown].push(...getSearchDataDropDownSpareParts?.data?.data?.spareparts[selectedDropDown]);

                break;

            case "getDependent":
                objectDropDown = {};
                objectHasMore = {};

                let queryString = '';

                Object.keys(selectedDropDown).map(async (dropDownListItem) => {
                    if (selectedDropDown[dropDownListItem][1].length) {
                        queryString = queryString.concat('&', dropDownListItem, '=', selectedDropDown[dropDownListItem][1])
                    }
                })

                let search = queryString.replace(/ /g, '_');

                Object.keys(selectedDropDown).map(async (dropDownListItem) => {
                    if (selectedDropDown[dropDownListItem][1].length === 0) {
                        let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + search + "&limit=999",
                            {
                                headers: {"Authorization": "Bearer " + history.accessToken},
                            });

                        objectHasMore[dropDownListItem] = false
                        setHasMoreDropDown(objectHasMore);

                        if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
                        objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
                    }
                    else {
                        let getDataDropDownSpareParts = await Axios.get(history.dataDropDownSparePartURL + dropDownListItem + "&limit=999",
                            {
                                headers: {"Authorization": "Bearer " + history.accessToken},
                            });

                        console.log(dropDownListItem)

                        objectHasMore[dropDownListItem] = false
                        setHasMoreDropDown(objectHasMore);

                        if (!objectDropDown[dropDownListItem]) objectDropDown[dropDownListItem] = [];
                        objectDropDown[dropDownListItem].push(...getDataDropDownSpareParts?.data?.data?.spareparts[dropDownListItem]);
                    }
                })

                setDataDropDownSpareParts(objectDropDown);

                break;

            default:
                break;
        }

        setDataDropDownSpareParts(objectDropDown);
    };

    useEffect(() => {
        if (!dataInitialization) handleGetDataDropDownSpareParts("getFirst", '', '').then(() => setDataInitialization(true));
        // if (dataDropDownChange) setDataDropDownChange(false);
        setTimeout(() => {
            if (focus !== '') {
                dropDownRef[focus].current.focus();
                setFocus('');
            }
        }, 100);

    }, [focus])

    return (
        <Flex paddingTop={5}>
            <HStack spacing={5} w={"full"}>
                {Object.keys(dropDownState).map((elem, index) => (
                    <VStack key={index + elem[0]} align={"left"}>
                        <Text className="font-bold mb-1" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{dropDownState[elem][0]}</Text>
                        <Menu style={{position: "relative"}} autoSelect={false}>
                            <MenuButton
                                w={228}
                                py={2}
                                borderRadius="xl"
                                borderColor={process.env.REACT_APP_ENV_COLOR}
                                borderWidth="1px"
                                _hover={{
                                    borderColor: process.env.REACT_APP_BORDER_COLOR
                                }}
                                _active={{
                                    borderColor: process.env.REACT_APP_BORDER_COLOR,
                                    bg: "gray.100"
                                }}
                                onClick={() => {
                                    let tempDropDownState = JSON.parse(JSON.stringify(dropDownState));
                                    Object.keys(tempDropDownState).map((value) => (
                                        elem !== value ? tempDropDownState[value][2] = false : tempDropDownState[value][2] = !tempDropDownState[value][2]
                                ))
                                    setDropDownState(tempDropDownState);
                                }}
                            >
                                {dropDownState[elem][1] === '' ? dropDownState[elem][0] : (dropDownState[elem][1].length > 20 ? dropDownState[elem][1].slice(0, 20) + ".." : dropDownState[elem][1])}
                                <ChevronDownIcon />
                            </MenuButton>
                            {
                                dropDownState[elem][2] && (
                                    <div
                                        id={"scrollableReOrder" + elem}
                                        style={{
                                            zIndex: 99,
                                            position: "absolute",
                                            borderRadius: "8px",
                                            boxShadow: " 2px 3px 5px 0px rgba(0,0,0,0.5)",
                                            marginTop: "80px",
                                            padding: "1rem 0.5rem",
                                            background: "#ffffff",
                                            width: "228px",
                                            height: "300px",
                                            overflow: "auto",
                                            display: "flex",
                                            flexDirection:"column"
                                        }}
                                    >
                                        <InputGroup>
                                            <Input
                                                color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                borderColor={process.env.REACT_APP_INPUT_COLOR}
                                                focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                                _hover={{ borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD }}
                                                marginBottom={"1rem"}
                                                ref={dropDownRef[elem]}
                                                type={"text"}
                                                width={"100%"}
                                                onClick={() => setFocus(elem)}
                                                onChange={(e) => {
                                                    handleGetDataDropDownSpareParts("getSearch", elem, e.target.value).then(() => setIsSearchActive(true));
                                                    setSearchString(searchString => ({...searchString, [elem]: e.target.value}));
                                                }}
                                            />
                                            <InputRightElement>
                                                <Image className="scale-25" src={SearchIcon} />
                                            </InputRightElement>
                                        </InputGroup>

                                        {dataDropDownSpareParts[elem]?.length === 0 ?
                                            <Text
                                                color={"red.700"}
                                                _hover={{color: "red.900"}}
                                            >
                                                No item
                                            </Text>
                                            :
                                            <InfiniteScroll
                                                scrollableTarget={"scrollableReOrder" + elem}
                                                dataLength={dataDropDownSpareParts[elem] ? dataDropDownSpareParts[elem].length : 0}
                                                next={() => handleGetDataDropDownSpareParts("getMore", elem, '')}
                                                hasMore={hasMoreDropDown[elem]}
                                                loader={Loader()}
                                            >
                                                {searchString[elem].length !== 0 &&
                                                    <Text
                                                        color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                        _hover={{color : process.env.REACT_APP_CHECKBOX_COLOR}}
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            let tempObject = JSON.parse(JSON.stringify(dropDownState));
                                                            tempObject[elem][1] = searchString[elem];

                                                            handleGetDataDropDownSpareParts("getDependent", tempObject, '').then();

                                                            setDropDownState(dropDownState =>
                                                                ({...dropDownState, [elem]: [dropDownState[elem][0], searchString[elem], !dropDownState[elem][2], ...dropDownState[elem].slice(3)]})
                                                            );
                                                        }}
                                                    >
                                                        All items containing '{searchString[elem]}'
                                                    </Text>
                                                }

                                                {dataDropDownSpareParts[elem]?.map((item, indexChild) => (
                                                    <Text
                                                        color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                                                        _hover={{color : process.env.REACT_APP_CHECKBOX_COLOR}}
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            let tempObject = JSON.parse(JSON.stringify(dropDownState));
                                                            tempObject[elem][1] = item;

                                                            handleGetDataDropDownSpareParts("getDependent", tempObject, '').then();

                                                            setDropDownState(dropDownState =>
                                                                ({...dropDownState, [elem]: [dropDownState[elem][0], item, !dropDownState[elem][2], ...dropDownState[elem].slice(3)]})
                                                            );
                                                        }}
                                                        key={indexChild + item}
                                                    >
                                                        {item}
                                                    </Text>
                                                ))}
                                            </InfiniteScroll>
                                        }
                                    </div>
                                )
                            }
                        </Menu>
                    </VStack>
                ))}
                <Spacer/>
                <ButtonGroup gap={3}>
                    <Button
                        colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                        onClick={() => {
                            let tempDropDownValue = {};
                            Object.keys(dropDownState).map((value) => (
                                tempDropDownValue[value] = dropDownState[value][1]
                            ))
                            props.setSearchItems(tempDropDownValue);
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        colorScheme={"teal"}
                        onClick={() => {
                            setIsSearchActive(false);
                            props.setIsReset(true);
                            handleGetDataDropDownSpareParts("getFirst", '', '').then();
                            setSearchString({
                                "part_name" : '',
                                "brand" : '',
                                "type" : '',
                                "bin_location" : '',
                            });
                            setDropDownState({
                                "part_name" : ["Item Name", '', false, 25],
                                "brand" : ["Brand", '', false, 25],
                                "type" : ["Type", '', false, 25],
                                "main_supplier" : ["Supplier", '', false, 25],
                            });
                        }}
                    >
                        Reset
                    </Button>
                </ButtonGroup>
            </HStack>
        </Flex>
    );
}

export default DropDowns;