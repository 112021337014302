import { useEffect , useState } from 'react';

import {
    Button,
    Image,
    Text,
    Container,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Spinner, Center
} from '@chakra-ui/react'

import ArchiveIcon from "../../assets/icons/modal/Modal_archive.svg";
import DeleteIcon from "../../assets/icons/modal/Modal_delete.svg";
import RestoreIcon from "../../assets/icons/modal/Modal_restore.svg";
import CheckIcon from "../../assets/img/iconCheck.svg";
import CrossIcon from "../../assets/img/iconCross.svg";
import UserIcon from "../../assets/img/iconUser.svg";

const AreYouSure = (props) => {
    const [modalData, setModalData] = useState({});

    useEffect(() => {
        handleModalType(props.type)
    }, [props.isOpen])

    function handleAction(type){
        props.setConfirmAction(type);
        props.onClose(true);
    }

    function handleSuccessAPI(){
        props.onClose(true);
        try {
            props.setRedirect(true);
        }
        catch (error) {
        }
    }

    function handleErrorAPI(){
        props.onClose(true);
    }

    function handleRequestAPI(){
        props.setConfirmSentResetPassword(true);
        props.onClose(true);
    }

    function handleModalType(type){
        let data = {}
        switch(type){
            case "Delete":
                data.title = "Are you sure to delete selected items?";
                data.content = "This action can not be undone";
                data.confirmation = "Delete";
                data.confirmationColor = "#D75555";
                data.cancel = "Cancel";
                data.handleConfirmation = function () {handleAction(type)};
                data.logo = DeleteIcon;
                break;
            case "Archive":
                data.title = "Are you sure to archive these items?";
                data.content = "You can restore it later at History Archive";
                data.confirmation = "Sure";
                data.confirmationColor = "blue.400";
                data.cancel = "Cancel";
                data.handleConfirmation = function () {handleAction(type)};
                data.logo =  ArchiveIcon;
                break;
            case "Restore":
                data.title = "Are you sure to restore these items?";
                data.content = "These items will be shown at Item List";
                data.confirmation = "Sure";
                data.confirmationColor = "#F1AF32";
                data.cancel = "Cancel";
                data.handleConfirmation = function () {handleAction(type)};
                data.logo = RestoreIcon;
                break;
            case "Request":
                data.title = "Are you sure?";
                data.content = "This action cannot be undone!";
                data.confirmation = "Sure";
                data.confirmationColor = "#F1AF32";
                data.cancel = "Cancel";
                data.handleConfirmation = handleRequestAPI;
                data.logo = UserIcon;
                break;
            case "Success":
                data.title = "Notification";
                data.content = "Operation is done successfully!";
                data.confirmation = "OK";
                data.confirmationColor = process.env.REACT_APP_BUTTON_COLOR;
                data.cancel = "Back";
                data.handleConfirmation = handleSuccessAPI;
                data.logo = CheckIcon;
                break;
            case "Error":
                data.title = "Error";
                data.content = "Unauthorized! " + (props.errorMessage ? props.errorMessage : '');
                data.confirmation = "OK";
                data.confirmationColor = "#FF0000";
                data.cancel = "Back";
                data.handleConfirmation = handleErrorAPI;
                data.logo = CrossIcon;
                break;
            case "Progress":
                data.title = "On progress";
                data.content = "Processing";
                data.confirmation = "Cancel";
                data.confirmationColor = "#FF0000";
                data.cancel = "Cancel";
                data.handleConfirmation = handleSuccessAPI;
                data.logo = UserIcon;
                break;
            default:
                break;
        }
        setModalData(data);
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            isCentered
            autoFocus={false}
            size={"sm"}
        >
            <ModalOverlay
                bg={"blackAlpha.500"}
                backdropFilter='auto'
                backdropBlur='2px'
            />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    <Container>
                        <Image className='scale-150 py-8 flex mx-auto' src={modalData.logo} alt="" />
                        {(props.type === "Progress") &&
                            <Center>
                                <Spinner color='green.500' size='xl'/>
                            </Center>
                        }
                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} textAlign={"center"} paddingBottom={5}>{modalData.title}</Text>
                        <Text fontSize={"sm"} color={"gray.500"} textAlign={"center"}>({modalData.content})</Text>
                    </Container>
                </ModalBody>

                <ModalFooter alignSelf={"center"}>
                    {(props.type !== "Success" && props.type !== "Error" && props.type !== "Progress") &&
                         <Button className='float-left mr-4' variant='ghost' backgroundColor={"#9D9D9D"} _hover={{ bg: process.env.REACT_APP_BUTTON_HOVER_COLOR }} color="white" onClick={props.onClose}>{modalData.cancel}</Button>
                    }
                    <Button className='float-right ml-4' backgroundColor={modalData.confirmationColor} _hover={{ bg: process.env.REACT_APP_BUTTON_HOVER_COLOR }} color="white" mr={3} onClick={modalData.handleConfirmation}>
                        {modalData.confirmation}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default AreYouSure;
