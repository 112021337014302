import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Center
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import Axios from '../../services/api/Axios';
import Cookies from "js-cookie";

const DashboardTableDataService = (props) => {
    const [dataInitialization,setDataInitialization] = useState(false);
    const [dataServices,setDataServices] = useState([]);

    const columnName = [
        "Month","Total Qty Request","Total Qty Outgoing","Percentage"
    ];

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const history = {
        dataServicesURL : "/request/rekap?sediaan_id=" + Cookies.get("idServing") + "&status=CLOSE&year=",
        accessToken : Cookies.get("accessToken"),
    }

    const handleGetDataService = async () => {
        try{
            let getDataServices = await Axios.get(history.dataServicesURL  + props.selectedYear,
                {
                    headers: {"Authorization": "Bearer " + history.accessToken},
                });

            let tempDataServices = getDataServices?.data?.data?.rekapRequest;

            tempDataServices.map((elem, index) => (
                tempDataServices[index]["month"] = months[index]
            ))

            setDataServices(tempDataServices);
        }
        catch(error){
            console.error(error);
        }
    }

    useEffect(() => {
        if (!dataInitialization) handleGetDataService().then(() => setDataInitialization(true));
        if (props.confirmChange) handleGetDataService().then(() => props.setConfirmChange(false));
    }, [props.confirmChange])

    return (
        <div>
            <TableContainer className='border-solid border-[1px] border-[#B7D4CF]' overflowY={"auto"} whiteSpace={"normal"} display={"flex"}>
                <Table variant='simple' size={"lg"}>
                    <Thead bg={process.env.REACT_APP_TABLE_COLOR}>
                        <Tr>
                            {columnName.map((elem, index) => (
                                <Th key={elem}>
                                    <h2 className='text-center font-bold text-sm'>{elem}</h2>
                                </Th>
                                
                            ))}
                        </Tr>
                    </Thead>

                    <Tbody>
                        {dataServices.map((elem, index) => (
                            <Tr key={elem.month + index} className='bg-white'>
                                <Td><Center>{elem.month}</Center></Td>
                                <Td><Center>{elem.qty_request}</Center></Td>
                                <Td><Center>{elem.qty_receive}</Center></Td>
                                <Td><Center>{(Number(elem.qty_request) === 0 || Number(elem.qty_receive) === 0) ? 0 : Math.round((elem.qty_receive / elem.qty_request) * 100)}%</Center></Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </div>
    );
}
 
export default DashboardTableDataService;