import React, {Fragment} from "react";
import { useState, useContext } from "react";

import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Checkbox,
    Image,
    Text,
    Center,
    Flex,
    TableCaption,
    Box,
} from "@chakra-ui/react";

import InfiniteScroll from "react-infinite-scroll-component";

import Loader from "../../../Loader/Loader";

import SortIcon from "../../../../assets/icons/table/TableSort.svg";
import {CheckboxExportContext} from "./ExportSparepart_Context";
import EmptyList from "../../../../assets/img/empty.svg";
import {useMediaQuery} from "react-responsive";

const Tables = (props) => {
    const checkboxContext = useContext(CheckboxExportContext);
    const isResolutionHigh = useMediaQuery({ query: "(min-width: 1920px)" })

    const columnName = {
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "specification": "Specification",
        "part_category" : "Item Category",
        "qty" : "Stock",
        "qty_min" : "Min Stock",
        "qty_max" : "Max Stock",
        "part_status": "Item Status",
        "inventory_type": "Inventory Type",
        "bin_location" : "Bin Loct",
        "qty_uom":"Quantity Unit",
        "main_supplier": "Main Supplier",
        "price": "Price",
        "lead_time" : "Lead Time"
    }

    const [sortState, setSortState] = useState(false);

    function handleCheckbox(sparePart){
        let index = (checkboxContext.checkboxState).indexOf(sparePart);
        let selectedArray = [...checkboxContext.checkboxState]
        if (index !== -1){
            selectedArray.splice(index, 1);
        }else{
            selectedArray.push(sparePart)
        }
        checkboxContext.setCheckboxState(selectedArray);
    }

    return (
        <Flex paddingTop={5}>
            {(props.dataSpareParts)?.length === 0 ?
                <Center w={"full"}>
                    <Image boxSize={"lg"} src={EmptyList} alt={"empty"}/>
                    <Text color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                        No items in here..
                    </Text>
                </Center>
                :
                <Box w={"full"}>
                    <InfiniteScroll scrollableTarget={"tableSparePart" + props.props.page} dataLength={props.dataSpareParts.length} next={() => props.setFetchMoreData(true)} hasMore={props.hasMoreTable} loader={''}>
                        <TableContainer id={"tableSparePart" + props.props.page} overflowY={"scroll"} whiteSpace={"normal"} display={"flex"} maxHeight={isResolutionHigh ? "60vh" : "50vh"} border={"1px solid #B7D4CF"}>
                            <Table variant="simple">
                                <TableCaption>
                                    {props.hasMoreTable ?
                                        <Loader/>
                                        :
                                        <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>End of Line (No more data)</Text>
                                    }
                                </TableCaption>
                                <Thead zIndex={1} pos='sticky' top={0} bg={process.env.REACT_APP_TABLE_COLOR}>
                                    <Tr>
                                        <Th>
                                            <Text className="m-1 text-center text-[#492472] font-bold text-sm"></Text>
                                        </Th>
                                        <Th>
                                            <Center>
                                                <Image className="cursor-pointer" src={SortIcon} alt="" onClick={() => {
                                                    setSortState(!sortState);
                                                    props.handleSortDataDropDownSparePartListItem("id", sortState);
                                                }}
                                                />
                                                <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>ID</Text>
                                            </Center>                                    </Th>
                                        {Object.keys(columnName).map((elem) => (
                                            <Fragment key={columnName[elem]}>
                                                <Th key={columnName[elem]}>
                                                    <Center>
                                                        <Image className="cursor-pointer" src={SortIcon} alt="" onClick={() => {
                                                            setSortState(!sortState);
                                                            props.handleSortDataDropDownSparePartListItem(elem, sortState);
                                                        }}
                                                        />
                                                        <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnName[elem]}</Text>
                                                    </Center>
                                                </Th>
                                            </Fragment>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {props.dataSpareParts?.map((dataSparePart) => (
                                        <Tr key={dataSparePart.id}>
                                            <Td>
                                                <Checkbox size="lg" colorScheme={process.env.REACT_APP_BORDER_COLOR} borderColor={process.env.REACT_APP_CHECKBOX_COLOR} onChange={() => handleCheckbox(dataSparePart)}>
                                                </Checkbox>
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.id}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_code}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_name}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.brand}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.type}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.specification}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_category}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty_min}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty_max}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.part_status}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.inventory_type}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.bin_location}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.qty_uom}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.main_supplier}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.price}
                                            </Td>
                                            <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                                {dataSparePart.lead_time}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </InfiniteScroll>
                </Box>
            }
        </Flex>
    );
}

export default Tables;