import React, {useEffect, useState} from 'react'

import {Box, Button, Center, Text, VStack} from "@chakra-ui/react";

import QrReader from 'react-qr-scanner'

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const Scanner = () => {
    const [result, setResult] = useState('');
    const [isScanning, setIsScanning] = useState(false);

    useEffect(() => {
        if (result) setIsScanning(false);
    }, [result])

    console.log(result);
    return(
        <>
            <Navbar/>
            <Center maxW={"full"} paddingTop={"2vh"}>
                <VStack>
                    <Box bg='gray.50' w='90%' p={1} color='white' border={isScanning ? "1px dotted green" : ''} borderRadius={"lg"}>
                        {isScanning &&
                            <QrReader
                                delay={1000}
                                style={{
                                    height: 240,
                                    width: 320,
                                }}
                                onError={(error) => console.error(error)}
                                onScan={(e) => !e ? console.log(e?.text) : setResult(e?.text)}
                            />
                        }
                        <Center>
                            <Button
                                variant='solid'
                                colorScheme='whatsapp'
                                position={"initial"}
                                onClick={() => setIsScanning(isScanning => !isScanning)}
                            >
                                {!isScanning ? "Open" : "Close"} Scanner
                            </Button>
                        </Center>
                    </Box>

                    <Text fontSize={"sm"} color={"gray.500"} textAlign={"center"} pt={3}>Scan result: {result ? result : "Undefined"}</Text>
                </VStack>
            </Center>
            <Footer />
        </>
    )
}

export default Scanner;
