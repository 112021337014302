import React, {Component} from "react";

import { Container } from "@chakra-ui/react";

import NavigationGuard from "../../services/hooks/NavigationGuard";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import DashboardSubHeader from "./SubHeader";
import DashboardSumContainer from "./SumContainer";
import DashboardTableContainer from "./TableContainer";
import DashboardDataServiceContainer from "./DataServiceContainer";

class Dashboard extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <Container maxW={"full"} paddingTop={"2vh"}>
                    <DashboardSubHeader />
                    <DashboardSumContainer />
                    <DashboardTableContainer />
                    <DashboardDataServiceContainer />
                </Container>
                <Footer />
            </div>
        );
    }
}

export default NavigationGuard(Dashboard);
