import React, {Fragment, useContext, useEffect, useState} from "react";
import {
    Button,
    ButtonGroup,
    Center,
    HStack,
    NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Spacer,
    Stack,
    Table, TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack
} from "@chakra-ui/react";
import {CheckBoxesContext} from "../../../components/CheckBoxes";
import Cookies from "js-cookie";
import Axios from "../../../services/api/Axios";
import {useNavigate} from "react-router-dom";
import GeneratePDF from "./GeneratePDF";
import {useMediaQuery} from "react-responsive";

const Resume = (props) => {
    const checkboxContext = useContext(CheckBoxesContext);
    const isResolutionHigh = useMediaQuery({ query: "(min-width: 1920px)" })

    let today = new Date().toLocaleString();
    let isCreate = false;

    const [dataResume, setDataResume] = useState({});
    const [dataInitialization, setDataInitialization] = useState(false);
    const [reOrderIdentifier, setReOrderIdentifier] = useState({});
    const [generatePDF, setGeneratePDF] = useState(false);

    let history = {
        createReOrderURL : "/reorder/case",
        addItemReOrderURL : "/reorder/add",
        removeItemReOrderURL : "/reorder/remove?sparepart_id=",
        submitReOrderURL : "/reorder/submit?id=",
        accessToken : Cookies.get("accessToken"),
        navigate : useNavigate(),
    }

    const handleResume = async (event) => {
        switch (event) {
            case "create":
                let tempObject = {};
                (checkboxContext.checkBoxState).map((dataSparePart) => {
                    let tempObjectData = {};

                    tempObjectData["qty"] = Number(dataSparePart.qty);
                    tempObjectData["qty_max"] = Number(dataSparePart.qty_max);
                    tempObjectData["qty_reorder"] = (Number((Math.round((dataSparePart.qty_max - dataSparePart.qty) * 100)/100).toFixed(2)));
                    tempObjectData["week_arrival"] = getCurrentWeek(Number(dataSparePart.lead_time)) || 0;
                    tempObject[dataSparePart.id] = tempObjectData;

                    return tempObject
                });

                setDataResume(tempObject);

                if (Object.keys(reOrderIdentifier).length === 0 && !isCreate) {
                    isCreate=true;

                    let createReOrderCase = await Axios.post(history.createReOrderURL,
                        {},
                        {
                            headers: {'Authorization': 'Bearer ' + history.accessToken},
                        });

                    console.log(createReOrderCase?.data?.data?.reorder?.id);

                    let tempObjectIdentifier = {}
                    tempObjectIdentifier["id"] = createReOrderCase?.data?.data?.reorder?.id;
                    tempObjectIdentifier["case_number"] = createReOrderCase?.data?.data?.reorder?.case_number;
                    tempObjectIdentifier["user_id"] = createReOrderCase?.data?.data?.reorder?.user_id;
                    tempObjectIdentifier["user"] = Cookies.get("name");
                    tempObjectIdentifier["date"] = today;
                    setReOrderIdentifier(tempObjectIdentifier);
                }

                break;

            case "add":
                let promiseArray = [];

                Object.keys(dataResume).map((dataSparePart) => {
                    promiseArray.push(new Promise((resolve) => {
                        let tempAddData = {};

                        tempAddData["sparepart_id"] = dataSparePart;
                        tempAddData["qty_reorder"] = dataResume[dataSparePart]["qty_reorder"];
                        tempAddData["week_arrival"] = dataResume[dataSparePart]["week_arrival"];

                        let addReOrderCase = Axios.post(history.addItemReOrderURL,
                            tempAddData,
                            {
                                headers: {'Authorization': 'Bearer ' + history.accessToken},
                            });

                        resolve(addReOrderCase);
                    }))

                    return ''
                })

                await Promise.all(promiseArray);

                break;

            case "submit":
                let submitReOrderCase = await Axios.post(history.submitReOrderURL + reOrderIdentifier["id"],
                    {},
                    {
                        headers: {'Authorization': 'Bearer ' + history.accessToken},
                    });

                console.log(submitReOrderCase);

                break;

            default:
                break;
        }
    }

    const columnNames = {
        "part_code" : "Item Code",
        "part_name" : "Item Name",
        "brand" : "Brand",
        "type" : "Type",
        "part_category" : "Item Category",
        "qty" : "Stock",
        "reorder" : "ReOrder",
        "qty_min" : "Min Stock",
        "qty_max" : "Max Stock",
        "qty_order" : "Order Quantity",
        "price" : "Item Price",
        "total_price" : "Total Price",
        "main_supplier" : "Main Supplier",
        "week" : "Estimated Arrival Week",
    }

    function getCurrentWeek (addition) {
        let currentDate = new Date();
        let startDate = new Date(currentDate.getFullYear(), 0, 1);
        let days = Math.floor((currentDate - startDate) /
            (24 * 60 * 60 * 1000));

        let weekNumber = Math.ceil(days / 7);

        return weekNumber + addition;
    }

    useEffect(() => {
        if (!dataInitialization) {handleResume("create").then(() => setDataInitialization(true))}
        if (props.submitResume) handleResume("add").then(() =>
            handleResume("submit").then(() => {
                props.setSubmitResume(false);
                props.onOpenSuccessModal();
        }));
    }, [props.submitResume, generatePDF]);

    return (
        <Stack paddingTop={10} spacing={"1%"}>
            <HStack w={"30%"} spacing={"10%"}>
                <VStack align={"start"}>
                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>PIC</Text>
                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>Case Number</Text>
                    <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>Date Created</Text>
                </VStack>
                <VStack align={"start"}>
                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{Cookies.get("name")}</Text>
                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{reOrderIdentifier["case_number"]}</Text>
                    <Text fontSize={"lg"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{today}</Text>
                </VStack>
            </HStack>

            <TableContainer overflowY={"auto"} whiteSpace={"normal"} display={"flex"} maxHeight={isResolutionHigh ? "55vh" : "45vh"} border={"1px solid #B7D4CF"}>
                <Table variant="simple">
                    <Thead zIndex={1} pos='sticky' top={0} bg={process.env.REACT_APP_TABLE_COLOR}>
                        <Tr>
                            <Th>
                                <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>No</Text>
                            </Th>
                            {Object.keys(columnNames).map((elem) => (
                                <Fragment key={columnNames[elem]}>
                                    <Th>
                                        <Text className="m-1 text-center font-bold text-sm" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>{columnNames[elem]}</Text>
                                    </Th>
                                </Fragment>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {(checkboxContext.checkBoxState).map((dataSparePart, index) => (
                            <Tr key={dataSparePart.id}>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    {index + 1}
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    {dataSparePart.part_code}
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    {dataSparePart.part_name}
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    {dataSparePart.brand}
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    {dataSparePart.type}
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    {dataSparePart.part_category}
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    {dataSparePart.qty}
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    {Math.ceil(Number(dataSparePart.qty_min) + Number(dataSparePart.qty_min / 10))}
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    {dataSparePart.qty_min}
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    {dataSparePart.qty_max}
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    <NumberInput
                                        borderColor={process.env.REACT_APP_INPUT_COLOR}
                                        min={0.001}
                                        max={999}
                                        isInvalid={Number(dataSparePart.qty) >= Number(dataSparePart.qty_max) || (dataResume[dataSparePart.id] ? Number(dataResume[dataSparePart.id]["qty_reorder"]) === 0 : false)}
                                        focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                        onChange={(e) => setDataResume(dataResume =>
                                            ({...dataResume, [dataSparePart.id]: ({...dataResume[dataSparePart.id], "qty_reorder": Number(e.valueOf()) })})
                                        )}
                                        defaultValue={(Number(dataSparePart.qty_max) - Number(dataSparePart.qty)) !== 0 ? (Number((Math.round((dataSparePart.qty_max - dataSparePart.qty) * 100)/100).toFixed(2))) : 1}
                                    >
                                        <NumberInputField _hover={{borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD}} />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    Rp {dataSparePart.price},00
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    Rp {Number(dataSparePart.price) * (dataResume[dataSparePart.id] ? dataResume[dataSparePart.id]["qty_reorder"] : (Number(dataSparePart.qty_max) - Number(dataSparePart.qty)))},00
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    {dataSparePart.main_supplier}
                                </Td>
                                <Td textAlign={"center"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>
                                    <NumberInput
                                        borderColor={process.env.REACT_APP_INPUT_COLOR}
                                        min={1}
                                        max={52}
                                        focusBorderColor={process.env.REACT_APP_CHECKBOX_COLOR}
                                        onChange={(e) => setDataResume(dataResume =>
                                            ({...dataResume, [dataSparePart.id]: ({...dataResume[dataSparePart.id], "week_arrival": e.valueOf()})})
                                        )}
                                        defaultValue={getCurrentWeek(Number(dataSparePart.lead_time)) || 0}
                                    >
                                        <NumberInputField _hover={{borderColor: process.env.REACT_APP_TEXT_COLOR_BOLD}} />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>

            <Center>
                <Spacer/>

                <ButtonGroup gap={3}>
                    <Button colorScheme={"red"} onClick={() => {
                        checkboxContext.setCheckBoxState([]);
                        props.setResumeState(false);
                    }}
                    >
                        Cancel
                    </Button>
                    <Button
                        colorScheme={"twitter"}
                        onClick={props.onOpenRequestModal}
                        isDisabled={(Object.keys(dataResume).length !== 0 ? ((Object.keys(dataResume).some(r => dataResume[r]["week_arrival"] === 0)) || (Object.keys(dataResume).some(r => dataResume[r]["qty"] >= dataResume[r]["qty_max"])) || (Object.keys(dataResume).some(r => dataResume[r]["qty_reorder"] === 0))) : true)}
                    >
                        Submit
                    </Button>
                </ButtonGroup>

                <Spacer/>

                {!generatePDF ?
                    <Button
                        colorScheme={process.env.REACT_APP_BUTTON_COLOR}
                        onClick={() => setGeneratePDF(true)}
                        isDisabled={(Object.keys(dataResume).length !== 0 ? ((Object.keys(dataResume).some(r => dataResume[r]["week_arrival"] === 0)) || (Object.keys(dataResume).some(r => dataResume[r]["qty"] >= dataResume[r]["qty_max"])) || (Object.keys(dataResume).some(r => dataResume[r]["qty_reorder"] === 0))) : true)}
                    >
                        Generate PDF
                    </Button>
                    :
                    <GeneratePDF dataResume={dataResume} reOrderIdentifier={reOrderIdentifier}/>
                }
            </Center>
        </Stack>
    );
}

export default Resume;
