import { createContext, React, useState } from 'react';

const HistoryContext = createContext();

const HistoryProvider = ({children}) => {
    const [data, setData] = useState({}) 
    const [date1, setDate1] = useState(null)
    const [date2, setDate2] = useState(null)
    const [columnNames, setColumnNames] = useState({}) 
    return(
        <HistoryContext.Provider value={{ data, setData, date1, setDate1, date2, setDate2,columnNames, setColumnNames }}>
            {children}
        </HistoryContext.Provider>
    )
}

export {HistoryContext, HistoryProvider};