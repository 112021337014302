import React  from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Hero_ImportSparepart from "../../../components/Hero/Sparepart/Import/ImportSparepart_Hero";
import Footer from "../../../components/Footer/Footer";

const SparePartImport = () => {
    return (
        <div>
            <Navbar />
            <Hero_ImportSparepart />
            <Footer />
        </div>
    );
}

export default SparePartImport;