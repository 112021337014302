import React, {useContext} from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Svg,
    Line,
} from "@react-pdf/renderer";

const ContentPDF = (props) => {
    // Styling PDF for React-PDF

    const styles = StyleSheet.create({
        // So Declarative and unDRY 👌
        page: {
            flexDirection: "column",
            backgroundColor: "white",
            paddingTop: "4%",
            paddingBottom: "6%",
            paddingHorizontal: "6%",
        },
        stickerPage: {
            flexDirection: "column",
            backgroundColor: "white",
            paddingTop: "4%",
            paddingBottom: "6%",
        },
        header: {
            fontSize: 7,
            fontFamily: "Helvetica-BoldOblique",
            textAlign: "right",
            marginBottom: 5,
            paddingBottom: 2,
            borderBottom: "0.25px solid black",
        },
        footer: {
            fontSize: 7,
            fontFamily: "Helvetica-BoldOblique",
            textAlign: "right",
            paddingTop: 2,
            borderTop: "0.25px solid black",
            position: "absolute",
            bottom: "4.5%",
            left: "6%",
            right: "6%",
        },
        footerSign: {
            position: "absolute",
            bottom: "6%",
            left: "6%",
            right: "6%",
        },
        newHalfLine: {
            marginBottom: 5
        },
        newLine: {
            marginBottom: 10
        },
        table: {
            marginBottom: 8,
            width: "100%",
        },
        stickerTable: {
            width: "100%",
        },
        row: {
            display: "flex",
            flexDirection: "row",
            position: "relative"
        },
        column: {
            display: "flex",
            flexDirection: "column",
            position: "relative"
        },
        dataTable: {
            fontSize: 9,
            fontFamily: "Helvetica",
            border: "0.5px solid black",
            paddingVertical: 1,
            paddingLeft: 4,
        },
        containerTable: {
            border: "0.5px solid black",
            position: "relative",
        },
        textBold: {
            fontSize: 9,
            fontFamily: "Helvetica-Bold",
            marginBottom: 5
        },
        textTable1: {
            fontSize: 9,
            fontFamily: "Helvetica",
            textAlign: "center",
        },
        textTable2: {
            fontSize: 8,
            fontFamily: "Helvetica",
            textAlign: "center",
        },
        textTable3: {
            fontSize: 20,
            fontFamily: "Helvetica",
            textAlign: "center",
        },
        textTable4: {
            fontSize: 20,
            fontFamily: "Helvetica",
            textAlign: "center",
        },
        textTable5: {
            fontSize: 7,
            fontFamily: "Helvetica-Bold",
            textAlign: "center",
        },
    });

    let totalPrice = 0;
    const addTotalPrice = (value) => {
        totalPrice += value
        return totalPrice
    }

    return (
        <Document>
            <Page size={"A4"} style={styles.page}>
                <Text style={styles.header} fixed>WPM Warehouse Management - PT Paragon Technology & Innovation</Text>

                <View style={styles.newHalfLine}/>
                <View style={styles.newHalfLine}/>


                <View style={styles.column}>
                    <Text style={[styles.textBold]}>PIC: {props.dataOrder["user"]}</Text>
                    <Text style={[styles.textBold, {fontFamily: "Helvetica-BoldOblique"}, {lineHeight: 1}]}>Purchase Order Number: {props.dataOrder["case_number"]}</Text>
                    <Text style={[styles.textBold, {fontFamily: "Helvetica-BoldOblique"}, {lineHeight: 1}]}>Date Created: {props.dataOrder["date"]}</Text>
                </View>

                <View style={styles.newHalfLine}/>
                <View style={styles.newHalfLine}/>
                <View style={styles.newHalfLine}/>

                <View style={styles.table} wrap={false}>
                    <View style={styles.row}>
                        <Text style={[styles.dataTable, {width: "4%"}]}>No</Text>
                        <Text style={[styles.dataTable, {width: "16%"}]}>Item Name</Text>
                        <Text style={[styles.dataTable, {width: "16%"}]}>Brand</Text>
                        <Text style={[styles.dataTable, {width: "16%"}]}>Type</Text>
                        <Text style={[styles.dataTable, {width: "5%"}]}>Qty</Text>
                        <Text style={[styles.dataTable, {width: "5%"}]}>Min</Text>
                        <Text style={[styles.dataTable, {width: "5%"}]}>Max</Text>
                        <Text style={[styles.dataTable, {width: "5%"}]}>PO</Text>
                        <Text style={[styles.dataTable, {width: "5%"}]}>WA</Text>
                        <Text style={[styles.dataTable, {width: "14%"}]}>Main Supplier</Text>
                        <Text style={[styles.dataTable, {width: "19%"}]}>Price per Item</Text>
                    </View>

                    {(props.dataSpareParts).map((dataSparePart, index) => (
                        <View key={dataSparePart.id} style={styles.row}>
                            <Text style={[styles.dataTable, {width: "4%"}]}>{index + 1}</Text>
                            <Text style={[styles.dataTable, {width: "16%"}]}>{dataSparePart.part_name}</Text>
                            <Text style={[styles.dataTable, {width: "16%"}]}>{dataSparePart.brand}</Text>
                            {((dataSparePart.type)?.length >= 14 && (dataSparePart.type)?.includes('-')) ?
                                <Text style={[styles.dataTable, {width: "16%", fontSize: 7}]}>{((dataSparePart.type).slice(0, ((dataSparePart.type).lastIndexOf('-') + 1))) + " " + ((dataSparePart.type).substr((dataSparePart.type).lastIndexOf('-') + 1))}</Text>
                                :
                                <Text style={[styles.dataTable, {width: "16%"}]}>{dataSparePart.type}</Text>
                            }
                            <Text style={[styles.dataTable, {width: "5%"}]}>{dataSparePart.qty}</Text>
                            <Text style={[styles.dataTable, {width: "5%"}]}>{dataSparePart.qty_min}</Text>
                            <Text style={[styles.dataTable, {width: "5%"}]}>{dataSparePart.qty_max}</Text>
                            <Text style={[styles.dataTable, {width: "5%"}]}>{props.dataResume[dataSparePart.id]["qty_reorder"]}</Text>
                            <Text style={[styles.dataTable, {width: "5%"}]}>{props.dataResume[dataSparePart.id]["week_arrival"]}</Text>
                            <Text style={[styles.dataTable, {width: "14%"}]}>{dataSparePart.main_supplier}</Text>
                            <Text style={[styles.dataTable, {width: "19%"}]}>Rp {(dataSparePart.price) * (props.dataResume[dataSparePart.id]["qty_reorder"])},00</Text>
                            {addTotalPrice((dataSparePart.price) * (props.dataResume[dataSparePart.id]["qty_reorder"]))}
                        </View>
                    ))}
                    <View style={styles.row}>
                        <Text style={[styles.dataTable, {width: "91%"}]}>Total</Text>
                        <Text style={[styles.dataTable, {width: "19%"}]}>Rp {totalPrice},00</Text>
                    </View>

                </View>

                <View style={styles.column}>
                    <Text style={[styles.textBold]}>Notes: </Text>
                    <Text style={[styles.textTable2, {lineHeight: 1, textAlign: "left"}]}>Qty: Current Stock</Text>
                    <Text style={[styles.textTable2, {lineHeight: 1, textAlign: "left"}]}>Min: Minimum Stock</Text>
                    <Text style={[styles.textTable2, {lineHeight: 1, textAlign: "left"}]}>Max: Maximum Stock</Text>
                    <Text style={[styles.textTable2, {lineHeight: 1, textAlign: "left"}]}>PO:  Purchase Order Quantity</Text>
                    <Text style={[styles.textTable2, {lineHeight: 1, textAlign: "left"}]}>WA: Estimated Week Arrival</Text>

                </View>

                <Text style={styles.footer} fixed>This order is computer generated, no signature required</Text>
            </Page>
        </Document>
    );
};

export default ContentPDF;