import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { useMediaQuery } from "react-responsive"

import {
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Image, MenuDivider, Text, VStack, HStack, Badge,
} from "@chakra-ui/react";

import Axios from "../../../services/api/Axios";
import Cookies from "js-cookie";

import RestoreIcon from "../../../assets/img/restore.svg";

const NavbarRestore = (props) => {
    const history = {
        accessToken : Cookies.get("accessToken"),
        role : Cookies.get("role"),
        listRestoreURL : "/outgoing/returnList?is_returned=false&sediaan_id=" + Cookies.get("idServing") + "&count=1",
        navigate : useNavigate(),
    }

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" })

    const [dataInitialization,setDataInitialization] = useState(false);

    const [restoreItems, setRestoreItems] = useState([]);
    const [restoreCount, setRestoreCount] = useState(0);

    const handleRestore = async () => {
        if (history.role !== "Teknisi") {
            try{
                let getDataRestore = await Axios.get(history.listRestoreURL,
                    {
                        headers: {'Authorization': 'Bearer ' + history.accessToken},
                    });
                
                setRestoreCount(getDataRestore.data?.data?.returnList?.length);
                setRestoreItems(getDataRestore.data?.data?.returnList);
            }
            catch(error){
                setRestoreItems([]);
            }
        }
    }

    useEffect(() => {
        setInterval(handleRestore, 10000);
    }, [])

    useEffect(() => {
        if (props.props.changeServing) handleRestore().then(() => props.props.setChangeServing(false));
        if (!dataInitialization) handleRestore().then(() => setDataInitialization(true));
    }, [props.props.changeServing])

    return (
        <Menu autoSelect={false}>
            <MenuButton
                as={Button}
                className={"mx-4"}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
            >
                <HStack>
                    <Badge visibility={restoreCount === 0 ? "hidden" : "visible"} borderRadius='md' bg={"orange.400"} color={"white"}>{restoreCount}</Badge>
                    <Image src={RestoreIcon} alt={"box"} />
                </HStack>
            </MenuButton>
            <MenuList maxW={isTabletOrMobile ? "40px" : ''}>
                <MenuItem isDisabled={true} _disabled={{
                    color: 'rgb(50, 50, 50)'
                }}>
                    {restoreCount !== 0
                        ?
                        <>
                            <HStack spacing={1}>
                                <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                    You have
                                </Text>
                                <Text fontSize={"lg"} fontWeight={"bold"} color={"orange.500"}>
                                    {restoreCount}
                                </Text>
                                <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                                    item{restoreCount > 1 ? "s" : ''} waiting to be restored!
                                </Text>
                            </HStack>

                            <MenuDivider borderColor={process.env.REACT_APP_TEXT_COLOR_BOLD} borderBottom={"2px double"}/>
                        </>
                        :
                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                            You don't have any item to be restored!
                        </Text>
                    }
                </MenuItem>

                {restoreItems?.map((restoreItem, index) => (
                    <Fragment key={restoreItem.id}>
                        <MenuItem onClick={() => history.navigate("/history/restore")}>
                            <VStack alignItems={"start"}>
                                <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{restoreItem.part_code}</Text>

                                <HStack>
                                    <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{restoreItem?.sparepart?.part_name} |</Text>
                                    <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{restoreItem?.sparepart?.type} |</Text>
                                    <Text fontSize={"md"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>{restoreItem?.sparepart?.brand}</Text>
                                </HStack>
                                <HStack spacing={1}>
                                    <Text fontSize={"md"} fontWeight={"bold"} color={"orange.500"}>{restoreItem.qty_return} {restoreItem?.sparepart?.qty_uom}</Text>
                                    <Text fontSize={"md"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_BOLD}>returned (out of {restoreItem.qty_receive} {restoreItem?.sparepart?.qty_uom})</Text>
                                </HStack>
                            </VStack>
                        </MenuItem>

                        <MenuDivider borderColor={process.env.REACT_APP_TEXT_COLOR_BOLD} borderBottom={index !== restoreItems.length -1 ? '1px solid' : "2px double"}/>
                    </Fragment>
                ))}
                {restoreCount !== 0
                    ?
                    <MenuItem onClick={() => history.navigate("/history/restore")}>
                        <Text fontSize={"lg"} fontWeight={"bold"} color={process.env.REACT_APP_TEXT_COLOR_LIGHT}>
                            View all
                        </Text>
                    </MenuItem>
                    :
                    ''
                }
            </MenuList>
        </Menu>
    );
}

export default NavbarRestore;