import React, {useEffect} from "react";

import {Container, Box} from "@chakra-ui/react";

import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Sidebar from "./Sidebar";
import ProfileSettingRightSideProfile from "./Form";
import {useMediaQuery} from "react-responsive";

const Profile = (props) => {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" })

    useEffect(() => {
    }, []);

    return (
        <>
            <Navbar />
            <Container maxW={"full"} paddingLeft={"25%"} paddingRight={"0%"} paddingBottom={"0%"}>
                <Box position={"fixed"} left={'0'} w={"25%"}>
                    <Sidebar />
                </Box>
                <Box bg={"#F3F8F8"} height={"90vh"} ml={!isTabletOrMobile ? '' : '4vw'}>
                    <ProfileSettingRightSideProfile props={props}/>
                </Box>
            </Container>
            <Footer />
        </>
    );
}

export default Profile;
