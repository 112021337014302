import {Grid, Box, Container, Text} from '@chakra-ui/react'
import { React, useState, useEffect} from 'react';

import HeroSparepartListIcon from "../../../assets/icons/hero/HomePage/Hero_sparepart.svg";
import HeroRequestIcon from "../../../assets/icons/hero/HomePage/Hero_request.svg";
import HeroOutgoingIcon from "../../../assets/icons/hero/HomePage/Hero_outgoing.svg";
import HeroReorderPointIcon from "../../../assets/icons/hero/HomePage/Hero_reorder_point.svg";
import HeroIcomingIcon from "../../../assets/icons/hero/HomePage/Hero_incoming.svg";
import HeroBinStorageIcon from "../../../assets/icons/hero/HomePage/Hero_bin_storage.svg";
import HeroHistoryIcon from "../../../assets/icons/hero/HomePage/Hero_history.svg";
import HeroAssetManagementIcon from "../../../assets/icons/hero/HomePage/Hero_asset_management.svg";
import HeroUserManagementIcon from "../../../assets/icons/hero/HomePage/Hero_user_management.svg";
import HeroDashboardIcon from "../../../assets/icons/hero/HomePage/Hero_dashboard.svg";

import HeroMenus from './Homepage_HeroMenus';
import axios from 'axios';
import Cookies from "js-cookie";

import { baseURL } from '../../../services/api/Axios';
import {useMediaQuery} from "react-responsive";

const Hero_Homepage = (props) => {
    const [privilege, setPrivilege] = useState({});

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" });

    let role =  Cookies.get("role") === "Teknisi" ? "User" : Cookies.get("role");


    function getCookie(key) {
        var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    }

    async function getPrivilege(){
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getCookie("accessToken")
            },
        }
        const res = await axios.get(baseURL+"/user", config)
        setPrivilege(res.data.data.user.userRole.meta.menu)
    }

    useEffect(() => {
        getPrivilege()
    }, [])

    return (
        <Container maxW={"full"} paddingTop={"2vh"}>
            <div className='pb-[10vh]'>
                <Text className="text-3xl" textColor={process.env.REACT_APP_TEXT_COLOR_BOLD}>Welcome, <b>{Cookies.get("name") ? Cookies.get("name") : "Guest"}</b></Text>
                <Text className='text-2xl' textColor={process.env.REACT_APP_TEXT_COLOR_LIGHT}>{Cookies.get("role") === "Teknisi" ? "User" : Cookies.get("role") && props.currentServing ? (Cookies.get("role") === "Teknisi" ? "User" : Cookies.get("role") + " Warehouse " + props.currentServing.charAt(0).toUpperCase() + props.currentServing.slice(1)) : "WPM Warehouse" }</Text>
            </div>
            <Grid templateColumns={!isTabletOrMobile ? "repeat(5, 1fr)" : "repeat(2, 1fr)"} gap={12}>
                <Box hidden={!privilege["sparepartList"]}>
                    <HeroMenus icon={HeroSparepartListIcon} title="Item List" path={"/item/list"} />
                </Box>
                <Box hidden={!privilege["request"]} w={"full"}>
                    <HeroMenus icon={HeroRequestIcon} title="Request" path={"/request/list"}/>
                </Box>
                <Box hidden={!privilege["outgoing"]}>
                    <HeroMenus icon={HeroOutgoingIcon} title="Outgoing" path={"/outgoing/list"}/>
                </Box>
                <Box hidden={!privilege["reorderPoint"]}>
                    <HeroMenus icon={HeroReorderPointIcon} title="Reorder Point" path={"/reOrderPoint/list"}/>
                </Box>
                <Box hidden={!privilege["incoming"]}>
                    <HeroMenus icon={HeroIcomingIcon} title="Incoming" path={"/incoming/list"}/>
                </Box>

                <Box hidden={!privilege["binStorage"]}>
                    <HeroMenus icon={HeroBinStorageIcon} title="Bin Storage" path={"/binStorage/list"}/>
                </Box>
                <Box hidden={!privilege["history"]}>
                    <HeroMenus icon={HeroHistoryIcon} title="History" path={"/history/listRequest"}/>
                </Box>
                <Box hidden={!privilege["assetManagement"]}>
                    <HeroMenus icon={HeroAssetManagementIcon} title="Asset Management" path={"/assetManagement/listDepartment"}/>
                </Box>
                <Box hidden={!privilege["userManagement"]}>
                    <HeroMenus icon={HeroUserManagementIcon} title="User Management" path={"/userManagement/list"}/>
                </Box>
                <Box hidden={!privilege["dashboard"]}>
                    <HeroMenus icon={HeroDashboardIcon} title="Dashboard" path={"/dashboard/list"}/>
                </Box>
            </Grid>
        </Container>
        
    );
};
export default Hero_Homepage;