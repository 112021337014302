import { useNavigate } from "react-router-dom";

import {
    Flex,
    Spacer,
    Image,
    Text,
    Input,
    InputGroup,
} from "@chakra-ui/react";

import HeaderIcon from "../../assets/icons/hero/HomePage/Hero_sparepart.svg";

const SubHeader = () => {
    const navigate = useNavigate();

    return (
        <Flex>
            <Flex cursor={"pointer"} onClick={() => navigate("/item/list")}>
                <Image boxSize={"60px"} src={HeaderIcon}/>
                <Text paddingTop={"2"} paddingLeft={"5"} fontSize={"3xl"} color={process.env.REACT_APP_TEXT_COLOR_BOLD} _hover={{color : process.env.REACT_APP_CHECKBOX_COLOR}}>Item List</Text>
            </Flex>

            <Spacer/>

            <InputGroup w={"20%"}>
                <Input
                    placeholder="Press Enter to Search"
                    size="lg"
                    bg="white"
                    borderRadius={"20px"}
                    color={process.env.REACT_APP_TEXT_COLOR_BOLD}
                    fontWeight={"medium"}
                    boxShadow="xl"
                    _active={{
                        borderColor: process.env.REACT_APP_BORDER_COLOR
                    }}
                    _focusVisible={{
                        zIndex: 1,
                        borderColor: process.env.REACT_APP_BORDER_COLOR,
                        boxShadow: ("0 0 0 1px " + process.env.REACT_APP_BORDER_COLOR)
                    }}
                    onKeyPress={((e) => e.key === "Enter" ? window.find(e.target.value) : '')}
                />
            </InputGroup>
        </Flex>
    );
}

export default SubHeader;